<template>
  <div
    class="bg-white shadow-md rounded-md px-3 py-4"
    :class="[order === 1 ? 'w-full' : order === 2 ? 'w-11/12 lg:w-10/12' : 'w-10/12 lg:w-8/12']"
  >
    <div class="w-full x gap-x-5 lg:items-center">
      <div class="w-9 h-full x justify-center">
        <div
          style="line-height: 36px"
          class="text-orange font-bold border border-orange rounded-full x items-center justify-center"
          :class="[
            order === 1
              ? 'w-8 h-8 lg:w-9 lg:h-9'
              : order === 2
              ? 'w-7 h-7 text-xs lg:w-8 lg:h-8 lg:text-sm'
              : 'w-6 h-6 lg:w-7 lg:h-7 text-xs',
          ]"
        >
          <span>{{ order }}</span>
        </div>
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    order: Number,
  },
};
</script>

import axios from "axios";

const getPastTrackingIdx = (trackings, tracking) => {
  return trackings.findIndex((t) => {
    return tracking.id === t.id;
  });
};

const pastTrackingsStorageKey = "__PAST_TRACKINGS__";

const trackingStore = {
  namespaced: true,
  state: {
    timer: {
      seconds: 0,
      minutes: 0,
      hours: 0,
    },
    loading: false,
    trackingsLoading: false,
    checkingCanTrack: false,
    submitting: false,
    selectedIssue: null,
    project: null,
    selectedTab: null,
    issues: [],
    trackings: [],
    canTrack: false,
    canTrackMessage: "",
    pastTrackings: [],
  },
  mutations: {
    setTimer(state, timer) {
      state.timer = timer;
    },
    setIssues(state, issues) {
      state.issues = issues;
    },
    setIssue(state, issue) {
      state.selectedIssue = issue;
    },
    setProject(state, project) {
      state.project = project;
    },
    setTrackings(state, trackings) {
      state.trackings = trackings;
    },
    setSelectedTab(state, tab) {
      state.selectedTab = tab;
    },
    setCanTrack(state, canTrack) {
      state.canTrack = canTrack;
    },
    setCanTrackMessage(state, canTrackMessage) {
      state.canTrackMessage = canTrackMessage;
    },
    setPastTracking(state, pastTrackings) {
      state.pastTrackings.splice(0);
      state.pastTrackings.push(...pastTrackings);
    },
    addPastTracking(state, tracking) {
      const idx = getPastTrackingIdx(state.pastTrackings, tracking);
      if (idx > -1) {
        state.pastTrackings[idx] = tracking;
      } else {
        state.pastTrackings.push(tracking);
      }
    },
    removePastTracking(state, tracking) {
      console.log(tracking);
      const idx = getPastTrackingIdx(state.pastTrackings, tracking);
      state.pastTrackings.splice(idx, 1);
    },
  },
  getters: {
    trackings: (state) => {
      if (state.selectedIssue)
        return state.trackings.filter(
          (t) => parseInt(t.gitlab_issue_iid, 0) === state.selectedIssue.iid,
        );
      return state.trackings;
    },
  },
  actions: {
    getProjectInfo({ commit, dispatch }) {
      commit("setTrackings", []);
      dispatch("getTrackings");
    },
    removePastTracking({ commit, dispatch }, { id }) {
      commit("removePastTracking", { id });
      dispatch("savePastTrackings");
    },
    savePastTracking(
      { commit, dispatch },
      // { project_id, issue_iid, comment, timer, title, total_spent_time, estimate_time },
      data,
    ) {
      commit("addPastTracking", data);
      dispatch("savePastTrackings");
    },
    savePastTrackings({ state }) {
      localStorage.setItem(pastTrackingsStorageKey, JSON.stringify(state.pastTrackings));
    },
    fetchPastTrackings({ commit }) {
      const data = localStorage.getItem(pastTrackingsStorageKey);
      if (data) {
        commit("setPastTracking", JSON.parse(data));
      }
    },
    async saveTracking({ state }, { projectId, issueIid, timer, comment, date }) {
      let success = false;
      try {
        state.submitting = true;
        await axios.post(`/projects/${projectId}/issues/${issueIid}/trackings`, {
          ...timer,
          comment,
          date,
        });
        success = true;
      } catch (e) {
        console.log(e, e.response);
      } finally {
        state.submitting = false;
      }
      return success;
    },
    async getProjectAndIssues({ state, commit, dispatch }, projectPath) {
      state.issues = [];
      state.project = null;

      state.loading = true;
      try {
        const { data } = await axios.get(`/projects/${decodeURIComponent(projectPath)}`);
        commit("setProject", data);
        const issues = await axios.get(`projects/${decodeURIComponent(projectPath)}/issues`);
        commit("setIssue", null);
        commit("setIssues", issues.data);
        dispatch("getProjectInfo");
      } catch (e) {
        console.log(e);
      } finally {
        state.loading = false;
      }
    },
    async getTrackings({ state, commit, dispatch }, force = false) {
      if (state.trackings.length > 0 && !force) return;
      state.trackingsLoading = true;

      const currentCTS = await dispatch("generateNewRequestCTS", "getTrackings", { root: true });

      try {
        const url = `/projects/${state.project.id}/trackings`;

        const trackings = (
          await axios.get(url, {
            cancelToken: currentCTS.token,
          })
        ).data;
        commit("setTrackings", trackings);
      } catch (e) {
        //
      } finally {
        if (!currentCTS.token.reason) state.trackingsLoading = false;
      }
    },
    async checkCanTrack({ state, commit }) {
      state.checkingCanTrack = true;
      return axios
        .get(`/user/can-track`)
        .then(({ data }) => {
          commit("setCanTrack", data.can_track);
          commit("setCanTrackMessage", data.message);
        })
        .finally(() => {
          state.checkingCanTrack = false;
        });
    },
  },
};

export default trackingStore;

<template>
  <pop-up-modal :height="'auto'" @close="close">
    <template>
      <div class="w-full h-full">
        <div class="w-full py-3 px-4 flex justify-between y text-black leading-5 text-left">
          <h1 class="text-lg font-bold">Save issue time</h1>
          <p class="text-gray-500">set hours and minutes to be saved</p>
        </div>

        <div>
          <div
            class="w-full mb-2 px-4 flex-1 flex flex-col overflow-y-scroll justify-start scrollbar-track-rounded-md scrollbar-thin scrollbar-track-gray-300"
          >
            <div class="x gap-x-2 items-center">
              <div class="w-full y items-start mb-3">
                <div class="p-2">
                  <b>Save time</b>
                </div>
                <div class="w-full flex items-start mt-1">
                  <div class="flex-1">
                    <date-picker
                      v-model="time"
                      type="time"
                      :clearable="false"
                      valueType="format"
                      format="HH:mm"
                      class="w-full disabled:bg-gray-100"
                      placeholder="00:00"
                      popup-class="hidden-calendar"
                    ></date-picker>
                  </div>
                </div>
              </div>

              <div class="px-5 pt-5 text-center w-5/12">
                max:
                <b>{{ issue.deductible_time | secondsToHuman }}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #actionButton>
      <button-primary
        @click.native="save"
        class="px-1 self-end mb-1 text-sm py-1 transition-colors duration-200 disabled:opacity-75"
        :disabled="!isValid || loading"
        :class="{ 'bg-transparent border': !isValid }"
      >
        <span :class="{ 'text-black': !isValid }"> Set </span>
      </button-primary>
    </template>
  </pop-up-modal>
</template>

<script>
import DatePicker from "vue2-datepicker";
import ButtonPrimary from "./ButtonPrimary.vue";
import PopUpModal from "./PopUpModal.vue";
import filters from "../utils/filters";

export default {
  props: {
    issue: Object,
  },
  mounted() {
    this.time = filters.secondsToTime(this.issue.deductible_time);
  },
  components: {
    ButtonPrimary,
    PopUpModal,
    DatePicker,
  },
  data() {
    return {
      loading: false,
      time: null,
    };
  },
  methods: {
    close(didUpdate = false) {
      this.$emit("close", didUpdate);
    },
    async save() {
      this.$emit("onSubmit", filters.timeToSeconds(this.time));
      this.$emit("close");
    },
  },
  computed: {
    isValid() {
      if (!this.time) return false;
      return filters.timeToSeconds(this.time) <= this.issue.deductible_time;
    },
  },
};
</script>

<style lang="postcss">
.custom-date-picker .mx-calendar {
  width: 50vh !important;
}
.hidden-calendar {
  display: none;
}
</style>

<template>
  <div>
    <tabs-list>
      <tab-button
        v-if="query"
        :title="'Search Results'"
        :active="true"
        :badge="showedUsers.length"
        :loading="usersLoading"
      />
      <tab-button
        v-if="!query"
        @click="filter = 'active'"
        :title="'Active'"
        :active="!query && filter === 'active'"
        :badge="activeUsers.length"
        :loading="usersLoading"
      />
      <tab-button
        v-if="!query"
        @click="filter = 'inactive'"
        :title="'Inactive'"
        :active="!query && filter === 'inactive'"
        :badge="inactiveUsers.length"
        :loading="usersLoading"
      />
      <tab-button
        v-if="!query"
        @click="filter = 'external'"
        :title="'External'"
        :active="!query && filter === 'external'"
        :badge="externalUsers.length"
        :loading="usersLoading"
      />

      <template #actionButtons>
        <slot name="actionButtons" />
      </template>
    </tabs-list>

    <div v-if="!usersLoading">
      <clickable-item
        v-for="(user, idx) in showedUsers"
        :key="idx"
        :route="
          !query
            ? {
                name: 'User',
                params: { id: user.id, user },
              }
            : null
        "
      >
        <div class="w-full x justify-start p-2 s-3 items-center py-2" v-if="!query">
          <div
            v-if="user.avatar"
            class="w-9 h-9 x items-center justify-center rounded-full"
            style="border: 1px solid #f5f5f5"
          >
            <img class="rounded-full" :src="user.avatar" />
          </div>
          <span
            class="w-9 h-9 bg-gray-200 text-lg x items-center justify-center rounded-full"
            v-else
            >{{ user.name.toUpperCase()[0] }}</span
          >
          <div class="y gap-y-2 items-start flex-1">
            <div>
              <h3 class="font-bold hover:underline">
                {{ user.name }}
              </h3>
              <span class="text-sm">{{ user.username }}</span>
            </div>
          </div>
        </div>
        <div v-else>
          <project-excerpt v-if="user.type === 'projects'" :project="user" :showAssignees="true" />
          <div v-else-if="user.type === 'issues'">
            <issue-excerpt :issue="user" />
            <assignees-excerpt :assignees="user.assignees" />
          </div>
          <div v-else-if="user.type === 'users'" class="px-2 py-3">
            <p>
              Found <b>{{ query }}</b> in their <b>{{ user.name }}</b>
            </p>
            <assignees-excerpt :assignees="user.users" />
          </div>
          <div v-else-if="user.type === 'languages'" class="px-2 py-3">
            <p>
              Found <b>{{ query }}</b> in their <b>Language</b>
            </p>
            <assignees-excerpt :assignees="user.users" />
          </div>
          <div v-else-if="user.type === 'skills'" class="px-2 py-3">
            <p>
              Found <b>{{ query }}</b> in their <b>Skills</b>
            </p>
            <assignees-excerpt :assignees="user.users" />
          </div>
          <a
            v-else-if="user.type === 'issue_comments'"
            target="_blank"
            class="px-2 py-3"
            :href="`${user.issue.web_url}#note_${user.gitlab_id}`"
          >
            <p><b>Commented:</b> {{ user.body }}</p>

            <div class="y">
              <assignees-excerpt :assignees="[user.user]" />
            </div>
          </a>
        </div>
      </clickable-item>
    </div>

    <loading-excerpt-list class="mt-2" v-else />

    <no-data v-if="!usersLoading && showedUsers.length < 1" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AssigneesExcerpt from "./AssigneesExcerpt.vue";
import ClickableItem from "./ClickableItem.vue";
import IssueExcerpt from "./IssueExcerpt.vue";
import LoadingExcerptList from "./LoadingExcerptList.vue";
import NoData from "./NoData.vue";
import ProjectExcerpt from "./ProjectExcerpt.vue";
import TabButton from "./TabButton.vue";
import TabsList from "./TabsList.vue";

export default {
  components: {
    TabsList,
    TabButton,
    LoadingExcerptList,
    NoData,
    ClickableItem,
    ProjectExcerpt,
    AssigneesExcerpt,
    IssueExcerpt,
  },
  props: {
    query: String,
  },
  async mounted() {
    if (this.users.length < 1) await this.getUsers();
  },
  data() {
    return {
      filter: "active",
      values: [],
    };
  },
  methods: {
    sortUsers() {
      if (this.query) {
        const parsed = this.users.map((s) => ({ ...s.searchable, ...s }));
        this.values = parsed.filter((s) => s.type !== "users");
        const users = [];
        const groups = parsed
          .filter((s) => s.type === "users")
          .reduce(
            (g, item) => ({
              ...g,
              [this.getMatched(item)]: [...(g[this.getMatched(item)] || []), item],
            }),
            {},
          );
        for (let i = 0; i < Object.keys(groups).length; i += 1) {
          const group = Object.keys(groups)[i];
          users.push({
            type: "users",
            name: group,
            users: groups[group],
          });
        }
        this.values = users.concat(this.values);
      } else {
        this.values = [...this.users];
      }
    },
    getMatched(user) {
      if (this.getMatchedUserColumns(user)) return this.getMatchedUserColumns(user);
      return null;
    },
    getMatchedUserColumns(user) {
      const columns = {
        name: "Name",
        email: "Email",
        username: "Username",
        role_description: "Role Description",
        skills: "Skills",
        group: "Group",
        phone: "Phone",
        linkedin_username: "Linkedin Username",
        language_skills: "Language Skills",
      };
      for (let i = 0; i < Object.keys(columns).length; i += 1) {
        const element = Object.keys(columns)[i];
        if (this.checkPartialMatch(user[element], this.query)) return columns[element];
      }
      return null;
    },
    selectUser(user) {
      this.$emit("userSelected", user);
    },
    checkPartialMatch(string, match) {
      if (!string || !match) return null;
      return string.toLowerCase().includes(match.toLowerCase());
    },
    ...mapActions("userInfo", ["getUsers"]),
  },
  computed: {
    showedUsers() {
      if (this.query) {
        return this.values;
      }
      if (this.filter === "active") return this.activeUsers;
      if (this.filter === "inactive") return this.inactiveUsers;
      if (this.filter === "external") return this.externalUsers;
      return this.users;
    },
    activeUsers() {
      return this.values.filter((u) => u.state === "active");
    },
    inactiveUsers() {
      return this.values.filter((u) => u.state === "inactive");
    },
    externalUsers() {
      return this.values.filter((u) => u.state === "external");
    },
    ...mapGetters("userInfo", ["users", "usersLoading"]),
  },
  watch: {
    users() {
      this.sortUsers();
    },
  },
};
</script>

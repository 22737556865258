<template>
  <div class="relative w-min y gap-y-2 items-center">
    <div
      class="timer-bg-group bg-white rounded-full shadow-2xl w-24 h-24 lg:w-36 lg:h-36 flex items-center justify-center transition-all cursor-pointer transform origin-center hover:scale-105"
      @click="toggle"
    >
      <div
        v-if="canDelete"
        @click.stop="$emit('delete')"
        title="delete"
        class="timer-delete-icon bg-red-500 transform rotate-45 absolute z-10 top-4 right-4 p-1 leading-zero rounded-full"
      >
        <i class="isax isax-add w-6 h-6 leading-zero text-lg text-white" />
      </div>
      <arch
        class="absolute w-28 h-28 lg:w-40 lg:h-40 -left-2 -top-2"
        :type="'circle'"
        :lineWidth="6"
        :percentage="progress"
      />
      <i
        class="timer-main-icon isax transition-all duration-500 transform"
        :class="[
          timerStarted
            ? 'isax-pause5 text-3xl lg:text-5xl'
            : 'isax-arrow-right-15 text-5xl lg:text-7xl',
        ]"
        :style="{ color: timerStarted ? '#FFB380' : '#16B983' }"
      />
    </div>
    <div class="text-center">
      <input
        type="text"
        class="description-input flex-1 h-full text-lg text-center w-36 border-none outline-none"
        :class="{ disabled: readOnly }"
        :value="displayedTime"
        @blur="timerInputChanged"
        :disabled="time"
        style="padding: 0 !important; background: transparent !important"
      />
      <p class="my-0 text-xs text-gray-450" v-if="description">
        {{ description }}
      </p>
    </div>

    <reset-icon
      v-if="!readOnly"
      @click.native.stop="reset"
      title="reset"
      class="absolute right-0 bottom-7 cursor-pointer"
    />
  </div>
</template>

<script>
import Arch from "./Arch.vue";
import ResetIcon from "../../assets/icons/ResetIcon.vue";

export default {
  components: {
    Arch,
    ResetIcon,
  },
  props: {
    time: Object,
    playing: {
      type: Boolean,
    },
    description: String,
    progress: {
      type: Number,
      default: () => 0,
    },
    canDelete: Boolean,
    readOnly: Boolean,
  },
  data() {
    return {
      isStopped: true,
      timerInterval: null,
      timer: {
        seconds: 0,
        minutes: 0,
        hours: 0,
      },
      date: new Date(),
    };
  },
  beforeDestroy() {
    if (this.timerInterval) clearInterval(this.timerInterval);
  },
  methods: {
    toggle(event) {
      if (this.time) {
        return;
      }
      event.stopPropagation();

      if (this.isStopped) {
        this.start();
      } else {
        this.stop();
      }
    },
    increaseTimer() {
      const now = new Date();
      Object.assign(this.timer, this.msToHMS(now - this.date));
    },
    start() {
      this.date = new Date(
        new Date().getTime() -
          this.HMSToMs(this.timer.hours, this.timer.minutes, this.timer.seconds),
      );
      this.timerInterval = setInterval(() => {
        this.increaseTimer();
        this.$nextTick(() => {
          this.notifiyTimerChanged();
        });
      }, 1000);
      this.isStopped = false;
      this.$emit("started");
    },
    stop() {
      if (this.timerInterval) clearInterval(this.timerInterval);
      this.isStopped = true;
      this.$emit("stopped");
    },
    reset() {
      this.stop();
      Object.assign(this.timer, {
        seconds: 0,
        minutes: 0,
        hours: 0,
      });
      this.date = new Date();
    },
    timerInputChanged(e) {
      this.stop();
      this.manuallySetTimer(...e.target.value.replace(/[HMS]/g, "").split(":"));
    },
    manuallySetTimer(hours, minutes, seconds) {
      Object.assign(this.timer, {
        seconds: Number(seconds),
        minutes: Number(minutes),
        hours: Number(hours),
      });
      this.$nextTick(() => {
        this.notifiyTimerChanged();
      });
    },
    msToHMS(ms) {
      let seconds = ms / 1000;
      const hours = Math.floor(parseInt(seconds / 3600, 10));
      seconds %= 3600;
      const minutes = Math.floor(parseInt(seconds / 60, 10));
      seconds = Math.floor(seconds % 60);
      return { hours, minutes, seconds };
    },
    HMSToMs(h, m, s) {
      return h * 60 * 60 * 1000 + m * 60 * 1000 + s * 1000;
    },
    notifiyTimerChanged() {
      this.$emit("timerChanged", this.timer);
    },
    getDisplayTime(timer) {
      const time = `${String(timer.hours).padStart(2, "0")}H:${String(timer.minutes).padStart(
        2,
        "0",
      )}M:${String(timer.seconds).padStart(2, "0")}S`;
      return time;
    },
  },
  computed: {
    timerStarted() {
      if (this.time) {
        return this.playing;
      }

      return !this.isStopped;
    },
    displayedTime() {
      if (this.time) {
        return this.getDisplayTime(this.time);
      }

      return this.getDisplayTime(this.timer);
    },
  },
};
</script>

<style>
.description-input {
  @apply text-black font-semibold;
}
.description-input.disabled {
  @apply text-gray-450;
}
.timer-delete-icon {
  @apply hidden;
}
.timer-bg-group:hover > .timer-delete-icon {
  @apply block;
}
.timer-bg-group:hover > .timer-main-icon {
  @apply rotate-full;
}
</style>

<template>
  <div class="x pb-1 text-xs text-gray-500 border-b border-gray-200">
    <p class="x s-2 flex-wrap md:flex-nowrap leading-6">
      <span v-for="(link, index) in links" :key="link.url" class="x s-2 items-center">
        <i v-if="index !== 0" class="isax isax-arrow-right-3 text-xs" />
        <span class="hover:underline cursor-pointer" @click="$router.push(link.url)">{{
          link.name
        }}</span>
      </span>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    links: Array,
  },
};
</script>

<template>
  <thanks-giving-bonus-container :order="order">
    <div class="w-full lg:x y lg:items-center gap-5">
      <label class="whitespace-pre-line lg:whitespace-nowrap font-bold w-full lg:w-1/2" :for="id">
        <slot />
      </label>

      <v-select
        class="selector w-full"
        :options="selectableUsers"
        :reduce="(u) => u.id"
        label="name"
        :value="value"
        @input="(val) => update(val)"
        placeholder="Select a user..."
        :clearable="true"
        :id="id"
      >
        <template #option="user">
          <user-excerpt :classes="'justify-start'" :user="user" />
        </template>
      </v-select>
    </div>
  </thanks-giving-bonus-container>
</template>

<script>
import { mapGetters } from "vuex";
import ThanksGivingBonusContainer from "./ThanksGivingBonusContainer.vue";
import UserExcerpt from "./UserExcerpt.vue";

export default {
  components: {
    ThanksGivingBonusContainer,
    UserExcerpt,
  },
  props: {
    value: Number,
    order: Number,
    selectedUsers: {
      type: Array,
      default: () => [],
    },
    id: {
      type: [String, Number],
      default: () => new Date().getTime(),
    },
  },
  methods: {
    update(value) {
      this.$emit("input", value);
    },
  },
  computed: {
    selectableUsers() {
      return this.users.filter(
        (u) =>
          u.state === "active" &&
          (u.id === this.value ||
            (!this.selectedUsers.includes(u.id) && u.id !== this.$store.state.user.id)),
      );
    },
    ...mapGetters("userInfo", ["users"]),
  },
};
</script>

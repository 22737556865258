<template>
  <div class="relative w-min y gap-y-2 items-center">
    <div
      :class="
        'group bg-white rounded-full shadow-2xl ' +
        widthClass +
        ' flex items-center justify-center transition-all cursor-pointer transform origin-center hover:scale-105'
      "
    >
      <slot
        :iconClass="'isax text-3xl lg:text-5xl transition-all duration-500 transform group-hover:rotate-full'"
      />
    </div>
    <div class="text-center">
      <slot name="title" />
    </div>
    <slot name="after" />
  </div>
</template>

<script>
export default {
  props: {
    widthClass: {
      type: String,
      default: () => "w-24 h-24 lg:w-36 lg:h-36",
    },
  },
};
</script>

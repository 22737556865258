<template>
  <div class="y items-center w-full">
    <main class="y p-3 s-6 max-w-screen-2xl w-full h-full">
      <!-- Breadcrumb -->
      <breadcrumb :links="breadcrumbLinks" />
      <projects-list
        :loading="projectsLoading && projectsFilters.page === 1"
        :projects="projects"
        @update="getData"
        :showAssignees="!isUserRoute"
        :showMyRole="isUserRoute"
        :paginationLoading="projectsLoading && projectsFilters.page > 1"
        @loadMore="getProjects"
        :disablePagination="isUserRoute || projectsFilters.reachedEnd"
        :badges="projectsCount"
      />
    </main>
  </div>
</template>

<script lang="ts">
import { computed } from "@vue/composition-api";
import "vue2-datepicker/index.css";
import { mapActions, mapState } from "vuex";
import Breadcrumb from "../components/Breadcrumb.vue";
import ProjectsList from "../components/ProjectsList.vue";
import useLoadUserInfo from "../compostions/useLoadUserInfo";

export default {
  name: "TrackingGoals",
  components: {
    Breadcrumb,
    ProjectsList,
  },
  data() {
    return {
      breadcrumbLink: [],
    };
  },
  setup(props, context) {
    const isUserRoute = computed(() => context.root.$route.name === "User Projects");
    const data = { isUserRoute };

    if (isUserRoute.value) {
      const { user, userLoading } = useLoadUserInfo({ context });
      Object.assign(data, {
        user,
        userLoading,
        isUserRoute,
      });
    }

    return data;
  },
  mounted() {
    if (this.isUserRoute) this.changeUser({ id: this.$route.params.id });
    this.getData();
  },
  methods: {
    getData() {
      if (this.isUserRoute) this.getUserProjects();
      else this.getAllProjects();
    },
    ...mapActions("userInfo", ["changeUser", "getUserProjects", "getAllProjects", "getProjects"]),
  },
  computed: {
    breadcrumbLinks() {
      if (this.isUserRoute) {
        return [
          { name: "Tracking", url: "/" },
          { name: "Users", url: "/users" },
          {
            name: this.user ? this.user.username : this.$route.params.id,
            url: `/users/${this.$route.params.id}/user`,
          },
          { name: "Projects", url: `/projects/users/${this.$route.params.id}` },
        ];
      }

      return [
        { name: "Tracking", url: "/" },
        { name: "Overview", url: "/overview" },
        { name: "Projects", url: "/overview/all-projects" },
      ];
    },
    ...mapState("userInfo", ["projects", "projectsLoading", "projectsFilters", "projectsCount"]),
  },
};
</script>

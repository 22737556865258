<template>
  <div class="y items-center w-full flex-1">
    <main class="y p-3 max-w-screen-2xl w-full h-full">
      <!-- Breadcrumb -->
      <breadcrumb
        :links="[
          { name: 'Tracking', url: '/' },
          { name: 'Gantt Chart', url: '/gantt' },
        ]"
      />
      <!-- Tabs -->
      <div class="x justify-between border-b border-gray-200">
        <div class="x pt-4 text-gray-500">
          <tab-button
            :title="'All milestones'"
            style="font-size: 14px !important"
            :active="true"
            :badge="ganttTasks.length"
          />
        </div>

        <div class="hidden md:x gap-x-2 items-end mt-4 pb-2">
          <div class="flex flex-col">
            <select
              v-model="filters.state"
              @change="checkSelectedAssignee"
              placeholder="Filter By state"
            >
              <option value="active">Display only active</option>
              <option value="">Display All</option>
            </select>
          </div>

          <div class="x">
            <select id="assignee" v-model="filters.assignee" placeholder="Filter By Assignee">
              <option :value="null">All</option>
              <option
                :value="user.gitlab_id"
                v-for="user of showedFilterUsers"
                v-bind:key="user.gitlab_id"
              >
                {{ user.name }}
              </option>
            </select>
          </div>

          <input
            class="mr-3"
            type="search"
            name="filter"
            id="filter"
            placeholder="Search or filter results..."
            v-model="filters.search"
          />

          <button-primary class="py-2.5">
            <a :href="`${$gitlabHost}/groups/weborigo/-/milestones`" target="_blank"
              >Go to Milestones</a
            >
          </button-primary>

          <button-primary
            v-if="isOwner"
            class="mx-2 disabled:bg-opacity-75 py-2.5"
            @click.native="fetchAllIssues"
            :disabled="fetchingIssues"
          >
            Refresh
          </button-primary>
        </div>
      </div>
      <!-- Gantt -->
      <gantt class="left-container flex-grow" ref="gantt"></gantt>
    </main>
  </div>
</template>

<script lang="ts">
import { mapGetters } from "vuex";
import Breadcrumb from "../components/Breadcrumb.vue";
import ButtonPrimary from "../components/ButtonPrimary.vue";
import Gantt from "../components/Gantt.vue";
import TabButton from "../components/TabButton.vue";
import alerts from "../utils/alerts";

export default {
  name: "App",
  components: {
    ButtonPrimary,
    Breadcrumb,
    Gantt,
    TabButton,
  },
  data() {
    return {
      fetchingIssues: false,
    };
  },
  mounted() {},
  methods: {
    async fetchAllIssues() {
      this.fetchingIssues = true;
      try {
        await this.axios.get("fetch/issues");
        window.Echo.private(`fetching`).listen(".issuesFetched", (data) => {
          alerts.showMessage(data.data.message, "issues", data.data.status !== "success");
          this.fetchingIssues = false;
          window.Echo.private("fetching").stopListening(".issuesFetched");
        });
      } catch (e) {
        this.fetchingIssues = false;
      }
    },
    checkSelectedAssignee() {
      const isSelectedInUsers = this.showedFilterUsers.some(
        (user) => user.gitlab_id === this.filters.assignee,
      );
      if (!isSelectedInUsers) this.filters.assignee = null;
    },
  },
  computed: {
    filters: {
      get() {
        return this.$store.state.gantt.filters;
      },
      set(val) {
        this.$store.commit("gantt/setFilters", val);
      },
    },
    isOwner() {
      return this.$store.state.user && this.$store.state.user.access_level === 50;
    },
    showedFilterUsers() {
      if (this.filters.state === "active")
        return this.filterUsers.filter((user) =>
          this.ganttTasks.some(
            (task) =>
              // is issue
              task.id.split("-")[0] === "i" &&
              task.state !== "closed" &&
              task.assignees.some((assignee) => assignee.gitlab_id === user.gitlab_id),
          ),
        );
      return this.filterUsers;
    },
    ...mapGetters("gantt", ["ganttTasks", "filterUsers"]),
  },
};
</script>

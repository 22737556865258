<template>
  <div class="y gap-y-5">
    <loading-excerpt v-for="(i, key) in length" v-bind:key="key" />
  </div>
</template>

<script lang="ts">
import LoadingExcerpt from "./LoadingExcerpt.vue";

export default {
  components: { LoadingExcerpt },
  props: {
    length: {
      type: Number,
      default: () => 5,
    },
  },
};
</script>

<template>
  <pop-up-modal @close="close" :height="'auto'">
    <template>
      <div class="px-4 py-3 w-full h-full">
        <div class="w-full pb-3 flex justify-between y text-black leading-5">
          <h1 class="text-lg font-bold">{{ isEdit ? "Edit" : "Create" }} Todo</h1>
          <p class="text-gray-500">{{ isEdit ? "Edit" : "Create" }} a normal or recurring todo</p>
        </div>

        <div class="w-full flex-1 flex flex-col justify-start pb-3">
          <div class="w-full y items-start mb-3">
            <div class="p-2">
              <b>Write your todo(s)</b>
            </div>
            <textarea
              placeholder="Write todo description..."
              v-model="editedTodo.body"
              class="w-full border rounded-md p-1 h-36"
            >
            </textarea>
          </div>

          <div class="w-full y items-start">
            <div class="p-2">
              <b>Pirority</b>
            </div>
            <v-select
              placeholder="Select a pirority"
              :options="priorities"
              v-model="editedTodo.priority"
              class="chooser h-12 w-full mb-4 rounded"
            />
          </div>

          <div class="w-full y items-start mb-3">
            <div class="p-2">
              <b>Is recurring?</b>
            </div>
            <toggle-button :label="'is_recurring'" class="ml-2" v-model="editedTodo.is_recurring" />
          </div>

          <div class="w-full y items-start" v-if="!editedTodo.is_recurring">
            <div class="p-2">
              <b>Choose a Due Date</b>
            </div>
            <date-picker
              v-model="editedTodo.due_date"
              valueType="format"
              format="YYYY-MM-DD"
              type="date"
              class="w-full"
              placeholder="Choose a date..."
            ></date-picker>
          </div>

          <div class="w-full y items-start overflow-y-visible" v-else>
            <div class="p-2">
              <b>The todo occurs every?</b>
            </div>
            <v-select
              placeholder="Select a unit"
              label="title"
              :options="periods"
              :reduce="(p) => p.value"
              v-model="editedTodo.recurring_type"
              class="chooser h-12 w-full mb-4 rounded"
            />
          </div>

          <div class="x" v-if="editedTodo.is_recurring">
            <div class="w-full y items-start" v-if="editedTodo.recurring_type === 'weekly'">
              <div class="p-2">
                <b>The todo will be generated every?</b>
              </div>
              <v-select
                placeholder="Select a day"
                label="title"
                multiple
                :options="daysOfWeek"
                v-model="editedTodo.recurring_day"
                :reduce="(d) => d.value"
                class="chooser h-12 w-full mb-4 rounded"
              />
            </div>

            <div class="w-full y items-start" v-if="editedTodo.recurring_type === 'monthly'">
              <div class="p-2">
                <b>The todo will be generated on day?</b>
              </div>
              <v-select
                placeholder="Select a day"
                :options="daysOfMonth"
                v-model="editedTodo.recurring_day[0]"
                class="chooser h-12 w-full mb-4 rounded"
              />
            </div>

            <div class="w-full y items-start mb-3" v-if="editedTodo.recurring_type">
              <div class="p-2">
                <b>Active the recurring?</b>
              </div>
              <toggle-button
                class="ml-2"
                :label="'is_recurring_active'"
                v-model="editedTodo.is_recurring_active"
              />
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #actionButton>
      <button-primary
        @click.native="save"
        class="px-1 self-end mb-1 text-sm py-1 transition-colors duration-200 disabled:opacity-80"
        :disabled="!isValid || loading"
      >
        {{ isEdit ? "Update" : "Create" }} Todo
      </button-primary>
    </template>
  </pop-up-modal>
</template>

<script>
import { mapGetters } from "vuex";
import DatePicker from "vue2-datepicker";
import { DateTime } from "luxon";
import ButtonPrimary from "./ButtonPrimary.vue";
import PopUpModal from "./PopUpModal.vue";
import ToggleButton from "./ToggleButton.vue";

export default {
  mounted() {
    let recurringDay = [];
    if (Array.isArray(this.todo.recurring_day)) {
      recurringDay = [...this.todo.recurring_day];
    } else if (typeof this.todo.recurring_day === "number") {
      recurringDay[0] = this.todo.recurring_day;
    }
    Object.assign(this.editedTodo, {
      ...this.todo,
      is_recurring: !!this.todo.is_recurring,
      is_recurring_active: !!this.todo.is_recurring_active,
      recurring_day: recurringDay,
      due_date: this.todo.due_date
        ? DateTime.fromISO(this.todo.due_date).toFormat("kkkk-MM-dd")
        : null,
    });
    if (this.todo.id) {
      this.isEdit = true;
    }

    this.daysOfMonth = Array.from({ length: 31 }, (_, i) => i + 1);
  },
  components: {
    ButtonPrimary,
    PopUpModal,
    DatePicker,
    ToggleButton,
  },
  props: {
    todo: {
      type: Object,
      default: () => ({}),
    },
    userId: {
      type: String,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      isEdit: false,
      editedTodo: {
        body: "",
        due_date: null,
        is_recurring: false,
        recurring_type: null,
        is_recurring_active: true,
        recurring_day: null,
        priority: "Normal",
      },
      periods: [
        {
          title: "Day",
          value: "daily",
        },
        {
          title: "Week",
          value: "weekly",
        },
        {
          title: "Month",
          value: "monthly",
        },
      ],
      daysOfWeek: [
        {
          title: "Sunday",
          value: 0,
        },
        {
          title: "Monday",
          value: 1,
        },
        {
          title: "Tuesday",
          value: 2,
        },
        {
          title: "Wednesday",
          value: 3,
        },
        {
          title: "Thursday",
          value: 4,
        },
        {
          title: "Friday",
          value: 5,
        },
        {
          title: "Saturday",
          value: 6,
        },
      ],
      daysOfMonth: [],
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ align: [] }],
            ["link"],
          ],
        },
      },
    };
  },
  methods: {
    close(didUpdate = false) {
      this.$emit("close", didUpdate);
    },
    async save() {
      if (!this.isValid) return;
      this.loading = true;
      try {
        const addUrl = `/todos`;
        const updateUrl = `${addUrl}/${this.editedTodo.id}`;
        if (this.isEdit) await this.axios.put(updateUrl, this.editedTodo);
        else
          await this.axios.post(addUrl, {
            ...this.editedTodo,
            user_id: this.userId,
          });
        this.close(true);
      } catch (e) {
        //
      } finally {
        this.loading = false;
      }
    },
    beforeToday(date) {
      return DateTime.fromJSDate(date) <= DateTime.now().minus({ days: 1 });
    },
  },
  computed: {
    isValid() {
      return (
        this.editedTodo.body != null &&
        this.editedTodo.body.length > 0 &&
        this.isRecurringFieldsValid
      );
    },
    isRecurringFieldsValid() {
      if (this.editedTodo.is_recurring && !this.editedTodo.recurring_type) return false;
      if (
        (this.editedTodo.recurring_type === "weekly" ||
          this.editedTodo.recurring_type === "monthly") &&
        this.editedTodo.recurring_day === null
      )
        return false;
      return true;
    },
    ...mapGetters("userInfo", {
      priorities: "todosPriorities",
    }),
  },
};
</script>

<style></style>

<template>
  <div class="flex items-center">
    <div
      class="flex relative items-center h-3.5 w-3.5 p-0.5 justify-center border border-red-300 rounded-sm"
    >
      <transition name="check">
        <div class="absolute inset-0.5 bg-red-300 z-sticky rounded-sm" v-if="checked"></div>
      </transition>
      <input
        :id="label"
        type="checkbox"
        :checked="checked"
        :disabled="disabled"
        @change="onChange"
        class="form-checkbox relative z-fixed focus:ring-brandLight h-3.5 w-3.5 text-brand border-gray-300 rounded"
      />
    </div>
    <div class="ml-3 text-xs y">
      <label :for="label" class="text-gray-700">{{ label }}</label>
      <span class="text-gray-500">{{ description }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    checked: {
      type: Boolean,
      default: () => false,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    label: {
      type: String,
      default: () => "",
    },
    description: {
      type: String,
      default: () => "",
    },
  },
  methods: {
    onChange(val) {
      this.$emit("change", val);
    },
  },
};
</script>

<style scoped>
.form-checkbox {
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  position: relative;
  cursor: pointer;
}

.check-enter-active,
.check-leave-active {
  transition: opacity 0.2s;
}
.check-enter, .check-leave-to /* .check-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

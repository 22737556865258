<template>
  <pop-up-modal :height="'auto'" :width="'auto'" @close="$emit('close')" :hideTooltip="true">
    <div class="w-full h-auto">
      <div class="w-full border-b p-3 px-3 flex justify-between x text-black leading-5">
        <div class="y justify-between">
          <h1 class="text-lg font-bold">Tracking Report</h1>
          <p class="text-gray-500">Tracked times by user</p>
        </div>
        <i class="isax isax-close-circle text-xl cursor-pointer" @click="$emit('close')" />
      </div>
      <div class="w-full">
        <div class="overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table
            class="body-scroll w-full"
            style="color: rgba(121, 121, 121); min-width: 500px"
            v-if="user"
          >
            <thead class="">
              <tr class="bg-gray-100">
                <th class="p-3 whitespace-nowrap">Spent At</th>
                <th class="p-3 whitespace-nowrap">User</th>
                <th class="p-3 whitespace-nowrap">Time Spent</th>
                <th class="p-3 whitespace-nowrap">Summary/Note</th>
              </tr>
            </thead>

            <tbody
              v-if="userTrackings.length > 0"
              class="divide-y divide-gray-200 scrollbar-thin scrollbar-thumb-rounded-3xl scrollbar-track-transparent scrollbar-thumb-gray-300"
              style="height: 65vh"
            >
              <tr v-for="tracking of userTrackings" v-bind:key="tracking.id">
                <th class="p-3 font-normal">{{ tracking.created_at | DateAndTime }}</th>
                <th class="p-3 font-normal">
                  {{ tracking.user ? tracking.user.name : tracking.gitlab_user.name }}
                </th>
                <th class="p-3 font-normal">{{ tracking.time_spent | parseTimeToHuman }}</th>
                <th class="p-3 font-normal">{{ tracking.comment }}</th>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td class="p-3">No Trackings found by you.</td>
              </tr>
            </tbody>

            <tfoot class="border-t border-gray-200">
              <tr class="bg-gray-100">
                <th class="p-3"></th>
                <th class="p-3"></th>
                <th class="p-3" v-if="totalUserTime">
                  {{ totalUserTime | formatSeconds | parseTimeToHuman }}
                </th>
                <th class="p-3" v-else>0h 0m</th>
                <th class="p-3">Summary/Note</th>
              </tr>
            </tfoot>
          </table>

          <table
            class="body-scroll min-w-full divide-y divide-gray-200"
            style="min-width: 500px"
            v-else
          >
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  User
                </th>
                <th
                  scope="col"
                  class="px-3 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Time Spent
                </th>
              </tr>
            </thead>
            <tbody
              v-if="trackings.length > 0"
              class="bg-white divide-y divide-gray-200 scrollbar-thin scrollbar-thumb-rounded-3xl scrollbar-track-transparent scrollbar-thumb-gray-300"
              style="height: 65vh"
            >
              <tr v-for="tracking of data" v-bind:key="tracking.id">
                <td>
                  <div class="x w-full pl-3 py-2 transform translate-x-0 gap-x-1">
                    <img
                      class="h-8 w-8 rounded-full object-cover mr-2 md:mr-3"
                      :src="tracking.avatar"
                      alt="avatar"
                    />
                    <div class="y">
                      <div class="text-sm font-medium text-gray-900">{{ tracking.name }}</div>
                      <span
                        >by
                        <a
                          :href="`${$gitlabHost}/${tracking.username}`"
                          target="_blank"
                          class="link-primary"
                          >{{ tracking.name }}</a
                        ></span
                      >
                    </div>
                  </div>
                </td>
                <td class="px-3 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                  {{ tracking.total_time | formatSeconds | parseTimeToHuman }}
                </td>
              </tr>
            </tbody>
            <tbody class="overflow-hidden" v-else>
              <tr>
                <td class="p-3">No Trackings found.</td>
              </tr>
            </tbody>
            <tfoot v-if="trackings.length > 0" class="bg-gray-50">
              <tr>
                <td
                  scope="col"
                  class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Total
                </td>
                <td
                  scope="col"
                  class="px-3 py-3 text-xs text-right font-medium text-gray-500 uppercase tracking-wider"
                >
                  {{ totalTime | formatSeconds | parseTimeToHuman }}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </pop-up-modal>
</template>

<script>
import { DateTime } from "luxon";
import PopUpModal from "./PopUpModal.vue";

export default {
  components: { PopUpModal },
  props: {
    trackings: {
      type: Array,
      default: () => [],
    },
    user: {
      type: Object,
    },
  },
  data() {
    return {
      data: [],
      totalTime: 0,
    };
  },
  mounted() {
    if (!this.user) this.groupTrackingsByUser();
  },
  methods: {
    groupTrackingsByUser() {
      const usersMap = new Map();
      for (let i = 0; i < this.trackings.length; i += 1) {
        const tracking = this.trackings[i];
        const userData = tracking.user ?? tracking.gitlab_user;
        const mapKey = JSON.stringify({
          id: userData.gitlab_id ?? userData.id,
          name: userData.name,
          username: userData.username,
          avatar: userData.avatar,
        });
        const userTotal = usersMap.get(mapKey);
        const seconds = this.timestrToSec(tracking.time_spent);
        usersMap.set(mapKey, (userTotal ?? 0) + seconds);
        this.totalTime += seconds;
      }

      this.data = Array.from(usersMap, function (item) {
        return { ...JSON.parse(item[0]), total_time: item[1] };
      });
      this.data = this.data.sort((a, b) => a.name.localeCompare(b.name));
    },
    timestrToSec(timestr) {
      const parts = timestr.split(":");
      return parts[0] * 3600 + parts[1] * 60;
    },
  },
  computed: {
    userTrackings() {
      return this.trackings.filter((t) => {
        return (t.user?.gitlab_id ?? t.gitlab_user?.gitlab_id) === this.user.gitlab_id;
      });
    },
    totalUserTime() {
      const seconds = this.userTrackings.reduce(
        (total, tracking) => total + this.timestrToSec(tracking.time_spent),
        0,
      );
      return seconds;
    },
  },
  filters: {
    formatSeconds(seconds) {
      const pad = function (num) {
        if (num < 10) {
          return `0${num}`;
        }
        return `${num}`;
      };
      return [pad(Math.floor(seconds / 3600)), pad(Math.floor(seconds / 60) % 60)].join(":");
    },
    DateAndTime(date) {
      return DateTime.fromISO(date).toHTTP();
    },
  },
};
</script>

<template>
  <svg viewBox="0 0 100 100">
    <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="-25%">
      <stop offset="0%" stop-color="#f5b384" />
      <stop offset="100%" stop-color="#cf6c84" />
    </linearGradient>
    <path
      fill="none"
      stroke-linecap="round"
      stroke="url(#gradient)"
      :stroke-width="lineWidth"
      :stroke-dasharray="dashArray"
      :stroke-dashoffset="-dashoffset"
      style="transition: stroke-dashoffset 0.5s ease-in-out"
      transform="rotate(90, 50, 50)"
      d="
            M 50, 50
            m -45, 0
            a 45,45 0 1,0 90,0
            a 45,45 0 1,0 -90,0
          "
    ></path>
  </svg>
</template>

<script>
export default {
  props: {
    percentage: {
      type: Number,
      default: () => 0,
    },
    lineWidth: {
      type: Number,
      default: () => 2,
    },
  },
  computed: {
    radius() {
      return this.width / 2;
    },
    circum() {
      return 2 * Math.PI * 45;
    },
    dashArray() {
      return 2 * Math.PI * 45;
    },
    dashoffset() {
      return this.dashArray * (1 - this.percentage);
    },
  },
};
</script>

<style></style>

export default function getProjectsFilter(hot, prop, options, selectedFilters) {
  const container = document.createElement("div");
  container.classList.add("htFiltersMenuValue");
  container.style.paddingBottom = "20px";
  const inputContainer = document.createElement("div");
  inputContainer.classList.add("htItemWrapper");
  inputContainer.innerHTML = `
    <div class="htFiltersMenuLabel">Filter by value:</div>
    <div class="htUIMultipleSelect">
      <div></div>
      <div class="htUIMultipleSelectSearch htUIInput"><input placeholder="Search" type="text">
        <div class="htUIInputIcon"></div>
      </div>
      <div class="htUISelectionControls">
        <div class="htUISelectAll"><a href="#">Select all</a></div>
        <div class="htUIClearAll"><a href="#">Clear</a></div>
      </div>
    </div>
  `;
  container.appendChild(inputContainer);

  const itemsContainer = document.createElement("div");
  itemsContainer.innerHTML = `<div class="htUIMultipleSelectHot handsontable" dir="ltr" id="ht_fdea037f695d0863"
    style="height: 110px; overflow: hidden;" data-originalstyle="height: 110px; overflow: hidden;"
    data-initialstyle="height: 110px; overflow: hidden;">
    <div class="ht_master handsontable" dir="ltr">
      <div class="wtHolder" style="position: relative; height: 110px; width: 183px; overflow-y: scroll;">
        <div class="wtHider" style="width: 164px; height: 131px;">
          <div class="wtSpreader" style="position: relative; top: 0px; left: 0px;">
            <table class="htCore" style="border: none">
              <colgroup>  
                <col class="rowHeader" style="width: 164px;">
              </colgroup>
              <thead></thead>
              <tbody>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>`;
  inputContainer.appendChild(itemsContainer);
  const tbody = itemsContainer.querySelector("tbody");

  for (let i = 0; i < options.length; i += 1) {
    const option = options[i];
    const isOptionSelected = selectedFilters.includes(`${option.value}`);
    const tr = document.createElement("tr");
    tr.innerHTML = `<td class="" title="${
      option.label
    }"><label class="htCheckboxRendererLabel fullWidth"><input
        class="htCheckboxRendererInput" type="checkbox" ${
          isOptionSelected ? "checked" : ""
        } autocomplete="off" value="${option.value}"
        data-col="0">${option.label}</label></td>`;
    tbody.appendChild(tr);
  }

  const selectAllBtn = inputContainer.querySelector(".htUISelectAll");
  const clearBtn = inputContainer.querySelector(".htUIClearAll");

  const getInputs = () => tbody.querySelectorAll("input[type=checkbox]");
  const filterCheckboxes = () => {
    const col = hot.propToCol(prop);
    const filtersPlugin = hot.getPlugin("filters");
    const ids = Array.from(getInputs())
      .filter((i) => i.checked)
      .map((i) => i.value);
    filtersPlugin.clearConditions(col);
    if (ids.length > 0) {
      filtersPlugin.addCondition(col, "by_value", [ids]);
    }
    selectedFilters.splice(0);
    selectedFilters.push(...ids);
    filtersPlugin.filter();
  };
  selectAllBtn.addEventListener("click", () => {
    getInputs().forEach((el) => {
      Object.assign(el, { checked: true });
    });
    filterCheckboxes();
  });
  clearBtn.addEventListener("click", () => {
    getInputs().forEach((el) => {
      Object.assign(el, { checked: false });
    });
    filterCheckboxes();
  });

  getInputs().forEach((input) => {
    input.addEventListener("change", filterCheckboxes);
  });

  const input = inputContainer.querySelector("input[type=text]");
  input.addEventListener("input", () => {
    const { value } = input;
    const optionRows = Array.from(itemsContainer.querySelectorAll("tbody tr"));
    optionRows.forEach((el) => {
      if (!value) {
        Object.assign(el.style, { display: "block" });
      } else if (
        el.querySelector("td").getAttribute("title").toLowerCase().indexOf(value.toLowerCase()) > -1
      ) {
        Object.assign(el.style, { display: "block" });
      } else {
        Object.assign(el.style, { display: "none" });
      }
    });
  });

  return container;
}

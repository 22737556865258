var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"y py-2.5 pl-4 1-grow hover:bg-gray-100"},[_c('a',{staticClass:"x gap-x-1 justify-between items-center hover:bg-gray-100",staticStyle:{"text-decoration":"none","color":"inherit"},attrs:{"href":_vm.todo.target_url,"target":"_blank"}},[_c('div',{staticClass:"x s-4 items-center flex-1"},[(_vm.author)?_c('avatar-icon',{attrs:{"src":_vm.author.avatar}}):_vm._e(),_c('div',{staticClass:"y"},[_c('div',{staticClass:"x gap-x-1"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.header)}}),_c('span',{staticClass:"inline-flex text-white w-auto ml-2 items-center justify-center px-2 py-1 text-xs leading-none rounded-full",staticStyle:{"line-height":"0.75em"},style:({
              'background-color': _vm.priorityBackgroundColor,
              color: _vm.priorityColor,
            })},[_vm._v("Priority: "+_vm._s(_vm.todo.priority))])]),_c('p',{staticClass:"line-clamp flex-1"},[_vm._v(" "+_vm._s(_vm.todo.body)+" ")]),(_vm.todo.recurring_parent_id)?_c('span',{staticClass:"text-xs text-gray-300 mt-1"},[_vm._v("auto generated ("+_vm._s(_vm._f("formatDate")(_vm.todo.created_at))+")")]):_vm._e()]),_c('br')],1),(_vm.showAssignees || _vm.isSupervised)?_c('assignees-excerpt',{attrs:{"assignees":_vm.assignees}}):_vm._e(),(_vm.todo.due_date)?_c('span',{staticClass:"x items-center mt-1",class:_vm.isPastDue() ? 'text-red-600' : ''},[_c('i',{staticClass:"isax isax-calendar-1 mr-1 text-xs"}),_c('time',[_vm._v(_vm._s(_vm.dueDate))])]):_vm._e(),(_vm.isAuthorized && !_vm.todo.is_recurring)?_c('div',[(!_vm.todo.is_done)?_c('button',{staticClass:"mr-1 py-2 px-3 rounded hover:bg-white",on:{"click":function($event){$event.preventDefault();
          $event.stopPropagation();
          _vm.$emit('edit', _vm.todo);}}},[_c('span',{staticClass:"text-black"},[_vm._v(" Edit ")])]):_vm._e(),(!_vm.todo.is_done)?_c('button-secondary',{nativeOn:{"click":function($event){$event.preventDefault();
          $event.stopPropagation();
          _vm.$emit('markedDone', _vm.todo);}}},[_vm._v("Done")]):_c('button-secondary',{nativeOn:{"click":function($event){$event.preventDefault();
          $event.stopPropagation();
          _vm.$emit('reopen', _vm.todo);}}},[_vm._v("Reopen")]),(_vm.canUnsupervise)?_c('button-secondary',{nativeOn:{"click":function($event){return _vm.$emit('unsupervise', _vm.todo, _vm.assignees[0])}}},[_vm._v("Close")]):_vm._e()],1):_vm._e(),(_vm.canSupervise)?_c('button-secondary',{nativeOn:{"click":function($event){return _vm.$emit('supervise', _vm.todo)}}},[_vm._v("Supervise")]):_vm._e(),(_vm.canDelete)?_c('button-secondary',{staticClass:"text-red-600",staticStyle:{"border-color":"rgb(220, 38, 38) !important","color":"rgb(220, 38, 38) !important"},nativeOn:{"click":function($event){return _vm.$emit('delete', _vm.todo)}}},[_vm._v("Delete")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
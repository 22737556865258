<template>
  <pop-up-modal :height="'auto'" @close="close">
    <template>
      <div class="w-full h-full">
        <div class="w-full py-3 px-4 flex justify-between y text-black leading-5">
          <h1 class="text-lg font-bold">{{ isEdit ? "Edit" : "Create" }} Link</h1>
          <p class="text-gray-500">Pinnable Link</p>
        </div>

        <div
          class="w-full mb-2 px-4 flex-1 flex flex-col overflow-y-scroll justify-start scrollbar-track-rounded-md scrollbar-thin scrollbar-track-gray-300"
        >
          <div class="w-full y items-start mb-3">
            <div class="p-2">
              <b>Title</b>
            </div>
            <div class="w-full flex items-start mt-1">
              <div class="flex-1">
                <input
                  v-model="editedLink.title"
                  type="text"
                  class="w-full"
                  placeholder="Enter Title"
                />
              </div>
            </div>
          </div>

          <div class="w-full y items-start mb-3">
            <div class="p-2">
              <b>Link</b>
            </div>
            <div class="w-full flex items-start mt-1">
              <div class="flex-1">
                <input v-model="editedLink.url" type="text" class="w-full" placeholder="https://" />
              </div>
            </div>
          </div>
          <!-- </div> -->
        </div>
      </div>
    </template>

    <template #actionButton>
      <button-primary
        @click.native="save"
        class="px-1 self-end mb-1 text-sm py-1 transition-colors duration-200 disabled:opacity-75"
        :disabled="!isValid || loading"
        :class="{ 'bg-transparent border': !isValid }"
      >
        <span :class="{ 'text-black': !isValid }"> {{ isEdit ? "Update" : "Create" }} Link </span>
      </button-primary>
    </template>
  </pop-up-modal>
</template>

<script>
import { mapActions } from "vuex";
import ButtonPrimary from "./ButtonPrimary.vue";
import PopUpModal from "./PopUpModal.vue";

export default {
  mounted() {
    if (this.link && this.link.id) {
      Object.assign(this.editedLink, {
        ...this.link,
      });
      this.isEdit = true;
    }
  },
  components: {
    ButtonPrimary,
    PopUpModal,
  },
  props: {
    link: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isEdit: false,
      loading: false,
      editedLink: {
        title: "",
        url: "",
      },
    };
  },
  methods: {
    close(didUpdate = false) {
      this.$emit("close", didUpdate);
    },
    async save() {
      if (!this.isValid) return;
      this.loading = true;
      try {
        if (this.isEdit) await this.updateLink(this.editedLink);
        else await this.addLink(this.editedLink);
      } catch (e) {
        //
      } finally {
        this.loading = false;
        this.close();
      }
    },
    isUrlValid(text) {
      const regexQuery =
        "^(https?://)(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$";
      const url = new RegExp(regexQuery, "i");
      return url.test(text);
    },
    ...mapActions(["addLink", "updateLink"]),
  },
  computed: {
    isValid() {
      if (!this.editedLink.title || !this.editedLink.url) return false;

      if (!this.isUrlValid(this.editedLink.url)) return false;

      return true;
    },
  },
};
</script>

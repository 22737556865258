import Vue from "vue";
import Vuex from "vuex";
import vueDebounce from "vue-debounce";
import axios from "axios";
import VueCompositionAPI from "@vue/composition-api";
import VueAxios from "vue-axios";
import VueMeta from "vue-meta";
import VueQuillEditor from "vue-quill-editor";
import vSelect from "vue-select";
import VCalendar from "v-calendar";
import { ObserveVisibility } from "vue-observe-visibility";
import Echo from "laravel-echo";
import * as Sentry from "@sentry/vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import "vue-select/dist/vue-select.css";
import "./index.css";
import alerts from "./utils/alerts";
import filters from "./utils/filters";
import getConfigsByToken from "./config/pusher.config";

store.dispatch("fetchToken");
store.dispatch("fetchTimezone");

window.Pusher = require("pusher-js");

window.Echo = new Echo(getConfigsByToken(store.getters.token));

if (store.getters.isLoggedIn) {
  store.dispatch("listenForTrackingSocket");
  store.dispatch("notifications/enableNotifications");
}

Vue.component("v-select", vSelect);
Vue.use(VCalendar);
Vue.use(VueQuillEditor);
Vue.directive("observe-visibility", ObserveVisibility);

Vue.directive("click-outside", {
  bind(el, binding, vnode) {
    // eslint-disable-next-line no-param-reassign
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
    document.body.addEventListener("touchstart", el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
    document.body.removeEventListener("touchstart", el.clickOutsideEvent);
  },
  stopProp(event) {
    event.stopPropagation();
  },
});

Vue.filter("getTimeAgo", filters.getTimeAgo);
Vue.filter("parseTimeToHuman", filters.parseTimeToHuman);
Vue.filter("secondsToHuman", filters.secondsToHuman);
Vue.filter("formatDate", filters.formatDate);
Vue.filter("formatDateTime", filters.formatDateTime);
Vue.filter("titleCase", filters.titleCase);

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_API_URL;
axios.defaults.headers.common = {
  Authorization: `Bearer ${store.getters.token}`,
  Accept: "Application/json",
};
axios.interceptors.response.use(
  (response) => {
    if (response.data.status && response.data.status === "success" && response.data.message)
      alerts.showMessage(response.data.message);
    if (response.data.status && response.data.status === "error") {
      alerts.showError(response);
      return Promise.reject(response);
    }
    return response;
  },
  (error) => {
    if (error.response && error.response.data) {
      // handle your errors here.
      alerts.showError(error.response);
      if (error.response.status === 401 && error.response.data.message) {
        if (
          error.response.data.message === "Gitlab access token expired" ||
          error.response.data.message === "Unauthenticated."
        ) {
          // will logout
          const url = error.request.responseURL;
          if (url.substring(url.lastIndexOf("/") + 1) !== "logout") {
            store.dispatch("logout");
            router.push("/auth/login");
          }
        }
      }
    }
    return Promise.reject(error);
  },
);

store.dispatch("fetchUserInfo");

Vue.use(Vuex);
Vue.use(VueCompositionAPI);
Vue.use(VueAxios, axios);
Vue.use(vueDebounce);
Vue.config.productionTip = false;

Vue.use(VueMeta, {
  keyName: "head",
});

Vue.mixin({
  computed: {
    $gitlabHost() {
      return process.env.VUE_APP_GITLAB_HOST;
    },
  },
});

Sentry.init({
  Vue,
  dsn: "https://697a4a8bc869401880019e8e763f9d5a@sentry.weborigo.eu/2",
  trackComponents: true,
  hooks: ["mount", "update", "destroy"],
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["api.dev.tracking.weborigo.eu"],
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

<template>
  <user-content
    :title="idea.title"
    :content="idea.description"
    :user="idea.user"
    :created_at="idea.created_at"
  >
    <template #footer>
      <div class="x gap-x-3 mt-3">
        <i
          class="isax isax-arrow-up-2 text-xl cursor-pointer hover:text-brand"
          :class="{ 'text-brand': idea.user_rate === 1 }"
          @click="vote(1)"
          title="upvote"
        />
        <span class="font-semibold">{{ idea.rate > 0 ? idea.rate : "" }}</span>
        <i
          class="isax isax-arrow-down-1 text-xl cursor-pointer hover:text-brand"
          :class="{ 'text-brand': idea.user_rate === 0 }"
          @click="vote(0)"
          title="downvote"
        />
      </div>
    </template>
  </user-content>
</template>

<script>
import UserContent from "./UserContent.vue";

export default {
  components: { UserContent },
  props: {
    idea: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    vote(vote) {
      if (this.$store.state.user.id === this.idea.user.id) return;
      if (vote === this.idea.user_rate) return;
      this.$emit("vote", this.idea.id, vote);
    },
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('tabs-list',{scopedSlots:_vm._u([{key:"actionButtons",fn:function(){return [_c('div',{staticClass:"x gap-x-2 flex-nowrap justify-end overflow-x-scroll md:overflow-x-hidden"},[_vm._t("actionButton")],2)]},proxy:true}],null,true)},_vm._l((_vm.tabs),function(tab){return _c('tab-button',{key:tab.key,attrs:{"size":'text-xs md:text-sm',"title":tab.title,"active":_vm.selectedTab === tab.key,"loading":tab.loading && tab.loading(),"badge":tab.badge()},on:{"click":function($event){_vm.selectedTab = tab.key}}})}),1),(
      [
        "all_issues",
        "opened_issues",
        "closed_issues",
        "non_estimtated",
        'no_due',
        "over_due" ].includes(_vm.selectedTab)
    )?_c('ul',{staticClass:"mt-0",attrs:{"role":"list"}},[(_vm.issuesLoading)?_c('loading-excerpt-list'):_c('div',{staticClass:"divide-y divide-solid"},_vm._l((_vm.showedIssues),function(issue){return _c('issue-excerpt',{key:issue.id,attrs:{"issue":issue,"labels":_vm.labels}})}),1)],1):_vm._e(),(_vm.selectedTab === "tracks")?_c('ul',{staticClass:"mt-0"},[_vm._l((5),function(x){return _c('tracking-loading-excerpt',{directives:[{name:"show",rawName:"v-show",value:(_vm.trackingsLoading),expression:"trackingsLoading"}],key:x,staticClass:"w-full"})}),_vm._l((_vm.trackings),function(track){return _c('tracking-excerpt',{directives:[{name:"show",rawName:"v-show",value:(!_vm.trackingsLoading),expression:"!trackingsLoading"}],key:track.id,attrs:{"track":track}})})],2):_vm._e(),_c('infinite-loader',{ref:"infiniteLoader",attrs:{"disabled":_vm.disablePagination,"loading":_vm.paginationLoading},on:{"loadMore":_vm.loadMore}}),(_vm.currentTab && (!_vm.currentTab.loading || !_vm.currentTab.loading()) && _vm.currentTab.badge() < 1)?_c('no-data'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="y">
    <div class="y md:x py-2.5 md:pl-4 justify-between text-gray-500 hover:bg-gray-100 flex-wrap">
      <div class="y w-full items-start flex-1">
        <div class="x items-center" style="margin-bottom: 2px">
          <h3 class="x gap-x-2 font-bold text-black">
            <pinnable
              :type="'issue'"
              :id="issue.gitlab_id ? issue.gitlab_id : issue.id"
              :modelKey="'gitlab_id'"
            >
              <a :href="issue.web_url" target="_blank">
                {{ issue.title }}
              </a>
              <span
                v-show="issue.state === 'closed'"
                class="ml-0.5 md:ml-2 items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 bg-red-500 rounded-full"
                >Closed</span
              >
            </pinnable>
          </h3>
        </div>
        <div class="y md:x md:s-3 leading-6 md:leading-normal flex-wrap">
          <span
            >#{{ issue.iid }} created
            <time :datetime="issue.created_at">{{ issue.created_at | getTimeAgo }}</time>
            by
            <a
              :href="issue.author.web_url"
              target="_blank"
              class="link-primary"
              v-if="issue.author"
              >{{ issue.author.name }}</a
            ></span
          >
          <span v-if="issue.milestone" class="x items-center">
            <i class="isax isax-clock mr-1 text-xs text-black" />
            <span :title="issue.milestone.description">{{ issue.milestone.title }}</span>
          </span>
          <div class="x flex-wrap gap-x-2">
            <span
              v-if="issue.due_date"
              class="x items-center"
              :class="isPastDue ? 'text-red-600' : ''"
            >
              <i class="isax isax-calendar-1 mr-1 text-xs" /><time :datetime="issue.due_date">{{
                issue.due_date
              }}</time>
            </span>
            <span
              v-for="(label, index) in issue.labels"
              :key="index"
              class="inline-flex w-auto ml-2 items-center justify-center px-2 py-1 text-xs leading-none rounded-full"
              style="line-height: 0.75em"
              :style="{
                'background-color': label.color,
                color: label.text_color,
              }"
              ><span :title="label.description">{{ label.name }}</span></span
            >
          </div>
        </div>
      </div>
      <div
        class="x md:y justify-around md:items-end w-full md:w-1/5 leading-10 md:leading-normal text-xs md:text-sm"
      >
        <span class="x s-3">
          <a
            v-if="issue.assignee"
            :title="`Assigned to ${issue.assignee.name}`"
            :href="`${$gitlabHost}/${issue.assignee.username}`"
            target="_blank"
            class="link-secondary"
            >{{ issue.assignee.name }}</a
          >
          <span v-else>No assignee</span>
          <a href="" target="_blank" title="Comments" class="link-secondary"
            ><i class="isax isax-message-2 text-black font-bold" /> {{ issue.user_notes_count }}</a
          >
        </span>
        <span class="text-center"
          >updated
          <time :datetime="issue.updated_at">{{ issue.updated_at | getTimeAgo }}</time></span
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { DateTime } from "luxon";
import Vue from "vue";
import Pinnable from "./Pinnable.vue";

export default Vue.extend({
  components: { Pinnable },
  props: {
    issue: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    isPastDue() {
      const jsDate = new Date(this.issue.due_date);
      return DateTime.fromJSDate(jsDate) < DateTime.now();
    },
  },
});
</script>

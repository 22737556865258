<template>
  <div class="y items-center w-full">
    <main class="y p-3 s-6 max-w-screen-2xl w-full h-full">
      <breadcrumb :links="[{ name: 'Trackings', url: '/now-tracking' }]" />
      <div
        v-for="(tracking, idx) in trackings"
        :key="idx"
        v-show="isAlive(tracking)"
        class="flex mb-2 justify-between items-center"
      >
        <div class="flex items-center">
          <img :src="tracking.avatar" style="width: 50px" class="rounded-full" />
          <div class="flex-col ml-2 mr-2 w-40">
            <h3 class="font-bold text-base">{{ tracking.name }}</h3>
            <h4>@{{ tracking.username }}</h4>
          </div>
        </div>
        <div class="flex-col items-center flex-1" style="height: fit-content">
          <div>{{ tracking.project.title }}</div>
          <div class="font-bold">{{ tracking.issue.title }}</div>
        </div>
        <div
          class="flex items-center"
          style="font-variant-numeric: tabular-nums; align-items: center"
        >
          <span class="text-2xl" style="height: fit-content">{{ tracking.time }}</span>
        </div>
      </div>
      <div v-if="noActiveTracking">
        <p>Nobody is tracking currently</p>
      </div>
    </main>
  </div>
</template>
<script>
import Breadcrumb from "../components/Breadcrumb.vue";

export default {
  name: "NowTracking",
  components: {
    Breadcrumb,
  },
  data() {
    return {
      time: new Date().getTime(),
    };
  },
  mounted() {
    setInterval(() => {
      this.time = new Date().getTime();
    }, 500);
  },
  computed: {
    trackings() {
      return this.$store.getters.trackings;
    },
    noActiveTracking() {
      return !this.trackings.some((t) => this.isAlive(t));
    },
  },
  methods: {
    isAlive(tracking) {
      return this.time - tracking.lastSeen < 3000;
    },
  },
};
</script>

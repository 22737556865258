import axios from "axios";
import { DateTime } from "luxon";

const bonusStore = {
  namespaced: true,
  state: {
    filters: {
      date: DateTime.local().startOf("month").toFormat("yyyy-LL"),
      employee_id: null,
      assignee_id: null,
      test_id: null,
    },
    loading: false,
    bonuses: [],
    connections: [],
    tests: [],
  },
  getters: {
    filters: (state) => state.filters,
    loading: (state) => state.loading,
    bonuses: (state) => state.bonuses,
    connections: (state) => state.connections,
    tests: (state) => state.tests,
    data: (state) => {
      const connections = state.connections.filter((c) => {
        return !state.bonuses.find(
          (b) =>
            b.assignee_id === c.assignee_id &&
            b.employee_id === c.employee_id &&
            b.bonus_test_id === c.bonus_test_id,
        );
      });
      return [...connections, ...state.bonuses];
    },
  },
  mutations: {
    setFilters(state, filters) {
      state.filters = filters;
    },
    setBonuses(state, bonuses) {
      state.bonuses = bonuses;
    },
    setConnections(state, connections) {
      state.connections = connections;
    },
    setTests(state, tests) {
      state.tests = tests;
    },
  },
  actions: {
    async fetchBonuses({ state, commit }) {
      let url = "/bonus-test-results?";
      if (state.filters.date) url += `&date=${state.filters.date}`;
      if (state.filters.employee_id) url += `&employee_id=${state.filters.employee_id}`;
      if (state.filters.assignee_id) url += `&assignee_id=${state.filters.assignee_id}`;
      if (state.filters.test_id) url += `&test_id=${state.filters.test_id}`;

      return axios.get(url).then((resp) => {
        commit("setBonuses", resp.data.data);
      });
    },
    async fetchConnections({ state, commit }) {
      let url = "/bonus-connections?";
      if (state.filters.employee_id) url += `&employee_id=${state.filters.employee_id}`;
      if (state.filters.assignee_id) url += `&assignee_id=${state.filters.assignee_id}`;
      if (state.filters.test_id) url += `&test_id=${state.filters.test_id}`;

      return axios.get(url).then((rep) => {
        commit("setConnections", rep.data.data);
      });
    },
    async fetchData({ dispatch, state }) {
      state.loading = true;
      await dispatch("fetchBonuses");
      await dispatch("fetchConnections");
      state.loading = false;
    },
    async fetchTests({ state, commit }) {
      state.loading = true;
      try {
        const { data } = await axios.get(`/bonus-tests`);
        commit("setTests", data.data);
      } catch (e) {
        console.log(e);
      } finally {
        state.loading = false;
      }
    },
  },
};

export default bonusStore;

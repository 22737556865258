<template>
  <div class="y gap-y-2 text-gray-425">
    <p class="font-bold xl:text-2xl">
      {{ estimate ? "Estimate Time" : "Spent Time" }}
    </p>
    <p class="text-xs xl:text-lg">
      {{ displayedTime }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    estimate: Boolean,
    time: {
      type: Object,
      default: () => ({
        hours: 0,
        minutes: 0,
      }),
    },
  },
  computed: {
    displayedTime() {
      const time = `${String(this.time.hours).padStart(2, "0")} Hours | ${String(
        this.time.minutes,
      ).padStart(2, "0")} Minutes`;
      return time;
    },
  },
};
</script>

<template>
  <button class="px-3 py-2 text-sm rounded bg-white text-black border border-gray-300 capitalize">
    <slot />
  </button>
</template>

<script lang="ts">
export default {
  name: "BbuttonSecondary",
  props: {
    color: {
      type: String,
      default: "",
    },
  },
};
</script>

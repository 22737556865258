const toasts = {
  state: {
    toasts: [],
  },
  mutations: {
    addToast(state, toast) {
      state.toasts.push(toast);
    },
    removeToast(state, toast) {
      state.toasts.splice(state.toasts.indexOf(toast), 1);
    },
  },
  actions: {
    showToast({ state, commit }, { message, type, group, duration = 5000 }) {
      const toast = { message, type, key: new Date().getTime() };
      if (group && state.toasts.some((t) => t.group === group)) return;
      commit("addToast", toast);
      setTimeout(() => commit("removeToast", toast), duration);
    },
  },
};

export default toasts;

import axios from "axios";

const workScheduleStore = {
  namespaced: true,
  state: {
    schedules: [],
    users: [],
    loading: true,
    forUser: null,
  },
  getters: {
    schedules: (state) => state.schedules,
    users: (state) => state.users,
    loading: (state) => state.loading,
    forUser: (state) => state.forUser,
  },
  mutations: {
    setSchedules(state, schedules) {
      state.schedules = schedules;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setUsers(state, users) {
      state.users = users;
    },
    setForUser(state, forUser) {
      state.forUser = forUser;
    },
  },
  actions: {
    async fetchData({ commit, dispatch, state }, { userId, force = false }) {
      commit("setLoading", true);
      try {
        const notChanged =
          !force && ((state.forUser === true && !userId) || (state.forUser === false && !userId));
        if (!notChanged || state.schedules.length < 1) {
          await dispatch("getData", userId);
        }
        if (state.users.length === 0) {
          await dispatch("getUsers");
        }
        commit("setForUser", userId !== null);
      } catch (error) {
        console.log(error);
      } finally {
        commit("setLoading", false);
      }
    },
    async getData({ commit }, userId) {
      const params = userId ? `&user_id=${userId}` : "";
      return axios
        .get(`work-schedules?${params}`)
        .then((resp) => {
          commit("setSchedules", resp.data.data);
        })
        .catch((e) => console.log(e));
    },
    async getUsers({ commit }) {
      return axios
        .get(`work-schedules/users`)
        .then((resp) => {
          commit("setUsers", resp.data.data);
        })
        .catch((e) => console.log(e));
    },
  },
};

export default workScheduleStore;

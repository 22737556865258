<template>
  <div class="y items-center w-full">
    <main class="y p-3 s-6 max-w-screen-2xl w-full h-full">
      <breadcrumb
        :links="[
          { name: 'Tracking', url: '/' },
          { name: 'Downloads', url: '/downloads' },
        ]"
      />

      <div class="x gap-x-3">
        <download-item
          @download="downloadVPNFile"
          class="w-full"
          :title="'OVPN File'"
          :description="'Your config file for connecting to the VPN server.'"
          :downloadText="'Download'"
        >
          <template #icon>
            <i class="isax isax-key-square text-gray-400 text-3xl"> </i>
          </template>
        </download-item>
      </div>
    </main>
  </div>
</template>

<script>
import { saveAs } from "file-saver";
import Breadcrumb from "../components/Breadcrumb.vue";
import DownloadItem from "../components/DownloadItem.vue";

export default {
  components: { Breadcrumb, DownloadItem },
  methods: {
    async downloadVPNFile() {
      const response = await this.axios.get(
        `vpn/download`,
        {},
        {
          responseType: "arraybuffer",
        },
      );
      const blob = new Blob([response.data]);
      saveAs(blob, `${this.$store.state.user.email}.ovpn`);
    },
  },
};
</script>

<style></style>

<template>
  <div class="x py-2.5 md:px-4 justify-between text-gray-500 hover:bg-gray-100 flex-wrap">
    <div class="x items-start flex-1">
      <img
        v-if="author"
        class="h-8 w-8 rounded-full object-cover mr-2 md:mr-3"
        :src="author.avatar"
        alt="avatar"
      />
      <div
        v-else
        class="h-8 w-8 min-w-min rounded-full bg-black mr-2 md:mr-3"
        style="min-width: 2rem"
      ></div>
      <div class="y items-start">
        <div class="x items-center" style="margin-bottom: 2px">
          <a
            class="line-clamp font-bold text-black"
            :title="track.comment"
            :href="link"
            target="_blank"
          >
            {{ track.comment }}
          </a>
        </div>
        <div class="x gap-x-2">
          <span v-if="author"
            >by
            <a
              :href="`${$gitlabHost}/${author.username}`"
              target="_blank"
              @click.prevent="$emit('clickedUser', author)"
              >{{ author.name }}</a
            ></span
          >
          <span v-else>by Anonymus User</span>
          <span class="x items-center">
            <i class="isax isax-clock mr-1 text-xs text-black" />
            <span>{{ track.time_spent | parseTimeToHuman }}</span>
          </span>
          <p v-if="track.issue && track.issue.project">
            <a :href="track.issue.project.web_url" target="_blank" class="text-sm">{{
              track.issue.project.path_with_namespace
            }}</a
            >/#<a class="link-secondary" target="_blank" :href="track.issue.web_url">{{
              track.issue.gitlab_iid
            }}</a>
          </p>
        </div>
      </div>
    </div>
    <div class="y items-end ml-1 md:w-1/6">
      <span
        ><time>{{ track.created_at | getTimeAgo }}</time></span
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    track: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    link() {
      return `${this.track.issue.web_url}#note_${this.track.gitlab_id}`;
    },
    author() {
      return this.track.user ?? this.track.gitlab_user;
    },
  },
};
</script>

<style scoped>
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>

<template>
  <div class="y items-center w-full">
    <main class="y p-3 s-6 max-w-screen-2xl w-full h-full">
      <breadcrumb
        :links="[
          { name: 'Tracking', url: '/' },
          { name: 'Users', url: '/users' },
        ]"
      />

      <div class="x w-full md:w-auto items-end">
        <input
          type="text"
          class="w-full"
          v-debounce:200="(val) => (query = val)"
          style="height: 35px"
          placeholder="Search user..."
        />
      </div>

      <user-list @userSelected="viewUser" :query="query"> </user-list>
    </main>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Breadcrumb from "../components/Breadcrumb.vue";
import UserList from "../components/UserList.vue";

export default {
  name: "Users",
  components: {
    UserList,
    Breadcrumb,
  },
  data() {
    return {};
  },
  mounted() {
    if (this.query) this.query = null;
    this.getUsers();
  },
  computed: {
    query: {
      get() {
        return this.$store.state.userInfo.query;
      },
      set(val) {
        this.$store.commit("userInfo/setQuery", val);
      },
    },
  },
  methods: {
    viewUser(user) {
      this.$router.push({
        name: "User",
        params: { id: user.id, user },
      });
    },
    ...mapActions("userInfo", ["getUsers"]),
  },
  watch: {
    query() {
      this.getUsers();
    },
  },
};
</script>

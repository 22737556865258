<template>
  <div class="y items-center w-full">
    <main class="y p-3 s-6 gap-y-4 max-w-screen-2xl w-full h-auto">
      <!-- Breadcrumb -->
      <breadcrumb
        :links="[
          { name: 'Tracking', url: '/' },
          { name: 'Overview', url: '/overview' },
        ]"
      />

      <issues-list
        :issues="issues"
        :mapIssues="(issues) => issues.slice(0, 10)"
        :issuesLoading="issuesLoading"
        :trackings="trackings"
        :trackingsLoading="trackingsLoading"
        :showAllIssues="false"
        :issuesBadges="issuesCount"
        :trackingsCount="trackingsCount"
      >
        <template #actionButton>
          <button-secondary @click.native="openIssues">See More</button-secondary>
        </template>
      </issues-list>

      <todos-list
        :todos="todos"
        :map="(t) => t.slice(0, 5)"
        :loading="todosLoading"
        :showAll="false"
        :canAdd="false"
        :badges="todosCount"
        :showAssignees="true"
        @update="getTodos"
        :isOverview="true"
      >
        <template #actionButton>
          <button-secondary @click.native="openTodos">See More</button-secondary>
        </template>
      </todos-list>
    </main>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Breadcrumb from "../components/Breadcrumb.vue";
import ButtonSecondary from "../components/ButtonSecondary.vue";
import IssuesList from "../components/IssuesList.vue";
import TodosList from "../components/TodosList.vue";

export default {
  mounted() {
    this.fetchOverviewData();
  },
  components: {
    Breadcrumb,
    IssuesList,
    TodosList,
    ButtonSecondary,
  },
  data() {
    return {};
  },
  methods: {
    openIssues() {
      this.$router.push({
        name: "Overview Issues",
      });
    },
    openTodos() {
      this.$router.push({
        name: "Overview Todos",
      });
    },
    ...mapActions("userInfo", ["fetchOverviewData", "getTodos"]),
  },
  computed: {
    ...mapState("userInfo", [
      "issues",
      "issuesCount",
      "issuesLoading",
      "trackings",
      "trackingsLoading",
      "trackingsCount",
      "todos",
      "todosCount",
      "todosLoading",
    ]),
  },
};
</script>

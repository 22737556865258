import { DateTime } from "luxon";
import axios from "axios";

const calendarStore = {
  namespaced: true,
  state: {
    start_date: DateTime.now().plus({ months: 2 }).toFormat("yyyy-LL-dd"),
    end_date: DateTime.now().plus({ months: 2 }).toFormat("yyyy-LL-dd"),
    user_id: null,
    events: [],
    loading: false,
  },
  getters: {
    loading: (state) => state.loading,
    events: (state) => state.events,
  },
  mutations: {
    setFilters(state, startDate, endDate, userId) {
      state.start_date = startDate;
      state.end_date = endDate;
      state.user_id = userId;
    },
    setEvents(state, events) {
      state.events = events;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
  },
  actions: {
    fetchEvents({ state, commit }, [startDate, endDate, userId]) {
      if (
        state.start_date === startDate &&
        state.end_date === endDate &&
        state.user_id === userId
      ) {
        return null;
      }

      state.loading = true;
      return axios
        .get(
          `/events?start_date=${startDate}&end_date=${endDate}&${
            userId ? `user_id=${userId}` : ``
          }`,
        )
        .then((resp) => {
          commit("setEvents", resp.data.data);
        })
        .finally(() => {
          state.loading = false;
        });
    },
  },
};

export default calendarStore;

<template>
  <div class="y items-center w-full">
    <main class="y p-3 s-6 max-w-screen-2xl w-full h-full">
      <breadcrumb
        :links="[
          { name: 'Tracking', url: '/' },
          { name: 'Thanksgiving Bonus', url: '/thanks-giving-bonus' },
        ]"
      />

      <div class="w-full y md:x gap-x-3 items-end mt-3">
        <field-item :label="'Month'">
          <template #input>
            <date-picker
              v-model="filters.date"
              :clearable="false"
              valueType="format"
              format="YYYY-MM"
              class="w-full disabled:bg-gray-100"
              type="month"
              placeholder="Choose a year and month..."
            ></date-picker>
          </template>
        </field-item>
      </div>

      <p class="font-bold text-lg" v-if="!loading">
        {{ canVote && currentMonthSelected ? "Voting" : "Results" }}
      </p>
      <div v-if="loading">
        <loading-excerpt-list class="mt-2" :length="10" />
      </div>
      <thanks-giving-bonus-selector v-else-if="canVote && currentMonthSelected" v-model="votes" />
      <div class="y gap-y-0.5" v-else>
        <thanks-giving-bonus-user-result
          v-for="result of bonuses"
          :key="result.id"
          :result="result"
        />

        <div v-if="bonuses.length < 1">No results found.</div>
      </div>

      <div v-if="!loading && canVote && currentMonthSelected" class="w-full x justify-end">
        <button-primary :disabled="submitting || !selectedOneUser" @click.native="saveVote"
          >Save</button-primary
        >
      </div>
    </main>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { DateTime } from "luxon";

import DatePicker from "vue2-datepicker";
import Breadcrumb from "../components/Breadcrumb.vue";
import FieldItem from "../components/FieldItem.vue";
import ThanksGivingBonusSelector from "../components/ThanksGivingBonusSelector.vue";
import ThanksGivingBonusUserResult from "../components/ThanksGivingBonusUserResult.vue";
import LoadingExcerptList from "../components/LoadingExcerptList.vue";
import ButtonPrimary from "../components/ButtonPrimary.vue";

export default {
  name: "ThanksGivingBonus",
  components: {
    DatePicker,
    Breadcrumb,
    ThanksGivingBonusSelector,
    FieldItem,
    ThanksGivingBonusUserResult,
    LoadingExcerptList,
    ButtonPrimary,
  },
  mounted() {
    if (this.bonuses.length < 1) this.fetchBonusesAndSettings();
  },
  methods: {
    ...mapActions("thanksgivingBonus", [
      "fetchBonusesAndSettings",
      "fetchBonuses",
      "setFilters",
      "saveVote",
    ]),
  },
  computed: {
    filters: {
      get() {
        return this.$store.state.thanksgivingBonus.filters;
      },
      set(val) {
        this.$store.commit("thanksgivingBonus/setFilters", val);
      },
    },
    votes: {
      get() {
        return this.$store.state.thanksgivingBonus.votes;
      },
      set(val) {
        this.$store.commit("thanksgivingBonus/setVotes", val);
      },
    },
    currentMonthSelected() {
      return this.filters.date === DateTime.local().startOf("month").toFormat("yyyy-LL");
    },
    selectedOneUser() {
      return this.votes.some((v) => v.to_user_id);
    },
    ...mapGetters("thanksgivingBonus", ["canVote", "loading", "submitting", "bonuses", "filters"]),
  },
  watch: {
    filters: {
      handler() {
        this.fetchBonuses();
      },
      deep: true,
    },
  },
};
</script>

<style></style>

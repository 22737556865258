<template>
  <li class="y py-2.5 pl-4 1-grow hover:bg-gray-100">
    <a
      :href="todo.target_url"
      target="_blank"
      class="x gap-x-1 justify-between items-center hover:bg-gray-100"
      style="text-decoration: none; color: inherit"
    >
      <div class="x s-4 items-center flex-1">
        <avatar-icon :src="author.avatar" v-if="author" />
        <div class="y">
          <div class="x gap-x-1">
            <span v-html="header"></span>
            <span
              class="inline-flex text-white w-auto ml-2 items-center justify-center px-2 py-1 text-xs leading-none rounded-full"
              style="line-height: 0.75em"
              :style="{
                'background-color': priorityBackgroundColor,
                color: priorityColor,
              }"
              >Priority: {{ todo.priority }}</span
            >
          </div>
          <p class="line-clamp flex-1">
            {{ todo.body }}
          </p>
          <span class="text-xs text-gray-300 mt-1" v-if="todo.recurring_parent_id"
            >auto generated ({{ todo.created_at | formatDate }})</span
          >
        </div>
        <br />
      </div>
      <assignees-excerpt v-if="showAssignees || isSupervised" :assignees="assignees" />

      <span
        v-if="todo.due_date"
        class="x items-center mt-1"
        :class="isPastDue() ? 'text-red-600' : ''"
      >
        <i class="isax isax-calendar-1 mr-1 text-xs" /><time>{{ dueDate }}</time>
      </span>

      <div v-if="isAuthorized && !todo.is_recurring">
        <button
          class="mr-1 py-2 px-3 rounded hover:bg-white"
          v-if="!todo.is_done"
          @click="
            $event.preventDefault();
            $event.stopPropagation();
            $emit('edit', todo);
          "
        >
          <span class="text-black"> Edit </span>
        </button>
        <button-secondary
          v-if="!todo.is_done"
          @click.native="
            $event.preventDefault();
            $event.stopPropagation();
            $emit('markedDone', todo);
          "
          >Done</button-secondary
        >
        <button-secondary
          v-else
          @click.native="
            $event.preventDefault();
            $event.stopPropagation();
            $emit('reopen', todo);
          "
          >Reopen</button-secondary
        >
        <button-secondary
          v-if="canUnsupervise"
          @click.native="$emit('unsupervise', todo, assignees[0])"
          >Close</button-secondary
        >
      </div>
      <button-secondary v-if="canSupervise" @click.native="$emit('supervise', todo)"
        >Supervise</button-secondary
      >
      <button-secondary
        v-if="canDelete"
        class="text-red-600"
        style="border-color: rgb(220, 38, 38) !important; color: rgb(220, 38, 38) !important"
        @click.native="$emit('delete', todo)"
        >Delete</button-secondary
      >
    </a>
  </li>
</template>

<script lang="ts">
import { DateTime } from "luxon";
import AvatarIcon from "./AvatarIcon.vue";
import ButtonSecondary from "./ButtonSecondary.vue";
import AssigneesExcerpt from "./AssigneesExcerpt.vue";

export default {
  name: "App",
  components: {
    ButtonSecondary,
    AvatarIcon,
    AssigneesExcerpt,
  },
  props: {
    // https://docs.gitlab.com/ee/api/todos.html
    todo: Object,
    showAssignees: {
      type: Boolean,
      default: () => false,
    },
    isSupervised: {
      type: Boolean,
      default: () => false,
    },
    isOverview: {
      type: Boolean,
      default: () => false,
    },
    superviseUser: {
      type: Object,
      default: () => null,
    },
  },
  methods: {
    isPastDue() {
      const jsDate = new Date(this.todo.due_date);
      return DateTime.fromJSDate(jsDate) < DateTime.now();
    },
  },
  computed: {
    header() {
      let { header } = this.todo;
      if (this.todo.is_closed) {
        header = header.replace(
          "<br>",
          `<span class="inline-flex ml-2 items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 bg-red-500 rounded-full">closed</span><br>`,
        );
      }
      return header;
    },
    user() {
      return this.$store.getters.user;
    },
    dueDate() {
      return DateTime.fromISO(this.todo.due_date).toFormat("kkkk-MM-d");
    },
    isAuthorized() {
      if (this.user.access_level >= 40) return true;
      const userGitLabId = parseInt(this.user.id, 0);
      return (
        (this.todo.author && this.todo.author.id === userGitLabId) ||
        this.todo.user.id === userGitLabId
      );
    },
    canDelete() {
      return this.todo.author.id === this.user.id || this.user.access_level >= 40;
    },
    isSupervising() {
      return this.todo.supervisors.some((u) => u.id === this.user.id);
    },
    canSupervise() {
      if (this.isSupervised) return false;
      return this.todo.user.id !== this.user.id && !this.isSupervising;
    },
    canUnsupervise() {
      if (!this.isSupervised) return false;
      return this.isSupervising || this.user.access_level >= 40;
    },
    author() {
      if (this.todo.author) return this.todo.author;
      return this.todo.gitlab_author;
    },
    assignees() {
      if (this.isSupervised) {
        if (this.isOverview) return [this.todo.supervisors[0]];
        return [this.superviseUser];
      }
      return [this.todo.user];
    },
    priorityBackgroundColor() {
      switch (this.todo.priority) {
        case "Low":
          return "#E6E6FA";
        case "Normal":
          return "#8D73B0";
        case "High":
          return "#603A8B";
        default:
          return "#330066";
      }
    },
    priorityColor() {
      switch (this.todo.priority) {
        case "Low":
          return "#000";
        default:
          return "#fff";
      }
    },
  },
};
</script>
<style scoped>
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>

import { DateTime } from "luxon";

function getTimeAgo(time) {
  return DateTime.fromISO(time).setLocale("en").toRelative();
}
function parseTimeToHuman(time) {
  const hms = time.split(":");
  let timeForHuman = "";

  if (hms[0] > 0) {
    timeForHuman += `${Number(hms[0])}h `;
  }
  if (hms[1] > 0) {
    timeForHuman += `${Number(hms[1])}m`;
  }

  return timeForHuman;
}

function timeToSeconds(time) {
  const [hours, minutes] = time.split(":");
  const seconds = hours * 60 * 60 + minutes * 60;
  return seconds;
}

function secondsToHuman(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  if (hours < 1 && minutes < 1) return "0";
  let humanTime = "";
  if (hours > 0) humanTime += `${hours} hours `;
  if (minutes > 0) humanTime += `${minutes} minus `;
  return humanTime;
}

function secondsToTime(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;
}

function formatDate(date) {
  if (!date) return "UNKOWN";
  return DateTime.fromISO(date).toFormat("yyyy-LL-dd");
}

function formatDateTime(date) {
  if (!date) return "UNKOWN";
  return DateTime.fromISO(date).toFormat(DateTime.DATETIME_SHORT);
}

function titleCaseWord(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function titleCase(string) {
  return string.split(" ").map(titleCaseWord).join(" ");
}

export default {
  getTimeAgo,
  parseTimeToHuman,
  timeToSeconds,
  secondsToHuman,
  secondsToTime,
  formatDate,
  formatDateTime,
  titleCase,
};

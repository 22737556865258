<template>
  <tbody class="shadow-md rounded-md" v-if="bonus && bonus.id" @click="toggleExpanded">
    <bonus-tracker-editor
      :value="editedBonus"
      @input="(val) => bonusChanged(val, editedBonus)"
      @cancel="isEdit = false"
      @delete="deleteBonus"
      :showSaveButton="isChanged"
      @save="updateBonus"
      @click.native="(event) => event.stopPropagation(event)"
      v-if="isEdit"
    />
    <tr v-else>
      <td
        scope="col"
        class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
      >
        <user-excerpt v-if="editedBonus.employee" :user="editedBonus.employee" />
      </td>
      <td
        scope="col"
        class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
      >
        <user-excerpt v-if="editedBonus.assignee" :user="editedBonus.assignee" />
      </td>
      <td
        scope="col"
        class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
      >
        {{ editedBonus.test ? editedBonus.test.name : "" }}
      </td>
      <td
        v-if="editedBonus.finished"
        scope="col"
        class="px-3 py-3 text-right text-base font-bold text-gray-900 uppercase tracking-wider"
      >
        {{ editedBonus.result_bonus }}%
        <span class="text-orange" v-if="editedBonus.extra_bonus">{{
          `+${editedBonus.extra_bonus}%`
        }}</span>
      </td>
      <td
        v-else
        scope="col"
        class="px-3 py-3 text-right text-xs font-medium text-gray-900 uppercase tracking-wider"
      >
        -
      </td>
      <td
        scope="col"
        class="px-3 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
      >
        <div v-if="!isEdit">
          <div
            class="icon-btn p-0 w-16 text-orange"
            v-if="editedBonus.finished"
            @click="canEdit && $emit('rate')"
          >
            <hoverable-element :enabled="canEdit">
              <i class="isax isax-tick-circle text-lg" />
              <template #on-hover> Edit </template>
            </hoverable-element>
          </div>
          <div
            @click="canEdit && $emit('rate')"
            class="icon-btn bg-orange text-white w-16 hover:bg-opacity-80"
            v-else-if="canEdit"
          >
            Rate
          </div>
          <div class="icon-btn w-20 opacity-50" v-else>Pending</div>
        </div>
        <div class="icon-btn w-16" v-else>Delete</div>
      </td>
      <td
        scope="col"
        class="px-3 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
      >
        <i
          class="isax isax-edit-2 text-xl cursor-pointer"
          v-if="canEdit"
          @click="(event) => event.stopPropagation(event) || toggleEdit()"
        />
      </td>
    </tr>
    <tr>
      <td class="px-3 py-3 transition-all" colspan="6" v-if="isExpanded && !isEdit">
        {{ editedBonus.notes }}
      </td>
    </tr>
    <tr v-if="isEdit && editedBonus.finished && canAddBonus">
      <td colspan="6" class="py-3">
        <div class="x gap-x-2 flex-1 items-center justify-center">
          <div class="x px-2 justify-between items-center shadow-md rounded-md">
            <input
              class="flex-1 outline-none py-1 px-1 text-right"
              type="number"
              min="0"
              max="100"
              v-model="editedBonus.extra_bonus"
            />
            <span class="font-bold">%</span>
          </div>
          <div class="icon-btn bg-orange text-white font-semibold" @click="updateBonus">
            Add Extra Bonus
          </div>
        </div>
      </td>
    </tr>
  </tbody>
  <tbody v-else>
    <bonus-tracker-editor
      v-for="bonus of bonuses"
      v-bind:key="bonus.idx"
      :value="bonus"
      @input="(val) => bonusChanged(val, bonus)"
      @cancel="cancelBonus(bonus.idx)"
    />
    <tr>
      <td class="px-3 pt-3 pb-5">
        <button-primary @click.native="newBonus">
          <i class="isax isax-add-circle text-base" />
        </button-primary>
      </td>
      <td class="px-3 pt-3 pb-5" colspan="5">
        <div class="x items-end justify-end">
          <div class="w-full x gap-x-2 justify-end mt-3">
            <button-secondary @click.native="cancel">Cancel</button-secondary>
            <button-primary :disabled="submitting" class="normal-case" @click.native="addBonuses"
              >Create connection(s)</button-primary
            >
          </div>
        </div>
      </td>
    </tr>
  </tbody>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BonusTrackerEditor from "./BonusTrackerEditor.vue";
import ButtonPrimary from "./ButtonPrimary.vue";
import HoverableElement from "./HoverableElement.vue";
import alerts from "../utils/alerts";
import UserExcerpt from "./UserExcerpt.vue";
import ButtonSecondary from "./ButtonSecondary.vue";

export default {
  components: { HoverableElement, ButtonPrimary, BonusTrackerEditor, UserExcerpt, ButtonSecondary },
  mounted() {
    if (!this.bonus) {
      this.newBonus();
    } else {
      this.editedBonus = JSON.parse(JSON.stringify(this.bonus));
    }
  },
  props: {
    bonus: {
      type: Object,
      default: () => null,
    },
    canEdit: {
      type: Boolean,
      default: () => false,
    },
    canAddBonus: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      editedBonus: { employee_id: null, assignee_id: null, bonus_test_id: null },
      bonuses: [],
      isEdit: false,
      isExpanded: false,
      types: ["Board test", "Worker test", "Manager test"],
      submitting: false,
    };
  },
  methods: {
    toggleEdit() {
      this.isEdit = !this.isEdit;
    },
    toggleExpanded() {
      if (!this.editedBonus.finished || this.isEdit) return;

      this.isExpanded = !this.isExpanded;
    },
    newBonus() {
      this.bonuses.push({
        idx: new Date().getTime(),
        employee_id: null,
        assignee_id: null,
        bonus_test_id: null,
      });
    },
    cancel() {
      this.$emit("cancel");
    },
    cancelBonus(idx) {
      for (let i = 0; i < this.bonuses.length; i += 1) {
        const bonus = this.bonuses[i];
        if (bonus.idx === idx) this.bonuses.splice(i, 1);
      }
      if (this.bonuses.length < 1) this.cancel();
    },
    bonusChanged(val, bonus) {
      Object.assign(bonus, { ...val });
    },
    async updateBonus() {
      const route = this.isConnection ? "connections" : "test-results";
      await this.axios.put(`bonus-${route}/${this.bonus.id}`, { ...this.editedBonus });
      this.isEdit = false;
      this.fetchData();
    },
    async addBonuses() {
      if (!this.isValid) return;
      try {
        this.submitting = true;
        await this.axios.post(`bonus-connections`, { connections: this.bonuses });
        this.fetchData();
        this.cancel();
      } catch (e) {
        console.log(e);
      } finally {
        this.submitting = false;
      }
    },
    async deleteBonus() {
      const confirmDelete = await alerts.showConfirm({
        title: "Delete Connection",
        message: `Are you sure you want to delete this connection?`,
      });
      if (!confirmDelete) return false;
      const route = this.isConnection ? "connections" : "test-results";
      await this.axios.delete(`bonus-${route}/${this.bonus.id}`);
      this.isEdit = false;
      this.fetchData();
      return true;
    },
    findBonusById(id) {
      for (let i = 0; i < this.bonuses.length; i += 1) {
        const bonus = this.bonuses[i];
        if (bonus.id === id) return bonus;
      }
      return null;
    },
    ...mapActions("bonus", ["fetchData"]),
  },
  computed: {
    isConnection() {
      return this.editedBonus.finished !== true && this.editedBonus.finished !== false;
    },
    isValid() {
      for (let i = 0; i < this.bonuses.length; i += 1) {
        const bonus = this.bonuses[i];
        if (!bonus.assignee_id || !bonus.employee_id || !bonus.bonus_test_id) return false;
      }
      return this.bonuses.length > 0;
    },
    isChanged() {
      return (
        this.bonus.assignee_id !== this.editedBonus.assignee_id ||
        this.bonus.employee_id !== this.editedBonus.employee_id ||
        this.bonus.bonus_test_id !== this.editedBonus.bonus_test_id
      );
    },
    ...mapGetters("userInfo", ["users"]),
  },
};
</script>

<style>
.icon-btn {
  @apply px-3 py-1 text-sm text-center rounded bg-white text-black capitalize shadow-md cursor-pointer hover:shadow-lg transition-all;
}
</style>

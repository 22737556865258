<template>
  <div class="flex flex-col-reverse md:x w-full">
    <transition-group
      name="tracking"
      tag="div"
      class="flex-1 flex flex-row-reverse gap-x-8 py-4 pr-8 overflow-x-scroll scrollbar scrollbar-thumb-gray-300"
    >
      <div v-for="t in showedPastTrackings" :key="t.id">
        <clock-timer
          :time="t.timer"
          :description="getTrackDescription(t)"
          :progress="getTrackProgress(t)"
          :canDelete="true"
          @delete="() => removePastTracking(t)"
          @click.native="() => $emit('resumedTracking', t)"
          :readOnly="true"
        />
      </div>
    </transition-group>

    <circle-button
      class="pt-4 mx-auto md:mx-0"
      @click.native="trackAnother"
      :widthClass="'w-24 h-24 lg:w-32 lg:h-32'"
    >
      <template #default="{ iconClass }">
        <i class="isax-add text-orange" :class="iconClass" />
      </template>

      <template #title>
        <p class="text-orange font-bold">Track Another</p>
      </template>
    </circle-button>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ClockTimer from "./ClockTimer.vue";
import CircleButton from "./CircleButton.vue";
import { getIssueTrackProgress, generateTrackingID } from "../../utils/tracking";

export default {
  props: {
    trackingData: Object,
  },
  components: {
    ClockTimer,
    CircleButton,
  },
  mounted() {
    this.fetchPastTrackings();
  },
  methods: {
    save() {
      const data = { ...this.trackingData };
      if (!data.id) {
        Object.assign(data, {
          id: generateTrackingID(),
        });
      }
      this.savePastTracking(data);
    },
    trackAnother() {
      this.save();
      this.$emit("startedNewTracking");
    },
    update() {
      this.savePastTracking(this.trackingData);
    },
    delete() {
      if (this.trackingData.id) {
        this.removePastTracking(this.trackingData);
      }
    },
    getTrackDescription(tracking) {
      return tracking.title;
    },
    getTrackProgress(tracking) {
      return getIssueTrackProgress(tracking, tracking.timer);
    },
    ...mapActions("tracking", ["fetchPastTrackings", "removePastTracking", "savePastTracking"]),
  },
  computed: {
    showedPastTrackings() {
      return this.pastTrackings.filter((t) => t.id !== this.trackingData.id);
    },
    ...mapState("tracking", ["pastTrackings"]),
  },
};
</script>

<style>
.tracking-item {
  transform: scale(1);
}
.tracking-enter-active,
.tracking-leave-active {
  transition: all 500ms;
}
.tracking-enter,
.tracking-leave-to {
  opacity: 0;
  transform: scale(0);
}
.tracking-move {
  transition: transform 500ms;
}
</style>

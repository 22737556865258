import { useGetters } from "vuex-composition-helpers";

const defaultTimeZone = 2;

export default function timezoneCalculator() {
  const { timezone } = useGetters(["timezone"]);

  const zeroPad = (num, places) => String(num).padStart(places, "0");
  const addOffsetToTime = (time, offset) => {
    const [hours, minutes] = time.split(":");
    let convertedHours = parseInt(hours, 0) + offset;
    if (convertedHours >= 24) {
      convertedHours -= 24;
    } else if (convertedHours < 0) {
      convertedHours += 24;
    }

    return `${zeroPad(convertedHours, 2)}:${minutes}`;
  };

  const addOffsetToDate = (dateStr, offset) => {
    // Parse the date string into a Date object
    const date = new Date(`${dateStr}`); // Adding 'Z' to indicate UTC

    // Add (or subtract, if offset is negative) the offset in hours
    date.setHours(date.getHours() + offset);

    // Format the date back to a string
    return date.toISOString().replace("T", " ").substring(0, 16);
  };

  const toTimezone = (time) => {
    let converted = time;

    if (timezone.value !== null) {
      const offset = timezone.value - defaultTimeZone;
      converted = addOffsetToTime(time, offset);
    }

    return converted;
  };

  const fromTimezone = (time) => {
    let converted = time;

    if (timezone.value !== null) {
      const offset = defaultTimeZone - timezone.value;
      converted = addOffsetToTime(time, offset);
    }

    return converted;
  };

  const dateToTimezone = (date) => {
    let converted = date;

    if (timezone.value !== null) {
      const offset = timezone.value - defaultTimeZone;
      converted = addOffsetToDate(date, offset);
    }

    return converted;
  };

  const dateFromTimezone = (date) => {
    let converted = date;

    if (timezone.value !== null) {
      const offset = defaultTimeZone - timezone.value;
      converted = addOffsetToDate(date, offset);
    }

    return converted;
  };

  return {
    toTimezone,
    fromTimezone,
    dateToTimezone,
    dateFromTimezone,
  };
}

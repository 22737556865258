<template>
  <div>
    <tabs-list class="overflow-visible">
      <tab-button
        v-if="showAll"
        @click="filter = null"
        :title="'All Projects'"
        :active="!filter"
        :badge="badges && badges[0] ? badges[0] : allProjectsCount"
        :loading="loading"
      />
      <tab-button
        @click="filter = 'active'"
        :title="'Active'"
        :active="filter === 'active'"
        :badge="badges && badges[1] ? badges[1] : activeProjects.length"
        :loading="loading"
      />
      <tab-button
        @click="filter = 'inactive'"
        :title="'Inactive'"
        :active="filter === 'inactive'"
        :badge="badges && badges[2] ? badges[2] : inActiveProjects.length"
        :loading="loading"
      />

      <template #actionButtons>
        <slot name="actionButton" />
      </template>
    </tabs-list>

    <ul role="list" v-if="!loading && !updating">
      <project-excerpt
        v-for="project in showedProjects"
        :key="project.id"
        :project="project"
        :showMyRole="showMyRole"
        :showAssignees="showAssignees"
      />
    </ul>
    <loading-excerpt-list class="mt-2" v-else />
    <infinite-loader
      ref="infiniteLoader"
      :disabled="disablePagination"
      :loading="paginationLoading"
      @loadMore="$emit('loadMore')"
    />
    <no-data v-if="!loading && !updating && showedProjects.length < 1" />
  </div>
</template>

<script lang="ts">
import "vue2-datepicker/index.css";
import TabButton from "./TabButton.vue";
import LoadingExcerptList from "./LoadingExcerptList.vue";
import TabsList from "./TabsList.vue";
import NoData from "./NoData.vue";
import InfiniteLoader from "./InfiniteLoader.vue";
import ProjectExcerpt from "./ProjectExcerpt.vue";

export default {
  components: {
    TabButton,
    LoadingExcerptList,
    TabsList,
    NoData,
    InfiniteLoader,
    ProjectExcerpt,
  },
  data() {
    return {
      filter: null,
      updating: false,
    };
  },
  props: {
    loading: {
      type: Boolean,
      default: () => false,
    },
    projects: {
      type: Array,
      default: () => [],
    },
    map: {
      type: Function,
      default: (projects) => projects,
    },
    showAll: {
      type: Boolean,
      default: () => true,
    },
    badges: {
      type: Array,
      default: () => [],
    },
    showMyRole: {
      type: Boolean,
      default: () => true,
    },
    showAssignees: {
      type: Boolean,
      default: () => false,
    },
    paginationLoading: {
      type: Boolean,
      default: () => false,
    },
    disablePagination: {
      type: Boolean,
      default: () => true,
    },
  },
  mounted() {
    if (!this.showAll) this.filter = "active";
  },
  methods: {
    updated() {
      this.$emit("update");
    },
  },
  computed: {
    canAdd() {
      const userId = parseInt(this.$route.params.id, 0);
      return this.$store.getters.user && this.$store.getters.user.id === userId;
    },
    showedProjects() {
      let projects = this.allProjects;

      if (this.filter === "active") projects = this.activeProjects;
      if (this.filter === "inactive") projects = this.inActiveProjects;

      return projects;
    },
    allProjectsCount() {
      let allProjectsCount = 0;
      if (this.badges && this.badges.length > 0)
        allProjectsCount = this.badges.reduce((partialSum, a) => partialSum + a, 0);
      return allProjectsCount;
    },
    allProjects() {
      return this.map(this.projects);
    },
    activeProjects() {
      return this.map(this.projects.filter((p) => p.is_active));
    },
    inActiveProjects() {
      return this.map(this.projects.filter((p) => !p.is_active));
    },
  },
};
</script>

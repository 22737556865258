<template>
  <div class="y items-center w-full">
    <main class="y p-3 s-6 max-w-screen-2xl w-full h-full">
      <breadcrumb
        :links="[
          { name: 'Tracking', url: '/' },
          { name: 'Ideas', url: '/ideas' },
          { name: 'Add', url: '.' },
        ]"
      />
      <div class="x justify-end gap-x-3">
        <button-secondary @click.native="back">Cancel</button-secondary>
        <button-primary @click.native="add" :disabled="!isValid || submitting"
          >Publish</button-primary
        >
      </div>

      <div class="y">
        <field-item :label="'Title'">
          <template #input>
            <validated-input v-model="idea.title" />
          </template>
        </field-item>

        <field-item :label="'Description'">
          <template #input>
            <quill-editor
              v-model="idea.description"
              class="quill-editor text-xs border-red shadow-md bg-white"
            >
            </quill-editor>
          </template>
        </field-item>
      </div>
    </main>
  </div>
</template>

<script>
import { quillEditor } from "vue-quill-editor";
import Breadcrumb from "../components/Breadcrumb.vue";
import ButtonPrimary from "../components/ButtonPrimary.vue";
import ButtonSecondary from "../components/ButtonSecondary.vue";
import FieldItem from "../components/FieldItem.vue";
import ValidatedInput from "../components/ValidatedInput.vue";

export default {
  components: {
    Breadcrumb,
    ButtonSecondary,
    ButtonPrimary,
    ValidatedInput,
    FieldItem,
    quillEditor,
  },
  data() {
    return {
      idea: {
        title: null,
        description: null,
      },
      submitting: false,
    };
  },
  methods: {
    back() {
      this.$router.back();
    },
    add() {
      this.submitting = true;
      this.axios({
        method: "post",
        url: `ideas`,
        data: {
          ...this.idea,
        },
      })
        .then((resp) => {
          if (resp.data.status === "success") this.back();
        })
        .catch((e) => console.log(e))
        .finally(() => {
          this.submitting = false;
        });
    },
  },
  computed: {
    isValid() {
      return this.idea.title && this.idea.description;
    },
  },
};
</script>

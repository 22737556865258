var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"y flex-grow 2-grow pb-2"},[_c('WebOrigoERPNavbar',{staticClass:"h-14 md:h-16",attrs:{"user":_vm.user,"userMenuItems":_vm.userMenuItems},on:{"logout":_vm.logout}}),_c('div',{staticClass:"x 2-grow scroll-container bg-gray-100"},[_c('WebOrigoERPSidebar',{attrs:{"mainRoute":'/',"items":_vm.sidebarItems,"mainIcon":'isax-convert-3d-cube'},scopedSlots:_vm._u([{key:"item-3",fn:function(ref){
var item = ref.item;
var isActive = ref.isActive;
return [_c('WebOrigoERPSidebarMenuItem',{attrs:{"title":item.title,"route":item.route,"isActive":isActive},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('icon-all-trackings',{staticClass:"weborigo-sidebar__item__container__icon"})]},proxy:true}],null,true)})]}},{key:"item-6",fn:function(ref){
var item = ref.item;
var isActive = ref.isActive;
return [_c('WebOrigoERPSidebarMenuItem',{attrs:{"title":item.title,"route":item.route,"isActive":isActive},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('p',{staticClass:"isax isax-chart-1 transform rotate-90 data-icon weborigo-sidebar__item__container__icon"})]},proxy:true}],null,true)})]}},{key:"item-9",fn:function(ref){
var item = ref.item;
var isActive = ref.isActive;
return [_c('WebOrigoERPSidebarMenuItem',{attrs:{"title":item.title,"route":item.route,"isActive":isActive},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('icon-box-of-ideas',{staticClass:"weborigo-sidebar__item__container__icon"})]},proxy:true}],null,true)})]}}])}),_c('div',{staticClass:"y overflow-y-scroll overflow-x-hidden scrollbar-thin scrollbar-thumb-gray-300 scrollbar-thumb-rounded-full scrollbar-track-transparent"},[_c('router-view'),_c('WebOrigoERPFooter',{staticClass:"relative bg-white md:h-28",staticStyle:{"margin-top":"auto"}})],1)],1),_c('div',{staticClass:"fixed top-12 right-12 w-auto h-auto z-10"},[_c('transition-group',{attrs:{"name":"toasts","tag":"ul"}},_vm._l((_vm.toasts),function(toast){return _c('toast',{key:toast.key,attrs:{"type":toast.type,"message":toast.message}})}),1),_c('transition',{attrs:{"name":"popup"}},_vm._l((_vm.alerts),function(alert){return _c('alert',{key:alert.key,attrs:{"title":alert.title,"message":alert.message,"onConfirm":alert.onConfirm,"onCancel":alert.onCancel,"dontShow":alert.dontShow,"alertKey":alert.key}})}),1)],1)],1),_c('div',{staticClass:"absolute left-0 right-0 top-0 h-1/2 z-0 bg-brand",staticStyle:{"z-index":"-1","background-position-x":"initial","background-position-y":"initial"},style:({
      'background-image': 'url(' + require('@/assets/bg-orange.jpg') + ')',
      'background-size': 'cover',
    })})])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="y gap-y-3">
    <div>
      <p class="font-bold text-gray-700">{{ label }}</p>
      <p
        v-if="subLabel"
        class="text-gray-450 text-xs xl:text-sm whitespace-nowrap text-ellipsis overflow-hidden"
      >
        {{ subLabel }}
      </p>
    </div>
    <v-select
      ref="select"
      :loading="loading"
      :disabled="loading || disabled"
      :options="options"
      label="label"
      :reduce="(i) => i.value"
      :value="value"
      @input="(v) => $emit('input', v)"
      class="selector selector--floating"
      :placeholder="placeholder"
      :filterBy="filterBy"
    >
      <template #open-indicator="{ attributes }">
        <i class="isax isax-arrow-down-1 text-lg text-gray-450" v-bind="attributes" />
      </template>

      <template #option="option">
        <slot name="select-option" :option="option" />
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    label: String,
    subLabel: String,
    placeholder: String,
    loading: Boolean,
    options: {
      type: Array,
      default: () => [],
    },
    disabled: Boolean,
    filterBy: Function,
  },
};
</script>

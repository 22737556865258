const useHttps = process.env.VUE_APP_BACKEND_WEBSOCKET_USE_HTTPS;

const config = {
  broadcaster: "pusher",
  key: process.env.VUE_APP_PUSHER_APP_KEY,
  cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
  forceTLS: useHttps === "true",
  authEndpoint: `${process.env.VUE_APP_BACKEND_API_URL}/broadcasting/auth`,
  wsHost: process.env.VUE_APP_BACKEND_WEBSOCKETS_HOST_URL,
  wsPath: process.env.VUE_APP_BACKEND_WEBSOCKETS_PATH,
};

if (useHttps !== "true")
  Object.assign(config, {
    wsPort: process.env.VUE_APP_BACKEND_WEBSOCKETS_HOST_PORT,
    enabledTransports: ["ws"],
  });
else
  Object.assign(config, {
    wssPort: process.env.VUE_APP_BACKEND_WEBSOCKETS_HOST_PORT,
    enabledTransports: ["ws", "wss"],
  });

export default function getConfigsByToken(token) {
  const c = {
    auth: {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    },
  };

  Object.assign(c, config);

  return c;
}

<template>
  <div
    class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
  >
    <input
      type="checkbox"
      :name="label"
      :id="label"
      class="checkbox-input absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
      :class="{
        'border-brand right-0': value,
        'border-gray-300 left-0': !value,
      }"
      style="z-index: 1"
      @click="toggle"
      :disabled="disabled"
    />
    <label
      :for="label"
      class="checkbox-background block overflow-hidden h-6 rounded-full cursor-pointer"
      :class="{ 'bg-brand': value, 'bg-gray-300': !value }"
    ></label>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      default: () => false,
    },
    label: {
      type: String,
      required: true,
    },
    disabled: Boolean,
  },
  methods: {
    toggle() {
      if (this.disabled) return;
      this.$emit("input", !this.value);
    },
  },
};
</script>

<style></style>

import { DateTime } from "luxon";

const ideaStore = {
  namespaced: true,
  state: {
    filters: {
      from: [
        DateTime.local().startOf("month").toFormat("yyyy-LL-dd"),
        DateTime.now().toFormat("yyyy-LL-dd"),
      ],
      search: "",
      order_by: "popularity",
    },
  },
  getters: {
    filters: (state) => state.filters,
  },
  mutations: {
    setFilters(state, filters) {
      state.filters = filters;
    },
  },
};

export default ideaStore;

<template>
  <div class="y items-center w-full">
    <main class="y p-3 s-6 max-w-screen-2xl w-full h-full">
      <breadcrumb
        :links="[
          { name: 'Tracking', url: '/' },
          { name: 'Bonus Tracker', url: '/bonus-tracker' },
        ]"
      />

      <div class="w-full y md:x gap-x-3 items-end mt-3" v-if="!adding">
        <field-item :label="'Month'">
          <template #input>
            <date-picker
              v-model="filters.date"
              :clearable="false"
              valueType="format"
              format="YYYY-MM"
              class="w-full disabled:bg-gray-100"
              type="month"
              placeholder="Choose a year and month..."
            ></date-picker>
          </template>
        </field-item>

        <field-item :label="'Employee'" v-if="isMaintainer || isOwner">
          <template #input>
            <v-select
              class="selector"
              :options="employees"
              :reduce="(u) => u.id"
              label="name"
              v-model="filters.employee_id"
              placeholder="Select an employee..."
            />
          </template>
        </field-item>

        <field-item :label="'Assignee'" v-if="isMaintainer || isOwner">
          <template #input>
            <v-select
              class="selector"
              :options="maintainersAndOwners"
              :reduce="(u) => u.id"
              label="name"
              v-model="filters.assignee_id"
              placeholder="Select an assignee..."
            />
          </template>
        </field-item>

        <field-item :label="'Test type'" v-if="isMaintainer || isOwner">
          <template #input>
            <v-select
              class="selector"
              :options="tests"
              :reduce="(t) => t.id"
              label="name"
              v-model="filters.test_id"
              placeholder="Select a test type..."
            />
          </template>
        </field-item>

        <button-primary
          v-if="isOwner"
          class="mb-3 h-9 whitespace-nowrap transform -translate-y-3"
          @click.native="adding = true"
        >
          Add new connection
        </button-primary>
      </div>

      <div class="overflow-visible sm:rounded-lg" v-if="!loading">
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50" v-if="data.length > 0">
            <tr>
              <th
                scope="col"
                class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Employee
              </th>
              <th
                scope="col"
                class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Assignee
              </th>
              <th
                scope="col"
                class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Type
              </th>
              <th
                scope="col"
                class="px-3 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Bonus
              </th>
              <th
                scope="col"
                class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                State
              </th>
              <th
                scope="col"
                class="px-3 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
              ></th>
            </tr>
          </thead>
          <bonus-tracker-excerpt
            ref="bonusAddingComponent"
            v-if="adding"
            @cancel="adding = false"
          />
          <bonus-tracker-excerpt
            v-for="connection of data"
            v-bind:key="(isConnection(connection) ? 'c-' : 'b-') + connection.id"
            :bonus="connection"
            @rate="rate(connection)"
            :canEdit="canEditBonus(connection)"
            :canAddBonus="!isConnection(connection) && isOwner"
            v-show="!adding"
          />
        </table>
      </div>

      <div v-else>
        <loading-excerpt-list class="mt-2" :length="10" />
      </div>
      <no-data v-if="!loading && data.length < 1 && !adding" />
    </main>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DatePicker from "vue2-datepicker";
import FieldItem from "../components/FieldItem.vue";
import Breadcrumb from "../components/Breadcrumb.vue";
import ButtonPrimary from "../components/ButtonPrimary.vue";
import LoadingExcerptList from "../components/LoadingExcerptList.vue";
import NoData from "../components/NoData.vue";
import BonusTrackerExcerpt from "../components/BonusTrackerExcerpt.vue";

export default {
  components: {
    FieldItem,
    Breadcrumb,
    ButtonPrimary,
    DatePicker,
    LoadingExcerptList,
    NoData,
    BonusTrackerExcerpt,
  },
  mounted() {
    this.getData();
    this.getUsers();
  },
  data() {
    return {
      ideas: [],
      adding: false,
    };
  },
  methods: {
    async getData() {
      this.fetchTests();
      await this.fetchData();
    },
    isConnection(c) {
      return c.finished !== true && c.finished !== false;
    },
    rate(bonus) {
      const routeName = this.isConnection(bonus)
        ? "BonusConnectionQuestions"
        : "BonusResultQuestions";
      this.$router.push({ name: routeName, params: { id: bonus.id, date: this.filters.date } });
    },
    canEditBonus(bonus) {
      return this.isOwner || (this.isMaintainer && this.$store.state.user.id === bonus.assignee.id);
    },
    ...mapActions("userInfo", ["getUsers"]),
    ...mapActions("bonus", ["fetchData", "fetchTests"]),
  },
  computed: {
    filters: {
      get() {
        return this.$store.state.bonus.filters;
      },
      set(val) {
        this.$store.commit("bonus/setFilters", val);
      },
    },
    isOwner() {
      return this.$store.state.user && this.$store.state.user.access_level === 50;
    },
    isMaintainer() {
      return this.$store.state.user && this.$store.state.user.access_level === 40;
    },
    maintainersAndOwners() {
      return this.users.filter((u) => u.access_level >= 40);
    },
    employees() {
      return this.users.filter((u) => u.state === "active");
    },
    ...mapGetters("userInfo", ["users"]),
    ...mapGetters("bonus", ["loading", "tests", "data"]),
  },
  watch: {
    filters: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
table th:first-child {
  @apply rounded-tl-lg;
}

table th:last-child {
  @apply rounded-tr-lg;
}
</style>

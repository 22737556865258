<template>
  <div class="y items-center w-full">
    <main class="y p-3 s-4 max-w-screen-2xl w-full h-full">
      <breadcrumb
        :links="[
          { name: 'Tracking', url: '/' },
          { name: 'Ideas', url: '/ideas' },
        ]"
      />
      <div class="w-full y md:x gap-x-3 items-end">
        <div class="w-full md:w-3/12">
          <field-item :label="'Range'">
            <template #input>
              <date-picker
                v-model="filters.from"
                :clearable="false"
                valueType="format"
                format="YYYY-MM-DD"
                class="w-full disabled:bg-gray-100"
                range
                placeholder="Choose a date..."
              ></date-picker>
            </template>
          </field-item>
        </div>

        <div class="w-full md:w-3/12">
          <field-item :label="'Sort By'">
            <template #input>
              <v-select
                class="selector"
                :options="sortOptions"
                :reduce="(o) => o.value"
                label="label"
                v-model="filters.order_by"
                :clearable="false"
              />
            </template>
          </field-item>
        </div>

        <div class="flex-1"></div>

        <div class="w-full md:w-5/12">
          <field-item :label="''" class="justify-self-end">
            <template #input>
              <validated-input :placeholder="'Filter by keyword...'" v-model="filters.search" />
            </template>
          </field-item>
        </div>

        <button-primary class="mb-3 w-24 transform -translate-y-3" @click.native="addIdea">
          Add Idea
        </button-primary>
      </div>

      <div class="mb-5 y gap-y-3">
        <div role="list" class="divide-y divide-solid" v-if="!loading">
          <idea-excerpt v-for="idea in ideas" v-bind:key="idea.id" :idea="idea" @vote="vote" />
        </div>
        <div v-else>
          <loading-excerpt-list class="mt-2" :length="10" />
        </div>
        <no-data v-if="!loading && ideas.length < 1" />
      </div>
    </main>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import FieldItem from "../components/FieldItem.vue";
import ValidatedInput from "../components/ValidatedInput.vue";
import Breadcrumb from "../components/Breadcrumb.vue";
import ButtonPrimary from "../components/ButtonPrimary.vue";
import IdeaExcerpt from "../components/IdeaExcerpt.vue";
import LoadingExcerptList from "../components/LoadingExcerptList.vue";
import NoData from "../components/NoData.vue";

export default {
  components: {
    FieldItem,
    ValidatedInput,
    Breadcrumb,
    ButtonPrimary,
    DatePicker,
    IdeaExcerpt,
    LoadingExcerptList,
    NoData,
  },
  mounted() {
    this.getData();
  },
  data() {
    return {
      loading: true,
      ideas: [],
      sortOptions: [
        {
          label: "Popularity",
          value: "popularity",
        },
        {
          label: "Newest",
          value: "newest",
        },
      ],
    };
  },
  methods: {
    getData() {
      this.loading = true;
      let url = `ideas?order_by=${this.filters.order_by}`;
      url += `&from=${this.filters.from[0]}&to=${this.filters.from[1]}`;
      url += `&search=${this.filters.search}`;
      this.axios({ method: "get", url })
        .then((resp) => {
          this.ideas = resp.data.data;
        })
        .catch((e) => console.log(e))
        .finally(() => {
          this.loading = false;
        });
    },
    vote(id, vote) {
      this.axios({
        method: "post",
        url: `ideas/${id}/vote`,
        data: {
          rate: vote,
        },
      })
        .then((resp) => {
          const idx = this.ideas.findIndex((i) => i.id === id);
          this.ideas[idx] = resp.data.data;
          this.ideas = JSON.parse(JSON.stringify(this.ideas));
        })
        .catch((e) => console.log(e))
        .finally(() => {});
    },
    addIdea() {
      this.$router.push({ name: "AddIdea" });
    },
  },
  computed: {
    filters: {
      get() {
        return this.$store.state.idea.filters;
      },
      set(val) {
        this.$store.commit("idea/setFilters", val);
      },
    },
  },
  watch: {
    filters: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
};
</script>

<style></style>

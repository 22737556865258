<template>
  <div>
    <tabs-list>
      <tab-button
        :size="'text-xs md:text-sm'"
        v-for="tab in tabs"
        v-bind:key="tab.key"
        @click="selectedTab = tab.key"
        :title="tab.title"
        :active="selectedTab === tab.key"
        :loading="tab.loading && tab.loading()"
        :badge="tab.badge()"
      />
      <template #actionButtons>
        <div class="x gap-x-2 flex-nowrap justify-end overflow-x-scroll md:overflow-x-hidden">
          <slot name="actionButton" />
        </div>
      </template>
    </tabs-list>
    <ul
      role="list"
      class="mt-0"
      v-if="
        [
          `all_issues`,
          `opened_issues`,
          `closed_issues`,
          `non_estimtated`,
          'no_due',
          `over_due`,
        ].includes(selectedTab)
      "
    >
      <loading-excerpt-list v-if="issuesLoading" />
      <div v-else class="divide-y divide-solid">
        <issue-excerpt
          v-for="issue in showedIssues"
          :key="issue.id"
          :issue="issue"
          :labels="labels"
        />
      </div>
    </ul>
    <ul class="mt-0" v-if="selectedTab === `tracks`">
      <tracking-loading-excerpt
        v-show="trackingsLoading"
        v-for="x in 5"
        v-bind:key="x"
        class="w-full"
      />
      <tracking-excerpt
        v-show="!trackingsLoading"
        v-for="track in trackings"
        v-bind:key="track.id"
        :track="track"
      />
    </ul>
    <infinite-loader
      ref="infiniteLoader"
      :disabled="disablePagination"
      :loading="paginationLoading"
      @loadMore="loadMore"
    />

    <no-data
      v-if="currentTab && (!currentTab.loading || !currentTab.loading()) && currentTab.badge() < 1"
    />
  </div>
</template>

<script lang="ts">
import { DateTime } from "luxon";
import IssueExcerpt from "./IssueExcerpt.vue";
import LoadingExcerptList from "./LoadingExcerptList.vue";
import TabButton from "./TabButton.vue";
import TabsList from "./TabsList.vue";
import TrackingExcerpt from "./TrackingExcerpt.vue";
import TrackingLoadingExcerpt from "./TrackingLoadingExcerpt.vue";
import NoData from "./NoData.vue";
import InfiniteLoader from "./InfiniteLoader.vue";

export default {
  components: {
    TabButton,
    TabsList,
    IssueExcerpt,
    TrackingLoadingExcerpt,
    TrackingExcerpt,
    LoadingExcerptList,
    NoData,
    InfiniteLoader,
  },
  mounted() {
    this.tabs = [
      {
        title: "All Issues",
        key: "all_issues",
        badge: () => {
          let allIssuesCount = this.allIssues.length;
          if (this.issuesBadges && this.issuesBadges.length > 0)
            allIssuesCount = this.issuesBadges.reduce((partialSum, a) => partialSum + a, 0);
          return allIssuesCount;
        },
        loading: () => this.issuesLoading,
      },
      {
        title: "Opened Issues",
        key: "opened_issues",
        badge: () =>
          this.issuesBadges && this.issuesBadges[1] != null
            ? this.issuesBadges[1]
            : this.openedIssues.length,
        loading: () => this.issuesLoading,
      },
      {
        title: "Closed Issues",
        key: "closed_issues",
        badge: () =>
          this.issuesBadges && this.issuesBadges[2] != null
            ? this.issuesBadges[2]
            : this.closedIssues.length,
        loading: () => this.issuesLoading,
      },
      {
        title: "Non Estimated Issues",
        key: "non_estimtated",
        badge: () =>
          this.issuesBadges && this.issuesBadges[3]
            ? this.issuesBadges[3]
            : this.closedIssues.length,
        loading: () => this.issuesLoading,
      },
      {
        title: "Without due date",
        key: "no_due",
        badge: () =>
          this.issuesBadges && this.issuesBadges[4]
            ? this.issuesBadges[4]
            : this.closedIssues.length,
        loading: () => this.issuesLoading,
      },
      {
        title: "Overdue Issues",
        key: "over_due",
        badge: () =>
          this.issuesBadges && this.issuesBadges[5]
            ? this.issuesBadges[5]
            : this.closedIssues.length,
        loading: () => this.issuesLoading,
      },
      {
        title: "Trackings",
        key: "tracks",
        badge: () => (this.trackingsCount != null ? this.trackingsCount : this.trackings.length),
        loading: () => this.trackingsLoading,
      },
    ];
    if (!this.showAllIssues) this.tabs.splice(0, 1);
    const { key } = this.tabs[0];
    this.selectedTab = key;
  },
  data() {
    return {
      tabs: [],
      selectedTab: null,
    };
  },
  props: {
    issuesLoading: {
      type: Boolean,
      default: () => false,
    },
    trackingsLoading: {
      type: Boolean,
      default: () => false,
    },
    issues: {
      type: Array,
      default: () => [],
    },
    labels: {
      type: Array,
      default: () => [],
    },
    mapIssues: {
      type: Function,
      default: (issues) => issues,
    },
    trackings: {
      type: Array,
      default: () => [],
    },
    showAllIssues: {
      type: Boolean,
      default: () => true,
    },
    issuesBadges: {
      type: Array,
      default: () => [],
    },
    trackingsCount: {
      type: Number,
      default: () => null,
    },
    paginationLoading: {
      type: Boolean,
      default: () => false,
    },
    disableIssuesPaginations: {
      type: Boolean,
      default: () => false,
    },
    disableTrackingsPaginations: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    loadMore() {
      if (
        [
          `all_issues`,
          `opened_issues`,
          `closed_issues`,
          `non_estimtated`,
          `no_due`,
          `over_due`,
        ].includes(this.selectedTab)
      )
        this.$emit("loadMoreIssues");
      else this.$emit("loadMoreTrackings");
    },
    isOpened(i) {
      return i.state === "opened";
    },
    isClosed(i) {
      return i.state === "closed";
    },
    notDocumentation(i) {
      return !i.labels.map((l) => l.name.toLowerCase()).includes("documentation"); // don't have documentation label
    },
  },
  computed: {
    showedIssues() {
      let issues = this.allIssues;

      if (this.selectedTab === "opened_issues") issues = this.openedIssues;
      if (this.selectedTab === "closed_issues") issues = this.closedIssues;
      if (this.selectedTab === "non_estimtated") issues = this.nonEstimtated;
      if (this.selectedTab === "no_due") issues = this.noDue;
      if (this.selectedTab === "over_due") issues = this.overDue;

      return issues;
    },
    allIssues() {
      return this.mapIssues(this.issues);
    },
    openedIssues() {
      return this.mapIssues(this.issues.filter(this.isOpened));
    },
    closedIssues() {
      return this.mapIssues(this.issues.filter(this.isClosed));
    },
    nonEstimtated() {
      return this.mapIssues(
        this.issues
          .filter(this.isOpened)
          .filter((i) => i.estimate_time === null || i.estimate_time === "00:00:00")
          .filter(this.notDocumentation),
      );
    },
    noDue() {
      return this.mapIssues(
        this.issues
          .filter(this.isOpened)
          .filter((i) => {
            return !i.due_date;
          })
          .filter(this.notDocumentation),
      );
    },
    overDue() {
      return this.mapIssues(
        this.issues.filter(this.isOpened).filter((i) => {
          if (!i.due_date) return false;
          return DateTime.now().diff(DateTime.fromISO(i.due_date), "days").toObject().days > 1;
        }),
      );
    },
    currentTab() {
      return this.tabs.find((tab) => tab.key === this.selectedTab);
    },
    disablePagination() {
      if ([`all_issues`, `opened_issues`, `closed_issues`].includes(this.selectedTab))
        return this.disableIssuesPaginations;
      return this.disableTrackingsPaginations;
    },
  },
};
</script>

<template>
  <div class="x items-center md:gap-x-3 gap-x-2" :class="classes">
    <img class="h-8 w-8 rounded-full object-cover" :src="user.avatar" alt="avatar" />
    {{ user.name }}
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
    classes: {
      type: String,
      default: () => "",
    },
  },
};
</script>

import axios from "axios";
import { ref, onMounted } from "@vue/composition-api";
import { useNamespacedState } from "vuex-composition-helpers";

export default function repositoryAddPage({ context, onUserLoaded = null }) {
  const route = context.root.$route;

  const user = ref({});
  const userLoading = ref(true);

  const { user: stateUser } = useNamespacedState("userInfo", ["user"]);

  async function loadUser(forceId = null) {
    const id = forceId ?? route.params.id;
    const userId = parseInt(id, 0);
    if (stateUser.value && userId === stateUser.value.id) {
      user.value = stateUser.value;
      if (onUserLoaded) onUserLoaded(user);
      return;
    }
    axios({
      method: "get",
      url: `/users/${id}`,
    })
      .then((resp) => {
        user.value = resp.data.data;
        if (onUserLoaded) onUserLoaded(user);
      })
      .catch((e) => console.log(e));
  }

  onMounted(async () => {
    user.value = route.params.user;
    if (!user.value) await loadUser();
    userLoading.value = false;
  });

  return {
    user,
    userLoading,
    loadUser,
  };
}

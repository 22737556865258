import axios from "axios";

const store = {
  namespaced: true,
  state: {
    loading: {
      languages: false,
      skills: false,
    },
    languages: [],
    skills: [],
  },
  getters: {
    languages: (state) => state.languages,
    skills: (state) => state.skills,
    loading: (state) => state.loading,
    languagesLoading: (state) => state.loading.languages,
    skillsLoading: (state) => state.loading.skills,
  },
  mutations: {
    setLanguagesLoading(state, loading) {
      state.loading.languages = loading;
    },
    setSkillsLoading(state, loading) {
      state.loading.skills = loading;
    },
    setLanguages(state, languages) {
      state.languages = languages;
    },
    setSkills(state, skills) {
      state.skills = skills;
    },
  },
  actions: {
    fetchLanguages({ commit }) {
      commit("setLanguagesLoading", true);
      return axios
        .get(`/languages`)
        .then((resp) => {
          commit("setLanguages", resp.data.data);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => commit("setLanguagesLoading", false));
    },
    fetchSkills({ commit }) {
      commit("setSkillsLoading", true);
      return axios
        .get(`/skills`)
        .then((resp) => {
          commit("setSkills", resp.data.data);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => commit("setSkillsLoading", false));
    },
  },
};

export default store;

<template>
  <div class="y items-center w-full">
    <main class="y p-3 s-6 gap-y-4 max-w-screen-2xl w-full h-auto">
      <!-- Breadcrumb -->
      <breadcrumb :links="[{ name: 'Tracking', url: '/' }]" />

      <dashboard-welcome-message style="width: 100%; height: auto" :name="firstName" />

      <div>
        <div class="x justify-between items-end border-b pb-2 border-gray-200">
          <p class="font-bold text-lg">Your Pins</p>
          <button-primary @click.native="showAddLink = true"> Create new Pin </button-primary>
        </div>
        <pin-excerpt
          v-for="pin in pins"
          :key="pin.id"
          :pin="pin"
          @edit="editLink(pin.model)"
          @delete="deletePin(pin.model)"
        />
      </div>

      <calendar v-if="!isExternal" :floating="false" />

      <div>
        <div class="x justify-between items-end pb-2">
          <p class="font-bold text-lg">Your Issues</p>
        </div>
        <issues-list
          :issues="issues"
          :mapIssues="(issues) => issues.slice(0, 5)"
          :issuesLoading="issuesLoading"
          :trackings="trackings"
          :trackingsLoading="trackingsLoading"
          :showAllIssues="false"
          :issuesBadges="issuesCount"
          :trackingsCount="trackingsCount"
        >
          <template #actionButton>
            <button-secondary @click.native="openIssues">See More</button-secondary>
          </template>
        </issues-list>
      </div>

      <div>
        <p class="font-bold mb-1 text-lg">Your Todos</p>
        <todos-list
          :todos="todos"
          :map="(t) => t.slice(0, 5)"
          :loading="todosLoading"
          @update="getTodos"
          :showAll="false"
          :badges="todosCount"
          :user="user"
        >
          <template #actionButton>
            <button-secondary @click.native="openTodos">See More</button-secondary>
          </template>
        </todos-list>
      </div>
    </main>

    <transition name="popup">
      <pin-add-model v-if="showAddLink" :link="link" @close="showAddLink = false" />
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import Breadcrumb from "../components/Breadcrumb.vue";
import TodosList from "../components/TodosList.vue";
import ButtonSecondary from "../components/ButtonSecondary.vue";
import Calendar from "../components/Calendar.vue";
import PinExcerpt from "../components/PinExcerpt.vue";
import ButtonPrimary from "../components/ButtonPrimary.vue";
import PinAddModel from "../components/PinAddModel.vue";
import alerts from "../utils/alerts";
import DashboardWelcomeMessage from "../components/DashboardWelcomeMessage.vue";
import IssuesList from "../components/IssuesList.vue";

export default {
  components: {
    Breadcrumb,
    TodosList,
    ButtonSecondary,
    Calendar,
    PinExcerpt,
    ButtonPrimary,
    PinAddModel,
    DashboardWelcomeMessage,
    IssuesList,
  },
  data() {
    return {
      showAddLink: false,
      link: null,
    };
  },
  async mounted() {
    this.changeUser(this.$store.state.user);
    await this.fetchHomeData();
    await this.fetchUserData();
  },
  methods: {
    async refreshData() {
      await this.fetchUserInfo();
      await this.fetchHomeData(true);
    },
    openTodos() {
      this.$router.push({
        name: "Tracking Todos",
        params: { id: this.user.id, user: this.user },
      });
    },
    openIssues() {
      this.$router.push({
        name: "User Issues",
        params: { id: this.user.id, user: this.user },
      });
    },
    editLink(link) {
      this.link = link;
      this.showAddLink = true;
    },
    async deletePin(link) {
      const confirmDelete = await alerts.showConfirm({
        title: "Delete Link",
        message: `Are you sure you want to delete this link?`,
      });
      if (!confirmDelete) return;
      this.deleteLink(link);
    },
    getProjectGroup(project) {
      const namesArray = project.path_with_namespace.split("/");
      if (namesArray.length < 2) return null;
      const name = namesArray[namesArray.length - 2];
      return name.split("-").join(" ");
    },
    ...mapMutations("userInfo", ["setUserId"]),
    ...mapActions(["deleteLink", "fetchUserInfo"]),
    ...mapActions("userInfo", [
      "fetchUserData",
      "changeUser",
      "getTodos",
      "getIssues",
      "getTrackings",
    ]),
    ...mapActions("home", ["fetchHomeData"]),
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    firstName() {
      if (!this.user) return "";
      return this.user.name.split(" ")[0];
    },
    ...mapGetters(["isExternal"]),
    ...mapState(["pins"]),
    ...mapState("userInfo", [
      "userId",
      "todos",
      "todosCount",
      "todosLoading",
      "issues",
      "issuesCount",
      "issuesLoading",
      "trackings",
      "trackingsLoading",

      "trackingsCount",
    ]),
    ...mapState("home", ["loading", "userProjects"]),
  },
};
</script>

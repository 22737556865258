<template>
  <div class="flex relative">
    <div
      class="absolute inset-0 z-10 flex items-center justify-center"
      style="background: rgba(255, 255, 255, 0.5)"
      v-if="loading"
    >
      <div class="w-32 h-32">
        <loading />
      </div>
    </div>
    <div class="w-full flex flex-col" ref="ganttAndControls">
      <div class="w-full bg-gray-100 text-gray-500 flex">
        <button class="py-3 px-3 hover:bg-gray-200" @click="collapseAll">
          <i class="isax isax-arrow-up-2 mr-1 text-base font-bold" />
          Collapse All
        </button>
        <button class="py-3 px-3 hover:bg-gray-200" @click="expandAll">
          <i class="isax isax-arrow-down-1 mr-1 text-base font-bold" />
          Expand All
        </button>
        <div class="flex-1"></div>
        <button
          class="self-end py-3 px-3 hover:bg-gray-200 disabled:opacity-80"
          :disabled="zoomScale < 1"
          @click="zoomIn"
        >
          <i class="isax isax-search-zoom-in mr-1 text-base font-bold" />
          Zoom In
        </button>
        <button
          class="self-end py-3 px-3 hover:bg-gray-200 disabled:opacity-80"
          @click="zoomOut"
          :disabled="zoomScale > 3"
        >
          <i class="isax isax-search-zoom-out-1 mr-1 text-base font-bold" />
          Zoom Out
        </button>
        <button
          class="self-end py-3 px-3 hover:bg-gray-200 border-l"
          @click="toggleGanttFullScreen"
        >
          <i class="isax isax-maximize-4 mr-1 text-base font-bold" />
          Full Screen
        </button>
      </div>
      <div ref="gantt" class="flex-1"></div>
    </div>
  </div>
</template>

<script>
import { gantt } from "dhtmlx-gantt";
import { DateTime } from "luxon";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Loading from "./Loading.vue";

export default {
  name: "gantt",
  props: {},
  components: { Loading },
  data() {
    return {
      zoomScale: 1,
      isFullScreenToggled: false,
      zoomConfig: {
        levels: [
          {
            name: "day",
            scale_height: 27,
            min_column_width: 80,
            scales: [{ unit: "day", step: 1, format: "%d %M" }],
          },
          {
            name: "week",
            scale_height: 50,
            min_column_width: 50,
            scales: [
              {
                unit: "week",
                step: 1,
                format(date) {
                  const dateToStr = gantt.date.date_to_str("%d %M");
                  const endDate = gantt.date.add(date, 6, "day");
                  const weekNum = gantt.date.date_to_str("%W")(date);
                  return `#${weekNum}, ${dateToStr(date)} - ${dateToStr(endDate)}`;
                },
              },
              { unit: "day", step: 1, format: "%j %D" },
            ],
          },
          {
            name: "month",
            scale_height: 50,
            min_column_width: 120,
            scales: [
              { unit: "month", format: "%F, %Y" },
              { unit: "week", format: "Week #%W" },
            ],
          },
          {
            name: "quarter",
            height: 50,
            min_column_width: 90,
            scales: [
              { unit: "month", step: 1, format: "%M" },
              {
                unit: "quarter",
                step: 1,
                format(date) {
                  const dateToStr = gantt.date.date_to_str("%M");
                  const endDate = gantt.date.add(gantt.date.add(date, 3, "month"), -1, "day");
                  return `${dateToStr(date)} - ${dateToStr(endDate)}`;
                },
              },
            ],
          },
          {
            name: "year",
            scale_height: 50,
            min_column_width: 30,
            scales: [{ unit: "year", step: 1, format: "%Y" }],
          },
        ],
      },
      loading: false,
    };
  },
  async mounted() {
    this.initGantt();
    if (this.ganttTasks.length < 1) {
      await this.getGanttTasks();
    }
    gantt.parse({
      tasks: this.ganttTasks,
      links: this.ganttLinks,
    });
    this.initGantt();
    while (this.ganttEvents.length) gantt.detachEvent(this.ganttEvents.pop());
    this.initGanttEvents();
    const centeredDate = new Date();
    gantt.showDate(centeredDate);
  },
  methods: {
    initGantt() {
      const gridWidth = 500;
      gantt.config.grid_width = gridWidth;
      gantt.config.row_height = 22;
      gantt.config.editor_types.progress = this.getProgressEditorType();

      gantt.config.xml_date = "%Y-%m-%d";
      gantt.config.lightbox = null;
      gantt.config.drag_project = true;

      const progressEditor = {
        type: "progress",
        map_to: "progress",
        min: 0,
        max: 100,
        set_value(value) {
          return value / 100;
        },
        get_value(id) {
          return gantt.getTask(id).value * 100;
        },
      };

      gantt.config.columns = [
        { name: "", label: "", width: 30, template: gantt.getWBSCode },
        {
          name: "text",
          label: "Task name",
          tree: true,
          width: "*",
          template: (obj) =>
            !obj.web_url ? obj.text : `<a href="${obj.web_url}" target="_blank">${obj.text}</a>`,
        },
        {
          name: "start_date",
          label: "Start",
          align: "center",
          width: gridWidth * 0.1,
          template: (obj) =>
            DateTime.fromISO(obj.start_date.toISOString()).setLocale("en").toFormat("d LLL"),
        },
        {
          name: "end_date",
          label: "End",
          align: "center",
          width: gridWidth * 0.09,
          template: (obj) =>
            DateTime.fromISO(obj.end_date.toISOString()).setLocale("en").toFormat("d LLL"),
        },
        {
          name: "progress",
          label: "Progress",
          align: "center",
          template: (obj) =>
            obj.progress || obj.progress === 0
              ? `${(obj.progress * 100).toFixed(2)}% <span style="color:red">${
                  obj.priority ?? ""
                }</span>`
              : null,
          editor: progressEditor,
          width: gridWidth * 0.11,
        },
        {
          name: "assignees",
          label: "Assignee",
          align: "center",
          width: gridWidth * 0.23,
          template: (obj) =>
            obj.assignees
              ? obj.assignees.map((assignee) => assignee.name.split(" ")[0]).join(",")
              : null,
        },
      ];

      const isToday = (date) => {
        const today = new Date();
        return (
          date.getDate() === today.getDate() &&
          date.getMonth() === today.getMonth() &&
          date.getFullYear() === today.getFullYear()
        );
      };

      gantt.ext.zoom.init(this.zoomConfig);
      gantt.ext.zoom.setLevel(this.zoomScale);
      gantt.plugins({
        fullscreen: true,
        marker: true,
      });
      gantt.config.open_tree_initially = true;
      gantt.templates.scale_cell_class = (date) => (isToday(date) ? "todayColumn" : "");
      const dateToStr = gantt.date.date_to_str(gantt.config.task_date);
      gantt.addMarker({
        start_date: new Date(),
        css: "today",
        text: "Today",
        title: dateToStr(new Date()),
      });
      gantt.init(this.$refs.gantt);
    },
    initGanttEvents() {
      const ganttEvents = [];

      const filterTasks = (id, task) => {
        const filterIssue = (issueId, issue) => {
          if (this.filters.assignee) {
            if (!issue.assignees.some((assignee) => assignee.gitlab_id === this.filters.assignee)) {
              return false;
            }
          }
          if (this.filters.search) {
            if (issue.text.toLowerCase().search(this.filters.search.toLowerCase()) < 0)
              return false;
          }
          if (this.filters.state === "active") {
            if (issue.state !== "active" && issue.state !== "opened") return false;
          }
          return true;
        };

        const filterMilestoneOrGroup = (milestoneId) => {
          const children = [];
          gantt.eachTask((childTask) => children.push(childTask), milestoneId);
          return children.some((childTask) => filterTasks(childTask.id, childTask));
        };

        if (id.substring(0, 1) === "i") return filterIssue(id, task);
        return filterMilestoneOrGroup(id, task);
      };

      ganttEvents.push(gantt.attachEvent("onBeforeTaskDisplay", filterTasks));
      ganttEvents.push(
        gantt.attachEvent("onAfterTaskUpdate", (id, task) => {
          const isMilestone = id.substring(0, 1) === "m";
          const isIssue = id.substring(0, 1) === "i";
          if (isMilestone) {
            this.updateMilestone(id.split("-")[1], {
              start_date: DateTime.fromISO(task.start_date.toISOString()).toFormat("yyyy-LL-dd"),
              end_date: DateTime.fromISO(task.end_date.toISOString()).toFormat("yyyy-LL-dd"),
            });
          } else if (isIssue) {
            this.updateIssue(id.split("-")[1], {
              start_date: DateTime.fromISO(task.start_date.toISOString()).toFormat("yyyy-LL-dd"),
              end_date: DateTime.fromISO(task.end_date.toISOString()).toFormat("yyyy-LL-dd"),
              progress: task.progress * 100,
            });
          } else {
            this.updateGroup(id.split("-")[1], {
              start_date: DateTime.fromISO(task.start_date.toISOString()).toFormat("yyyy-LL-dd"),
              end_date: DateTime.fromISO(task.end_date.toISOString()).toFormat("yyyy-LL-dd"),
            });
          }
          return true;
        }),
      );

      const onAddLink = async (id, link) => {
        const fromId = link.source.split("-")[1];
        const toId = link.target.split("-")[1];

        const fromMileStone = link.source.substring(0, 1) === "m";
        const toMileStone = link.target.substring(0, 1) === "m";
        const fromIssue = link.source.substring(0, 1) === "i";
        const toIssue = link.target.substring(0, 1) === "i";
        let from = "";
        if (fromMileStone) from = "milestone";
        else if (fromIssue) from = "issue";
        else from = "group";
        const data = {};
        if (toMileStone) data.milestone_id = toId;
        else if (toIssue) data.issue_id = toId;
        else data.group_id = toId;
        const newLink = await this.addLink(from, fromId, data);
        Object.assign(link, { ...link, link_id: newLink.pivot.id });
      };

      const onDeleteLink = (id, task) => {
        this.deleteLink(task.link_id);
      };

      ganttEvents.push(gantt.attachEvent("onAfterLinkAdd", onAddLink));
      ganttEvents.push(gantt.attachEvent("onAfterLinkDelete", onDeleteLink));
      ganttEvents.push(
        gantt.attachEvent(
          "onAfterLinkUpdate",
          (id, link) => onDeleteLink(id, link.link_id) && onAddLink(id, link),
        ),
      );
      this.setGanttEvents(ganttEvents);
    },
    async getGanttTasks() {
      try {
        this.loading = true;
        const { groups, issues, milestones, links, filterUsers } = (
          await this.axios.get(`/milestones`)
        ).data;

        this.setFilterUsers(filterUsers);

        const ganttTasks = [];
        const ganttLinks = [];

        const linkAll = (object, filterId, from, groupId) => {
          if (object.group)
            object.group.forEach((link) => {
              if (link.from_id === filterId)
                ganttLinks.push(this.getLink(link, from, `g-${link.to_id}`));
            });

          if (object.issue)
            object.issue.forEach((link) => {
              if (link.from_id === filterId)
                ganttLinks.push(this.getLink(link, from, `i-${link.to_id}-${groupId}`));
            });

          if (object.milestone)
            object.milestone.forEach((link) => {
              if (link.from_id === filterId)
                ganttLinks.push(this.getLink(link, from, `m-${link.to_id}-${groupId}`));
            });
        };

        groups.forEach((group) => {
          const groupTask = this.getGanttDataForGroup(group);
          ganttTasks.push(groupTask);
          const groupLinks = links.group ?? [];
          linkAll(groupLinks, group.id, groupTask.id, group.id);
        });

        ganttTasks.sort((a, b) => a.text.localeCompare(b.text));

        const milestonesTasks = new Map();
        issues.forEach((issue) => {
          const milestoneTaskId = `m-${issue.milestone_id}-g-${issue.group_id}`;

          if (!milestonesTasks.get(milestoneTaskId)) {
            const milestone = milestones.find((m) => m.id === issue.milestone_id);
            if (milestone) {
              const milestoneTask = this.getGanttDataForMilestone(milestone, issue.group_id);
              ganttTasks.push(milestoneTask);
              milestonesTasks.set(milestoneTaskId, milestoneTask);
              const milestoneLinks = links.milestone ?? [];
              linkAll(milestoneLinks, milestone.id, milestoneTask.id, issue.group_id);
            }
          }

          const issueTask = this.getGanttDataForIssue(issue, issue.group_id);
          ganttTasks.push(issueTask);
          const issueLinks = links.issue ?? [];
          linkAll(issueLinks, issue.id, issueTask.id, issue.group_id);
        });

        this.updateGanttTasks(ganttTasks);
        this.updateGanttLinks(ganttLinks);
      } catch (e) {
        // eslint-disable-next-line no-alert
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    getLink(link, from, to) {
      return {
        link_id: link.id,
        source: from,
        target: to,
        type: 0,
      };
    },
    getGanttDataForGroup(group) {
      let ganttNamespace = group.name;
      if (group.parent_name) ganttNamespace = `${group.parent_name} ${ganttNamespace}`;

      return {
        id: `g-${group.id}`,
        text: ganttNamespace,
        assignees: [],
        color: "#FF6700",
        progressColor: "rgba(255,255,255,0.2)",
        state: "opened",
        type: gantt.config.types.project,
      };
    },
    getGanttDataForMilestone(milestone, groupId) {
      return {
        id: `m-${milestone.id}-${groupId}`,
        parent: `g-${groupId}`,
        start_date: milestone.start_date,
        text: milestone.title,
        end_date: milestone.end_date ?? milestone.due_date,
        assignees: [],
        progress: milestone.progress / 100,
        web_url: `${milestone.parent_url}/-/milestones/${milestone.gitlab_iid}`,
        type: gantt.config.types.project,
        color: "#8F8F8F",
        state: milestone.state,
      };
    },
    getGanttDataForIssue(issue, groupId) {
      const startDate = issue.start_date ?? issue.created_at;
      let endDate = issue.end_date;
      if (issue.closed_at) endDate = issue.closed_at;

      const assignees = issue.assignees ?? [];
      const gitlabAssignees = issue.gitlab_assignees ?? [];

      const millisecondsInWeek = 1000 * 60 * 60 * 24 * 7;

      let textColor = null;
      let color = null;
      let priority = null;
      const priorities = {
        Low: "!",
        Normal: "!!",
        High: "!!!",
        Urgent: "!!!!",
      };

      issue.labels.forEach((l) => {
        const p = priorities[l.name.replace("Priority: ", "")];
        if (p) {
          priority = p;
          return;
        }

        textColor = l.text_color;
        color = l.color;
      });

      return {
        id: `i-${issue.id}-${groupId}`,
        start_date: startDate,
        end_date:
          endDate ??
          issue.due_date ??
          new Date(new Date(issue.created_at).getTime() + millisecondsInWeek),
        text: issue.title,
        web_url: issue.web_url,
        parent: `m-${issue.milestone_id}-${groupId}`,
        assignees: assignees.concat(gitlabAssignees),
        priority,
        textColor,
        color,
        progressColor: "rgba(255,255,255,0.2)",
        progress: issue.progress > 0 ? issue.progress / 100 : 0,
        state: issue.state,
      };
    },
    updateGantt() {
      gantt.render();
    },
    async saveUpdate(id, type, data) {
      this.loading = true;
      try {
        await this.axios.put(`/${type}/${id}`, data);
      } catch {
        //
      } finally {
        this.loading = false;
      }
    },
    async updateGroup(id, data) {
      return this.saveUpdate(id, "groups", data);
    },
    async updateMilestone(id, data) {
      return this.saveUpdate(id, "milestones", data);
    },
    async updateIssue(id, data) {
      return this.saveUpdate(id, "issues", data);
    },
    getProgressEditorType() {
      const getInput = (node) => {
        return node.querySelector("input");
      };

      return {
        show: (id, column, config, placeholder) => {
          const min = 0;
          const max = 100;

          const html = `<div><input type='number' min='${min}' max='${max}' name='${column.name}'></div>`;
          // eslint-disable-next-line no-param-reassign
          placeholder.innerHTML = html;
        },
        set_value(value, id, column, node) {
          getInput(node).value = (value * 100).toFixed(2);
        },
        get_value(id, column, node) {
          return getInput(node).value / 100 || 0;
        },
        is_changed(value, id, column, node) {
          const currentValue = this.get_value(id, column, node);
          return Number(value) !== Number(currentValue);
        },
        is_valid(value) {
          const min = 0;
          const max = 100;
          const renderedValue = parseFloat(value) * 100;
          return !Number.isNaN(renderedValue) && renderedValue <= max && renderedValue >= min;
        },
        focus(node) {
          const input = getInput(node);
          if (!input) {
            return;
          }
          if (input.focus) {
            input.focus();
          }

          if (input.select) {
            input.select();
          }
        },
      };
    },
    async addLink(type, from, data) {
      const createdLinkData = (await this.axios.post(`/${type}s/${from}/link`, data)).data;
      return createdLinkData.data;
    },
    async deleteLink(id) {
      await this.axios.delete(`/milestones/delete-link/${id}`, {
        link_id: id,
      });
    },
    expandAll() {
      gantt.eachTask((task) => {
        // eslint-disable-next-line no-param-reassign
        task.$open = true;
      });
      gantt.render();
    },
    collapseAll() {
      gantt.eachTask((task) => {
        // eslint-disable-next-line no-param-reassign
        task.$open = false;
      });
      gantt.render();
    },
    zoomIn() {
      gantt.ext.zoom.zoomIn();
      this.zoomScale = gantt.ext.zoom.getCurrentLevel();
    },
    zoomOut() {
      gantt.ext.zoom.zoomOut();
      this.zoomScale = gantt.ext.zoom.getCurrentLevel();
    },
    toggleGanttFullScreen() {
      if (!this.isFullScreenToggled) {
        this.openFullscreen(this.ganttAndControls);
      } else {
        this.closeFullscreen();
      }
      this.isFullScreenToggled = !this.isFullScreenToggled;
      setTimeout(() => gantt.render(), 500);
    },
    openFullscreen(elem) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
    },
    closeFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen();
      }
    },
    ...mapMutations("gantt", ["setGanttEvents", "setFilterUsers"]),
    ...mapActions("gantt", ["updateGanttTasks", "updateGanttLinks"]),
  },
  computed: {
    ganttAndControls() {
      return this.$refs.ganttAndControls;
    },
    ...mapGetters("gantt", ["filters", "ganttTasks", "ganttEvents", "ganttLinks"]),
  },
  watch: {
    filters: {
      handler() {
        this.updateGantt();
      },
      deep: true,
    },
  },
};
</script>

<style>
@import "~dhtmlx-gantt/codebase/dhtmlxgantt.css";
.todayColumn {
  background-color: #ff6700 !important;
  color: #fff !important;
}
button:disabled {
  pointer-events: none;
}
.gantt_cell {
  font-size: 11px !important;
}
.gantt_row.gantt_row_milestone,
.gantt_row.gantt_row_project {
  font-weight: bold !important;
  color: #ff6700 !important;
}
.gantt_task_line .gantt_task_content {
  font-size: 10px !important;
}
</style>

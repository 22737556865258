<template>
  <div class="w-full x justify-start pl-2 py-2 px-3 s-3 items-center">
    <div class="y gap-y-1 items-start flex-1 w-1/2 md:w-auto one-line-clamp">
      <h3 class="font-bold hover:underline">
        <pinnable :type="'milestone'" :id="milestone.id">
          <a :href="getMilestoneLink(milestone)" target="_blank">
            {{ milestone.title }}
            <span v-if="milestone.project || milestone.group" class="text-gray-600 font-medium">
              -
              {{
                milestone.group ? milestone.group.full_path : milestone.project.path_with_namespace
              }}</span
            >
          </a>
        </pinnable>
      </h3>

      <div
        class="x gap-x-1"
        :class="isPastDue(milestone) ? 'text-red-600' : ''"
        v-if="milestone.start_date && milestone.due_date"
      >
        <span v-if="milestone.start_date" class="x gap-x-1 items-center text-xs">
          <i class="isax isax-calendar-1" />
          <time :datetime="milestone.start_date">{{ milestone.start_date | formatDate }}</time>
        </span>
        -
        <span v-if="milestone.due_date" class="x gap-x-1 items-center text-xs">
          <time :datetime="milestone.due_date">{{ milestone.due_date | formatDate }}</time>
        </span>
      </div>
      <p class="text-xs" v-else>Indefinite milestone</p>
    </div>
    <div class="w-full md:w-1/3">
      <div class="h-1.5 w-full bg-gray-200 rounded-md">
        <div
          class="h-full bg-green-500 rounded-sm"
          :style="{ width: milestone.progress + '%' }"
        ></div>
      </div>
      <div class="x w-full justify-between mt-1">
        <span class="text-xs"
          >{{ milestone.issues_count }} Issue{{ milestone.issues_count > 1 ? "s" : "" }}</span
        >
        <span>{{ milestone.progress }}%</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { DateTime } from "luxon";
import Pinnable from "./Pinnable.vue";

export default {
  components: { Pinnable },
  props: {
    milestone: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getMilestoneLink(milestone) {
      const parentUrl = milestone.group ? milestone.group.web_url : milestone.project.web_url;

      return `${parentUrl}/-/milestones/${milestone.gitlab_iid}`;
    },
    isPastDue(milestone) {
      const jsDate = new Date(milestone.due_date);
      return DateTime.fromJSDate(jsDate) < DateTime.now();
    },
  },
};
</script>

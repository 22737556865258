<template>
  <div class="w-full x justify-start pl-2 py-2 s-3 items-center">
    <span class="w-9 h-9 bg-gray-200 text-gray-500 x items-center justify-center rounded">
      <i class="isax isax-link" />
    </span>

    <div class="y items-start flex-1 w-1/2 md:w-auto one-line-clamp">
      <div class="x gap-x-2">
        <a :href="link.url" target="_blank" class="font-bold hover:underline">
          {{ link.title }}
        </a>
        <icon-unpin class="ml-2 cursor-pointer" @click.native="$emit('delete')" />
      </div>
    </div>
    <div class="x gap-x-2">
      <button-secondary @click.native="$emit('edit')"> Edit </button-secondary>
    </div>
  </div>
</template>

<script>
import IconUnpin from "../assets/icons/IconUnpin.vue";
import ButtonSecondary from "./ButtonSecondary.vue";

export default {
  components: { ButtonSecondary, IconUnpin },
  props: {
    link: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>

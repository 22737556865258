<template>
  <div class="y items-center w-full">
    <main class="y p-3 s-6 gap-y-4 max-w-screen-2xl w-full h-auto">
      <!-- Breadcrumb -->
      <breadcrumb
        :links="[
          { name: 'Tracking', url: '/' },
          { name: 'Users', url: '/users' },
          {
            name: user ? user.username : $route.params.id,
            url: `/users/users/${$route.params.id}`,
          },
        ]"
      />
      <div class="relative h-auto y gap-y-2 md:x md:gap-x-5" v-if="!userLoading && user">
        <div
          class="absolute top-0 right-0 z-fixed cursor-pointer"
          v-if="$store.state.user.access_level === 50"
        >
          <button-secondary class="mr-1.5" v-if="editMode" @click.native="editMode = !editMode">
            Cancel
          </button-secondary>
          <button-primary v-if="editMode" @click.native="updateUser"> Save </button-primary>
          <i v-else class="isax isax-edit text-xl" @click="editMode = !editMode" />
        </div>

        <div class="y items-center justify-center h-full w-full md:w-1/3 p-2 md:p-0 bg-gray-50">
          <img class="w-24 h-24 rounded-full" :src="user.avatar" />
          <h1 class="text-gray-600 text-2xl my-2">{{ user.name }}</h1>
          <input
            class="edit-input text-sm mb-0.5 text-center"
            style="font-weight: bold !important"
            :disabled="!editMode"
            v-model="user.role"
          />
          <datetime
            class="theme-brand"
            :value="editMode ? user.membership_date : null"
            :placeholder="`since ${user.membership_date}`"
            value-zone="local"
            :format="'yyyy-LL-dd'"
            @input="dateChanged"
            :disabled="!editMode"
          />
        </div>
        <div class="h-full flex-1 y gap-y-2 md:gap-y-5">
          <div
            class="flex-1 bg-gray-50 y gap-y-3 justify-around text-xs md:text-sm p-2 md:justify-center font-semibold"
          >
            <div class="x gap-x-5">
              <div class="skill-item">
                <b class="font-bold text-sm md:text-base">Role:</b>
                <input class="edit-input" :disabled="!editMode" v-model="user.role_description" />
              </div>
              <div class="skill-item">
                <b class="font-bold text-sm md:text-base">Group:</b>
                <input class="edit-input" :disabled="!editMode" v-model="user.group" />
              </div>
            </div>

            <div class="x gap-x-5">
              <div class="skill-item">
                <b class="font-bold text-sm md:text-base">Skills:</b>
                <skills-selector v-if="editMode" v-model="skills" />
                <div v-else class="text-sm">
                  <span v-for="(skill, index) in user.skills" :key="skill.id"
                    >{{ skill.name }}<i v-if="index !== skills.length - 1">, </i></span
                  >
                </div>
              </div>
              <div class="skill-item">
                <b class="font-bold text-sm md:text-base">Languages:</b>
                <languages-selector v-if="editMode" v-model="languages" />
                <div v-else class="text-sm">
                  <span v-for="(language, index) in user.languages" :key="language.id"
                    >{{ language.name }}<i v-if="index !== languages.length - 1">, </i></span
                  >
                </div>
              </div>
            </div>

            <div class="skill-item">
              <b class="font-bold text-sm md:text-base">Capacity:</b>
              <input class="edit-input" :disabled="!editMode" v-model="user.capacity" />
            </div>
          </div>
          <div class="h-auto md:h-1/4 w-full y gap-y-2 md:x md:gap-x-5">
            <div class="flex-1 bg-gray-50 y justify-center gap-y-0.5 p-2">
              <b class="font-bold text-base">Email:</b>
              <a :href="'mailto:' + user.email" class="font-semibold">{{ user.email }}</a>
            </div>
            <div class="flex-1 bg-gray-50 y justify-center gap-y-0.5 p-2">
              <b class="font-bold text-base">Phone:</b>
              <a :href="editMode ? 'javascript:' : 'tel:' + user.phone">
                <input
                  :disabled="!editMode"
                  v-model="user.phone"
                  class="font-semibold cursor-pointer edit-input"
                />
              </a>
            </div>
            <div class="flex-1 bg-gray-50 y justify-center gap-y-0.5 p-2">
              <b class="font-bold text-base">Linkedin:</b>
              <a
                :href="
                  editMode ? 'javascript:' : 'https://www.linkedin.com/in/' + user.linkedin_username
                "
                :target="editMode ? '' : '_blank'"
              >
                <input
                  :disabled="!editMode"
                  v-model="user.linkedin_username"
                  class="font-semibold cursor-pointer edit-input"
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="x h-64 w-full gap-x-5" v-else>
        <div class="y items-center justify-center h-full w-1/3 bg-gray-50 gap-y-3">
          <div class="w-24 h-24 rounded-full ssc-square"></div>
          <div class="mb-2 font-bold text-lg mr-2 ssc-square h-5 w-10/12 rounded-md" />
          <div class="font-bold text-lg mr-2 ssc-square w-36 h-3 rounded-md" />
        </div>
        <div class="h-full flex-1 bg-gray-50 y justify-center gap-y-5 p-2 font-semibold">
          <div>
            <div class="mb-2 font-bold text-lg mr-2 ssc-square w-full h-5 rounded-md" />
            <div class="font-bold text-lg mr-2 ssc-square w-36 h-3 rounded-md" />
          </div>
          <div>
            <div class="mb-2 font-bold text-lg mr-2 ssc-square w-full h-5 rounded-md" />
            <div class="font-bold text-lg mr-2 ssc-square w-36 h-3 rounded-md" />
          </div>
          <div>
            <div class="mb-2 font-bold text-lg mr-2 ssc-square w-full h-5 rounded-md" />
            <div class="font-bold text-lg mr-2 ssc-square w-36 h-3 rounded-md" />
          </div>
        </div>
      </div>

      <todos-list
        :todos="todos"
        :map="(t) => t.slice(0, 5)"
        :loading="todosLoading"
        @update="getTodos"
        :showAll="false"
        :badges="todosCount"
        :user="user"
      >
        <template #actionButton>
          <button-secondary @click.native="openTodos">See More</button-secondary>
        </template>
      </todos-list>

      <div>
        <projects-list
          :projects="projects"
          :loading="projectsLoading"
          :badges="projectsCount"
          :showAll="false"
        >
          <template #actionButton>
            <button-secondary @click.native="openProjects">See More</button-secondary>
          </template>
        </projects-list>
      </div>

      <issues-list
        :issues="issues"
        :mapIssues="(issues) => issues.slice(0, 5)"
        :issuesLoading="issuesLoading"
        :trackings="trackings"
        :trackingsLoading="trackingsLoading"
        :showAllIssues="false"
        :issuesBadges="issuesCount"
        :trackingsCount="trackingsCount"
      >
        <template #actionButton>
          <button-secondary @click.native="openIssues">See More</button-secondary>
        </template>
      </issues-list>

      <calendar :user="user" :floating="false" />
    </main>
  </div>
</template>

<script lang="ts">
import { ref } from "@vue/composition-api";
import "vue2-datepicker/index.css";
import "vue-datetime/dist/vue-datetime.css";
import { DateTime } from "luxon";
import { Datetime } from "vue-datetime";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import Breadcrumb from "../components/Breadcrumb.vue";
import ButtonSecondary from "../components/ButtonSecondary.vue";
import Calendar from "../components/Calendar.vue";
import IssuesList from "../components/IssuesList.vue";
import ProjectsList from "../components/ProjectsList.vue";
import TodosList from "../components/TodosList.vue";
import useLoadUserInfo from "../compostions/useLoadUserInfo";
import LanguagesSelector from "../components/LanguagesSelector.vue";
import ButtonPrimary from "../components/ButtonPrimary.vue";
import SkillsSelector from "../components/SkillsSelector.vue";
// import FullCalendar from "../components/FullCalendar.vue";

export default {
  name: "TrackingGoals",
  components: {
    Breadcrumb,
    TodosList,
    ButtonSecondary,
    IssuesList,
    ProjectsList,
    Calendar,
    Datetime,
    LanguagesSelector,
    ButtonPrimary,
    SkillsSelector,
    // FullCalendar,
  },
  data() {
    return {
      trackingTabs: [],
      selectedTrackingTab: null,
      selectedTodoTab: null,
      editMode: false,
    };
  },
  setup(props, context) {
    const languages = ref([]);
    const skills = ref([]);

    const onUserLoaded = (user) => {
      languages.value = user.value.languages;
      skills.value = user.value.skills;
    };
    const { user, userLoading, loadUser } = useLoadUserInfo({ context, onUserLoaded });
    return {
      user,
      userLoading,
      languages,
      skills,
      loadUser,
    };
  },
  mounted() {
    this.trackingTabs = [
      {
        title: "All Issues",
        key: "all_issues",
        badge: () => this.issues.length,
      },
      {
        title: "Opened Issues",
        key: "opened_issues",
        badge: () => this.openedIssues.length,
      },
      {
        title: "Closed Issues",
        key: "closed_issues",
        badge: () => this.closedIssues.length,
      },
      {
        title: "Trackings",
        key: "tracks",
        badge: () => this.trackings.length,
        loading: () => this.trackingsLoading,
      },
    ];
    const { key } = this.trackingTabs[0];
    this.selectedTrackingTab = key;
  },
  methods: {
    openProject(project) {
      this.$router.push({
        path: `/projects/${project.path_with_namespace}/track`,
      });
    },
    openProjects() {
      this.$router.push({
        name: "User Projects",
        params: { id: this.user.id, user: this.user },
      });
    },
    openIssues() {
      this.$router.push({
        name: "User Issues",
        params: { id: this.user.id, user: this.user },
      });
    },
    openTodos() {
      this.$router.push({
        name: "Tracking Todos",
        params: { id: this.user.id, user: this.user },
      });
    },
    seeProjects() {
      this.$router.push({
        name: "Project Choose",
      });
    },
    dateChanged(value) {
      if (value) this.user.membership_date = DateTime.fromISO(value).toFormat("yyyy-LL-dd");
    },
    async updateUser() {
      const parseIfObject = (l) => (typeof l === "object" ? l.id : l);
      return this.axios
        .put(`/users/${this.user.id}`, {
          role: this.user.role,
          membership_date: this.user.membership_date,
          role_description: this.user.role_description,
          group: this.user.group,
          capacity: this.user.capacity,
          phone: this.user.phone,
          linkedin_username: this.user.linkedin_username,
          languages: this.languages.map(parseIfObject),
          skills: this.skills.map(parseIfObject),
        })
        .then((reap) => {
          this.editMode = false;
          this.user = reap.data.data;
        });
    },
    ...mapMutations("userInfo", ["setUser"]),
    ...mapActions("userInfo", [
      "changeUser",
      "fetchUserData",
      "getProjects",
      "getIssues",
      "getTrackings",
      "getTodos",
    ]),
  },
  computed: {
    currentTab() {
      return this.trackingTabs.find((tab) => tab.key === this.selectedTrackingTab);
    },
    isLoggedInUser() {
      return (
        this.$store.getters.user &&
        this.$store.getters.user.id === parseInt(this.$route.params.id, 0)
      );
    },
    ...mapState("userInfo", [
      "projects",
      "projectsCount",
      "projectsLoading",
      "issues",
      "issuesCount",
      "issuesLoading",
      "trackings",
      "trackingsLoading",
      "todos",
      "todosCount",
      "todosLoading",
      "trackingsCount",
    ]),
    ...mapGetters(["isExternal"]),
  },
  watch: {
    "$route.params.id": {
      handler(newVal, oldVal) {
        if (newVal === oldVal) return;
        if (newVal) {
          this.changeUser({ id: newVal });
          const { fetchUserData, loadUser } = this;
          this.$nextTick(() => fetchUserData() && loadUser(newVal));
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
.skill-item {
  @apply x gap-x-1 flex-1 md:items-center flex-wrap;
}
.edit-input {
  @apply bg-gray-50 border-none font-semibold resize-none p-0;
}
.vdatetime >>> input {
  @apply bg-gray-50 text-center border-none placeholder-black;
}
@media (max-width: 767px) {
  .one-line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
</style>

<template>
  <pop-up-modal
    @close="close"
    :width="'25vw'"
    :height="'auto'"
    :showCancelButton="false"
    style="z-index: 99999 !important"
  >
    <template>
      <div
        class="w-full pl-8 inline-block align-bottom bg-white rounded-lg text-left overflow-hidden"
      >
        <div class="pt-5 pb-4">
          <div class="flex items-start">
            <div
              class="flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100"
            >
              <svg
                class="h-6 w-6 text-red-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
              </svg>
            </div>
            <div class="mt-0 ml-4 text-left px-2">
              <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                {{ title }}
              </h3>
              <div class="mt-2">
                <p class="text-sm text-gray-500">
                  {{ message }}
                </p>
                <checkbox-input
                  class="my-5"
                  v-if="dontShow"
                  :checked="showAgain"
                  @change="showAgain = !showAgain"
                  :label="showAgainLabel"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #actionButton>
      <div class="x gap-x-2 justify-end">
        <button @click="cancel" class="px-3 py-2 text-sm rounded text-gray-500">Cancel</button>
        <button
          @click="
            close();
            onConfirm(dontShow ? showAgain : null);
          "
          class="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 font-medium text-white hover:bg-red-700 mt-0 w-auto text-sm"
        >
          Confirm
        </button>
      </div>
    </template>
  </pop-up-modal>
</template>

<script lang="ts">
import { mapActions } from "vuex";
import CheckboxInput from "./CheckboxInput.vue";
import PopUpModal from "./PopUpModal.vue";

export default {
  components: {
    PopUpModal,
    CheckboxInput,
  },
  props: {
    alertKey: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    onConfirm: {
      type: Function,
      required: true,
    },
    onCancel: Function,
    dontShow: Object,
  },
  data() {
    return {
      showAgain: false,
    };
  },
  computed: {
    showAgainLabel() {
      if (!this.dontShow || !this.dontShow.text) return `Don't warn me again`;
      return this.dontShow.text;
    },
  },
  methods: {
    close() {
      this.dismissAlert(this.alertKey);
    },
    cancel() {
      this.close();
      if (this.onCancel) this.onCancel(this.dontShow ? this.showAgain : null);
    },
    ...mapActions(["dismissAlert"]),
  },
};
</script>

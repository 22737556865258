import axios from "axios";

const bonusStore = {
  namespaced: true,
  state: {
    user: null,
    loading: false,
    issues: [],
    issuesLoading: false,
    trackings: [],
    bonuses: [],
    projects: [],
    projectsLoading: [],
    companyBranches: [],
    companyBranchesLoading: [],
  },
  getters: {
    user: (state) => state.user,
    trackings: (state) => state.trackings,
    bonuses: (state) => state.bonuses,
    loading: (state) => state.loading,
    issues: (state) => state.issues,
    issuesLoading: (state) => state.issuesLoading,
    projects: (state) => state.projects,
    projectsLoading: (state) => state.projectsLoading,
    companyBranches: (state) => state.companyBranches,
    companyBranchesLoading: (state) => state.companyBranchesLoading,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setTrackings(state, trackings) {
      state.trackings = trackings;
    },
    setBonuses(state, bonuses) {
      state.bonuses = bonuses;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setProjects(state, projects) {
      state.projects = projects;
    },
    setProjectsLoading(state, projectsLoading) {
      state.projectsLoading = projectsLoading;
    },
    setCompanyBranches(state, companyBranches) {
      state.companyBranches = companyBranches;
    },
    setCompanyBranchesLoading(state, companyBranchesLoading) {
      state.companyBranchesLoading = companyBranchesLoading;
    },
    setIssues(state, issues) {
      state.issues = issues;
    },
    setIssuesLoading(state, issuesLoading) {
      state.issuesLoading = issuesLoading;
    },
  },
  actions: {
    changeUser({ dispatch, commit }, user) {
      commit("setUser", user);
      return dispatch("fetchSheets");
    },
    async fetchSheets({ dispatch, commit }) {
      commit("setLoading", true);
      const promise = Promise.all([dispatch("fetchTrackings"), dispatch("fetchBonuses")]);

      return promise.finally(() => {
        commit("setLoading", false);
      });
    },
    async fetchTrackings({ state, commit, rootGetters }) {
      const userId = (state.user ?? rootGetters.user).id;
      const url = `/trackings/${userId}/sheet`;
      return axios.get(url).then((resp) => {
        commit("setTrackings", resp.data.data);
      });
    },
    async fetchBonuses({ state, commit }) {
      const userId = state.user?.id;
      const url = `/sheet-bonuses`;
      return axios
        .get(url, {
          params: { user_id: userId },
        })
        .then((resp) => {
          commit("setBonuses", resp.data);
        });
    },
    async fetchProjects({ commit }) {
      commit("setProjectsLoading", true);
      const url = `/projects/all`;
      return axios
        .get(url)
        .then((resp) => {
          commit("setProjects", resp.data.data);
        })
        .finally(() => {
          commit("setProjectsLoading", false);
        });
    },
    async fetchCompanyBranches({ commit }) {
      commit("setCompanyBranchesLoading", true);
      const url = `/company-branches`;
      return axios
        .get(url)
        .then((resp) => {
          commit("setCompanyBranches", resp.data);
        })
        .finally(() => {
          commit("setCompanyBranchesLoading", false);
        });
    },
    async fetchIssues({ commit }, projectId) {
      commit("setIssues", []);
      commit("setIssuesLoading", true);
      const url = `/projects/${projectId}/all-issues`;
      return axios
        .get(url)
        .then((resp) => {
          commit("setIssues", resp.data);
        })
        .finally(() => {
          commit("setIssuesLoading", false);
        });
    },
  },
};

export default bonusStore;

<template>
  <div class="w-full flex-1 flex flex-col justify-start">
    <div class="w-full y gap-y-2.5 items-start mb-3">
      <div>
        <slot name="label">
          <label class="block text-sm font-medium text-gray-700">{{ label }}</label>
        </slot>
      </div>
      <div class="w-full flex items-start mt-0.5">
        <div class="flex-1">
          <slot name="input" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: () => "",
    },
  },
};
</script>

<template>
  <div>
    <!-- Tabs -->
    <tabs-list>
      <tab-button
        v-if="showAll"
        @click="filter = null"
        :title="'All Todos'"
        :active="!filter"
        :badge="allTodosBadge"
        :loading="loading"
      />
      <tab-button
        @click="filter = 'opened'"
        :title="'Opened'"
        :active="filter === 'opened'"
        :badge="badges && badges[1] ? badges[1] : openedTodos.length"
        :loading="loading"
      />
      <tab-button
        @click="filter = 'closed'"
        :title="'Closed'"
        :active="filter === 'closed'"
        :badge="badges && badges[2] ? badges[2] : closedTodos.length"
        :loading="loading"
      />
      <tab-button
        @click="filter = 'recurring'"
        :title="'Recurring'"
        :active="filter === 'recurring'"
        :badge="badges && badges[3] ? badges[3] : recurringTodos.length"
        :loading="loading"
      />
      <tab-button
        @click="filter = 'supervised'"
        :title="'Supervised'"
        :active="filter === 'supervised'"
        :badge="badges && badges[4] ? badges[4] : supervisedTodos.length"
        :loading="loading"
      />
      <template #actionButtons>
        <slot name="actionButton" />
        <button-primary v-if="canAdd" @click.native="add"> Add Todo </button-primary>
      </template>
    </tabs-list>
    <!-- Todo list -->
    <ul role="list" class="divide-y divide-solid" v-if="!loading && !updating">
      <li v-for="(todo, index) in showedTodos" :key="index">
        <todo-excerpt
          :todo="todo"
          @markedDone="markDone"
          @reopen="reopen"
          @delete="deleteTodo"
          @supervise="supervise"
          @unsupervise="unsupervise"
          @edit="edit"
          :showAssignees="showAssignees"
          :isSupervised="filter === 'supervised'"
          :isOverview="isOverview"
          :superviseUser="user"
        />
      </li>
    </ul>
    <loading-excerpt-list class="mt-2" v-else />
    <infinite-loader
      ref="infiniteLoader"
      :disabled="disablePagination || maxTabElements"
      :loading="paginationLoading"
      @loadMore="$emit('loadMore')"
    />
    <no-data v-if="!loading && !updating && showedTodos.length < 1" />
    <transition name="popup">
      <todo-add-modal
        v-if="selectedTodo != null"
        :todo="selectedTodo"
        @close="closeModal"
        :user-id="$route.params.id"
      />
    </transition>
  </div>
</template>

<script lang="ts">
import "vue2-datepicker/index.css";
import ButtonPrimary from "./ButtonPrimary.vue";
import TodoAddModal from "./TodoAddModal.vue";
import TodoExcerpt from "./TodoExcerpt.vue";
import TabButton from "./TabButton.vue";
import LoadingExcerptList from "./LoadingExcerptList.vue";
import TabsList from "./TabsList.vue";
import NoData from "./NoData.vue";
import InfiniteLoader from "./InfiniteLoader.vue";
import alerts from "../utils/alerts";

export default {
  name: "App",
  components: {
    TodoExcerpt,
    ButtonPrimary,
    TodoAddModal,
    TabButton,
    LoadingExcerptList,
    TabsList,
    NoData,
    InfiniteLoader,
  },
  props: {
    isOverview: {
      type: Boolean,
      default: () => false,
    },
    user: {
      type: Object,
      default: () => null,
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    todos: {
      type: Array,
      default: () => [],
    },
    autoFilter: {
      type: Boolean,
      default: () => true,
    },
    map: {
      type: Function,
      default: (todos) => todos,
    },
    showAll: {
      type: Boolean,
      default: () => true,
    },
    badges: {
      type: Array,
      default: () => [],
    },
    canAdd: {
      type: Boolean,
      default: () => true,
    },
    showAssignees: {
      type: Boolean,
      default: () => false,
    },
    paginationLoading: {
      type: Boolean,
      default: () => false,
    },
    disablePagination: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      filter: "",
      showModal: false,
      selectedTodo: null,
      updating: false,
    };
  },
  mounted() {
    if (!this.showAll) this.filter = "opened";
  },
  methods: {
    async markDone(todo) {
      this.updating = true;
      try {
        await this.axios.post(`/todos/${todo.id}/done`);
        this.updating = false;
        this.updated();
      } catch (e) {
        this.updating = false;
      }
    },
    async reopen(todo) {
      this.updating = true;
      try {
        await this.axios.post(`/todos/${todo.id}/reopen`);
        this.updating = false;
        this.updated();
      } catch (e) {
        this.updating = false;
      }
    },
    async deleteTodo(todo) {
      const confirmed = await alerts.showConfirm({
        title: "Delete Todo",
        message: `Are you sure you want to delete this todo?`,
      });
      if (!confirmed) return;

      this.updating = true;
      try {
        await this.axios.delete(`/todos/${todo.id}`);
        this.updating = false;
        this.updated();
      } catch (e) {
        this.updating = false;
      }
    },
    async supervise(todo) {
      this.updating = true;
      try {
        await this.axios.post(`/todos/${todo.id}/supervise`);
        this.updating = false;
        this.updated();
      } catch (e) {
        this.updating = false;
      }
    },
    async unsupervise(todo, user) {
      this.updating = true;
      try {
        await this.axios.delete(`/todos/${todo.id}/unsupervise/${user.id}`);
        this.updating = false;
        this.updated();
      } catch (e) {
        this.updating = false;
      }
    },
    edit(todo) {
      this.selectedTodo = todo;
    },
    add() {
      this.selectedTodo = {
        body: null,
        todo: null,
      };
    },
    closeModal(update) {
      this.selectedTodo = null;
      if (update) this.updated();
    },
    updated() {
      this.$emit("update");
    },
    isOpen(todo) {
      return (
        !todo.is_done && !this.isRecurring(todo) && (this.isOverview || !this.isSupervised(todo))
      );
    },
    isClosed(todo) {
      return (
        todo.is_done && !this.isRecurring(todo) && (this.isOverview || !this.isSupervised(todo))
      );
    },
    isRecurring(todo) {
      return todo.is_recurring;
    },
    isSupervised(todo) {
      if (this.isOverview) return todo.supervisors.length > 0;
      if (!this.user) return [];
      return todo.supervisors.some((u) => u.id === this.user.id);
    },
  },
  computed: {
    showedTodos() {
      let todos = this.allTodos;

      if (this.filter === "opened") todos = this.openedTodos;
      if (this.filter === "closed") todos = this.closedTodos;
      if (this.filter === "recurring") todos = this.recurringTodos;
      if (this.filter === "supervised") todos = this.supervisedTodos;

      return todos;
    },
    allTodos() {
      return this.map(this.todos);
    },
    openedTodos() {
      return this.map(this.todos.filter(this.isOpen));
    },
    closedTodos() {
      return this.map(this.todos.filter(this.isClosed));
    },
    recurringTodos() {
      return this.map(this.todos.filter(this.isRecurring));
    },
    supervisedTodos() {
      if (!this.isOverview) return this.map(this.todos.filter(this.isSupervised));

      // duplicate todos to show each supervisor in a seprated todo
      const dupTodos = [];
      this.todos.filter(this.isSupervised).forEach((todo) => {
        todo.supervisors.forEach((supervisor) => {
          dupTodos.push({
            ...todo,
            supervisors: [supervisor],
          });
        });
      });

      return this.map(dupTodos);
    },
    allTodosBadge() {
      if (this.badges && this.badges.length > 0) return this.badges.reduce((sum, a) => sum + a, 0);
      return this.allTodos.length;
    },
    maxTabElements() {
      const currentTabIndex = {
        null: 0,
        opened: 1,
        closed: 2,
        recurring: 3,
      }[this.filter];

      return this.badges && this.badges[currentTabIndex] <= this.showedTodos.length;
    },
  },
};
</script>

<template>
  <div class="x gap-y-3 gap-x-5 px-5 py-2.5 flex-wrap">
    <div v-for="user in assignees" v-bind:key="user.id" :user="user">
      <clickable-item
        class="border-transparent hover:underline hover:bg-opacity-0"
        :route="{
          path: `/users/${user.id}/user`,
        }"
        @click.native="$event.stopPropagation()"
      >
        <user-excerpt :user="user" :classes="'font-bold'" />
      </clickable-item>
    </div>
  </div>
</template>

<script>
import ClickableItem from "./ClickableItem.vue";
import UserExcerpt from "./UserExcerpt.vue";

export default {
  components: { ClickableItem, UserExcerpt },
  props: {
    assignees: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

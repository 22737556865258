<template>
  <div class="y items-center w-full">
    <main class="y p-3 s-6 max-w-screen-2xl w-full h-full">
      <!-- Breadcrumb -->
      <breadcrumb :links="breadcrumbLinks" />
      <todos-list
        :todos="todos"
        :loading="todosLoading && todosFilters.page === 1"
        :paginationLoading="todosLoading && todosFilters.page > 1"
        @loadMore="getTodos"
        :disablePagination="todosFilters.reachedEnd"
        :showAll="true"
        :canAdd="isUserRoute"
        :badges="todosCount"
        :showAssignees="!isUserRoute"
        @update="getData"
        :isOverview="!isUserRoute"
        :user="isUserRoute ? user : null"
      />
    </main>
  </div>
</template>

<script lang="ts">
import { computed } from "@vue/composition-api";
import { mapActions, mapState } from "vuex";
import Breadcrumb from "../components/Breadcrumb.vue";
import TodosList from "../components/TodosList.vue";
import useLoadUserInfo from "../compostions/useLoadUserInfo";

export default {
  name: "TrackingGoals",
  components: {
    Breadcrumb,
    TodosList,
  },
  setup(props, context) {
    const isUserRoute = computed(() => context.root.$route.name === "Tracking Todos");
    const data = { isUserRoute };

    if (isUserRoute.value) {
      const { user, userLoading } = useLoadUserInfo({ context });
      Object.assign(data, {
        user,
        userLoading,
        isUserRoute,
      });
    }

    return data;
  },
  mounted() {
    if (this.isUserRoute) this.changeUser({ id: this.$route.params.id });
    this.getData();
  },
  methods: {
    getData() {
      this.todosFilters.page = 1;
      this.$nextTick(() => {
        if (this.isUserRoute) this.getUsertodos();
        else this.getAllTodos();
      });
    },
    ...mapActions("userInfo", ["changeUser", "getAllTodos", "getUsertodos", "getTodos"]),
  },
  computed: {
    breadcrumbLinks() {
      if (this.isUserRoute)
        return [
          { name: "Tracking", url: "/" },
          { name: "Users", url: "/users" },
          {
            name: this.user ? this.user.username : this.$route.params.id,
            url: `/users/${this.$route.params.id}/user`,
          },
          { name: "Todos", url: `/users/${this.$route.params.id}/todos` },
        ];

      return [
        { name: "Tracking", url: "/" },
        { name: "Overview", url: "/overview" },
        { name: "Todos", url: "/overview/todos" },
      ];
    },
    ...mapState("userInfo", ["todos", "todosLoading", "todosCount", "todosFilters"]),
  },
};
</script>

import store from "../store/index";

function getErrorMessage(response) {
  let errorMessage = "";

  switch (response.status) {
    case 500:
      errorMessage = "Error from the server";
      break;
    case 422:
      {
        const { errors } = response.data;
        Object.getOwnPropertyNames(errors).forEach((field) => {
          const errorsArray = errors[field];
          errorMessage += errorsArray.join(" ");
        });
      }
      break;

    default:
      errorMessage = response.data?.message;
      break;
  }

  return errorMessage;
}

function showMessage(message, group, error = false) {
  store.dispatch("showToast", {
    type: error ? "error" : "success",
    message,
    group,
  });
}

function showError(error) {
  store.dispatch("showToast", {
    type: "error",
    message: getErrorMessage(error),
  });
}

function showConfirm({ title, message, dontShow }) {
  return new Promise((resolve) => {
    const onClose = (confirmed, neverAgain) => {
      if (dontShow) resolve({ confirm: confirmed, neverAgain });
      else resolve(confirmed);
    };
    store.dispatch("showAlert", {
      title,
      message,
      onConfirm(neverAgain) {
        onClose(true, neverAgain);
      },
      onCancel(neverAgain) {
        onClose(false, neverAgain);
      },
      dontShow,
    });
  });
}

function parseObjectToParams(obj) {
  const nullRemoved = { ...obj };
  Object.keys(nullRemoved).forEach((k) => nullRemoved[k] == null && delete nullRemoved[k]);
  return new URLSearchParams(nullRemoved).toString();
}

export default {
  showError,
  showMessage,
  showConfirm,
  parseObjectToParams,
};

<template>
  <div class="flex md:x flex-col-reverse gap-y-2 px-6 py-4 gap-x-3 shadow-md rounded-md bg-white">
    <div class="flex-1">
      <b class="text-lg">{{ title }}</b>
      <p class="mt-2" v-html="content"></p>

      <slot name="footer" />
    </div>
    <div class="y items-center justify-center" v-if="user">
      <img
        class="h-14 w-14 rounded-full object-cover cursor-pointer"
        :src="user.avatar"
        @click="openUser"
        alt="avatar"
      />
      <b class="text-center mt-2 cursor-pointer" @click="openUser">{{ user.name }}</b>
      <span class="text-center mt-1 font-semibold text-xs text-gray-500" v-if="created_at">{{
        created_at | formatDate
      }}</span>
    </div>
  </div>
</template>

<script>
import { DateTime } from "luxon";

export default {
  props: {
    title: String,
    content: String,
    user: Object,
    created_at: String,
  },
  methods: {
    openUser() {
      this.$router.push({
        name: "User",
        params: { id: this.user.id, user: this.user },
      });
    },
  },
  filters: {
    formatDate(time) {
      return DateTime.fromISO(time).toFormat("yyyy-LL-dd");
    },
  },
};
</script>

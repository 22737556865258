<template>
  <div>
    <issue-excerpt v-if="type === 'issue'" :issue="model" class="border-b" />
    <project-excerpt v-else-if="type === 'project'" :project="model" :showAssignees="true" />
    <milestone-excerpt v-else-if="type === 'milestone'" :milestone="model" class="border-b" />
    <link-excerpt
      v-else-if="type === 'link'"
      :link="model"
      class="border-b"
      @edit="$emit('edit')"
      @delete="$emit('delete')"
    />
  </div>
</template>

<script>
import IssueExcerpt from "./IssueExcerpt.vue";
import LinkExcerpt from "./LinkExcerpt.vue";
import MilestoneExcerpt from "./MilestoneExcerpt.vue";
import ProjectExcerpt from "./ProjectExcerpt.vue";

export default {
  components: {
    IssueExcerpt,
    ProjectExcerpt,
    MilestoneExcerpt,
    LinkExcerpt,
  },
  props: {
    pin: {
      type: Object,
      required: true,
    },
  },
  computed: {
    type() {
      return this.pin.pinnable_type.split("\\").pop().toLowerCase();
    },
    model() {
      return this.pin.model;
    },
  },
};
</script>

<style></style>

import axios from "axios";

const homeStore = {
  namespaced: true,
  state: {
    userProjects: [],
    loading: false,
    loaded: false,
  },
  mutations: {
    setUserprojects(state, userProjects) {
      state.userProjects = userProjects;
    },
  },
  actions: {
    fetchHome({ commit, state }) {
      return axios.get("user/home").then((resp) => {
        const { data } = resp.data;
        commit("setUserprojects", data.user_projects);
        state.loaded = true;
      });
    },
    async fetchHomeData({ dispatch, state, rootGetters }, force) {
      state.loading = true;
      await dispatch("userInfo/fetchHomeData", {}, { root: true });
      if ((!state.loaded && !rootGetters.isExternal) || force) await dispatch("fetchHome");
      state.loading = false;
    },
  },
};

export default homeStore;

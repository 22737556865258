var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative"},[(_vm.showOldCalender)?_c('old-calendar',{attrs:{"floating":false},on:{"showTimeline":_vm.showNewCalender}}):_vm._e(),_c('full-calendar',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showOldCalender),expression:"!showOldCalender"}],ref:"fullCalendar",staticClass:"weborigo-calendar",attrs:{"options":_vm.calendarOptions},scopedSlots:_vm._u([{key:"eventContent",fn:function(arg){return [(
          arg.event.display == 'auto' || (arg.event.display == 'background' && !arg.event.allDay)
        )?_c('p',{staticClass:"group",class:{ 'text-white text-center pt-1.5': arg.event.display == 'background' }},[(arg.event.start)?_c('b',[_vm._v(_vm._s(_vm._f("showTime")(arg.event.start)))]):_vm._e(),(arg.event.end)?_c('b',[_vm._v(" - "+_vm._s(_vm._f("showTime")(arg.event.end)))]):_vm._e(),_c('b',[_vm._v(" "+_vm._s(arg.event.title))]),(arg.event.url)?_c('i',{staticClass:"isax isax-export-3 ml-1 text-xs cursor-pointer text-white"}):_vm._e(),(_vm.canCreateEvent(arg.event) && arg.event.display != 'background')?_c('i',{staticClass:"isax isax-edit ml-2 text-base cursor-pointer text-transparent rounded-full p-2 group-hover:bg-orange group-hover:text-white",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();
            _vm.editEvent(arg.event);}}}):_vm._e(),(_vm.canCreateEvent(arg.event) && arg.event.display != 'background')?_c('i',{staticClass:"isax isax-trash ml-2 text-base cursor-pointer text-transparent rounded-full p-2 group-hover:bg-orange group-hover:text-white",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();
            _vm.deleteEvent(arg.event);}}}):_vm._e()]):_vm._e()]}}])}),(_vm.loading)?_c('div',{staticClass:"w-full h-full x items-center justify-center absolute inset-0 bg-white z-tooltip bg-opacity-75"},[_c('loading')],1):_vm._e(),_c('transition',{attrs:{"name":"popup"}},[(_vm.showAddModal)?_c('event-add-modal',{attrs:{"userId":_vm.user != null ? _vm.user.id : null,"event":_vm.editedEvent},on:{"close":_vm.addModalClosed}}):_vm._e()],1),_c('transition',{attrs:{"name":"popup"}},[(_vm.showScheduleModal)?_c('work-schedule-add-modal',{attrs:{"schedule":_vm.selectedSchedule},on:{"close":function($event){_vm.showScheduleModal = false;
        _vm.refreshData();}}}):_vm._e(),(_vm.showDefaultTimeModal)?_c('default-work-time-modal',{on:{"close":function (update) {
          _vm.showDefaultTimeModal = false;
          update ? _vm.renderEvents() : null;
        }}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
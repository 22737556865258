<template>
  <v-select
    class="selector flex-1"
    :value="value"
    @input="(val) => $emit('input', val)"
    :loading="skillsLoading"
    :reduce="(i) => i.id"
    label="name"
    :options="skills"
    multiple
  />
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    value: [String, Array, Number],
  },
  mounted() {
    if (this.skills.length === 0) {
      this.fetchSkills();
    }
  },
  computed: {
    ...mapGetters("options", ["skills", "skillsLoading"]),
  },
  methods: {
    ...mapActions("options", ["fetchSkills"]),
  },
};
</script>

<style></style>

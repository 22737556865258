export function getHoursAndMinutesFromString(str) {
  let [hours, minutes] = str.split(":");
  hours = String(hours).padStart(2, "0");
  minutes = String(minutes).padStart(2, "0");
  return {
    hours,
    minutes,
  };
}

export function getTotalSecondsFromTime(time) {
  return time.hours * 60 * 60 + time.minutes * 60;
}

export function getTotalSecondsFromString(str) {
  const time = getHoursAndMinutesFromString(str);
  return getTotalSecondsFromTime(time);
}
export function getIssueProgress(issue) {
  const estimateTime = getTotalSecondsFromString(issue.estimate_time);
  if (estimateTime === 0) {
    return 0;
  }
  const spentTime = getTotalSecondsFromString(issue.total_spent_time);
  if (spentTime > estimateTime) return 1;
  return spentTime / estimateTime;
}

export function getIssueTrackProgress(issue, tracked) {
  const estimateTime = issue.estimate_time ? getTotalSecondsFromString(issue.estimate_time) : 0;
  if (estimateTime === 0) {
    return tracked.seconds / 60;
  }
  const progress = getIssueProgress(issue);
  if (progress === 1) {
    return 1;
  }
  const trackedTime = getTotalSecondsFromTime(tracked);
  const trackedProgress = trackedTime / estimateTime;
  const trackProgress = progress + trackedProgress;
  return Math.min(trackProgress, 1);
}

export function generateTrackingID() {
  const randomStr = (Math.random() + 1).toString(36).substring(7);
  return `tracking_${randomStr}_${new Date().getTime()}`;
}

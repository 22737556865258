<template>
  <v-select
    placeholder="Select a timezone"
    class="selector"
    style="min-width: 185px"
    :options="timeZones"
    :reduce="(i) => i.val"
    :value="timezone"
    @input="setTimezone"
    :clearable="false"
  >
    <template #selected-option="{ label }"> Timezone: {{ label }} </template>
  </v-select>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  data() {
    const timeZones = this.generateTimeZones();
    return {
      timeZones,
    };
  },
  async mounted() {
    if (!this.timezone) {
      const timezone = await this.getTimezone();

      this.setTimezone(timezone && !Number.isNaN(timezone) ? timezone : this.getUserTimezone());
    }
  },
  methods: {
    getUserTimezone() {
      return Math.floor((new Date().getTimezoneOffset() / 60) * -1);
    },
    generateTimeZones() {
      let start = 7;
      return [...new Array(20).keys()].map(() => {
        const val = start;
        start -= 1;
        let label = val;
        if (val === 0) {
          label = "UTC";
        } else if (val < 0) {
          label = `UTC${val}`;
        } else if (val > 0) {
          label = `UTC+${val}`;
        }
        return {
          val,
          label,
        };
      });
    },
    ...mapActions(["getTimezone"]),
    ...mapMutations(["setTimezone"]),
  },
  computed: {
    ...mapGetters(["timezone"]),
  },
};
</script>

<style></style>

import axios from "axios";
import { DateTime } from "luxon";

const store = {
  namespaced: true,
  state: {
    filters: {
      date: DateTime.local().startOf("month").toFormat("yyyy-LL"),
    },
    loading: false,
    submitting: false,
    canVote: false,
    votes: [
      {
        to_user_id: null,
        order: 1,
      },
      {
        to_user_id: null,
        order: 2,
      },
      {
        to_user_id: null,
        order: 3,
      },
    ],
    bonuses: [],
  },
  getters: {
    filters: (state) => state.filters,
    loading: (state) => state.loading,
    submitting: (state) => state.submitting,
    votes: (state) => state.votes,
    canVote: (state) => state.canVote,
    bonuses: (state) => state.bonuses,
  },
  mutations: {
    setFilters(state, filters) {
      state.filters = filters;
    },
    setBonuses(state, bonuses) {
      state.bonuses = bonuses;
    },
    setCanVote(state, canVote) {
      state.canVote = canVote;
    },
    setVotes(state, votes) {
      for (let i = 0; i < votes.length; i += 1) {
        const vote = votes[i];
        for (let j = 0; j < state.votes.length; j += 1) {
          if (vote.order === state.votes[j].order) {
            state.votes[j].to_user_id = vote.to_user_id;
          }
        }
      }
    },
  },
  actions: {
    async fetchBonusesAndSettings({ state, dispatch }) {
      state.loading = true;
      await dispatch("fetchSettings");
      await dispatch("fetchBonuses");
      state.loading = false;
    },
    async fetchBonuses({ state, commit }) {
      let url = "/thanksgiving-bonus?";
      if (state.filters.date) url += `&date=${state.filters.date}`;
      state.loading = true;
      return axios.get(url).then((resp) => {
        commit("setBonuses", resp.data.data);
        state.loading = false;
      });
    },
    async fetchSettings({ commit }) {
      return axios.get("thanksgiving-bonus/settings").then((resp) => {
        const { data } = resp;
        commit("setCanVote", data.can_vote);
        const votes = data.saved_votes;
        if (votes) {
          commit("setVotes", votes);
        }
      });
    },
    async saveVote({ state }) {
      state.submitting = true;
      const votes = state.votes
        .filter((v) => v.to_user_id)
        .map((v) => ({
          user_id: v.to_user_id,
          order: v.order,
        }));
      return axios.put("thanksgiving-bonus", { votes }).finally(() => {
        state.submitting = false;
      });
    },
  },
};

export default store;

<template>
  <pop-up-modal @close="close" :height="'auto'">
    <template>
      <div
        class="px-4 py-3 w-full h-auto overflow-y-scroll scrollbar-thumb-rounded-md scrollbar-thin scrollbar-thumb-gray-300"
        style="max-height: 80vh"
      >
        <div class="w-full pb-3 flex justify-between y text-black leading-5">
          <h1 class="text-lg font-bold">Add bonus</h1>
          <p class="text-gray-500">Add bonus to {{ user ? user.name : "your" }} sheet</p>
        </div>

        <div class="w-full flex-1 flex flex-col justify-start pb-3">
          <field-item :label="'Date'">
            <template #input>
              <date-picker
                v-model="bonusSheet.date"
                valueType="format"
                format="YYYY-MM-DD hh:mm A"
                :use12h="true"
                type="datetime"
                class="w-full"
                placeholder="Choose time..."
              ></date-picker>
            </template>
          </field-item>

          <field-item :label="'Salary'">
            <template #input>
              <input class="w-full" type="number" v-model="bonusSheet.salary" />
            </template>
          </field-item>

          <field-item :label="'HUF Exchange Rate'">
            <template #input>
              <input class="w-full" type="number" v-model="bonusSheet.huf_exchange_rate" />
            </template>
          </field-item>

          <field-item :label="'Project'">
            <template #input>
              <input class="w-full" type="text" :value="'Bonus'" disabled />
            </template>
          </field-item>

          <field-item :label="'Comment'">
            <template #input>
              <input class="w-full" type="text" v-model="bonusSheet.comment" />
            </template>
          </field-item>

          <field-item :label="'Approved'">
            <template #input>
              <toggle-button :label="'Approved'" class="ml-2" v-model="bonusSheet.approved" />
            </template>
          </field-item>

          <field-item :label="'Company'">
            <template #input>
              <v-select
                placeholder="No company selected"
                :options="companyBranches"
                v-model="bonusSheet.company"
                class="selector"
              />
            </template>
          </field-item>

          <field-item :label="'Payment No.'">
            <template #input>
              <input class="w-full" type="text" v-model="bonusSheet.payment_no" />
            </template>
          </field-item>

          <field-item :label="'Payment Date'">
            <template #input>
              <date-picker
                v-model="bonusSheet.payment_date"
                valueType="format"
                format="YYYY-MM-DD"
                type="datetime"
                class="w-full"
                placeholder="Choose time..."
              ></date-picker>
            </template>
          </field-item>
        </div>
      </div>
    </template>

    <template #actionButton>
      <button-primary
        @click.native="save"
        class="px-1 self-end mb-1 text-sm py-1 transition-colors duration-200 disabled:opacity-80"
        :disabled="!isValid || loading"
      >
        Add
      </button-primary>
    </template>
  </pop-up-modal>
</template>

<script>
import { mapGetters } from "vuex";
import DatePicker from "vue2-datepicker";
import { DateTime } from "luxon";
import ButtonPrimary from "./ButtonPrimary.vue";
import PopUpModal from "./PopUpModal.vue";
import FieldItem from "./FieldItem.vue";
import ToggleButton from "./ToggleButton.vue";

export default {
  components: {
    ButtonPrimary,
    PopUpModal,
    DatePicker,
    FieldItem,
    ToggleButton,
  },
  props: {
    user: Object,
  },
  data() {
    return {
      loading: false,
      bonusSheet: {
        date: DateTime.now().toFormat("kkkk-MM-dd hh:mm a"),
        salary: 0,
        huf_exchange_rate: 0,
        comment: null,
        approved: 1,
        company: null,
        payment_no: null,
        payment_date: DateTime.now().toFormat("kkkk-MM-dd"),
      },
    };
  },
  methods: {
    close(data = null) {
      this.$emit("close", data);
    },
    async save() {
      if (!this.isValid) return;
      this.loading = true;
      try {
        const resp = await this.axios.post("sheet-bonuses", {
          ...this.bonusSheet,
          user_id: this.user?.id,
        });
        this.close(resp.data.data);
      } catch (e) {
        //
      } finally {
        this.loading = false;
      }
    },
    beforeToday(date) {
      return DateTime.fromJSDate(date) <= DateTime.now().minus({ days: 1 });
    },
  },
  computed: {
    isValid() {
      return this.bonusSheet.date;
    },
    ...mapGetters("trackingsSheet", ["companyBranches"]),
  },
};
</script>

<style></style>

<template>
  <div class="flex flex-col">
    <div class="w-100 y md:x">
      <!-- Time Spent -->
      <div class="flex-1 y mb-4 md:mr-4 md:mb-0">
        <v-select
          v-if="!loading"
          label="title"
          :options="selectableIssues"
          v-model="selectedIssue"
          :disabled="selectedTab === 'tracks' && trackingsLoading"
          :reduce="(i) => (i.id ? i : null)"
          :clearable="!!(selectedIssue && selectedIssue.id)"
          class="chooser h-12 w-full mb-4 rounded"
        >
          <template #option="{ state, title }">
            <p class="whitespace-normal" :class="state === 'closed' ? 'text-gray-400' : ''">
              {{ title }}
              <span
                v-show="state === 'closed'"
                style="font-size: 0.6rem"
                class="inline-flex ml-2 items-center justify-center px-2 py-1 font-bold leading-none text-red-100 bg-red-500 rounded-full"
                >Closed</span
              >
            </p>
          </template>
        </v-select>
        <div class="x s-6">
          <div class="flex flex-col mr-2" v-if="timeEstimated">
            <span class="text-xs mb-2"> Estimated Time </span>
            <span class="font-bold text-base md:text-2xl tracking-wider">
              {{ timeEstimated.hours }} <span class="text-xs">hours</span>
              {{ timeEstimated.minutes }}
              <span class="text-xs">mins</span>
            </span>
          </div>
          <div class="flex flex-col" v-if="timeSpent">
            <span class="text-xs mb-2"> Spent Time </span>
            <span class="font-bold text-base md:text-2xl tracking-wider">
              {{ timeSpent.hours }} <span class="text-xs">hours</span> {{ timeSpent.minutes }}
              <span class="text-xs">mins</span>
            </span>
          </div>
          <div class="flex flex-col justify-end" v-if="timeSpent">
            <i
              title="Report"
              class="isax isax-timer-1 text-2xl cursor-pointer"
              @click="$emit('openReportModal')"
            />
          </div>
        </div>
      </div>

      <div class="flex-1">
        <timer
          @started="timerStarted"
          @stopped="stopTimer"
          class="w-full mb-4"
          ref="trackingTimer"
          @timerChanged="onTimerChanged"
        />
        <!-- COMMENT -->
        <textarea
          class="border-1 p-1 rounded w-full h-28"
          placeholder="Add comment..."
          v-model="comment"
        />
        <!-- SAVE -->
        <div class="y md:x s-2 justify-end self-end mt-1.5 md:h-9 w-full">
          <button
            class="h-full w-full flex-2 flex py-2 justify-center items-center px-3 rounded-md text-white disabled:opacity-50"
            style="background: #ff6700"
            @click="saveTracking()"
            :disabled="!canSaveTracking"
          >
            <icon-save-tracking />
            <span
              class="align-top text-xl ml-3.5"
              style="height: 24px !important; line-height: 22px !important"
            >
              Save Tracking
            </span>
          </button>
        </div>
      </div>
    </div>
    <div
      class="w-1/2 self-end mt-3 text-red-500 font-bold text-xs px-2 x"
      v-if="!canStartTracking && triedToSubmit"
    >
      <i class="isax isax-danger mt-0.5 mx-2 h-full" />
      {{ canStartTrackingMessage }}
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import IconSaveTracking from "../assets/icons/IconSaveTracking.vue";
import Timer from "./Timer.vue";
import getConfigsByToken from "../config/pusher.config";

export default {
  name: "Tracking",
  components: {
    Timer,
    IconSaveTracking,
  },
  props: {
    project: Object,
  },
  beforeDestroy() {
    if (this.socketWorker) this.socketWorker.terminate();
  },
  data() {
    return {
      isStopped: true,
      comment: null,
      timeTracks: [],
      estimation_hours: 0,
      estimation_minutes: 0,
      timeTracksLoading: false,
      time_estimated: null,
      socketWorker: null,
      triedToSubmit: false,
    };
  },
  computed: {
    ...mapState("tracking", [
      "timer",
      "issues",
      "submitting",
      "trackingsLoading",
      "selectedTab",
      "loading",
      "canTrack",
      "canTrackMessage",
    ]),
    ...mapGetters("tracking", ["trackings"]),
    selectedIssue: {
      get() {
        return this.$store.state.tracking.selectedIssue;
      },
      set(val) {
        this.$store.commit("tracking/setIssue", val);
      },
    },
    selectableIssues() {
      const issues = [{ title: "No issue selected" }];
      if (this.issues) {
        issues.push(...this.issues.filter((i) => i.state !== "closed"));
      }
      return issues;
    },
    displayTime() {
      const time = `${String(this.timer.hours).padStart(2, "0")}h ${String(
        this.timer.minutes,
      ).padStart(2, "0")}m ${String(this.timer.seconds).padStart(2, "0")}s`;
      return time;
    },
    timeSpent() {
      if (this.selectedIssue)
        return this.getHoursAndMinutesFromSeconds(this.selectedIssue.time_stats.total_time_spent);
      let totalTimeSpent = 0;
      this.issues.forEach((issue) => {
        totalTimeSpent += issue.time_stats.total_time_spent;
      });
      return this.getHoursAndMinutesFromSeconds(totalTimeSpent);
    },
    timeEstimated() {
      if (this.selectedIssue)
        return this.getHoursAndMinutesFromSeconds(this.selectedIssue.time_stats.time_estimate);

      return {
        hours: 0,
        minutes: 0,
      };
    },
    canSave() {
      return (
        this.selectedIssue !== null &&
        (this.timer.hours > 0 || this.timer.minutes > 0) &&
        !this.submitting
      );
    },
    canSaveTracking() {
      return this.canSave && this.comment !== null && this.comment.length > 0;
    },
    isTimerStarted() {
      return !this.$refs.trackingTimer.isStopped;
    },
    canStartTracking() {
      return (
        this.canTrack &&
        this.selectedIssue &&
        (!this.selectedIssue || this.selectedIssue.state === "opened")
      );
    },
    canStartTrackingMessage() {
      if (!this.selectedIssue)
        return "First of all, you have to choose an issue to track time spent.";
      if (!this.canTrack) return this.canTrackMessage;
      return "You cannot track to closed issues. First please reopen the issue.";
    },
  },
  mounted() {
    this.setSavedTrackingData();
  },
  methods: {
    setSavedTrackingData() {
      const trackingData = this.getSavedTrackingData();
      if (trackingData) {
        if (trackingData.timer) this.setTimer(trackingData.timer);
        if (trackingData.comment) this.comment = trackingData.comment;
      }
    },
    getSavedTrackingData() {
      try {
        let savedData = localStorage.getItem(
          `${this.project.id}_tracking_${this.selectedIssue ? this.selectedIssue.id : ""}`,
        );
        savedData = JSON.parse(savedData);
        return savedData;
      } catch (e) {
        return null;
      }
    },
    saveLocalTrackingData() {
      const trackingData = {
        timer: { ...this.timer },
        comment: this.comment,
      };
      localStorage.setItem(
        `${this.project.id}_tracking_${this.selectedIssue ? this.selectedIssue.id : ""}`,
        JSON.stringify(trackingData),
      );
    },
    setTimer(val) {
      this.$store.commit("tracking/setTimer", val);
      this.$refs.trackingTimer.manuallySetTimer(val.hours, val.minutes, val.seconds);
    },
    onTimerChanged(val) {
      this.$store.commit("tracking/setTimer", val);
      this.saveLocalTrackingData();
    },
    async saveTracking() {
      await this.checkCanTrack();
      this.triedToSubmit = true;
      if (!this.canSaveTracking || !this.canStartTracking) return;
      const saved = await this.$store.dispatch("tracking/saveTracking", {
        projectId: this.project.id,
        issueIid: this.selectedIssue.iid,
        timer: this.timer,
        comment: this.comment,
      });

      if (saved) {
        this.selectedIssue.time_stats.total_time_spent +=
          this.timer.hours * 60 * 60 + this.timer.minutes * 60 + this.timer.seconds;
        this.setTimer({
          seconds: 0,
          minutes: 0,
          hours: 0,
        });
        this.$refs.trackingTimer.reset();
        this.comment = "";
        this.$store.dispatch("tracking/getTrackings", true);
      }
    },
    getHoursAndMinutesFromSeconds(seconds) {
      const hours = String(Math.floor(seconds / 3600)).padStart(2, "0");
      const minutes = String(Math.floor((seconds % 3600) / 60)).padStart(2, "0");
      return {
        hours,
        minutes,
      };
    },
    startSocketWorker() {
      if (!this.socketWorker) this.socketWorker = new Worker("/scripts/tracking.worker.js");

      const { user } = this.$store.getters;
      const { project } = this;
      const issue = this.selectedIssue;

      this.socketWorker.postMessage({
        action: "start",
        startTimer: this.timer,
        trackingData: {
          name: user.name,
          username: user.username,
          avatar: user.avatar,
          issue: {
            id: issue ? issue.id : null,
            title: issue ? issue.title : "No issue selected",
          },
          project: {
            id: project.id,
            title: project?.name_with_namespace,
          },
        },
        pusherConfigs: {
          ...getConfigsByToken(this.$store.getters.token),
          token: this.$store.getters.token,
          channel: "private-now-tracking",
          event: "client-tracking",
        },
      });
    },
    stopSocketWorker() {
      this.socketWorker.postMessage({
        action: "stop",
      });
    },
    timerStarted() {
      this.startSocketWorker();
    },
    stopTimer() {
      this.stopSocketWorker();
    },
    ...mapActions("tracking", ["checkCanTrack", "getProjectAndIssues"]),
  },
  watch: {
    comment() {
      this.saveLocalTrackingData();
    },
    displayTime() {
      this.saveLocalTrackingData();
    },
    selectedIssue() {
      if (this.isTimerStarted) {
        this.stopSocketWorker();
        this.startSocketWorker();
      }
      if (this.timer.hours === 0 && this.timer.minutes === 0 && this.timer.seconds === 0)
        this.setSavedTrackingData();
    },
  },
};
</script>

<style>
.chooser .vs__search {
  @apply border-none;
}
.chooser .vs__dropdown-toggle {
  @apply h-12 border-gray-200;
}
.chooser .vs__selected-options {
  @apply flex-nowrap;
}
</style>

<template>
  <tbody class="bg-white shadow-md rounded-md">
    <tr>
      <td
        scope="col"
        class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
      >
        <user-excerpt :classes="'y text-center'" v-if="assignee" :user="assignee" />
      </td>
      <td
        scope="col"
        class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
      >
        <a class="font-bold text-gray-900 pb-2" :href="issue.project.web_url" target="_blank">
          {{ issue.project.name }}
        </a>
        <br />
        <span class="text-gray-600 font-small" style="font-size: 0.7rem">
          {{ issue.project.path_with_namespace }}</span
        >
      </td>
      <td
        scope="col"
        class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
      >
        <a :href="issue.web_url" target="_blank" :title="issue.title">
          {{ issue.id }}
        </a>
      </td>
      <td
        scope="col"
        class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
      >
        {{ issue.due_date }}
      </td>
      <td
        scope="col"
        class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
      >
        {{ issue.closed_at | formatDate }}
      </td>
      <td
        scope="col"
        class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
      >
        {{ issue.estimated_time | secondsToHuman }}
      </td>
      <td
        scope="col"
        class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
      >
        {{ issue.spent_time | secondsToHuman }}
      </td>
      <td
        scope="col"
        class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
      >
        {{ issue.remaining_time | secondsToHuman }}
      </td>
      <td
        scope="col"
        class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
      >
        {{ issue.saved_time | secondsToHuman }}
      </td>
      <td
        scope="col"
        class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
      >
        {{ issue.deductible_time | secondsToHuman }}
      </td>
      <td
        scope="col"
        class="px-3 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
      >
        <div v-if="canEdit" class="x items-center justify-center text-xs gap-x-1">
          <div
            :title="issue.pushed ? 'Un-Push' : 'Push'"
            class="x items-center justify-center shadow-md bg-white text-orange rounded-full cursor-pointer transition-colors hover:bg-gray-50"
            style="height: 40px; width: 40px; text-align: center"
            @click="issue.pushed ? unpush() : push()"
          >
            <i
              class="isax text-2xl"
              :class="[
                pushing
                  ? 'isax-rotate-left animate-spin'
                  : issue.pushed
                  ? 'isax-calendar-remove'
                  : 'isax-calendar-circle',
              ]"
            ></i>
          </div>
          <div
            title="Save time"
            class="x items-center justify-center shadow-md bg-orange text-white rounded-full cursor-pointer transition-colors hover:bg-brand"
            style="height: 40px; width: 40px; text-align: center"
            @click="save"
          >
            <i
              class="isax text-xl"
              :class="[savingTime ? 'isax-rotate-left animate-spin' : 'isax-flag']"
            ></i>
          </div>
          <save-issue-time-modal
            :issue="issue"
            v-if="showSaveTimeModal"
            @close="showSaveTimeModal = false"
            @onSubmit="onSaveTimeModalSubmit"
          />
        </div>
      </td>
    </tr>
  </tbody>
</template>

<script>
import { mapActions } from "vuex";
import alerts from "../utils/alerts";
import SaveIssueTimeModal from "./SaveIssueTimeModal.vue";
import UserExcerpt from "./UserExcerpt.vue";

export default {
  components: {
    UserExcerpt,
    SaveIssueTimeModal,
  },
  props: {
    issue: {
      type: Object,
      default: () => null,
    },
    canEdit: Boolean,
  },
  data() {
    return {
      pushing: false,
      savingTime: false,
      showSaveTimeModal: false,
    };
  },
  methods: {
    async push() {
      if (this.pushing) return false;
      const confirm = await alerts.showConfirm({
        title: "Push issue",
        message: `Are you sure you want to push this issue to the next month?`,
      });
      if (!confirm) return false;
      this.pushing = true;
      try {
        await this.pushIssue({ issueId: this.issue.id });
      } finally {
        this.pushing = false;
      }
      return true;
    },
    async unpush() {
      if (this.pushing) return false;
      const confirm = await alerts.showConfirm({
        title: "Un-Push issue",
        message: `Are you sure you want to un-push this issue back to its' month?`,
      });
      if (!confirm) return false;
      this.pushing = true;
      try {
        await this.unPushIssue({ issueId: this.issue.id });
      } finally {
        this.pushing = false;
      }
      return true;
    },
    async save() {
      this.showSaveTimeModal = true;
    },
    async onSaveTimeModalSubmit(savedTime) {
      this.savingTime = true;
      try {
        await this.saveTime({ issueId: this.issue.id, savedTime });
      } finally {
        this.savingTime = false;
      }
    },
    ...mapActions("issuesBonus", ["pushIssue", "unPushIssue", "saveTime"]),
  },
  computed: {
    assignee() {
      const { assignee } = this.issue;
      return {
        ...assignee,
        avatar: assignee.avatar_url,
      };
    },
  },
};
</script>

<style>
.icon-btn {
  @apply px-3 py-1 text-sm text-center rounded bg-white text-black capitalize shadow-md cursor-pointer hover:shadow-lg transition-all;
}
</style>

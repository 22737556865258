function parseObjectToParams(obj) {
  const nullRemoved = { ...obj };
  Object.keys(nullRemoved).forEach(
    (k) => (nullRemoved[k] === null || nullRemoved[k] === "") && delete nullRemoved[k],
  );
  return new URLSearchParams(nullRemoved).toString();
}

function toTitleCase(str) {
  return str.charAt(0).toUpperCase() + str.substr(1);
}

function subtractTime(time1, time2) {
  // Split the time strings into hours and minutes
  const [hours1, minutes1] = time1.split(":").map(Number);
  const [hours2, minutes2] = time2.split(":").map(Number);

  // Convert the times to minutes
  const totalMinutes1 = hours1 * 60 + minutes1;
  const totalMinutes2 = hours2 * 60 + minutes2;

  // Subtract the second time from the first
  const differenceInMinutes = totalMinutes1 - totalMinutes2;

  // Convert the difference back to hours and minutes
  const hours = Math.floor(differenceInMinutes / 60);
  const minutes = differenceInMinutes % 60;

  // Return the result as a string in "hh:mm" format
  return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
}

export default {
  parseObjectToParams,
  toTitleCase,
  subtractTime,
};

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import TrackingIssues from "../views/TrackingIssues.vue";
import Tracking from "../views/Tracking.vue";
import TrackingTodos from "../views/TrackingTodos.vue";
import TrackingGantt from "../views/TrackingGantt.vue";
import NowTracking from "../views/NowTracking.vue";
import ProjectChooser from "../views/ProjectChoose.vue";
import UserIssues from "../views/UserIssues.vue";
import UserProjects from "../views/UserProjects.vue";
import Users from "../views/Users.vue";
import AllTrackings from "../views/AllTrackings.vue";
import User from "../views/User.vue";
import Ideas from "../views/Ideas.vue";
import AddIdea from "../views/AddIdea.vue";
import BonusTracker from "../views/BonusTracker.vue";
import IssuesBonus from "../views/IssuesBonus.vue";
import ThanksGivingBonus from "../views/ThanksGivingBonus.vue";
import BonusQuestions from "../views/BonusQuestions.vue";
import Overview from "../views/Overview.vue";
import Downloads from "../views/Downloads.vue";
import store from "../store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/projects",
    name: "Project Choose",
    redirect: {
      name: "SelectProject",
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/auth/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/gantt",
    name: "Tracking Gantt",
    component: TrackingGantt,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/users/:id/todos",
    name: "Tracking Todos",
    props: true,
    component: TrackingTodos,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/now-tracking",
    name: "NowTracking",
    component: NowTracking,
    meta: {
      requiresAuth: true,
      onlyInternalUsers: true,
    },
  },
  {
    path: "/projects/:project*/track",
    name: "Tracking Issues",
    props: true,
    component: TrackingIssues,
    meta: {
      requiresAuth: true,
      onlyInternalUsers: true,
    },
  },
  {
    path: "/tracking",
    name: "Tracking",
    props: true,
    component: Tracking,
    meta: {
      requiresAuth: true,
      onlyInternalUsers: true,
    },
  },
  {
    path: "/projects/:group*",
    name: "SelectProject",
    component: ProjectChooser,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
    meta: {
      requiresAuth: true,
      onlyInternalUsers: true,
    },
  },
  {
    path: "/trackings",
    name: "Users",
    component: AllTrackings,
    meta: {
      requiresAuth: true,
      onlyInternalUsers: true,
    },
  },
  {
    path: "/users/:id/user",
    name: "User",
    props: true,
    component: User,
    meta: {
      requiresAuth: true,
      onlyInternalUsers: true,
    },
  },
  {
    path: "/users/:id/issues",
    name: "User Issues",
    component: UserIssues,
    props: true,
    meta: {
      requiresAuth: true,
      onlyInternalUsers: true,
    },
  },
  {
    path: "/users/:id/all-projects",
    name: "User Projects",
    component: UserProjects,
    props: true,
    meta: {
      requiresAuth: true,
      onlyInternalUsers: true,
    },
  },
  {
    path: "/overview/all-projects",
    name: "Overview Projects",
    component: UserProjects,
    meta: {
      requiresAuth: true,
      onlyInternalUsers: true,
    },
  },
  {
    path: "/overview/issues",
    name: "Overview Issues",
    component: UserIssues,
    meta: {
      requiresAuth: true,
      onlyInternalUsers: true,
    },
  },
  {
    path: "/overview/todos",
    name: "Overview Todos",
    component: TrackingTodos,
    meta: {
      requiresAuth: true,
      onlyInternalUsers: true,
    },
  },
  {
    path: "/ideas",
    name: "Ideas",
    component: Ideas,
    meta: {
      requiresAuth: true,
      onlyInternalUsers: true,
    },
  },
  {
    path: "/ideas/add",
    name: "AddIdea",
    component: AddIdea,
    meta: {
      requiresAuth: true,
      onlyInternalUsers: true,
    },
  },
  {
    path: "/bonus-tracker",
    name: "BonusTracker",
    component: BonusTracker,
    meta: {
      requiresAuth: true,
      onlyInternalUsers: true,
    },
  },
  {
    path: "/issues-bonus",
    name: "IssuesBonus",
    component: IssuesBonus,
    meta: {
      requiresAuth: true,
      onlyInternalUsers: true,
    },
  },
  {
    path: "/bonus-tracker/connections/:id/questions/:date",
    name: "BonusConnectionQuestions",
    component: BonusQuestions,
    meta: {
      requiresAuth: true,
      onlyInternalUsers: true,
    },
  },
  {
    path: "/bonus-tracker/results/:id/questions",
    name: "BonusResultQuestions",
    component: BonusQuestions,
    meta: {
      requiresAuth: true,
      onlyInternalUsers: true,
    },
  },
  {
    path: "/bonus-tracker/results/:id/view",
    name: "BonusResult",
    component: BonusQuestions,
    meta: {
      requiresAuth: true,
      onlyInternalUsers: true,
    },
    props: {
      viewOnly: true,
    },
  },
  {
    path: "/thanks-giving-bonus",
    name: "ThanksGivingBonus",
    component: ThanksGivingBonus,
  },
  {
    path: "/overview",
    name: "Overview",
    component: Overview,
    meta: {
      requiresAuth: true,
      onlyInternalUsers: true,
    },
  },
  {
    path: "/downloads",
    name: "Overview Milestones",
    component: Downloads,
    meta: {
      requiresAuth: true,
      onlyInternalUsers: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth) && !store.getters.isLoggedIn) {
    next("/auth/login");
    return;
  }

  if (to.matched.some((record) => record.meta.onlyInternalUsers) && store.getters.isExternal) {
    next("/");
    return;
  }

  next();
});

export default router;

const gantt = {
  namespaced: true,
  state: {
    ganttTasks: [],
    ganttLinks: [],
    filterUsers: [],
    selectedUser: null,
    filters: {
      assignee: null,
      state: "active",
      search: null,
    },
    ganttEvents: [],
  },
  mutations: {
    addGanttTasks: (state, ganttTasks) => {
      state.ganttTasks = ganttTasks;
    },
    addGanttLinks: (state, ganttLinks) => {
      state.ganttLinks = ganttLinks;
    },
    setFilterUsers: (state, filterUsers) => {
      state.filterUsers = filterUsers;
    },
    setFilters: (state, filters) => {
      state.filters = filters;
    },
    setGanttEvents(state, events) {
      state.ganttEvents = events;
    },
  },
  getters: {
    ganttTasks: (state) => state.ganttTasks,
    ganttLinks: (state) => state.ganttLinks,
    filterUsers: (state) => state.filterUsers,
    filters: (state) => state.filters,
    ganttEvents: (state) => state.ganttEvents,
  },
  actions: {
    updateGanttTasks({ commit }, ganttTasks) {
      commit("addGanttTasks", ganttTasks);
    },
    updateGanttLinks({ commit }, ganttLinks) {
      commit("addGanttLinks", ganttLinks);
    },
  },
};

export default gantt;

<template>
  <div
    class="w-full x items-center justify-center pt-2"
    style="min-height: 20px"
    v-observe-visibility="visibilityChanged"
  >
    <loading v-if="loading" />
  </div>
</template>

<script>
import Loading from "./Loading.vue";

export default {
  components: { Loading },
  data() {
    return {
      waiting: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.waiting = false;
    }, 1000);
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    scrollWrapper: {
      type: String,
      default: () => ".main-content",
    },
  },
  methods: {
    visibilityChanged(isVisible) {
      if (isVisible && !this.waiting && !this.loading && !this.disabled) {
        this.loadMore();
      }
    },
    async loadMore() {
      this.$emit("loadMore");
    },
  },
};
</script>

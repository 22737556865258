<template>
  <clickable-item
    :route="{
      path: `/projects/${project.path_with_namespace}/track`,
    }"
  >
    <div class="w-full x justify-start pl-2 py-2 s-3 items-center">
      <div
        v-if="project.avatar_url"
        class="w-9 h-9 rounded x items-center justify-center"
        style="border: 1px solid #f5f5f5"
      >
        <img class="rounded" :src="project.avatar_url" />
      </div>

      <span class="w-9 h-9 bg-gray-200 text-lg x items-center justify-center" v-else>{{
        project.name.toUpperCase()[0]
      }}</span>

      <div class="y items-start flex-1 w-1/2 md:w-auto one-line-clamp">
        <pinnable :id="project.id" :type="'project'">
          <p class="font-bold hover:underline">
            <span v-if="getProjectGroup(project)">
              {{ getProjectGroup(project) | titleCase }}
              <i class="isax isax-arrow-right-3 text-md" />
            </span>
            {{ project.name | titleCase }}
          </p>
        </pinnable>
        <span class="text-sm">{{ project.path_with_namespace }}</span>
      </div>

      <div class="y md:x w-1/5 md:w-1/3" v-if="showMyRole">
        <p class="md:mx-3 md:font-bold text-xs md:text-sm">{{ project.role }}</p>
        <span class="x gap-x-0.5 items-center text-black md:font-bold">
          <i class="isax isax-clock mr-1 text-xs" />
          <p class="text-xs font-normal">
            {{ project.role_start_date }}
            <span v-if="project.role_expires_at">- {{ project.role_expires_at }}</span>
          </p>
        </span>
      </div>

      <div class="px-5" v-if="showAssignees">
        <div
          v-for="user in project.assignees"
          v-bind:key="user.id"
          :user="user"
          v-show="user.pivot.role === 'Project Manager'"
        >
          <clickable-item
            class="border-transparent"
            :route="{
              path: `/users/${user.id}/user`,
            }"
            @click.native="$event.stopPropagation()"
          >
            <div class="x gap-x-2">
              <img class="h-8 w-8 rounded-full object-cover" :src="user.avatar" alt="avatar" />
              <div class="y">
                <b class="hover:underline">
                  {{ user.name }}
                </b>
                <span>{{ user.pivot.role }}</span>
              </div>
            </div>
          </clickable-item>
        </div>
      </div>
    </div>
  </clickable-item>
</template>

<script>
import ClickableItem from "./ClickableItem.vue";
import Pinnable from "./Pinnable.vue";

export default {
  components: { ClickableItem, Pinnable },
  props: {
    project: {
      type: Object,
      required: true,
    },
    showMyRole: {
      type: Boolean,
      default: () => false,
    },
    showAssignees: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    getProjectGroup(project) {
      const namesArray = project.path_with_namespace.split("/");
      if (namesArray.length < 2) return null;
      const name = namesArray[namesArray.length - 2];
      return name.split("-").join(" ");
    },
  },
};
</script>

<style></style>

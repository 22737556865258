import axios from "axios";

const bonusStore = {
  namespaced: true,
  state: {
    issues: [],
    issuesLoading: false,
    projects: [],
    projectsLoading: [],
  },
  getters: {
    issues: (state) => state.issues,
    issuesLoading: (state) => state.issuesLoading,
    projects: (state) => state.projects,
    projectsLoading: (state) => state.projectsLoading,
  },
  mutations: {
    setProjects(state, projects) {
      state.projects = projects;
    },
    setProjectsLoading(state, projectsLoading) {
      state.projectsLoading = projectsLoading;
    },
    setIssues(state, issues) {
      state.issues = issues;
    },
    setIssuesLoading(state, issuesLoading) {
      state.issuesLoading = issuesLoading;
    },
  },
  actions: {
    async fetchProjects({ commit }) {
      commit("setProjectsLoading", true);
      const url = `/projects/all`;
      return axios
        .get(url)
        .then((resp) => {
          commit("setProjects", resp.data.data);
        })
        .finally(() => {
          commit("setProjectsLoading", false);
        });
    },
    async fetchIssues({ commit }, projectId) {
      commit("setIssues", []);
      commit("setIssuesLoading", true);
      const url = `/projects/${projectId}/all-issues`;
      return axios
        .get(url)
        .then((resp) => {
          commit("setIssues", resp.data);
        })
        .finally(() => {
          commit("setIssuesLoading", false);
        });
    },
  },
};

export default bonusStore;

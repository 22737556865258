import axios from "axios";

const urlBase64ToUint8Array = (base64String) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  /* eslint-disable-next-line */
  const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/");
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; i += 1) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

const getSWRegistration = () => {
  return navigator.serviceWorker.getRegistration();
};

export default {
  namespaced: true,
  state: {
    notifications: [],
    unreadNotificationsCount: 0,
    page: 1,
    loading: false,
    noMore: false,
  },
  mutations: {
    setNotifications(state, notifications) {
      state.notifications = notifications;
    },
    setUnreadNotificationsCount(state, unreadNotificationsCount) {
      state.unreadNotificationsCount = unreadNotificationsCount;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setPage(state, page) {
      state.page = page;
    },
    setNoMore(state, noMore) {
      state.noMore = noMore;
    },
  },
  actions: {
    fetchNotifications({ commit, state }, force = false) {
      if (force) commit("setPage", 1);
      else if (state.noMore) return null;
      commit("setLoading", true);
      return axios
        .get(`notifications?page=${state.page}`)
        .then(
          (response) => {
            const { notifications, unread_notifications_count: unreadNotificationsCount } =
              response.data.data;

            commit("setNotifications", [
              ...(state.page === 1 ? [] : state.notifications),
              ...notifications.data,
            ]);
            commit("setUnreadNotificationsCount", unreadNotificationsCount);

            if (notifications.meta.per_page > notifications.data.length) {
              commit("setNoMore", true);
            }

            commit("setPage", state.page + 1);
          },
          (error) => console.log(error),
        )
        .finally(() => commit("setLoading", false));
    },
    markAsRead({ commit, state }, notificationId) {
      return axios
        .put(`notifications/${notificationId}`, {
          read: true,
        })
        .then(
          () => {
            commit("setUnreadNotificationsCount", state.unreadNotificationsCount - 1);
            const n = state.notifications.find(
              (notification) => notification.id === notificationId,
            );
            n.read = true;
          },
          (error) => console.log(error),
        );
    },
    markAllAsRead({ commit, state }) {
      return axios.post("notifications/mark-all-as-read").then(
        () => {
          commit("setUnreadNotificationsCount", 0);
          commit(
            "setNotifications",
            state.notifications.map((notification) => ({ ...notification, read: true })),
          );
        },
        (error) => console.log(error),
      );
    },
    askPermission({ dispatch }) {
      return new Promise((resolve, reject) => {
        const permissionResult = Notification.requestPermission((result) => {
          resolve(result);
        });
        if (permissionResult) {
          permissionResult.then(resolve, reject);
        }
      }).then((permissionResult) => {
        if (permissionResult !== "granted") {
          throw new Error("We weren't granted permission.");
        } else {
          dispatch("subscribeUserToPush");
        }
      });
    },
    unsubscribe({ dispatch }) {
      dispatch("unsubscribeUserToPush");
      localStorage.setItem("notification_subscribed", false);
    },
    unsubscribeUserToPush() {
      getSWRegistration().then((registration) => {
        registration.pushManager.getSubscription().then(function (subscription) {
          subscription.unsubscribe();
        });
      });
    },
    subscribeUserToPush({ dispatch }) {
      getSWRegistration()
        .then((registration) => {
          const subscribeOptions = {
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(process.env.VUE_APP_PUBLIC_VAPID_KEY),
          };
          return registration.pushManager.subscribe(subscribeOptions);
        })
        .then((pushSubscription) => {
          dispatch("sendSubscriptionToBackEnd", pushSubscription);
          localStorage.setItem("notification_subscribed", true);
          return pushSubscription;
        });
    },
    sendSubscriptionToBackEnd(_, subscription) {
      return axios("/notifications/subscribe", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(subscription),
      }).then((responseData) => {
        if (!(responseData.data && responseData.data.success)) {
          throw new Error("Bad response from server.");
        }
      });
    },
    enableNotifications({ dispatch, state, commit }) {
      if (localStorage.getItem("notification_subscribed") !== "true") dispatch("askPermission");
      navigator.serviceWorker.addEventListener("message", function (event) {
        if (event.data) {
          const data = JSON.parse(event.data);
          if (data.type === "push") {
            const { notification } = data.data;
            commit("setNotifications", [notification, ...state.notifications]);
            commit("setUnreadNotificationsCount", state.unreadNotificationsCount + 1);
          }
        }
      });
    },
  },
};

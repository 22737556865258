import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";
import tracking from "./modules/tracking";
import projects from "./modules/projects";
import trackingsSheet from "./modules/trackingsSheet";
import toasts from "./modules/toasts";
import gantt from "./modules/gantt";
import userInfo from "./modules/user";
import alerts from "./modules/alert";
import workSchedule from "./modules/workSchedule";
import ideaStore from "./modules/idea";
import bonusStore from "./modules/bonus";
import issuesBonusStore from "./modules/issuesBonus";
import thanksgivingBonusStore from "./modules/thanksgivingBonus";
import homeStore from "./modules/home";
import notifications from "./modules/notifications";
import calendar from "./modules/calendar";
import options from "./modules/options";
import getConfigsByToken from "../config/pusher.config";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    projectSelectionString: null,
    issues: [],
    user: null,
    statics: null,
    token: null,
    trackings: [],
    milestones: [],
    ganttTasks: [],
    pins: [],
    usersMap: null,
    loadingUsersMap: false,
    timezone: null,
    requestsCTS: new Map(),
  },
  mutations: {
    updateTrackings: (state, track) => {
      const idx = state.trackings.findIndex(
        (t) => t.username === track.username && t.project.id === track.project.id,
      );
      const now = new Date().getTime();
      const trackData = { ...track, lastSeen: now };

      if (idx !== -1) state.trackings[idx] = trackData;
      else state.trackings.push(trackData);

      state.trackings = JSON.parse(
        JSON.stringify(state.trackings.sort((a, b) => a.name.localeCompare(b.name))),
      );
    },
    addGanttTasks: (state, ganttTasks) => {
      state.ganttTasks = ganttTasks;
    },
    changeProjectSelectionString(state, string) {
      state.projectSelectionString = string;
    },
    setUser(state, user) {
      state.user = user;
      this.dispatch("saveUser", user);
    },
    setStatics(state, statics) {
      state.statics = statics;
    },
    setPins(state, pins) {
      state.pins = pins;
    },
    removePin(state, index) {
      state.pins.splice(index, 1);
    },
    setToken(state, token) {
      state.token = token;
      this.dispatch("saveToken", token);
    },
    setRequestsCTS(state, { request, source }) {
      state.requestsCTS.set(request, source);
    },
    setUsersMap(state, map) {
      state.usersMap = map;
    },
    setLoadingUsersMap(state, loading) {
      state.loadingUsersMap = loading;
    },
    setTimezone(state, timezone) {
      state.timezone = timezone;
      this.dispatch("saveTimezone", timezone);
    },
  },
  getters: {
    projectBreadcrumb: () => (fullPath, fullName) => {
      const splitPaths = fullPath.split("/");
      const splitNames = fullName.split("/");
      const newBreadcrumbLinks = [{ name: "Tracking", url: "/" }];
      splitPaths.forEach((p, index) => {
        const splitPathsCopy = JSON.parse(JSON.stringify(splitPaths));
        splitPathsCopy.splice(index + 1);
        const url = splitPathsCopy.join("/");
        newBreadcrumbLinks.push({
          name: splitNames[index],
          url: `/projects/${url}`,
        });
      });
      return newBreadcrumbLinks;
    },
    trackings: (state) => state.trackings,

    milestones: (state) => state.milestones,
    ganttTasks: (state) => state.ganttTasks,
    user: (state) => state.user,
    statics: (state) => state.statics,
    token: (state) => state.token,
    isLoggedIn: (state) => state.token && state.token !== "null",
    isExternal: (state) => state.user.state === "external",
    getTokenSourceByRequest: (state) => (token) => {
      return state.requestsCTS.get(token);
    },
    pinned:
      (state) =>
      (type, id, key = "id") => {
        return state.pins.find(
          (p) => p.pinnable_type.split("\\").pop().toLowerCase() === type && p.model[key] === id,
        );
      },
    timezone: (state) => state.timezone,
  },
  actions: {
    fetchUserInfo({ commit, dispatch }) {
      if (this.getters.isLoggedIn) {
        const user = localStorage.getItem("user");
        try {
          commit("setUser", JSON.parse(user));
          dispatch("fetchStatics");
          dispatch("fetchPins");
          dispatch("notifications/fetchNotifications");
        } catch (e) {
          return null;
        }
        return axios({ method: "get", url: "/user" }).then((resp) => {
          commit("setUser", resp.data);
        });
      }
      return null;
    },
    saveUser({ state }) {
      localStorage.setItem("user", JSON.stringify(state.user));
    },
    fetchStatics({ commit }) {
      axios({ method: "get", url: "/statics" }).then((resp) => {
        commit("setStatics", resp.data);
      });
    },
    fetchPins({ commit }) {
      axios({ method: "get", url: "/pins" }).then((resp) => {
        commit("setPins", resp.data.data);
      });
    },
    updateGanttTasks({ commit }, ganttTasks) {
      commit("addGanttTasks", ganttTasks);
    },
    changeSelection({ commit }, newProjectSelectionString) {
      commit("changeProjectSelectionString", newProjectSelectionString);
    },
    logout({ commit, dispatch }) {
      dispatch("notifications/unsubscribe");
      axios({ method: "post", url: "/auth/logout" });
      commit("setUser", "");
      commit("setToken", "");
    },
    fetchToken({ commit }) {
      const token = localStorage.getItem("accessToken");
      commit("setToken", token);
    },
    async fetchTimezone({ dispatch, commit }) {
      const timezone = (await dispatch("getTimezone")) ?? 2;
      commit("setTimezone", timezone);
    },
    saveToken({ state }) {
      localStorage.setItem("accessToken", state.token);
    },
    listenForTrackingSocket({ commit, getters }) {
      if (typeof Worker !== "undefined") {
        const worker = new SharedWorker("/scripts/live.tracking.worker.js");

        worker.port.onmessage = function (evt) {
          commit("updateTrackings", evt.data);
        };

        worker.onerror = function (err) {
          console.log(err.message);
          worker.port.close();
        };

        worker.onmessageerror = function (err) {
          console.log(err);
          worker.port.close();
        };

        const pusherConfig = getConfigsByToken(getters.token);

        worker.port.postMessage({ pusherKey: pusherConfig.key, pusherConfig });
      } else {
        window.Echo.connector.pusher.config.auth.headers.Authorization = `Bearer ${this.getters.token}`;
        window.Echo.private("now-tracking").listenForWhisper("tracking", (track) => {
          commit("updateTrackings", track);
        });
      }
    },
    async getUsersMap({ commit }) {
      commit("setLoadingUsersMap", true);
      try {
        const response = await axios.get(`colleauge-map`);
        commit("setUsersMap", response.data);
      } catch (e) {
        console.log(e);
      } finally {
        commit("setLoadingUsersMap", false);
      }
    },
    pin({ state, commit }, { type, id }) {
      const data = {
        type,
      };
      switch (type) {
        case "project":
          data.project_id = id;
          break;
        case "issue":
          data.issue_id = id;
          break;
        case "milestone":
          data.milestone_id = id;
          break;
        case "group":
          data.group_id = id;
          break;
        default:
          break;
      }
      return axios({ method: "post", url: `/pins`, data }).then((resp) => {
        const pin = resp.data.data;
        commit("setPins", [...state.pins, pin]);
      });
    },
    unpin({ state, commit, getters }, { id, type, key = "id" }) {
      const pin = getters.pinned(type, id, key);
      return axios({ method: "delete", url: `/pins/${pin.id}` }).then(() => {
        const idx = state.pins.indexOf(pin);
        commit("removePin", idx);
      });
    },
    addLink({ state, commit }, link) {
      return axios({ method: "post", url: `/links`, data: link }).then((resp) => {
        const pin = resp.data.data;
        commit("setPins", [...state.pins, pin]);
      });
    },
    updateLink({ state, commit }, link) {
      return axios({ method: "put", url: `/links/${link.id}`, data: link }).then(() => {
        const idx = state.pins.findIndex(
          (p) =>
            p.pinnable_type.split("\\").pop().toLowerCase() === "link" && p.model.id === link.id,
        );
        state.pins[idx].model = link;
        commit("setPins", [...state.pins]);
      });
    },
    deleteLink({ state, commit }, link) {
      return axios({ method: "delete", url: `/links/${link.id}` }).then(() => {
        const idx = state.pins.findIndex(
          (p) =>
            p.pinnable_type.split("\\").pop().toLowerCase() === "link" && p.model.id === link.id,
        );
        commit("removePin", idx);
      });
    },
    getTimezone() {
      const timezone = localStorage.getItem("timezone");
      return timezone ? parseInt(timezone, 0) : null;
    },
    saveTimezone({ state }) {
      localStorage.setItem("timezone", state.timezone);
    },
    generateNewRequestCTS({ commit, getters }, request) {
      const perviouseCTS = getters.getTokenSourceByRequest(request);
      if (perviouseCTS) {
        perviouseCTS.cancel();
      }
      const currentCTS = axios.CancelToken.source();
      commit("setRequestsCTS", {
        request,
        source: currentCTS,
      });

      return currentCTS;
    },
  },
  modules: {
    tracking,
    projects,
    trackingsSheet,
    toasts,
    gantt,
    userInfo,
    alerts,
    workSchedule,
    idea: ideaStore,
    bonus: bonusStore,
    issuesBonus: issuesBonusStore,
    thanksgivingBonus: thanksgivingBonusStore,
    home: homeStore,
    notifications,
    options,
    calendar,
  },
});

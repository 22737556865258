<template>
  <pop-up-modal @close="close" :height="'auto'" :width="'35%'">
    <template>
      <div class="px-4 py-3 w-full h-full">
        <div class="w-full pb-3 flex justify-between y text-black leading-5">
          <h1 class="text-lg font-bold">Assign user</h1>
          <p class="text-gray-500">Assign user to {{ project ? project.name : "" }}</p>
        </div>
        <div class="w-full flex-1 mb-10 y gap-y-2 overflow-visible justify-start">
          <div class="w-full y items-start">
            <div class="p-2">
              <b>Select a user</b>
            </div>
            <v-select
              :loading="usersLoading"
              label="name"
              placeholder="No user selected"
              :options="users"
              v-model="user"
              class="chooser h-12 w-full mb-2 rounded"
            />
          </div>
          <div class="w-full y items-start">
            <div class="p-2">
              <b>Select a role</b>
            </div>
            <v-select
              placeholder="No role selected"
              :options="roles"
              v-model="role"
              class="chooser h-12 w-full mb-2 rounded"
            />
          </div>
          <div class="w-full y items-start">
            <div class="p-2">
              <b>Choose start date</b>
            </div>
            <div class="w-full flex items-start mt-1">
              <div class="flex-1">
                <date-picker
                  v-model="start_date"
                  :clearable="false"
                  valueType="format"
                  format="YYYY-MM-DD"
                  class="w-full disabled:bg-gray-100"
                  placeholder="Choose a date..."
                ></date-picker>
              </div>
            </div>
          </div>
          <div class="w-full y items-start">
            <div class="p-2">
              <b>Choose role expiration date</b>
            </div>
            <div class="w-full flex items-start mt-1">
              <div class="flex-1">
                <date-picker
                  v-model="expires_at"
                  :clearable="true"
                  :disabled-date="afterToday"
                  valueType="format"
                  format="YYYY-MM-DD"
                  class="w-full disabled:bg-gray-100"
                  placeholder="Choose a date..."
                ></date-picker>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #actionButton>
      <button-primary
        @click.native="save"
        class="px-1 self-end mb-1 text-sm py-1 transition-colors duration-200 disabled:opacity-80"
        :disabled="!isValid || submitting"
      >
        {{ isUserAssigned ? "Update" : "Assign" }}
      </button-primary>
    </template>
  </pop-up-modal>
</template>

<script>
import DatePicker from "vue2-datepicker";
import { DateTime } from "luxon";
import { mapActions, mapGetters } from "vuex";
import ButtonPrimary from "./ButtonPrimary.vue";
import PopUpModal from "./PopUpModal.vue";

export default {
  mounted() {
    if (this.users.length < 1) {
      this.getUsers();
    }
  },
  components: {
    ButtonPrimary,
    PopUpModal,
    DatePicker,
  },
  props: {
    project: {
      type: Object,
      default: () => ({}),
    },
    assignedUsers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      roles: ["Project Manager", "Vice Project Manager", "Lead Developer", "Developer"],
      user: null,
      role: null,
      start_date: DateTime.now().toISODate(),
      expires_at: null,
      submitting: false,
    };
  },
  methods: {
    close(didUpdate = false) {
      this.$emit("close", didUpdate);
    },
    async save() {
      if (!this.isValid) return;
      this.submitting = true;
      try {
        const resp = await this.axios.post(`projects/${this.project.id}/user/${this.user.id}`, {
          role: this.role,
          start_date: this.start_date,
          expires_at: this.expires_at,
        });
        this.close(resp.data.data);
      } catch (e) {
        //
      } finally {
        this.submitting = false;
      }
    },
    afterToday(date) {
      return DateTime.fromJSDate(date) <= DateTime.now();
    },
    ...mapActions("userInfo", ["getUsers"]),
  },
  computed: {
    isValid() {
      return this.user && this.role;
    },
    isUserAssigned() {
      if (!this.user) return false;
      return this.assignedUsers.some((u) => u.id === this.user.id);
    },
    ...mapGetters("userInfo", ["users", "usersLoading"]),
  },
  watch: {
    user(newVal, oldVal) {
      if (!newVal || (oldVal && oldVal.id === newVal.id)) {
        return;
      }
      if (this.isUserAssigned) {
        const assignment = this.assignedUsers.find((u) => u.id === this.user.id);
        this.role = assignment.pivot.role;
        this.start_date = assignment.pivot.start_date;
        if (assignment.pivot.expires_at) this.expires_at = assignment.pivot.expires_at;
      }
    },
  },
};
</script>

<style></style>

<template>
  <div class="hover:bg-gray-100 border-b cursor-pointer" @click="open">
    <a :href="linkPreview" @click.prevent="">
      <slot />
    </a>
  </div>
</template>

<script>
export default {
  props: {
    path: {
      type: String,
    },
    route: {
      type: Object,
    },
    openLink: {
      type: Boolean,
      default: () => true,
    },
  },
  methods: {
    open() {
      if (this.openLink) {
        const route = this.path || this.route;
        if (route) this.$router.push(route);
      }
    },
  },
  computed: {
    linkPreview() {
      const route = this.path || this.route;
      if (route) return this.$router.resolve(route).href;
      return null;
    },
  },
};
</script>

<template>
  <thanks-giving-bonus-container :order="result.order">
    <div class="x gap-x-5 items-center">
      <user-excerpt :classes="'font-bold'" :user="result.user" />
      <b class="text-orange text-lg">{{ result.bonus }}%</b>
    </div>
  </thanks-giving-bonus-container>
</template>

<script>
import ThanksGivingBonusContainer from "./ThanksGivingBonusContainer.vue";
import UserExcerpt from "./UserExcerpt.vue";

export default {
  components: {
    ThanksGivingBonusContainer,
    UserExcerpt,
  },
  props: {
    result: Object,
  },
};
</script>

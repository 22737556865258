<template>
  <div
    class="x w-full gap-x-2 cursor-pointer"
    @mouseenter="showPin = true"
    @mouseleave="showPin = false"
  >
    <div><slot /></div>
    <transition name="pin">
      <div class="pinnable-pin" v-show="showPin || pinned">
        <div v-if="!loading" :title="pinned ? 'Unpin from Home' : 'Pin to Home'">
          <icon-pin v-if="!pinned" @click.native="pin" />
          <icon-unpin v-else @click.native="unpin" />
        </div>
        <i v-else class="isax isax-refresh rotate loading-icon" />
      </div>
    </transition>
  </div>
</template>

<script>
import IconPin from "../assets/icons/IconPin.vue";
import IconUnpin from "../assets/icons/IconUnpin.vue";

export default {
  props: {
    type: {
      type: String,
      required: true,
      validator(value) {
        return ["project", "issue", "milestone", "group"].includes(value);
      },
    },
    id: {
      type: Number,
      required: true,
    },
    modelKey: String,
  },
  components: {
    IconPin,
    IconUnpin,
  },
  data() {
    return {
      showPin: false,
      loading: false,
    };
  },
  methods: {
    async pin(event) {
      event.stopPropagation();
      event.preventDefault();
      this.loading = true;
      try {
        await this.$store.dispatch("pin", {
          type: this.type,
          id: this.id,
        });
      } catch (e) {
        //
      } finally {
        this.loading = false;
      }
    },
    async unpin(event) {
      event.stopPropagation();
      event.preventDefault();
      this.loading = true;
      try {
        await this.$store.dispatch("unpin", {
          type: this.type,
          id: this.id,
          key: this.modelKey,
        });
      } catch (e) {
        //
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    pinned() {
      return this.$store.getters.pinned(this.type, this.id, this.modelKey);
    },
  },
};
</script>

<style>
.pin-enter-active {
  transition: all 0.5s;
}
.pin-enter {
  @apply opacity-0;
}

.pin-enter {
  @apply mt-14;
}

.loading-icon {
  @apply table;
}
.loading-icon:hover {
  text-decoration: none !important;
}
</style>

<template>
  <div class="tracking-container y items-center w-full">
    <main class="p-3 s-6 max-w-screen-2xl w-full h-auto">
      <div class="y gap-y-16 p-4 w-full">
        <!-- date selector row -->
        <div class="self-end md:w-52">
          <field-item>
            <template #input>
              <date-picker
                v-model="trackingDate"
                :clearable="true"
                :disabled-date="afterToday"
                valueType="format"
                format="YYYY-MM-DD"
                class="tracking-calendar w-full disabled:bg-gray-100"
                popup-class="tracking-calendar-popup"
                placeholder="Choose a date..."
              >
                <template #icon-clear>
                  <div class="pl-1 border-l">
                    <i class="isax isax-close-circle text-gray-450" />
                  </div>
                </template>

                <template #icon-calendar>
                  <div class="pl-1 border-l">
                    <i class="isax isax-calendar-add text-gray-450" />
                  </div>
                </template>
              </date-picker>
            </template>
          </field-item>
        </div>

        <!-- Tracking row -->
        <div class="y flex-1 md:flex-none w-full gap-y-6 lg:x gap-x-12">
          <clock-timer
            ref="trackingTimer"
            class="mx-auto lg:mx-0 h-min"
            @started="timerStarted"
            @stopped="stopTimer"
            @timerChanged="onTimerChanged"
            :progress="trackingSecondsProgress"
          />

          <div class="y md:x gap-5" style="flex: 3">
            <tracking-select
              class="flex-1 md:max-w-1/2"
              :label="'Select Project'"
              :placeholder="'No project selected...'"
              :subLabel="selectedProject ? selectedProjectSubLabel : 'Please select a project...'"
              :loading="projectsLoading"
              :options="selectableProjects"
              v-model="selectedProjectId"
              :filterBy="filterProject"
            >
              <template #select-option="{ option: { label, path } }">
                <p class="font-bold">{{ label }}</p>
                <p class="text-xs text-gray-450">{{ path }}</p>
              </template>
            </tracking-select>

            <div class="flex-1 md:max-w-1/2 y gap-y-3 md:gap-y-5">
              <tracking-select
                :label="'Select an issue'"
                :placeholder="'No issue selected...'"
                :subLabel="
                  selectedProject
                    ? selectedIssue
                      ? selectedIssueSubLabel
                      : 'Please select an issue'
                    : 'Please select a project...'
                "
                :disabled="!selectedProjectId"
                :loading="issuesLoading"
                :options="selectableIssues"
                v-model="selectedIssueIid"
              >
                <template #select-option="{ option: { state, label } }">
                  <p class="whitespace-normal" :class="state === 'closed' ? 'text-gray-400' : ''">
                    {{ label }}
                    <span
                      v-show="state === 'closed'"
                      style="font-size: 0.6rem"
                      class="inline-flex ml-2 items-center justify-center px-2 py-1 font-bold leading-none text-red-100 bg-red-500 rounded-full"
                      >Closed</span
                    >
                  </p>
                </template>
              </tracking-select>

              <tracking-times
                v-show="selectedIssue"
                :estimated="timeEstimated"
                :spent="timeSpent"
              />
              <div
                class="w-full self-end mt-3 text-red-500 font-bold text-xs px-2 x"
                v-if="!canStartTracking && triedToSubmit"
              >
                <i class="isax isax-danger mt-0.5 mx-2 h-full" />
                {{ canStartTrackingMessage }}
              </div>
            </div>
          </div>
          <div class="y h-min" style="flex: 2">
            <field-item>
              <template #label>
                <label class="block font-bold text-gray-700">{{ label }}</label>
              </template>
              <template #input>
                <textarea
                  class="border-1 p-1 rounded w-full h-28"
                  placeholder="Add comment..."
                  v-model="comment"
                />
              </template>
            </field-item>
            <button
              @click="saveTracking()"
              :disabled="!canSaveTracking"
              class="place-self-end md:h-9 w-full flex-2 flex py-1 justify-center items-center px-3 rounded-md text-white disabled:opacity-50"
              style="background: #ff6700"
            >
              <icon-save-tracking v-if="!submitting" />
              <span v-if="!submitting" class="ml-3.5"> Save Tracking </span>
              <div v-else class="x w-full gap-x-2 items-center justify-center">
                <i class="isax isax-refresh rotate loading-icon mx-2" />
                Saving...
              </div>
            </button>
          </div>
        </div>

        <!-- Past trackings -->
        <hr class="border-2" />
        <past-trackings
          ref="pastTrackings"
          :trackingData="trackingData"
          @resumedTracking="resumedTracking"
          @startedNewTracking="startedNewTracking"
        />
      </div>
    </main>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import { mapActions, mapGetters, mapState } from "vuex";
import DatePicker from "vue2-datepicker";
import FieldItem from "../components/FieldItem.vue";
import ClockTimer from "../components/Tracking/ClockTimer.vue";
import TrackingSelect from "../components/Tracking/TrackingSelect.vue";
import TrackingTimes from "../components/Tracking/TrackingTimes.vue";
import PastTrackings from "../components/Tracking/PastTrackings.vue";
import getConfigsByToken from "../config/pusher.config";
import { getHoursAndMinutesFromString, getIssueTrackProgress } from "../utils/tracking";
import utils from "../utils/utils";
import alerts from "../utils/alerts";
import IconSaveTracking from "../assets/icons/IconSaveTracking.vue";

const emptyTrackingKey = "__EMPTY_TRACKING__";

export default {
  components: {
    DatePicker,
    FieldItem,
    ClockTimer,
    TrackingSelect,
    TrackingTimes,
    PastTrackings,
    IconSaveTracking,
  },
  beforeDestroy() {
    if (this.socketWorker) this.socketWorker.terminate();
  },
  data() {
    return {
      socketWorker: null,
      trackingDate: DateTime.local().toFormat("yyyy-LL-dd"),
      comment: null,
      selectedProjectId: null,
      selectedIssueIid: null,
      selectedPastTracking: null,
      triedToSubmit: false,
      pastTrackingId: null,
    };
  },
  mounted() {
    this.setTimer({
      seconds: 0,
      minutes: 0,
      hours: 0,
    });
    this.setEmptyTracking();
    if (this.projects.length < 1) {
      this.fetchProjects();
    }
  },
  methods: {
    afterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date > today;
    },
    filterProject(p, label, search) {
      const searchTerms = search.split(" ");
      return searchTerms.every(
        (term) =>
          (label || "").toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1 ||
          (p.path || "").toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1,
      );
    },
    setTimer(val) {
      this.$store.commit("tracking/setTimer", val);
      this.$refs.trackingTimer.manuallySetTimer(val.hours, val.minutes, val.seconds);
    },
    onTimerChanged(val) {
      this.$store.commit("tracking/setTimer", val);
    },
    saveLocalTrackingData() {
      if (this.pastTrackingId) {
        this.$refs.pastTrackings.update();
      } else {
        this.saveEmptyTracking();
      }
    },
    startedNewTracking() {
      this.$refs.trackingTimer.stop();
      this.selectedIssueIid = null;
      this.selectedProjectId = null;
      this.setTimer({
        seconds: 0,
        minutes: 0,
        hours: 0,
      });
      this.comment = null;
      this.$refs.trackingTimer.reset();
      this.pastTrackingId = null;
      this.clearEmptyTracking();
    },
    resumedTracking(tracking) {
      if (!this.pastTrackingId) {
        const timerNotEmpty =
          this.timer.seconds > 0 || this.timer.minutes > 0 || this.timer.hours > 0;
        if (timerNotEmpty || this.selectedProjectId) {
          this.$refs.pastTrackings.save();
        }
      }

      this.$refs.trackingTimer.stop();
      this.pastTrackingId = null;
      this.$nextTick(() => {
        this.selectedProjectId = tracking.project_id;
        this.$nextTick(() => {
          this.selectedIssueIid = tracking.issue_iid;
          this.$nextTick(() => {
            const { hours, minutes, seconds } = tracking.timer;
            this.setTimer({ hours, minutes, seconds });
            this.comment = tracking.comment;
            this.pastTrackingId = tracking.id;
            this.clearEmptyTracking();
          });
        });
      });
    },
    setEmptyTracking() {
      const data = localStorage.getItem(emptyTrackingKey);
      if (data) {
        const tracking = JSON.parse(data);
        this.selectedProjectId = tracking.project_id;
        const { hours, minutes, seconds } = tracking.timer;
        this.setTimer({ hours, minutes, seconds });
        this.comment = tracking.comment;
      }
    },
    saveEmptyTracking() {
      localStorage.setItem(
        emptyTrackingKey,
        JSON.stringify({
          project_id: this.selectedProjectId,
          timer: this.timer,
          comment: this.comment,
        }),
      );
    },
    clearEmptyTracking() {
      localStorage.removeItem(emptyTrackingKey);
    },
    async saveTracking() {
      await this.checkCanTrack();
      this.triedToSubmit = true;
      if (!this.canSaveTracking || !this.canStartTracking) return;

      if (this.trackingDate !== DateTime.local().toFormat("yyyy-LL-dd")) {
        const confirmed = await alerts.showConfirm({
          title: "Tracking Date",
          message: `You are tracking for a previous day. Please, confirm`,
        });
        if (!confirmed) return;
      }

      const saved = await this.$store.dispatch("tracking/saveTracking", {
        projectId: this.selectedProject.gitlab_id,
        issueIid: this.selectedIssue.gitlab_iid,
        timer: this.timer,
        comment: this.comment,
        date: this.trackingDate,
      });

      if (saved) {
        this.selectedIssue.total_spent_time +=
          this.timer.hours * 60 * 60 + this.timer.minutes * 60 + this.timer.seconds;
        this.setTimer({
          seconds: 0,
          minutes: 0,
          hours: 0,
        });
        this.$refs.pastTrackings.delete();
        this.selectedIssueIid = null;
        this.selectedProjectId = null;
        this.comment = "";
      }
    },
    startSocketWorker() {
      if (!this.socketWorker) this.socketWorker = new Worker("/scripts/tracking.worker.js");

      const { user } = this.$store.getters;
      const project = this.selectedProject;
      const issue = this.selectedIssue;

      this.socketWorker.postMessage({
        action: "start",
        startTimer: this.timer,
        trackingData: {
          name: user.name,
          username: user.username,
          avatar: user.avatar,
          issue: {
            id: issue ? issue.id : null,
            title: issue ? issue.title : "No issue selected",
          },
          project: {
            id: project?.id,
            title: project
              ? `${utils.toTitleCase(project.namespace_name)} / ${utils.toTitleCase(project.name)}`
              : "No project selected",
          },
        },
        pusherConfigs: {
          ...getConfigsByToken(this.$store.getters.token),
          token: this.$store.getters.token,
          channel: "private-now-tracking",
          event: "client-tracking",
        },
      });
    },
    stopSocketWorker() {
      this.socketWorker.postMessage({
        action: "stop",
      });
    },
    timerStarted() {
      this.startSocketWorker();
    },
    stopTimer() {
      this.stopSocketWorker();
    },
    ...mapActions("projects", ["fetchProjects", "fetchIssues"]),
    ...mapActions("tracking", ["checkCanTrack"]),
  },
  computed: {
    isTimerStarted() {
      return !this.$refs.trackingTimer.isStopped;
    },
    canStartTracking() {
      return this.canTrack && (!this.selectedIssue || this.selectedIssue.state === "opened");
    },
    canSaveTracking() {
      return (
        this.selectedIssue !== null &&
        (this.timer.hours > 0 || this.timer.minutes > 0) &&
        !this.submitting &&
        !this.checkingCanTrack &&
        this.comment !== null &&
        this.comment.trim().length > 0
      );
    },
    canStartTrackingMessage() {
      if (!this.selectedIssue)
        return "First of all, you have to choose an issue to track time spent.";
      if (!this.canTrack) return this.canTrackMessage;
      return "You cannot track to closed issues. First please reopen the issue.";
    },
    selectableProjects() {
      return this.projects.map((p) => ({
        label: utils.toTitleCase(p.name),
        path: utils.toTitleCase(p.path_with_namespace),
        value: p.gitlab_id,
      }));
    },
    selectedProject() {
      return this.projects.find((p) => p.gitlab_id === this.selectedProjectId);
    },
    selectedProjectSubLabel() {
      return `${this.selectedProject?.namespace_name} | ${this.selectedProject?.name}`;
    },
    selectableIssues() {
      return this.issues.map((i) => ({
        label: i.title,
        state: i.state,
        value: i.gitlab_iid,
      }));
    },
    selectedIssue() {
      return this.issues.find((p) => p.gitlab_iid === this.selectedIssueIid);
    },
    selectedIssueSubLabel() {
      return `${this.selectedProject.name} | ${this.selectedIssue.title}`;
    },
    timeSpent() {
      if (this.selectedIssue)
        return getHoursAndMinutesFromString(this.selectedIssue.total_spent_time);

      return {
        hours: 0,
        minutes: 0,
      };
    },
    timeEstimated() {
      if (this.selectedIssue) return getHoursAndMinutesFromString(this.selectedIssue.estimate_time);

      return {
        hours: 0,
        minutes: 0,
      };
    },
    trackingSecondsProgress() {
      if (this.selectedIssue) {
        return getIssueTrackProgress(this.selectedIssue, this.timer);
      }

      return this.timer.seconds / 60;
    },
    trackingData() {
      const { hours, minutes, seconds } = this.timer;
      return {
        id: this.pastTrackingId,
        project_id: this.selectedProjectId,
        issue_iid: this.selectedIssueIid,
        title:
          this.selectedIssue?.title ?? this.selectedProject
            ? `${utils.toTitleCase(this.selectedProject.namespace_name)} / ${utils.toTitleCase(
                this.selectedProject.name,
              )}`
            : null,
        comment: this.comment,
        timer: { hours, minutes, seconds },
        total_spent_time: this.selectedIssue?.total_spent_time,
        estimate_time: this.selectedIssue?.estimate_time,
      };
    },
    ...mapGetters("projects", ["projects", "projectsLoading", "issues", "issuesLoading"]),
    ...mapState("tracking", [
      "timer",
      "submitting",
      "checkingCanTrack",
      "canTrack",
      "canTrackMessage",
    ]),
  },
  watch: {
    selectedProjectId(n, o) {
      if (Number(n) !== Number(o)) {
        this.selectedIssueIid = null;
        if (n) {
          this.fetchIssues(this.selectedProjectId);
        }
      }
      this.$nextTick(() => {
        this.saveLocalTrackingData();
      });
    },
    selectedIssueIid() {
      if (this.isTimerStarted) {
        this.stopSocketWorker();
        this.startSocketWorker();
      }
      this.$nextTick(() => {
        this.saveLocalTrackingData();
      });
    },
    comment() {
      this.$nextTick(() => {
        this.saveLocalTrackingData();
      });
    },
    timer: {
      handler() {
        this.$nextTick(() => {
          this.saveLocalTrackingData();
        });
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
div > .tracking-container {
  @apply bg-gray-100 !important;
}
</style>

<template>
  <v-select
    class="selector flex-1"
    :value="value"
    @input="(val) => $emit('input', val)"
    :loading="languagesLoading"
    :reduce="(i) => i.id"
    label="name"
    :options="languages"
    multiple
  />
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    value: [String, Array, Number],
  },
  mounted() {
    if (this.languages.length === 0) {
      this.fetchLanguages();
    }
  },
  computed: {
    ...mapGetters("options", ["languages", "languagesLoading"]),
  },
  methods: {
    ...mapActions("options", ["fetchLanguages"]),
  },
};
</script>

<style></style>

<template>
  <div class="y items-center w-full">
    <main class="y p-3 s-6 max-w-screen-2xl w-full h-full">
      <breadcrumb :links="breadcrumbLinks" />
      <div class="w-full h-full x justify-center items-center" v-if="loading">
        <loading />
      </div>

      <div class="y gap-y-3 items-center pb-5" v-if="!loading && result">
        <div class="x w-full justify-center items-center">
          <div class="flex-1"></div>
          <b v-if="viewOnly">{{ result.employee.name }}'s - {{ date }} Results</b>
          <b v-else>Rate {{ result.employee.name }}'s - {{ date }}</b>
          <div class="flex-1"></div>
          <div class="x gap-x-2">
            <button-secondary @click.native="cancel">Cancel</button-secondary>
            <button-primary v-if="viewOnly || !isConnection" @click.native="copyLink"
              >Copy view link</button-primary
            >
          </div>
        </div>

        <div class="w-full y gap-y-3 py-4" v-for="category of categories" v-bind:key="category.id">
          <b class="text-lg">{{ category.order }}. {{ category.name }}</b>
          <div
            class="w-full py-5 shadow-md rounded-md y gap-y-2 px-5"
            v-for="question of questions"
            v-bind:key="question.id"
            v-show="question.category.id === category.id"
          >
            <b>{{ category.order }}.{{ question.order }} {{ question.description }}</b>
            <div class="y w-1/2 ml-5">
              <rate-slider
                class="ml-5"
                v-if="question.type === 'rating'"
                v-model="question.rate"
                @input="refreshQuestions"
                :disabled="viewOnly"
              />

              <toggle-button
                class="checkbox"
                v-model="question.rate"
                @input="refreshQuestions"
                :label="`${question.id}`"
                v-else-if="question.type === 'yes/no'"
                :disabled="viewOnly"
              />

              <p
                class="font-bold text-orange text-base"
                :class="{ 'text-center': question.type === 'rating' }"
              >
                <span v-if="question.type === 'rating'">
                  {{ question.rate }}
                </span>
                <span v-else-if="question.type === 'yes/no'">
                  {{ question.rate ? "yes" : "no" }}
                </span>
              </p>
            </div>

            <validated-input
              class="w-11/12 mt-3"
              :classes="'shadow-md h-40'"
              :isTextarea="true"
              v-model="question.rate"
              @input="refreshQuestions"
              v-if="question.type === 'textarea'"
              :disabled="viewOnly"
            />
          </div>
        </div>

        <button-primary
          v-if="!viewOnly"
          class="mb-5 w-4/12 block mx-auto"
          @click.native="save"
          :disabled="submitting"
        >
          {{ submitting ? "Submitting" : "Save" }}</button-primary
        >
      </div>
    </main>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { DateTime } from "luxon";
import Breadcrumb from "../components/Breadcrumb.vue";
import Loading from "../components/Loading.vue";
import ButtonSecondary from "../components/ButtonSecondary.vue";
import RateSlider from "../components/RateSlider.vue";
import ToggleButton from "../components/ToggleButton.vue";
import ButtonPrimary from "../components/ButtonPrimary.vue";
import ValidatedInput from "../components/ValidatedInput.vue";
import alerts from "../utils/alerts";

export default {
  components: {
    Breadcrumb,
    Loading,
    ButtonSecondary,
    RateSlider,
    ToggleButton,
    ButtonPrimary,
    ValidatedInput,
  },
  props: {
    viewOnly: Boolean,
  },
  mounted() {
    this.getData();
  },
  data() {
    return {
      loading: true,
      questions: [],
      categories: [],
      result: null,
      month: null,
      submitting: false,
    };
  },
  methods: {
    async getData() {
      this.month = DateTime.fromISO(this.$route.params.date).toFormat("LLLL");
      const resultId = this.$route.params.id;
      const resp = await this.axios.get(`${this.route}/${resultId}`);
      this.result = resp.data.data;
      this.getQuestions();
    },
    async getQuestions() {
      const resp = await this.axios.get(`bonus-tests/${this.result.bonus_test_id}/questions`);
      this.questions = resp.data.data;
      this.setCategories();
      this.loading = false;
    },
    setCategories() {
      const previousAnswers = this.result.answers ?? [];
      const categoriesMap = new Map();

      for (let i = 0; i < this.questions.length; i += 1) {
        const question = this.questions[i];
        const previousAnswer = previousAnswers.find(
          (a) => a.bonus_test_question_id === question.id,
        );

        let rate = 0;
        if (question.type === "rating") rate = previousAnswer?.value ?? 1;
        else if (question.type === "yes/no")
          rate = previousAnswer ? parseInt(previousAnswer.value, 0) : 0;
        else if (question.type === "textarea") rate = previousAnswer?.value ?? "";
        question.rate = rate;

        categoriesMap.set(question.category.id, question.category);
      }
      this.categories = Array.from(categoriesMap.values());
    },
    async save() {
      this.submitting = true;
      try {
        let date = null;
        if (this.isConnection) {
          date = this.$route.params.date;
        }
        const resp = await this.axios.post(`${this.route}/${this.result.id}/result`, {
          questions: this.questions,
          date,
        });
        if (resp.data.status === "success") {
          await this.fetchBonuses();
          this.bonuses = JSON.parse(JSON.stringify(this.bonuses));
          this.cancel();
        }
      } catch {
        //
      } finally {
        this.submitting = false;
      }
    },
    cancel() {
      this.$router.push({ name: "BonusTracker" });
    },
    refreshQuestions() {
      this.questions = JSON.parse(JSON.stringify(this.questions));
    },
    copyLink() {
      const route = {
        name: "BonusResult",
        params: {
          id: this.$route.params.id,
        },
      };
      const link = this.$router.resolve(route).href;
      const text = `${window.location.protocol}//${window.location.host}${link}`;
      navigator.clipboard.writeText(text).then(
        () => {
          alerts.showMessage("Link copied to clipboard");
        },
        () => {
          alerts.showMessage("Error copying to clipboard", null, true);
        },
      );
    },
    ...mapActions("bonus", ["fetchBonuses"]),
  },
  computed: {
    isConnection() {
      return this.$route.name === "BonusConnectionQuestions";
    },
    date() {
      if (this.isConnection) return this.month;
      return DateTime.fromISO(this.result.created_at).toFormat("LLLL");
    },
    route() {
      const route = this.isConnection ? "connections" : "test-results";
      return `bonus-${route}`;
    },
    bonuses: {
      get() {
        return this.$store.state.bonus.bonuses;
      },
      set(val) {
        this.$store.commit("bonus/setBonuses", val);
      },
    },
    breadcrumbLinks() {
      let currentRotue = `/bonus-tracker/results/${this.$route.params.id}/questions`;
      if (this.$route.name === "BonusConnectionQuestions") {
        currentRotue = `/bonus-tracker/connections/${this.$route.params.id}/questions`;
      }

      return [
        { name: "Tracking", url: "/" },
        { name: "Bonus Tracker", url: "/bonus-tracker" },
        { name: "Rate", url: currentRotue },
      ];
    },
  },
};
</script>

<style>
.checkbox .checkbox-input {
  @apply bg-orange shadow-md border-none !important;
}
.checkbox .checkbox-background {
  background-image: linear-gradient(to right, #ffffff, #ff6700);
  margin-top: 4px;
  @apply h-4 !important;
}
</style>

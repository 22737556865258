<template>
  <pop-up-modal :height="'auto'" :width="'auto'" @close="close">
    <table
      v-if="!loading"
      class="body-scroll w-full"
      style="color: rgba(121, 121, 121); min-width: 500px"
    >
      <tbody
        v-if="logs.length"
        class="divide-y divide-gray-200 scrollbar-thin scrollbar-thumb-rounded-3xl scrollbar-track-transparent scrollbar-thumb-gray-300"
        style="height: 65vh"
      >
        <tr v-for="log of logs" v-bind:key="log.id">
          <th class="p-3 text-left font-normal">{{ parseLog(log) }}</th>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td class="p-3">No Logs found.</td>
        </tr>
      </tbody>
    </table>

    <div
      class="w-96 h-96 x items-center justify-center inset-0 bg-white z-tooltip bg-opacity-75"
      v-else
    >
      <loading />
    </div>

    <template #actionButton>
      <button-primary
        @click.native="close"
        class="px-1 self-end mb-1 text-sm py-1 transition-colors duration-200 disabled:opacity-75"
      >
        OK
      </button-primary>
    </template>
  </pop-up-modal>
</template>

<script>
import { DateTime } from "luxon";
import PopUpModal from "./PopUpModal.vue";
import ButtonPrimary from "./ButtonPrimary.vue";
import Loading from "./Loading.vue";

export default {
  components: { PopUpModal, ButtonPrimary, Loading },
  data() {
    return {
      logs: [],
      loading: true,
    };
  },
  async mounted() {
    const resp = await this.axios.get("work-schedule-changes");
    this.logs = resp.data.data;
    this.loading = false;
  },
  methods: {
    close() {
      this.$emit("close");
    },
    parseLog(log) {
      const logDate = DateTime.fromISO(log.created_at).toLocaleString(DateTime.DATETIME_SHORT);
      const logMessage = `[${logDate}] [${log.user.name}] [${log.event}] [${
        log.work_schedule.user.name
      }] [${log.work_schedule.day}] [${log.value ? "free" : "work day"}]`;

      return logMessage;
    },
  },
};
</script>

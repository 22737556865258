<template>
  <vue-slider
    class="cursor-pointer"
    :dotSize="23"
    :height="15"
    :min="1"
    :max="10"
    :width="'100%'"
    :process="false"
    :dotStyle="{
      background: '#ff6700',
      border: 'none',
      'box-shadow': '0 4px 6px -1px rgba(0, 0, 0, 0.2), 0 2px 4px -1px rgba(0, 0, 0, 0.3)',
    }"
    :railStyle="{
      'background-image': 'linear-gradient(to right, #ffffff, #ff6700)',
    }"
    tooltip="none"
    :value="value"
    @change="(val) => $emit('input', val)"
    :disabled="disabled"
  >
  </vue-slider>
</template>

<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";

export default {
  props: {
    value: {
      type: [String, Number],
      default: () => 1,
    },
    disabled: Boolean,
  },
  components: {
    VueSlider,
  },
};
</script>

<style></style>

export default {
  state: {
    alerts: [],
  },
  mutations: {
    addAlert(state, alert) {
      state.alerts.push(alert);
    },
    removeAlert(state, key) {
      for (let i = 0; i < state.alerts.length; i += 1) {
        if (state.alerts[i].key === key) state.alerts.splice(i, 1);
      }
    },
  },
  actions: {
    showAlert({ commit }, { title, message, onConfirm, onCancel, dontShow }) {
      const key = new Date().getTime();
      const alert = { title, message, onConfirm, onCancel, key, dontShow };
      commit("addAlert", alert);
      return key;
    },
    dismissAlert({ commit }, key) {
      commit("removeAlert", key);
    },
  },
};

<template>
  <button
    @click="$emit('click')"
    class="p-2 border-transparent whitespace-nowrap"
    :class="{
      'text-trueblack font-medium border-b-2 border-brand': active,
      [size]: true,
    }"
  >
    <span>{{ title }}</span>
    <div
      v-if="badge != null"
      style="background: rgb(240, 240, 240)"
      class="ml-1 inline-flex items-center justify-center px-1.5 py-0.5 text-xs leading-none rounded-full"
      :class="{ 'bg-brand': active }"
    >
      <span v-if="!loading">
        {{ badge }}
      </span>
      <i v-else class="isax isax-refresh rotate" />
    </div>
  </button>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: () => false,
    },
    title: {
      type: String,
    },
    badge: {
      type: Number,
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    size: {
      type: String,
      default: () => "text-xs md:text-base",
    },
  },
};
</script>

import axios from "axios";
import utils from "../../utils/utils";

const getUrlAndParamsFromFilter = (filtersObjects, userRoute, otherRoute) => {
  const filters = { ...filtersObjects };
  const forUser = filters.forUser === true;
  delete filters.forUser;
  delete filters.reachedEnd;
  let route = otherRoute;
  if (forUser) route = userRoute;
  const params = utils.parseObjectToParams(filters);

  return {
    route,
    params,
  };
};

function getUniqueList(arr) {
  return [...new Map(arr.map((item) => [item.id, item])).values()];
}

const defaultFilters = {
  limit: 10,
  page: null,
  forUser: true,
  reachedEnd: false,
};

const overviewPagesFilters = {
  limit: null,
  page: 1,
  forUser: false,
  reachedEnd: false,
};

const userPagesFilters = {
  limit: null,
  page: null,
  forUser: true,
};

const userStore = {
  namespaced: true,
  state: {
    users: [],
    allUsers: [],
    usersLoading: false,
    userId: {},
    user: {},
    projects: [],
    projectsFilters: { ...defaultFilters },
    issuesFilters: { ...defaultFilters },
    todosFilters: { ...defaultFilters },
    projectsCount: [],
    projectsLoading: true,
    trackingTabs: [],
    issues: [],
    issuesCount: [],
    issuesLoading: true,
    trackings: [],
    trackingsLoading: true,
    trackingsFilters: { ...defaultFilters },
    trackingsCount: 0,
    todos: [],
    todosPriorities: [],
    todosCount: [],
    todosLoading: true,
    query: null,
  },
  getters: {
    users: (state) => state.users,
    allUsers: (state) => state.allUsers,
    usersLoading: (state) => state.usersLoading,
    user: (state) => state.user,
    userId: (state) => state.userId,
    projects: (state) => state.projects,
    projectsCount: (state) => state.projectsCount,
    projectsLoading: (state) => state.projectsLoading,
    trackingTabs: (state) => state.trackingTabs,
    issues: (state) => state.issues,
    issuesCount: (state) => state.issuesCount,
    issuesLoading: (state) => state.issuesLoading,
    trackings: (state) => state.trackings,
    trackingsLoading: (state) => state.trackingsLoading,
    trackingsCount: (state) => state.trackingsCount,
    todos: (state) => state.todos,
    todosCount: (state) => state.todosCount,
    todosPriorities: (state) => state.todosPriorities,
    todosLoading: (state) => state.todosLoading,
    query: (state) => state.query,
  },
  mutations: {
    setUsers(state, users) {
      state.users = users;
    },
    setAllUsers(state, allUsers) {
      state.allUsers = allUsers;
    },
    setUsersLoading(state, loading) {
      state.usersLoading = loading;
    },
    setUserId(state, id) {
      state.userId = id;
    },
    setUser(state, user) {
      state.user = user;
    },
    setProjects(state, projects) {
      state.projects = projects;
    },
    setProjectsCount(state, projectsCount) {
      state.projectsCount = projectsCount;
    },
    setProjectsLoading(state, loading) {
      state.projectsLoading = loading;
    },
    setIssues(state, issues) {
      state.issues = issues;
    },
    setIssuesCount(state, issuesCount) {
      state.issuesCount = issuesCount;
    },
    setIssuesLoading(state, loading) {
      state.issuesLoading = loading;
    },
    setTrackings(state, trackings) {
      state.trackings = trackings;
    },
    setTrackingsLoading(state, loading) {
      state.trackingsLoading = loading;
    },
    setTrackingsCount(state, trackingsCount) {
      state.trackingsCount = trackingsCount;
    },
    setTodos(state, todos) {
      state.todos = todos;
    },
    setTodosCount(state, todosCount) {
      state.todosCount = todosCount;
    },
    setTodosPriorities(state, todosPriorities) {
      state.todosPriorities = todosPriorities;
    },
    setTodosLoading(state, loading) {
      state.todosLoading = loading;
    },
    setQuery(state, query) {
      state.query = query;
    },
  },
  actions: {
    async getUsers({ commit, state, dispatch }) {
      commit("setUsersLoading", true);
      const currentCTS = await dispatch("generateNewRequestCTS", "getTrackings", { root: true });

      return axios({
        method: "get",
        url: `/users?query=${state.query ?? ""}`,
        cancelToken: currentCTS.token,
      })
        .then((resp) => {
          commit("setUsers", resp.data);
          if (!state.query) {
            commit("setAllUsers", resp.data);
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          if (!currentCTS.token.reason) commit("setUsersLoading", false);
        });
    },
    fetchOverviewData({ dispatch, state }) {
      const overviewFilters = {
        limit: 10,
        page: null,
        forUser: false,
      };
      state.projectsFilters = overviewFilters;
      state.issuesFilters = overviewFilters;
      state.todosFilters = overviewFilters;
      state.trackingsFilters = overviewFilters;
      dispatch("getProjects");
      dispatch("getIssues");
      dispatch("getTodos");
      dispatch("getTrackings");
    },
    fetchUserData({ dispatch, state }) {
      const userFilters = {
        limit: 5,
        page: null,
        forUser: true,
      };

      state.projectsFilters = userFilters;
      state.issuesFilters = userFilters;
      state.todosFilters = userFilters;
      state.trackingsFilters = userFilters;

      dispatch("getProjects");
      dispatch("getIssues");
      dispatch("getTrackings");
      dispatch("getTodos");
    },
    fetchHomeData({ dispatch, state }) {
      const userFilters = {
        limit: 5,
        page: null,
        forUser: true,
      };

      state.todosFilters = userFilters;

      dispatch("getTodos");
    },
    getUserProjects({ dispatch, state }) {
      state.projectsFilters = userPagesFilters;
      dispatch("getProjects");
    },
    getUserIssues({ dispatch, state }, paginating = false) {
      const filters = { ...userPagesFilters, page: paginating ? 1 : null, reachedEnd: false };
      state.issuesFilters = filters;
      state.trackingsFilters = filters;
      dispatch("getIssues");
      dispatch("getTrackings");
    },
    getUsertodos({ dispatch, state }) {
      state.todosFilters = { ...userPagesFilters, page: 1, reachedEnd: false };
      dispatch("getTodos");
    },
    getAllProjects({ dispatch, state }) {
      state.projectsFilters = { ...overviewPagesFilters };
      dispatch("getProjects");
    },
    getAllIssues({ dispatch, state }) {
      const filters = { ...overviewPagesFilters };

      Object.assign(state.issuesFilters, filters);
      Object.assign(state.trackingsFilters, filters);
      dispatch("getIssues");
      dispatch("getTrackings");
    },
    getAllTodos({ dispatch, state }) {
      state.todosFilters = overviewPagesFilters;
      dispatch("getTodos");
    },
    changeUser({ state, commit }, { id }) {
      if (!state.userId || state.userId !== id) {
        commit("setUserId", id);
      }
    },
    getUser({ commit }, { id }) {
      axios({
        method: "get",
        url: `/users/${id}`,
      })
        .then((resp) => {
          const user = resp.data.data;
          commit("setUser", user);
        })
        .catch((e) => console.log(e));
    },
    getProjects({ state, commit }) {
      const { route, params } = getUrlAndParamsFromFilter(
        state.projectsFilters,
        `/users/${state.userId}/projects`,
        `projects`,
      );
      commit("setProjectsLoading", true);
      axios({
        method: "get",
        url: `${route}?${params}`,
        baseURL: process.env.VUE_APP_BACKEND_API_URL,
      })
        .then((resp) => {
          const { projects } = resp.data.data;
          if (state.projectsFilters.page) {
            state.projectsFilters.page += 1;
            const allProjects = [...state.projects, ...projects];
            commit("setProjects", getUniqueList(allProjects));
            if (projects.length < 10) state.projectsFilters.reachedEnd = true;
          } else {
            commit("setProjects", projects);
          }
          let { count } = resp.data.data;
          count = [null, count.active, count.in_active];
          commit("setProjectsCount", count);
        })
        .catch((e) => console.log(e))
        .finally(() => {
          commit("setProjectsLoading", false);
        });
    },
    getIssues({ state, commit }) {
      const { route, params } = getUrlAndParamsFromFilter(
        state.issuesFilters,
        `/users/${state.userId}/issues`,
        `issues`,
      );
      commit("setIssuesLoading", true);
      axios({
        method: "get",
        url: `${route}?${params}`,
        baseURL: process.env.VUE_APP_BACKEND_API_URL,
      })
        .then((resp) => {
          const { issues } = resp.data.data;
          if (state.issuesFilters.page) {
            state.issuesFilters.page += 1;
            const allIssues = [...state.issues, ...issues];
            commit("setIssues", getUniqueList(allIssues));
            if (issues.length < 10) state.issuesFilters.reachedEnd = true;
          } else {
            commit("setIssues", issues);
          }
          let { count } = resp.data.data;
          count = [
            null,
            count.opened,
            count.closed,
            count.non_estimtated,
            count.no_due,
            count.over_due,
          ];
          commit("setIssuesCount", count);
        })
        .catch((e) => console.log(e))
        .finally(() => {
          commit("setIssuesLoading", false);
        });
    },
    getTrackings({ state, commit }) {
      const { route, params } = getUrlAndParamsFromFilter(
        state.trackingsFilters,
        `/users/${state.userId}/trackings`,
        `trackings`,
      );
      commit("setTrackingsLoading", true);
      axios({
        method: "get",
        url: `${route}?${params}`,
        baseURL: process.env.VUE_APP_BACKEND_API_URL,
      })
        .then((resp) => {
          const { data } = resp;
          const { trackings } = data.data;
          if (state.trackingsFilters.page) {
            state.trackingsFilters.page += 1;
            commit("setTrackings", [...state.trackings, ...trackings]);
            if (trackings.length < 10) state.trackingsFilters.reachedEnd = true;
          } else {
            commit("setTrackings", trackings);
          }
          commit("setTrackingsCount", data.data.count);
        })
        .catch((e) => console.log(e))
        .finally(() => {
          commit("setTrackingsLoading", false);
        });
    },
    getTodos({ state, commit }) {
      const { route, params } = getUrlAndParamsFromFilter(
        state.todosFilters,
        `/users/${state.userId}/todos-preview`,
        `todos-overview`,
      );
      commit("setTodosLoading", true);
      axios({
        method: "get",
        url: `${route}?${params}`,
        baseURL: process.env.VUE_APP_BACKEND_API_URL,
      })
        .then((resp) => {
          const { todos, priorities } = resp.data.data;
          if (state.todosFilters.page) {
            if (state.todosFilters.page === 1) commit("setTodos", todos);
            else {
              const allTodos = [...state.todos, ...todos];
              commit("setTodos", getUniqueList(allTodos));
            }
            if (todos.length < 10) state.todosFilters.reachedEnd = true;
            state.todosFilters.page += 1;
          } else {
            commit("setTodos", todos);
          }

          let { count } = resp.data.data;
          count = [null, count.opened, count.closed, count.recurring, count.supervised];
          commit("setTodosCount", count);
          commit("setTodosPriorities", priorities);
        })
        .catch((e) => console.log(e))
        .finally(() => {
          commit("setTodosLoading", false);
        });
    },
  },
};

export default userStore;

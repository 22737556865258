<template>
  <pop-up-modal :height="'auto'" @close="close" :hideTooltip="isView">
    <template>
      <div class="w-full h-full">
        <div class="w-full pb-3 px-3 flex justify-between items-center x text-black leading-5">
          <div class="w-full py-3 px-4 flex justify-between y text-black leading-5">
            <h1 class="text-lg font-bold">{{ isEdit ? "Edit" : "Create" }} Work Time</h1>
            <p class="text-gray-500">
              {{ editedSchedule.user ? editedSchedule.user.name : "me" }} - {{ editedSchedule.day }}
            </p>
          </div>
          <div class="x gap-x-2 w-full justify-end">
            <time-zone-selector />
            <button-primary
              @click.native="times.push({ from: null, to: null })"
              class="w-auto whitespace-nowrap h-10"
            >
              <span class="text-xs">Add a time range</span>
            </button-primary>
          </div>
        </div>

        <p class="px-4 mb-5" v-if="times && times.length < 1">No work times set.</p>
        <div v-if="times">
          <div
            v-for="(time, index) in times"
            :key="time.id"
            class="w-full mb-2 px-4 flex-1 flex flex-col overflow-y-scroll justify-start scrollbar-track-rounded-md scrollbar-thin scrollbar-track-gray-300"
          >
            <div class="x gap-x-2">
              <div class="w-full y items-start mb-3">
                <div class="p-2">
                  <b>From</b>
                </div>
                <div class="w-full flex items-start mt-1">
                  <div class="flex-1">
                    <date-picker
                      v-model="time.from"
                      type="time"
                      :clearable="false"
                      valueType="format"
                      format="HH:mm"
                      class="w-full disabled:bg-gray-100"
                      placeholder="00:00"
                      popup-class="hidden-calendar"
                    ></date-picker>
                  </div>
                </div>
              </div>

              <div class="w-full y items-start mb-3">
                <div class="p-2">
                  <b>To</b>
                </div>
                <div class="w-full flex items-start mt-1">
                  <div class="flex-1">
                    <date-picker
                      v-model="time.to"
                      type="time"
                      :clearable="false"
                      valueType="format"
                      format="HH:mm"
                      class="w-full disabled:bg-gray-100"
                      placeholder="00:00"
                      popup-class="hidden-calendar"
                    ></date-picker>
                  </div>
                </div>
              </div>

              <div class="w-10 flex-1 items-center x pt-7">
                <i
                  class="isax isax-close-circle text-gray-500 cursor-pointer"
                  @click="times.splice(index, 1)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #actionButton>
      <button-primary
        @click.native="save"
        class="px-1 self-end mb-1 text-sm py-1 transition-colors duration-200 disabled:opacity-75"
        :disabled="!isValid || loading"
        :class="{ 'bg-transparent border': !isValid }"
      >
        <span :class="{ 'text-black': !isValid }">
          {{ isEdit ? "Update" : "Create" }} Schedule
        </span>
      </button-primary>
    </template>
  </pop-up-modal>
</template>

<script>
import DatePicker from "vue2-datepicker";
import { DateTime } from "luxon";
import ButtonPrimary from "./ButtonPrimary.vue";
import PopUpModal from "./PopUpModal.vue";
import timezoneCalculator from "../compostions/useTimezoneCalculator";
import TimeZoneSelector from "./TimeZoneSelector.vue";

export default {
  setup() {
    const { toTimezone, fromTimezone } = timezoneCalculator();

    return {
      toTimezone,
      fromTimezone,
    };
  },
  mounted() {
    if (this.schedule && this.schedule.id) {
      Object.assign(this.editedSchedule, {
        ...this.schedule,
        day: this.schedule.day ? DateTime.fromISO(this.schedule.day).toFormat("kkkk-MM-dd") : null,
        user: this.schedule.user,
      });
      this.isEdit = !this.isView;
    } else
      Object.assign(
        this.editedSchedule,
        this.schedule ?? {
          is_free: this.isFree,
          day: this.schedule.day,
          work_times: [],
        },
      );
    this.times = JSON.parse(JSON.stringify(this.editedSchedule.work_times));

    this.updateTimezones();
  },
  components: {
    ButtonPrimary,
    PopUpModal,
    DatePicker,
    TimeZoneSelector,
  },
  props: {
    schedule: {
      type: Object,
      default: () => ({}),
    },
    isView: {
      type: Boolean,
      default: () => false,
    },
    isFree: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      loading: false,
      isEdit: false,
      editedSchedule: {
        day: DateTime.now().toISODate(),
        is_free: false,
        work_times: [],
      },
      days: [],
      times: null,
    };
  },
  methods: {
    close(didUpdate = false) {
      this.$emit("close", didUpdate);
    },
    updateTimezones() {
      for (let i = 0; i < this.times.length; i += 1) {
        const time = this.times[i];
        time.from = this.toTimezone(time.from);
        time.to = this.toTimezone(time.to);
      }
    },
    async save() {
      if (!this.isValid) return;
      this.loading = true;
      try {
        const addUrl = `/work-schedules`;
        const updateUrl = `${addUrl}/${this.editedSchedule.id}`;
        const times = JSON.parse(JSON.stringify(this.times)).map((time) => {
          Object.assign(time, {
            from: this.fromTimezone(time.from),
            to: this.fromTimezone(time.to),
          });
          return time;
        });
        const data = {
          ...this.editedSchedule,
          work_times: times,
        };
        if (this.isEdit) await this.axios.put(updateUrl, data);
        else {
          await this.axios.post(addUrl, data);
        }
        this.close(true);
      } catch (e) {
        //
      } finally {
        this.loading = false;
      }
    },
    beforeToday(date) {
      return DateTime.fromJSDate(date) <= DateTime.now().minus({ days: 1 });
    },
    timestrToSec(timestr) {
      const parts = timestr.split(":");
      return parts[0] * 3600 + parts[1] * 60;
    },
    timeSeconds(time) {
      return this.timestrToSec(time.to) - this.timestrToSec(time.from);
    },
  },
  computed: {
    isValid() {
      if (!this.editedSchedule.day) return false;
      if (!this.editedSchedule.is_free) {
        if (this.times) {
          for (let i = 0; i < this.times.length; i += 1) {
            const workTime = this.times[i];
            if (!workTime.from || !workTime.to) {
              return false;
            }
          }
        }
      }

      return true;
    },
    totalTime() {
      return this.editedSchedule.times.reduce((total, time) => total + this.timeSeconds(time), 0);
    },
  },
  filters: {
    formatSeconds(seconds) {
      const pad = function (num) {
        if (num < 10) {
          return `0${num}`;
        }
        return `${num}`;
      };
      return [pad(Math.floor(seconds / 3600)), pad(Math.floor(seconds / 60) % 60)].join(":");
    },
  },
  watch: {
    timezone() {
      this.updateTimezones();
    },
  },
};
</script>

<style lang="postcss">
.custom-date-picker .mx-calendar {
  width: 50vh !important;
}
.hidden-calendar {
  display: none;
}
</style>

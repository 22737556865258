<template>
  <li class="border rounded-lg shadow-md pl-3 pr-4 py-3 flex items-center justify-between text-sm">
    <div class="flex-1 flex items-center">
      <!-- Heroicon name: solid/paper-clip -->
      <slot name="icon">
        <svg
          class="flex-shrink-0 h-5 w-5 text-gray-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
            clip-rule="evenodd"
          />
        </svg>
      </slot>

      <div class="ml-5 flex-1 y gap-y-0">
        <span class="truncate"> {{ title }} </span>
        <span class="text-sm text-gray-500"> {{ description }} </span>
      </div>
    </div>
    <div class="ml-4 flex-shrink-0">
      <button-primary @click.native="$emit('download')">{{ downloadText }}</button-primary>
    </div>
  </li>
</template>

<script>
import ButtonPrimary from "./ButtonPrimary.vue";

export default {
  components: { ButtonPrimary },
  props: {
    title: String,
    description: String,
    downloadText: String,
  },
};
</script>

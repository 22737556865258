<template>
  <img :src="src" alt="Avatar" :class="`w-${width} h-${height} rounded-full`" />
</template>
<script>
export default {
  props: {
    width: {
      type: Number,
      default: 10,
    },
    height: {
      type: Number,
      default: 10,
    },
    src: String,
  },
};
</script>

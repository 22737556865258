import axios from "axios";
import { DateTime } from "luxon";

const bonusStore = {
  namespaced: true,
  state: {
    filters: {
      month: DateTime.local().minus({ months: 1 }).startOf("month").toFormat("yyyy-LL"),
    },
    loading: false,
    issues: [],
  },
  getters: {
    filters: (state) => state.filters,
    loading: (state) => state.loading,
    issues: (state) => state.issues,
    issuesByUser: (state) => {
      const users = new Map();
      state.issues.forEach((i) => {
        const user = users.get(i.assignee.id) ?? {
          saved_time: 0,
          deductible_time: 0,
          ...i.assignee,
        };
        user.saved_time += i.saved_time;
        user.deductible_time += i.deductible_time;
        users.set(i.assignee.id, user);
      });

      return Array.from(users.values());
    },
  },
  mutations: {
    setFilters(state, filters) {
      state.filters = filters;
    },
    setIssues(state, issues) {
      state.issues = issues;
    },
  },
  actions: {
    async fetchIssues({ state, commit }) {
      state.loading = true;
      const url = `/issues-bonus?month=${state.filters.month}`;
      return axios
        .get(url)
        .then((resp) => {
          commit("setIssues", resp.data);
        })
        .finally(() => {
          state.loading = false;
        });
    },
    async pushIssue({ commit, state }, { issueId }) {
      return axios
        .post("/issues-bonus-push", {
          issue_id: issueId,
          month: state.filters.month,
        })
        .then(() => {
          const idx = state.issues.findIndex((i) => i.id === issueId);
          state.issues.splice(idx, 1);
          commit("setIssues", state.issues);
        });
    },
    async unPushIssue({ commit, state }, { issueId }) {
      return axios.delete(`/issues-bonus-push?issue_id=${issueId}`).then(() => {
        const idx = state.issues.findIndex((i) => i.id === issueId);
        state.issues.splice(idx, 1);
        commit("setIssues", state.issues);
      });
    },
    async saveTime({ commit, state }, { issueId, savedTime }) {
      return axios
        .post("/issues-bonus-save-time", {
          issue_id: issueId,
          saved_time: savedTime,
        })
        .then(() => {
          const idx = state.issues.findIndex((i) => i.id === issueId);
          state.issues[idx].saved_time = savedTime;
          state.issues[idx].deductible_time = state.issues[idx].remaining_time - savedTime;
          commit("setIssues", state.issues);
        });
    },
  },
};

export default bonusStore;

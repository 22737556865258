<template>
  <pop-up-modal :height="'auto'" @close="close">
    <template>
      <div class="w-full h-full">
        <div class="w-full pb-3 px-3 flex justify-between items-center x text-black leading-5">
          <div class="w-full py-3 px-4 flex justify-between y text-black leading-5">
            <h1 class="text-lg font-bold">Default Work Time</h1>
            <p class="text-gray-500">set your default work time</p>
          </div>
          <div class="x gap-x-2 w-full justify-end">
            <time-zone-selector />
            <button-primary
              @click.native="times.push({ from: null, to: null, id: new Date().getTime() })"
              class="w-auto whitespace-nowrap h-10"
            >
              <span class="text-xs">Add a time range</span>
            </button-primary>
          </div>
        </div>

        <p class="px-4 mb-5" v-if="times && times.length < 1">No work times set.</p>
        <div v-if="times">
          <div
            v-for="(time, index) in times"
            :key="time.id"
            class="w-full mb-2 px-4 flex-1 flex flex-col overflow-y-scroll justify-start scrollbar-track-rounded-md scrollbar-thin scrollbar-track-gray-300"
          >
            <div class="x gap-x-2">
              <div class="w-full y items-start mb-3">
                <div class="p-2">
                  <b>From</b>
                </div>
                <div class="w-full flex items-start mt-1">
                  <div class="flex-1">
                    <date-picker
                      v-model="time.from"
                      type="time"
                      :clearable="false"
                      valueType="format"
                      format="HH:mm"
                      class="w-full disabled:bg-gray-100"
                      placeholder="00:00"
                      popup-class="hidden-calendar"
                    ></date-picker>
                  </div>
                </div>
              </div>

              <div class="w-full y items-start mb-3">
                <div class="p-2">
                  <b>To</b>
                </div>
                <div class="w-full flex items-start mt-1">
                  <div class="flex-1">
                    <date-picker
                      v-model="time.to"
                      type="time"
                      :clearable="false"
                      valueType="format"
                      format="HH:mm"
                      class="w-full disabled:bg-gray-100"
                      placeholder="00:00"
                      popup-class="hidden-calendar"
                    ></date-picker>
                  </div>
                </div>
              </div>

              <div class="w-10 flex-1 items-center x pt-7">
                <i
                  class="isax isax-close-circle text-gray-500 cursor-pointer"
                  @click="times.splice(index, 1)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #actionButton>
      <button-primary
        @click.native="save"
        class="px-1 self-end mb-1 text-sm py-1 transition-colors duration-200 disabled:opacity-75"
        :disabled="!isValid || loading"
        :class="{ 'bg-transparent border': !isValid }"
      >
        <span :class="{ 'text-black': !isValid }"> Set </span>
      </button-primary>
    </template>
  </pop-up-modal>
</template>

<script>
import DatePicker from "vue2-datepicker";
import { mapGetters } from "vuex";
import timezoneCalculator from "../compostions/useTimezoneCalculator";
import ButtonPrimary from "./ButtonPrimary.vue";
import PopUpModal from "./PopUpModal.vue";
import TimeZoneSelector from "./TimeZoneSelector.vue";

export default {
  setup() {
    const { toTimezone, fromTimezone } = timezoneCalculator();

    return {
      toTimezone,
      fromTimezone,
    };
  },
  mounted() {
    const me = this.$store.state.user;
    if (me.work_times) {
      this.times = JSON.parse(JSON.stringify(me.work_times ?? "[]"));
    }
    this.updateTimezones();
  },
  components: {
    ButtonPrimary,
    PopUpModal,
    DatePicker,
    TimeZoneSelector,
  },
  data() {
    return {
      loading: false,
      times: null,
    };
  },
  methods: {
    close(didUpdate = false) {
      this.$emit("close", didUpdate);
    },
    updateTimezones() {
      for (let i = 0; i < this.times.length; i += 1) {
        const time = this.times[i];
        time.from = this.toTimezone(time.from);
        time.to = this.toTimezone(time.to);
      }
    },
    async save() {
      if (!this.isValid) return;
      this.loading = true;
      try {
        const times = JSON.parse(JSON.stringify(this.times)).map((time) => {
          Object.assign(time, {
            from: this.fromTimezone(time.from),
            to: this.fromTimezone(time.to),
          });
          return time;
        });
        const resp = await this.axios.post(`users/work-times`, {
          work_times: times,
        });
        if (resp.data.data) {
          this.$store.commit("setUser", resp.data.data);
        }
        this.close(true);
      } catch (e) {
        console.log("e", e);
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    isValid() {
      if (!this.times) return false;
      for (let i = 0; i < this.times.length; i += 1) {
        const workTime = this.times[i];
        if (!workTime.from || !workTime.to) {
          return false;
        }
      }
      return true;
    },
    ...mapGetters(["timezone"]),
  },
  watch: {
    timezone() {
      this.updateTimezones();
    },
  },
};
</script>

<style lang="postcss">
.custom-date-picker .mx-calendar {
  width: 50vh !important;
}
.hidden-calendar {
  display: none;
}
</style>

<template>
  <div class="y items-center w-full">
    <main class="y p-3 s-6 max-w-screen-2xl w-full h-full">
      <breadcrumb :links="breadcrumbLinks" />
      <div
        class="flex justify-between flex-wrap border-b border-gray-200 pb-3"
        style="margin-top: 16px !important"
      >
        <div class="hidden md:flex" v-if="groupInfoLoading">
          <div class="ssc-square rounded mr-2" style="height: 64px; width: 64px" />
          <div class="flex flex-col mt-2">
            <div class="mb-2 font-bold text-lg mr-2 ssc-square w-36 h-5 rounded-md" />
            <div class="font-bold text-lg mr-2 ssc-square w-80 h-4 rounded-md" />
          </div>
        </div>
        <div class="hidden md:flex" v-else>
          <div
            v-if="groupInfo.avatar_url"
            class="mr-2 rounded flex items-center justify-center"
            style="height: 64px; width: 64px; border: 1px solid #f5f5f5"
          >
            <img class="rounded" :src="groupInfo.avatar_url" />
          </div>
          <div
            v-else
            style="height: 64px; width: 64px"
            class="bg-gray-200 mr-2 text-2xl rounded flex items-center justify-center"
          >
            {{ groupInfo.name.toUpperCase()[0] }}
          </div>
          <div class="flex flex-col mt-1">
            <div class="font-bold text-lg mr-2">{{ groupInfo.name }}</div>
            <div>{{ groupInfo.description }}</div>
          </div>
        </div>
        <div class="x w-full md:w-auto items-end">
          <input
            type="text"
            class="w-full"
            v-debounce:200="search"
            style="height: 35px"
            placeholder="Filter projects by name"
          />
        </div>
      </div>

      <ul v-if="!loading" style="margin-top: 8px !important">
        <clickable-item
          :openLink="false"
          @click.native="handleClick(project)"
          :path="resolveLink(project)"
          v-for="project in projects"
          :key="project.id"
        >
          <div class="x justify-start p-2 s-3 items-center py-2">
            <div
              v-if="project.avatar_url"
              class="w-9 h-9 rounded x items-center justify-center"
              style="border: 1px solid #f5f5f5"
            >
              <img class="rounded" :src="project.avatar_url" />
            </div>
            <span class="w-9 h-9 bg-gray-200 text-lg x items-center justify-center" v-else>{{
              project.name.toUpperCase()[0]
            }}</span>
            <div class="y items-start flex-1">
              <pinnable
                :type="!!project.path_with_namespace ? 'project' : 'group'"
                :id="project.gitlab_id ? project.gitlab_id : project.id"
                :modelKey="'gitlab_id'"
              >
                <h3 class="font-bold hover:underline">
                  {{ project.name }}
                </h3>
              </pinnable>
              <span class="text-sm">{{ project.description }}</span>
            </div>
            <p class="mx-3" v-if="project.role">
              Your role is<br />
              <span class="font-bold">{{ project.role }}</span>
            </p>
            <button-secondary
              @click.native="
                ($event) => {
                  $event.stopPropagation();
                  $event.preventDefault();
                  gotToGitlab(project);
                }
              "
            >
              Go to Gitlab
            </button-secondary>
          </div>
        </clickable-item>

        <div class="mt-10 w-full flex items-center justify-center">
          <p v-if="projects.length < 1">There is no results with this keyword.</p>
        </div>
      </ul>
      <div v-else class="w-100 h-100 flex justify-center"><loading class="mt-5" /></div>
    </main>
  </div>
</template>

<script lang="ts">
import Breadcrumb from "../components/Breadcrumb.vue";
import Loading from "../components/Loading.vue";
import ClickableItem from "../components/ClickableItem.vue";
import ButtonSecondary from "../components/ButtonSecondary.vue";
import Pinnable from "../components/Pinnable.vue";

export default {
  components: { Breadcrumb, Loading, ClickableItem, ButtonSecondary, Pinnable },
  name: "ProjectChooser",
  beforeRouteUpdate(to, from, next) {
    if (to.name === from.name) {
      const breadCrumbIndex = this.breadcrumbLinks.findIndex((l) => l.url === to.path);
      if (breadCrumbIndex !== -1) {
        this.breadcrumbLinks.splice(breadCrumbIndex + 1);
      }
      this.resetState();
      this.group = to.params.group ? encodeURIComponent(to.params.group) : "";
      this.getGroupInfo();
      this.getGroupsAndProjects();
    }
    next();
  },
  data() {
    return {
      loading: true,
      filterStr: "",
      group: null,
      groupInfo: null,
      groupInfoLoading: true,
      projects: [],
      breadcrumbLinks: [
        { name: "Tracking", url: "/" },
        { name: "WebOrigo", url: "/projects" },
      ],
    };
  },
  mounted() {
    this.group = this.$route.params.group ? encodeURIComponent(this.$route.params.group) : null;
    this.getGroupInfo();
    this.getGroupsAndProjects();
  },
  methods: {
    selectProject(newSelectionString) {
      if (this.projectsMap[newSelectionString]) {
        this.$router.push("/tracking/issues");
      } else {
        this.$router.push("/select-project");
        this.$store.dispatch("changeSelection", newSelectionString);
      }
    },
    async getGroupsAndProjects() {
      try {
        this.loading = true;
        const params = {
          search: this.filterStr,
        };
        let groupsUrl = "/groups";
        if (this.group) {
          groupsUrl += `/${decodeURIComponent(this.group)}/subgroups`;
        }

        const groups = await this.axios.get(groupsUrl, {
          params,
        });

        let projectsUrl = "";
        if (this.group) {
          projectsUrl = `groups/${decodeURIComponent(this.group)}/projects`;
        } else {
          projectsUrl = "groups/projects";
        }

        const newData = [...groups.data.data];
        if (this.group) {
          const projects = await this.axios.get(projectsUrl, { params });
          newData.push(...projects.data.data);
        }

        this.projects = [...newData];

        this.projects = this.projects.sort((a, b) => a.name.localeCompare(b.name));
      } catch (e) {
        //
      } finally {
        this.loading = false;
      }
    },
    loadMoreGroupsAndProjects() {
      this.getGroupsAndProjects();
    },
    getGroupInfo() {
      if (this.group === null) {
        this.groupInfo = {
          avatar_url: "/images/weborigo.jpg",
          name: "WebOrigo",
          description:
            "Marketing automation | ERP&CRM systems | Online payment solutions | Industrial automation",
        };
        this.groupInfoLoading = false;
        return;
      }
      this.groupInfoLoading = true;
      this.axios
        .get(`/groups/${decodeURIComponent(this.group)}`)
        .then((resp) => {
          this.groupInfo = resp.data;
          this.setBreadcrumbLinks();
        })
        .catch((e) => console.log(e, e.response))
        .finally(() => {
          this.groupInfoLoading = false;
        });
    },
    setBreadcrumbLinks() {
      this.breadcrumbLinks = this.$store.getters.projectBreadcrumb(
        this.groupInfo.full_path,
        this.groupInfo.full_name,
      );
    },
    resolveLink(project) {
      const isProject = !!project.path_with_namespace;
      let link;
      if (isProject) {
        link = this.$router.resolve({
          path: `/projects/${project.path_with_namespace}/track`,
        });
      } else {
        link = this.$router.resolve(`/projects/${project.full_path}`);
      }
      return link.href;
    },
    handleClick(project) {
      const isProject = !!project.path_with_namespace;
      if (isProject) {
        const breadcrumbLinkForProject = JSON.parse(JSON.stringify(this.breadcrumbLinks));
        breadcrumbLinkForProject.push({
          name: project.name,
          url: `/projects/${project.path_with_namespace}/track`,
        });
        this.$store.state.breadcrumbLinkForProject = breadcrumbLinkForProject;
        this.$router.push({
          path: `/projects/${project.path_with_namespace}/track`,
        });
      } else {
        this.breadcrumbLinks.push({ name: project.name, url: `/projects/${project.full_path}` });
        this.$router.push(`/projects/${project.full_path}`);
      }
    },
    resetState() {
      this.loading = true;
      this.filterStr = "";
      this.projects = [];
      this.group = null;
      this.groupInfo = null;
      this.groupInfoLoading = true;
    },
    search(str) {
      this.filterStr = str;
      this.getGroupsAndProjects();
    },
    gotToGitlab(project) {
      window.open(project.web_url, "_blank");
    },
  },
  computed: {},
};
</script>
<style>
.ssc-square {
  display: block;
  background-color: rgba(160, 156, 156, 0.17);
}
.ssc-square,
.ssc-head-line,
.ssc-line,
.ssc-circle {
  position: relative;
  overflow: hidden;
}
.ssc-square:after,
.ssc-head-line:after,
.ssc-line:after,
.ssc-circle:after {
  content: "";
  -webkit-animation: ssc-loading 0.7s infinite;
  animation: ssc-loading 0.7s infinite;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  z-index: 1;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(transparent),
    color-stop(rgba(255, 255, 255, 0.3)),
    to(transparent)
  );
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.3), transparent);
}
@keyframes ssc-loading {
  from {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  to {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}
</style>

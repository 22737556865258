<template>
  <div class="y items-center w-full">
    <main class="y p-3 s-6 max-w-screen-2xl w-full h-full">
      <breadcrumb
        :links="[
          { name: 'Tracking', url: '/' },
          { name: 'Issues Bonus', url: '/issues-bonus' },
        ]"
      />

      <div class="w-full y md:x gap-x-3 items-end mt-3">
        <field-item :label="'Month'">
          <template #input>
            <date-picker
              v-model="filters.month"
              :clearable="false"
              valueType="format"
              format="YYYY-MM"
              class="w-full disabled:bg-gray-100"
              type="month"
              placeholder="Choose a year and month..."
            ></date-picker>
          </template>
        </field-item>
      </div>

      <div class="overflow-visible sm:rounded-lg" v-if="!loading">
        <h1 class="font-bold text-xl mb-5">Issues</h1>
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50" v-if="issues.length > 0">
            <tr>
              <th
                scope="col"
                class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Employee
              </th>
              <th
                scope="col"
                class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Project
              </th>
              <th
                scope="col"
                class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Issue IID
              </th>
              <th
                scope="col"
                class="px-3 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Due date
              </th>
              <th
                scope="col"
                class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Finished at
              </th>
              <th
                scope="col"
                class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Estimated time
              </th>
              <th
                scope="col"
                class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Spent time
              </th>
              <th
                scope="col"
                class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Remaining time
              </th>
              <th
                scope="col"
                class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Saved time
              </th>
              <th
                scope="col"
                class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Deductible time
              </th>
              <th
                scope="col"
                class="px-3 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
              ></th>
            </tr>
          </thead>

          <issues-bonus-excerpt
            v-for="issue of issues"
            v-bind:key="issue.id"
            :issue="issue"
            :canEdit="canEdit"
          />
        </table>

        <h1 class="mt-12 font-bold text-xl mb-5">Summary</h1>
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50" v-if="issues.length > 0">
            <tr>
              <th
                scope="col"
                class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Employee
              </th>
              <th
                scope="col"
                class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Total Saved Time
              </th>
              <th
                scope="col"
                class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Total Deductible Time
              </th>
            </tr>
          </thead>

          <tr class="bg-white" v-for="user of issuesByUser" v-bind:key="user.id">
            <td
              scope="col"
              class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              <user-excerpt :user="getUserWithAvatar(user)" />
            </td>
            <td
              scope="col"
              class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              {{ user.saved_time | secondsToHuman }}
            </td>
            <td
              scope="col"
              class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              {{ user.deductible_time | secondsToHuman }}
            </td>
          </tr>
        </table>
      </div>

      <div v-else>
        <loading-excerpt-list class="mt-2" :length="10" />
      </div>
      <no-data v-if="!loading && issues.length < 1" />
    </main>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DatePicker from "vue2-datepicker";
import FieldItem from "../components/FieldItem.vue";
import Breadcrumb from "../components/Breadcrumb.vue";
import LoadingExcerptList from "../components/LoadingExcerptList.vue";
import NoData from "../components/NoData.vue";
import IssuesBonusExcerpt from "../components/IssuesBonusExcerpt.vue";
import UserExcerpt from "../components/UserExcerpt.vue";

export default {
  components: {
    FieldItem,
    Breadcrumb,
    DatePicker,
    LoadingExcerptList,
    NoData,
    IssuesBonusExcerpt,
    UserExcerpt,
  },
  mounted() {
    this.getData();
  },
  data() {
    return {};
  },
  methods: {
    async getData() {
      this.fetchIssues();
    },
    getUserWithAvatar(user) {
      return {
        ...user,
        avatar: user.avatar_url,
      };
    },
    ...mapActions("issuesBonus", ["fetchIssues", "pushIssue", "unPushIssue", "saveTime"]),
  },
  computed: {
    filters: {
      get() {
        return this.$store.state.issuesBonus.filters;
      },
      set(val) {
        this.$store.commit("bonus/setFilters", val);
      },
    },
    canEdit() {
      return this.$store.state.user && this.$store.state.user.access_level >= 40;
    },
    ...mapGetters("issuesBonus", ["loading", "issues", "issuesByUser"]),
  },
  watch: {
    filters: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
table th:first-child {
  @apply rounded-tl-lg;
}

table th:last-child {
  @apply rounded-tr-lg;
}
</style>

<template>
  <pop-up-modal @close="close" :height="'auto'">
    <template>
      <div class="px-4 py-3 w-full h-full">
        <div class="w-full pb-3 flex justify-between y text-black leading-5">
          <h1 class="text-lg font-bold">{{ isEdit ? "Edit" : "Create" }} Event</h1>
          <p class="text-gray-500">{{ isEdit ? "Edit" : "Create" }} a normal or recurring event</p>
        </div>

        <div class="w-full flex-1 flex flex-col justify-start pb-3">
          <field-item :label="'User'" v-if="!isEdit">
            <template #input>
              <v-select
                :loading="usersLoading"
                label="name"
                placeholder="No user selected"
                :options="activeUsers"
                v-model="editedEvent.user_id"
                :reduce="(u) => u.id"
                class="chooser h-12 w-full mb-2 rounded"
              />
            </template>
          </field-item>

          <field-item :label="'Title'">
            <template #input>
              <validated-input v-model="editedEvent.title" />
            </template>
          </field-item>
          <field-item :label="'Link'">
            <template #input>
              <validated-input v-model="editedEvent.url" />
            </template>
          </field-item>

          <div class="w-full y items-start mb-3">
            <div class="p-2">
              <b>Is recurring?</b>
            </div>
            <toggle-button
              :label="'is_recurring'"
              class="ml-2"
              v-model="editedEvent.is_recurring"
            />
          </div>

          <div class="x gap-x-2" v-if="!editedEvent.is_recurring">
            <div class="w-full y items-start">
              <div class="p-2">
                <b>Event Start</b>
              </div>
              <date-picker
                v-model="editedEvent.start_date"
                valueType="format"
                format="YYYY-MM-DD HH:mm"
                type="datetime"
                class="w-full"
                placeholder="Choose a date..."
              ></date-picker>
            </div>

            <div class="w-full y items-start">
              <div class="p-2">
                <b>Event End</b>
              </div>
              <date-picker
                v-model="editedEvent.end_date"
                valueType="format"
                format="YYYY-MM-DD HH:mm"
                type="datetime"
                class="w-full"
                placeholder="Choose a date..."
              ></date-picker>
              <p class="text-gray-400 text-xs pt-2 pr-5">
                Optional, if left empty the event will be only a hour.
              </p>
            </div>
          </div>

          <div class="x gap-x-2" v-if="editedEvent.is_recurring">
            <div class="w-full y items-start mb-3">
              <div class="p-2">
                <b>From</b>
              </div>
              <div class="w-full flex items-start mt-1">
                <div class="flex-1">
                  <date-picker
                    v-model="editedEvent.from"
                    type="time"
                    :clearable="false"
                    valueType="format"
                    format="HH:mm"
                    class="w-full disabled:bg-gray-100"
                    placeholder="00:00"
                    popup-class="hidden-calendar"
                  ></date-picker>
                  <p class="text-gray-400 text-xs pt-2 pr-5">
                    Optional, if left empty will be from the start of the day.
                  </p>
                </div>
              </div>
            </div>

            <div class="w-full y items-start mb-3">
              <div class="p-2">
                <b>To</b>
              </div>
              <div class="w-full flex items-start mt-1">
                <div class="flex-1">
                  <date-picker
                    v-model="editedEvent.to"
                    type="time"
                    :clearable="false"
                    valueType="format"
                    format="HH:mm"
                    class="w-full disabled:bg-gray-100"
                    placeholder="00:00"
                    popup-class="hidden-calendar"
                  ></date-picker>
                  <p class="text-gray-400 text-xs pt-2 pr-5">
                    Optional, if left empty will be until the end of the day.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="w-full y items-start overflow-y-visible" v-if="editedEvent.is_recurring">
            <div class="p-2">
              <b>The event occurs every?</b>
            </div>
            <v-select
              placeholder="Select a unit"
              label="title"
              :options="periods"
              :reduce="(p) => p.value"
              v-model="editedEvent.recurring_type"
              class="chooser h-12 w-full mb-4 rounded"
            />
          </div>

          <div class="x" v-if="editedEvent.is_recurring">
            <div class="w-full y items-start" v-if="editedEvent.recurring_type === 'weekly'">
              <div class="p-2">
                <b>The event will be generated every?</b>
              </div>
              <v-select
                placeholder="Select a day"
                label="title"
                multiple
                :options="daysOfWeek"
                v-model="editedEvent.recurring_day"
                :reduce="(d) => d.value"
                class="chooser h-12 w-full mb-4 rounded"
              />
            </div>

            <div class="w-full y items-start" v-if="editedEvent.recurring_type === 'monthly'">
              <div class="p-2">
                <b>The event will be generated on day?</b>
              </div>
              <v-select
                placeholder="Select a day"
                :options="daysOfMonth"
                v-model="editedEvent.recurring_day[0]"
                class="chooser h-12 w-full mb-4 rounded"
              />
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #actionButton>
      <button-primary
        @click.native="save"
        class="px-1 self-end mb-1 text-sm py-1 transition-colors duration-200 disabled:opacity-80"
        type="button"
        :disabled="!isValid || loading"
      >
        {{ isEdit ? "Update" : "Create" }} Event
      </button-primary>
    </template>
  </pop-up-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DatePicker from "vue2-datepicker";
import { DateTime } from "luxon";
import ButtonPrimary from "./ButtonPrimary.vue";
import PopUpModal from "./PopUpModal.vue";
import ToggleButton from "./ToggleButton.vue";
import FieldItem from "./FieldItem.vue";
import ValidatedInput from "./ValidatedInput.vue";
import timezoneCalculator from "../compostions/useTimezoneCalculator";

export default {
  setup() {
    const { toTimezone, fromTimezone, dateToTimezone, dateFromTimezone } = timezoneCalculator();

    return {
      toTimezone,
      fromTimezone,
      dateToTimezone,
      dateFromTimezone,
    };
  },
  mounted() {
    if (!this.userId && this.users.length < 1) {
      this.getUsers();
    }

    if (this.event) {
      console.log(this.event);
      let recurringDay = [];
      if (Array.isArray(this.event.recurring_day)) {
        recurringDay = [...this.event.recurring_day];
      } else if (typeof this.event.recurring_day === "number") {
        recurringDay[0] = this.event.recurring_day;
      }
      Object.assign(this.editedEvent, {
        ...this.event,
        start_date: this.event.start_date ? this.dateToTimezone(this.event.start_date) : null,
        end_date: this.event.end_date ? this.dateToTimezone(this.event.end_date) : null,
        from: this.event.from ? this.toTimezone(this.event.from) : null,
        to: this.event.to ? this.toTimezone(this.event.to) : null,

        is_recurring: !!this.event.is_recurring,
        recurring_day: recurringDay,
      });
      console.log("editedEvent", this.editedEvent);
      this.isEdit = true;
    }

    this.daysOfMonth = Array.from({ length: 31 }, (_, i) => i + 1);
  },
  components: {
    ButtonPrimary,
    PopUpModal,
    DatePicker,
    ToggleButton,
    FieldItem,
    ValidatedInput,
  },
  props: {
    event: {
      type: Object,
    },
    userId: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      isEdit: false,
      editedEvent: {
        title: "",
        url: null,
        start_date: null,
        end_date: null,
        is_recurring: false,
        recurring_type: null,
        recurring_day: null,
        from: null,
        to: null,
        user_id: null,
      },
      periods: [
        {
          title: "Day",
          value: "daily",
        },
        {
          title: "Week",
          value: "weekly",
        },
        {
          title: "Month",
          value: "monthly",
        },
      ],
      daysOfWeek: [
        {
          title: "Monday",
          value: 0,
        },
        {
          title: "Tuesday",
          value: 1,
        },
        {
          title: "Wednesday",
          value: 2,
        },
        {
          title: "Thursday",
          value: 3,
        },
        {
          title: "Friday",
          value: 4,
        },
        {
          title: "Saturday",
          value: 5,
        },
        {
          title: "Sunday",
          value: 6,
        },
      ],
      daysOfMonth: [],
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ align: [] }],
            ["link"],
          ],
        },
      },
    };
  },
  methods: {
    close(didUpdate = false) {
      this.$emit("close", didUpdate);
    },
    async save() {
      if (!this.isValid) return;
      this.loading = true;
      const timezoneOverrides = {
        start_date: this.editedEvent.start_date
          ? this.dateFromTimezone(this.editedEvent.start_date)
          : null,
        end_date: this.editedEvent.end_date
          ? this.dateFromTimezone(this.editedEvent.end_date)
          : null,
        from: this.editedEvent.from ? this.fromTimezone(this.editedEvent.from) : null,
        to: this.editedEvent.to ? this.fromTimezone(this.editedEvent.to) : null,
      };

      try {
        const addUrl = `/events`;
        const updateUrl = `${addUrl}/${this.editedEvent.id}`;
        if (this.isEdit)
          await this.axios.put(updateUrl, {
            ...this.editedEvent,
            ...timezoneOverrides,
          });
        else
          await this.axios.post(addUrl, {
            ...this.editedEvent,
            ...timezoneOverrides,
            user_id: this.userId ?? this.editedEvent.user_id,
          });
        this.close(this.editedEvent);
      } catch (e) {
        //
      } finally {
        this.loading = false;
      }
    },
    beforeToday(date) {
      return DateTime.fromJSDate(date) <= DateTime.now().minus({ days: 1 });
    },
    ...mapActions("userInfo", ["getUsers"]),
  },
  computed: {
    isValid() {
      return (
        this.editedEvent.title != null &&
        this.editedEvent.title.length > 0 &&
        this.isRecurringFieldsValid
      );
    },
    isRecurringFieldsValid() {
      // if (!this.userId && !this.editedEvent.user_id) return false;
      if (!this.editedEvent.is_recurring && !this.editedEvent.start_date) return false;
      if (this.editedEvent.is_recurring && !this.editedEvent.recurring_type) return false;
      if (
        (this.editedEvent.recurring_type === "weekly" ||
          this.editedEvent.recurring_type === "monthly") &&
        this.editedEvent.recurring_day === null
      )
        return false;
      return true;
    },
    activeUsers() {
      return this.users.filter((u) => u.state === "active");
    },
    ...mapGetters("userInfo", ["users", "usersLoading"]),
  },
};
</script>

<style></style>

<template>
  <div class="y gap-y-0.5">
    <thanks-giving-bonus-user-select
      :value="getValueFromIndex(0)"
      :order="1"
      :selectedUsers="selectedUsers"
      @input="(val) => updateIndex(0, val)"
    >
      I got the most help this moth from:
    </thanks-giving-bonus-user-select>

    <thanks-giving-bonus-user-select
      :value="getValueFromIndex(1)"
      :order="2"
      :selectedUsers="selectedUsers"
      @input="(val) => updateIndex(1, val)"
    >
      I got the second most help this moth from:
    </thanks-giving-bonus-user-select>

    <thanks-giving-bonus-user-select
      :value="getValueFromIndex(2)"
      :order="3"
      :selectedUsers="selectedUsers"
      @input="(val) => updateIndex(2, val)"
    >
      I got the third most help this moth from:
    </thanks-giving-bonus-user-select>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ThanksGivingBonusUserSelect from "./ThanksGivingBonusUserSelect.vue";

export default {
  components: {
    ThanksGivingBonusUserSelect,
  },
  props: {
    value: Array,
    order: Number,
  },
  mounted() {
    if (this.users.length < 1) {
      this.getUsers();
    }
  },
  methods: {
    updateIndex(index, value) {
      const values = JSON.parse(JSON.stringify(this.value));
      values[index].to_user_id = value;
      this.$emit("input", values);
    },
    getValueFromIndex(index) {
      return this.value[index].to_user_id;
    },
    ...mapActions("userInfo", ["getUsers"]),
  },
  computed: {
    selectedUsers() {
      return this.value.filter((v) => v.to_user_id).map((u) => u.to_user_id);
    },
    ...mapGetters("userInfo", ["users"]),
  },
};
</script>

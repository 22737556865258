<template>
  <tr>
    <td
      scope="col"
      colspan="1"
      class="py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
    >
      <v-select
        class="selector md:w-52 h-40"
        :options="employees"
        :reduce="(u) => u.id"
        label="name"
        :value="value.employee_id"
        @input="(val) => update('employee_id', val)"
        placeholder="Select an employee..."
        :clearable="false"
      >
        <template #option="user">
          <user-excerpt :classes="'justify-start'" :user="user" />
        </template>
      </v-select>
    </td>
    <td
      scope="col"
      colspan="1"
      class="py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
    >
      <v-select
        class="selector md:w-52"
        :options="maintainersAndOwners"
        :reduce="(u) => u.id"
        label="name"
        :value="value.assignee_id"
        @input="(val) => update('assignee_id', val)"
        placeholder="Select an assignee..."
        :clearable="false"
      >
        <template #option="user">
          <user-excerpt :classes="'justify-start'" :user="user" />
        </template>
      </v-select>
    </td>
    <td
      scope="col"
      colspan="1"
      class="py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
    >
      <v-select
        class="selector md:w-52"
        :options="tests"
        :reduce="(t) => t.id"
        label="name"
        :value="value.bonus_test_id"
        @input="(val) => update('bonus_test_id', val)"
        placeholder="Select a test type..."
        :clearable="false"
      />
    </td>
    <td
      v-if="value.finished"
      scope="col"
      class="px-3 py-3 text-right text-base font-bold text-gray-900 uppercase tracking-wider"
    >
      {{ value.result_bonus }}%
      <span class="text-orange">{{ `+${value.extra_bonus}%` }}</span>
    </td>
    <td
      v-else
      scope="col"
      colspan="1"
      class="px-3 py-3 text-right text-xs font-medium text-gray-900 uppercase tracking-wider"
    >
      -
    </td>
    <td
      scope="col"
      colspan="1"
      class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
    >
      <div class="icon-btn w-16" v-if="value.id" @click="$emit('delete')">Delete</div>
      <div class="icon-btn w-16 opacity-50" v-else>Pending</div>
    </td>
    <td
      scope="col"
      colspan="1"
      class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
    >
      <div class="x gap-x-2">
        <i
          class="isax isax-close-circle text-xl cursor-pointer"
          @click="$emit('cancel')"
          title="cancel"
        />
        <i
          v-if="showSaveButton"
          class="isax isax-tick-circle text-xl cursor-pointer"
          @click="$emit('save')"
          title="save"
        />
      </div>
    </td>
  </tr>
</template>

<script>
import { mapGetters } from "vuex";
import UserExcerpt from "./UserExcerpt.vue";

export default {
  components: { UserExcerpt },
  props: {
    value: {
      type: Object,
      default: () => ({
        employee_id: null,
        assignee_id: null,
        bonus_test_id: null,
      }),
    },
    showSaveButton: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    update(key, value) {
      this.$emit("input", { ...this.value, [key]: value });
    },
  },
  computed: {
    maintainersAndOwners() {
      return this.users.filter((u) => u.access_level >= 40);
    },
    employees() {
      return this.users.filter((u) => u.state === "active");
    },
    ...mapGetters("userInfo", ["users"]),
    ...mapGetters("bonus", ["tests"]),
  },
};
</script>

<style>
.icon-btn {
  @apply px-3 py-1 text-sm text-center rounded bg-white text-black capitalize shadow-md cursor-pointer hover:shadow-lg transition-all;
}
</style>

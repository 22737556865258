<template>
  <div class="y relative w-full overflow-y-hidden flex-1">
    <breadcrumb
      class="py-4 mx-4"
      :links="[
        { name: 'Tracking', url: '/' },
        { name: 'All Trackings', url: '/trackings' },
      ]"
    />

    <div
      v-if="canEdit"
      v-show="filterUser || !loading"
      class="flex flex-col md:flex-row gap-x-4 items-center px-4 py-6"
    >
      <field-item :label="'Employee'" class="flex-1">
        <template #input>
          <v-select
            class="selector"
            :options="displayedUsers"
            label="name"
            v-model="filterUser"
            placeholder="Select an employee..."
          />
        </template>
      </field-item>

      <button-primary class="h-min mt-5 w-32" @click.native="showBonusAddModal = true">
        Add bonus
      </button-primary>
    </div>

    <div class="flex items-center h-full justify-center" v-if="loading">
      <loading />
    </div>
    <hot-table
      class="z-0"
      v-show="!loading"
      id="user-tracking-table"
      ref="hotTableComponent"
      :rowHeaders="true"
      :colHeaders="true"
      :settings="hotSettings"
    ></hot-table>

    <div
      class="bg-white w-full p-1 pl-14 border-t-2 x items-center gap-x-2 absolute bottom-5"
      v-if="0 < colsSummary.length"
    >
      <div
        class="x gap-x-2"
        :class="{ 'border-l pl-2': idx > 0 }"
        v-for="(summary, idx) of colsSummary"
        :key="idx"
      >
        <span class="font-bold">{{ summary.header }}:</span>
        <div class="text-sm">Sum: {{ summary.sum }},</div>
        <div class="text-sm">AVG: {{ summary.avg }}</div>
      </div>
    </div>

    <transition name="popup">
      <bonus-sheet-add-modal
        v-if="showBonusAddModal"
        @close="onSheetModalClose"
        :user="filterUser"
      />
    </transition>
  </div>
</template>

<script>
import { HyperFormula } from "hyperformula";
import Handsontable from "handsontable";
import { registerAllModules } from "handsontable/registry";
import { HotTable } from "@handsontable/vue";
import { DateTime } from "luxon";
import { mapActions, mapGetters } from "vuex";
import Breadcrumb from "../components/Breadcrumb.vue";
import FieldItem from "../components/FieldItem.vue";
import Loading from "../components/Loading.vue";
import ButtonPrimary from "../components/ButtonPrimary.vue";
import BonusSheetAddModal from "../components/BonusSheetAddModal.vue";
import PromiseSelectEditor from "../editors/PromiseSelectEditor";
import alerts from "../utils/alerts";
import getProjectsFilter from "../editors/projectsFilter";
import "handsontable/dist/handsontable.full.css";

export default {
  components: {
    Breadcrumb,
    FieldItem,
    Loading,
    HotTable,
    ButtonPrimary,
    BonusSheetAddModal,
  },
  async mounted() {
    registerAllModules();
    Handsontable.renderers.registerRenderer("currency", this.currencyRenderer);
    Handsontable.renderers.registerRenderer("select", this.selectRenderer);
    Handsontable.editors.registerEditor("promise-select", PromiseSelectEditor);
    if (!this.canEdit) this.disableEditing();
    if (this.canEdit && this.users.length < 1) {
      this.getUsers();
    }

    if (this.projects.length < 1) {
      this.fetchProjects();
    }

    if (this.companyBranches.length < 1) {
      this.fetchCompanyBranches();
    }

    this.$nextTick(this.initData);
  },
  data() {
    const decimal = {
      type: "numeric",
      numericFormat: {
        pattern: "0.00",
        culture: "en-US",
      },
    };

    const currencyHUF = {
      ...decimal,
      numericFormat: {
        culture: "hu-HU",
        pattern: "0,0.00 $",
      },
    };

    return {
      oldRowData: {},
      colsSummary: [],
      readOnlyProps: ["start_time", "end_time", "worked_hours", "total", "huf_total", "comment"],
      bonusReadOnlyProps: ["total", "huf_total"],
      hotData: [],
      cachedHeadsValues: {},
      projectIdsFilter: [],
      showBonusAddModal: false,
      hotSettings: {
        formulas: {
          engine: HyperFormula,
        },
        dropdownMenu: {
          items: {
            clear_column: {
              hidden: () => {
                const { col } = this.hotInstance.getSelectedRangeLast().to;
                return this.readOnlyProps.indexOf(this.hotInstance.colToProp(col)) > -1;
              },
            },
            alignment: {
              hidden: () => false,
            },
            "---------": {},
            filter_by_condition: {
              hidden: () => {
                const { col } = this.hotInstance.getSelectedRangeLast().to;
                return (
                  [
                    "start_time",
                    "end_time",
                    "worked_hours",
                    "salary",
                    "total",
                    "huf_exchange_rate",
                    "huf_total",
                    "payment_date",
                    "gitlab_issue_iid",
                  ].indexOf(this.hotInstance.colToProp(col)) < 0
                );
              },
            },
            filter_by_value: {
              hidden: () => {
                const { col } = this.hotInstance.getSelectedRangeLast().to;
                return (
                  ["company", "approved", "payment_no"].indexOf(this.hotInstance.colToProp(col)) < 0
                );
              },
            },
            projects: {
              hidden: () => {
                const { col } = this.hotInstance.getSelectedRangeLast().to;
                return this.hotInstance.colToProp(col) !== "gitlab_project_id";
              },
              renderer: () => {
                let projectsIds = this.trackings.reduce(
                  (all, t) => all.add(Number(t.gitlab_project_id)),
                  new Set(),
                );
                projectsIds = Array.from(projectsIds);
                const projects = this.projects.filter(
                  (p) => projectsIds.indexOf(Number(p.gitlab_id)) > -1,
                );

                const options = projects.map((p) => ({
                  label: `${p.namespace_name} / ${this.toTitleCase(p.name)}`,
                  value: p.gitlab_id,
                }));
                options.unshift({
                  label: "Bonus",
                  value: "Bonus",
                });

                return getProjectsFilter(
                  this.hotInstance,
                  "gitlab_project_id",
                  options,
                  this.projectIdsFilter,
                );
              },
              disableSelection: true, // Prevent mouseoever from highlighting the item for selection
              isCommand: false, // Prevent clicks from executing command and closing the menu
            },
            // eslint-disable-next-line no-dupe-keys
            "---------": {},
            filter_action_bar: {
              hidden: () => {
                const { col } = this.hotInstance.getSelectedRangeLast().to;
                return this.hotInstance.colToProp(col) === "gitlab_project_id";
              },
            },
          },
        },
        persistentState: true,
        manualColumnMove: true,
        data: [],
        height: "100%",
        width: "auto",
        colHeaders: [
          "Work Start",
          "Work End",
          "Worked Hours",
          "Salary",
          "Total",
          "Exchange rate HUF",
          "Total in HUF",
          "Project",
          "Issue ID",
          "Trackings description",
          "Approved",
          "Company",
          "Payment Number",
          "Payment Date",
        ],
        fillHandle: {
          direction: "vertical",
        },
        manualColumnResize: true,
        stretchH: "all",
        columns: [
          // Work start
          {
            data: "start_time",
            readOnly: true,
            type: "date",
            allowEmpty: true,
            dateFormat: "YYYY-MM-DD HH:mm A",
          },
          // Work end
          {
            data: "end_time",
            readOnly: true,
            type: "date",
            allowEmpty: true,
            dateFormat: "YYYY-MM-DD HH:mm A",
          },
          // Worked hours
          {
            data: "worked_hours",
            ...decimal,
            readOnly: true,
          },
          // Salary
          {
            data: "salary",
            ...decimal,
            numericFormat: {
              pattern: "0,0.00",
            },
            type: "numeric",
          },
          // Total
          {
            data: "total",
            ...decimal,
            numericFormat: {
              pattern: "0,0.00",
            },
            readOnly: true,
            type: "numeric",
          },
          // Exchange rate HUF
          {
            data: "huf_exchange_rate",
            ...currencyHUF,
            renderer: "currency",
            type: "numeric",
          },
          // Total in HUF
          {
            data: "huf_total",
            ...currencyHUF,
            readOnly: true,
            renderer: "currency",
          },
          // Project
          {
            data: "gitlab_project_id",
            fillHandle: false,
            type: "numeric",
            renderer: "select",
            editor: "promise-select",
            selectOptions: () => {
              return this.projectsOptions;
            },
            width: "200px",
            key: "project_id",
          },
          // Issue ID
          {
            data: "gitlab_issue_iid",
            fillHandle: false,
            // type: "numeric",
            width: "200px",
            editor: "promise-select",
            selectOptions: this.loadIssues,
          },
          // Tracking description
          { data: "comment", type: "text", readOnly: true },
          // Approved
          {
            data: "approved",
            type: "checkbox",
            checkedTemplate: 1,
            uncheckedTemplate: 0,
            className: "htCenter",
          },
          // Company
          {
            data: "company",
            editor: "select",
            selectOptions: () => this.companyBranches,
            type: "text",
          },
          // Payment Number
          { data: "payment_no" },
          // Payment Date
          {
            data: "payment_date",
            type: "date",
            correctFormat: true,
            dateFormat: "YYYY-MM-DD",
            allowEmpty: true,
          },
          {
            data: "type",
          },
          {
            data: "index",
          },
        ],
        contextMenu: {
          items: {
            remove_row: {
              hidden: () => {
                const { row } = this.hotInstance.getSelectedRangeLast().to;
                return !this.isRowBonus(row);
              },
            },
          },
        },
        columnSorting: true,
        hiddenColumns: {
          columns: [14, 15],
        },
        filters: true,
        licenseKey: "non-commercial-and-evaluation",
        selectionMode: "range",
        currentRowClassName: "currentRow",
        outsideClickDeselects: false,
        beforeAutofill: (selectionData, sourceRange, targetRange) => {
          const getArrayFromRange = (from, to) => {
            const range = [];
            for (let i = from; i <= to; i += 1) {
              range.push(i);
            }
            return range;
          };

          // transform rows range to rows indexes array
          const sourceRows = getArrayFromRange(sourceRange.from.row, sourceRange.to.row);
          const targetRows = getArrayFromRange(targetRange.from.row, targetRange.to.row);
          const targetCols = getArrayFromRange(targetRange.from.col, targetRange.to.col);

          const data = [];
          // loopting throw each target row
          for (let i = 0; i < targetRows.length; i += 1) {
            const rowData = [];
            // loopting throw each target col and fill it
            for (let j = 0; j < targetCols.length; j += 1) {
              const col = targetCols[j];
              const prop = this.hotInstance.colToProp(col);
              const targetRow = targetRows[i];
              const rowIsBonus = this.isRowBonus(targetRow);

              // disabling auto fill in read-only cols
              if (rowIsBonus) {
                if (this.bonusReadOnlyProps.indexOf(prop) > -1) {
                  alerts.showMessage("You can't autofill read-only columns.", null, true);
                  return false;
                }
              } else if (this.readOnlyProps.indexOf(prop) > -1) {
                alerts.showMessage("You can't autofill read-only columns.", null, true);
                return false;
              }

              if (["gitlab_project_id", "gitlab_issue_iid"].indexOf(prop) > -1) {
                alerts.showMessage(
                  "You can't edit projects or issues using autofill, please edit separately.",
                  null,
                  true,
                );
                return false;
              }

              // getting the data from the cross-bonding source row
              const rowIndex = sourceRows[i % sourceRows.length];
              const value = this.hotInstance.getDataAtCell(rowIndex, col);
              rowData.push(value);
            }
            data.push(rowData);
          }

          return data;
        },
        afterLoadData: () => {
          // loop throw rows
          for (let row = 0; row < this.hotInstance.countRows(); row += 1) {
            // if row is a bonus, update bonus row edit settings
            if (this.isRowBonus(row)) {
              for (let col = 0; col < this.hotInstance.countCols(); col += 1) {
                const prop = this.hotInstance.colToProp(col);
                if (["start_time", "end_time", "comment"].indexOf(prop) > -1) {
                  this.hotInstance.setCellMeta(row, col, "readOnly", false);
                }
                if (["gitlab_project_id", "gitlab_issue_iid"].indexOf(prop) > -1) {
                  this.hotInstance.setCellMeta(row, col, "readOnly", true);
                }
              }
            }
          }
        },
        afterSelection: (r1, c1, r2, c2) => {
          const colStart = Math.min(c1, c2);
          const colEnd = Math.max(c1, c2);
          const rowStart = Math.min(r1, r2);
          const rowEnd = Math.max(r1, r2);

          const summary = [];
          if (rowStart !== rowEnd) {
            for (let col = colStart; col <= colEnd; col += 1) {
              const prop = this.hotInstance.colToProp(col);
              if (
                ["worked_hours", "salary", "total", "huf_exchange_rate", "huf_total"].indexOf(
                  prop,
                ) > -1
              ) {
                let sum = 0;
                for (let row = rowStart; row <= rowEnd; row += 1) {
                  sum += Number(this.hotInstance.getDataAtCell(row, col));
                }

                let c = "";
                if (["huf_exchange_rate", "huf_total"].indexOf(prop) > 0) {
                  c = "Ft";
                }

                const formatNumber = (n) =>
                  n.toLocaleString(
                    undefined, // leave undefined to use the visitor's browser
                    // locale or a string like 'en-US' to override it.
                    { minimumFractionDigits: 2 },
                  );
                const avg = `${formatNumber(sum / (rowEnd - rowStart + 1))}${c}`;
                sum = `${formatNumber(sum)}${c}`;
                summary.push({
                  header: this.hotInstance.getColHeader(col),
                  sum,
                  avg,
                });
              }
            }
          }
          this.colsSummary = summary;
        },
        afterChange: (changes, source) => {
          if (source === "loadData") {
            return;
          }

          if (changes && changes.length) {
            if (
              changes.length > 1 ||
              !["gitlab_issue_iid", "gitlab_project_id"].includes(changes[0][1])
            ) {
              const editedData = {
                trackings: new Set(),
                bonuses: new Set(),
              };

              changes.forEach(([row, prop, oldValue, newValue]) => {
                const col = this.hotInstance.propToCol(prop);
                const cellProps = this.hotInstance.getCellMeta(row, col);
                if (cellProps.type === "numeric") {
                  if (Number(oldValue) === Number(newValue)) {
                    return;
                  }
                } else if (!oldValue && !newValue) {
                  return;
                } else if (oldValue === newValue) {
                  return;
                }

                const tracking = this.getSheetTracking(row);
                const rowIsBonus = this.isRowBonus(row);

                if (rowIsBonus) {
                  if (this.bonusReadOnlyProps.indexOf(prop) > -1) {
                    return;
                  }
                } else if (this.readOnlyProps.indexOf(prop) > -1) {
                  return;
                }

                editedData[prop] = newValue;
                if (rowIsBonus) {
                  tracking[prop] = newValue;
                  editedData.bonuses.add(tracking.id);
                } else {
                  tracking[prop] = newValue;
                  editedData.trackings.add(tracking.id);
                }
              });

              editedData.trackings = Array.from(editedData.trackings);
              if (editedData.trackings.length > 0) {
                this.updateBulkTracking(editedData);
              }
              editedData.bonuses = Array.from(editedData.bonuses);
              if (editedData.bonuses.length > 0) {
                this.updateBulkBonus(editedData);
              }
            } else {
              // Editing project id or issue iid
              const [row, prop, oldValue, newValue] = changes[0];
              if (Number(oldValue) === Number(newValue)) return;
              if (this.getRowType(row) === "bonus") {
                return;
              }
              const tracking = this.getSheetTracking(row);
              this.hotInstance.batch(() => {
                if (prop === "gitlab_project_id") {
                  this.disableEditing();
                  // save the data
                  this.oldRowData.worked_hours = this.WorkedHours(tracking);
                  this.oldRowData.comment = tracking.comment;
                  tracking.gitlab_project_id = newValue;

                  // clear issue iid, comment, and worked hours cells
                  this.hotInstance.setDataAtCell(
                    row,
                    this.hotInstance.propToCol("gitlab_issue_iid"),
                    null,
                  );
                  this.hotInstance.setDataAtCell(row, this.hotInstance.propToCol("comment"), null);
                  this.hotInstance.setDataAtCell(
                    row,
                    this.hotInstance.propToCol("worked_hours"),
                    null,
                  );
                } else {
                  if (!newValue) {
                    return;
                  }
                  // set old data
                  if (this.oldRowData.comment && this.oldRowData.worked_hours) {
                    this.hotInstance.setDataAtCell(
                      row,
                      this.hotInstance.propToCol("worked_hours"),
                      this.oldRowData.worked_hours,
                    );
                    this.hotInstance.setDataAtCell(
                      row,
                      this.hotInstance.propToCol("comment"),
                      this.oldRowData.comment,
                    );
                  }
                  this.oldRowData = {};
                  tracking.gitlab_issue_iid = newValue;
                  this.enableEditing();
                  this.updateTracking(tracking.id, {
                    project_id: tracking.gitlab_project_id,
                    issue_iid: tracking.gitlab_issue_iid,
                  });
                }
              });
            }
          }
        },
        beforeRemoveRow: (idx, amount, rows) => {
          const ids = [];
          for (let i = 0; i < rows.length; i += 1) {
            const row = rows[i];
            if (!this.isRowBonus(row)) {
              alerts.showMessage("You can't delete trackings.", null, true);
              return false;
            }
            ids.push(this.getSheetTracking(row).id);
          }

          this.deleteBulkBonus({
            ids,
          });

          return true;
        },
      },
    };
  },
  computed: {
    hotTable() {
      return this.$refs.hotTableComponent;
    },
    hotInstance() {
      return this.hotTable?.hotInstance;
    },
    selectedHotData() {
      return this.hotInstance.getSelectedRange();
    },
    colsHeadValues() {
      const headers = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N"];
      let workedHoursHeader = null;
      let salaryHeader = null;
      let totalHeader = null;
      let hufExchangeRateHeader = null;
      for (let i = 0; i < headers.length; i += 1) {
        if (workedHoursHeader && salaryHeader && totalHeader && hufExchangeRateHeader) {
          break;
        }

        const head = headers[i];
        if (this.hotInstance.colToProp(i) === "worked_hours") {
          workedHoursHeader = head;
        } else if (this.hotInstance.colToProp(i) === "salary") {
          salaryHeader = head;
        } else if (this.hotInstance.colToProp(i) === "total") {
          totalHeader = head;
        } else if (this.hotInstance.colToProp(i) === "huf_exchange_rate") {
          hufExchangeRateHeader = head;
        }
      }

      return {
        workedHoursHeader,
        salaryHeader,
        totalHeader,
        hufExchangeRateHeader,
      };
    },
    user() {
      return this.$store.state.user;
    },
    filterUser: {
      get() {
        return this.$store.state.trackingsSheet.user;
      },
      async set(val) {
        await this.changeUser(val);
        this.loadData(true);
      },
    },
    canEdit() {
      return this.user && this.user.access_level >= 40;
    },
    projectsOptions() {
      return this.projects.map((p) => ({
        label: `${p.namespace_name} / ${this.toTitleCase(p.name)}`,
        value: p.gitlab_id,
      }));
    },
    parsedTrackings() {
      const parsedTrackings = this.trackings.map((tracking, idx) =>
        this.parseTrackingToHotData(tracking, idx, idx === 0),
      );
      const parsedBonuses = this.bonuses.map((bonus, idx) =>
        this.parseTrackingToHotData(bonus, idx, false, false),
      );

      const sheets = [];

      for (let i = 0; i < parsedTrackings.length; i += 1) {
        if (parsedBonuses.length === 0) {
          sheets.push(...parsedTrackings.slice(i));
          break;
        }

        const tracking = parsedTrackings[i];
        if (
          new Date(parsedBonuses[0].start_time).getTime() > new Date(tracking.start_time).getTime()
        ) {
          sheets.push(...parsedBonuses.splice(0, 1));
        }
        sheets.push(tracking);

        if (i === parsedTrackings.length - 1) {
          sheets.push(...parsedBonuses);
        }
      }

      return sheets;
    },
    parsedIssues() {
      return this.issues.map((i) => ({
        label: `#${i.gitlab_iid} ${i.title}`,
        value: i.gitlab_iid,
      }));
    },
    displayedUsers() {
      return this.users
        .filter((u) => u.state !== "external")
        .sort((a, b) => {
          if (a.state === b.state) {
            return b.name - a.name;
          }
          return b.state === "active" ? 1 : -1;
        });
    },
    ...mapGetters("trackingsSheet", [
      "trackings",
      "bonuses",
      "loading",
      "projects",
      "projectsLoading",
      "companyBranches",
      "companyBranchesLoading",
      "issues",
      "issuesLoading",
    ]),
    ...mapGetters("userInfo", ["users"]),
  },
  methods: {
    async initData() {
      if (this.trackings.length < 1) {
        await this.changeUser(null);
        this.loadData();
      } else {
        this.loadData(true);
      }
    },
    enableEditing() {
      this.hotInstance.updateSettings({
        readOnly: false,
      });
    },
    disableEditing() {
      this.hotInstance.updateSettings({
        readOnly: true,
      });
    },
    getColsHeads(force = false) {
      if (force) {
        const heads = this.colsHeadValues;
        this.cachedHeadsValues = heads;
        return heads;
      }

      return this.cachedHeadsValues;
    },
    async loadData(force = false) {
      if (force) {
        this.hotInstance.batch(() => {
          this.hotInstance.updateSettings({
            data: this.parsedTrackings,
          });
          if (this.canEdit) {
            this.disableEditing();
            this.enableEditing();
          }
        });
      } else {
        this.hotInstance.loadData(this.parsedTrackings);
      }
    },
    async loadIssues(row) {
      const indexes = this.hotInstance.rowIndexMapper.getIndexesSequence();
      const trackingIndex = indexes[row];
      const tracking = this.trackings[trackingIndex];

      const projectGitlabId = tracking.gitlab_project_id;
      if (this.projectId !== projectGitlabId) {
        this.projectId = projectGitlabId;
        await this.fetchIssues(projectGitlabId);
      }
      return this.parsedIssues;
    },
    async updateBulkTracking(data) {
      await this.axios.put("/trackings/bulk-update", data);
    },
    async updateBulkBonus(data) {
      await this.axios.put("/sheet-bonuses/bulk-update", data);
    },
    async deleteBulkBonus(data) {
      await this.axios.delete("/sheet-bonuses/bulk-delete", {
        params: data,
      });
    },
    async updateTracking(id, data) {
      await this.axios.put(`/trackings/${id}`, data);
    },
    onSheetModalClose(data) {
      if (data) {
        this.bonuses.unshift(data);
        this.bonuses.sort((a, b) => {
          const aDate = new Date(a.start_time).getTime();
          const bDate = new Date(b.start_time).getTime();
          if (aDate > bDate) {
            return -1;
          }
          if (aDate < bDate) {
            return 1;
          }
          return 0;
        });
        this.loadData(true);
      }
      this.showBonusAddModal = false;
    },
    getColIdxInSameRow(col) {
      return `INDEX(${col}:${col},ROW())`;
    },
    parseTrackingToHotData(tracking, idx, getCols = false, isTracking = true) {
      const { workedHoursHeader, salaryHeader, totalHeader, hufExchangeRateHeader } =
        this.getColsHeads(getCols);

      return {
        // A
        start_time: isTracking ? this.startTime(tracking) : this.toSheetTime(tracking.start_time),
        // B
        end_time: isTracking ? this.endTime(tracking) : this.toSheetTime(tracking.end_time),
        // C
        worked_hours: isTracking ? this.WorkedHours(tracking) : 1,
        // D
        salary: parseFloat(tracking.salary ?? 0),
        // E
        total: `=${this.getColIdxInSameRow(workedHoursHeader)}*${this.getColIdxInSameRow(
          salaryHeader,
        )}`,
        // F
        huf_exchange_rate: parseFloat(tracking.huf_exchange_rate ?? 0),
        // G
        huf_total: `=${this.getColIdxInSameRow(totalHeader)}*${this.getColIdxInSameRow(
          hufExchangeRateHeader,
        )}`,
        gitlab_project_id: isTracking ? tracking.gitlab_project_id : "Bonus",
        gitlab_issue_iid: isTracking ? tracking.gitlab_issue_iid : null,
        comment: tracking.comment,
        approved: tracking.approved,
        company: tracking.company,
        payment_no: tracking.payment_no,
        payment_date: tracking.payment_date
          ? DateTime.fromISO(tracking.payment_date).toISODate()
          : null,
        type: isTracking ? "tracking" : "bonus",
        index: idx,
      };
    },
    startTime(tracking) {
      const endDate = new Date(tracking.created_at).getTime();
      const startDate = endDate - this.WorkedHours(tracking) * 60 * 60 * 1000;
      return DateTime.fromMillis(startDate).toFormat("kkkk-MM-dd t");
    },
    endTime(tracking) {
      return this.toSheetTime(tracking.created_at);
    },
    toSheetTime(time) {
      return DateTime.fromISO(time).toFormat("kkkk-MM-dd t");
    },
    WorkedHours(tracking) {
      const hms = tracking.time_spent.split(":");
      const hours = Number(hms[0]);
      const minutes = Number(hms[1]);
      return (hours + minutes / 60).toFixed(2);
    },
    currencyRenderer(hotInstance, td, row, column, prop, value, cellProperties) {
      const currency = ["total", "salary"].indexOf(prop) > -1 ? "€" : "Ft";
      Handsontable.renderers.TextRenderer.apply(this, [
        hotInstance,
        td,
        row,
        column,
        prop,
        `${Number(value).toFixed(2)}${currency}`,
        cellProperties,
      ]);
    },
    selectRenderer(hotInstance, td, row, column, prop, value, cellProperties) {
      let text = "";
      if (this.isRowBonus(row) && prop === "gitlab_project_id") {
        text = value;
      } else {
        const options = cellProperties.selectOptions();
        const selectedOption = (o) => {
          if (cellProperties.type === "numeric") {
            return Number(o.value) === Number(value);
          }
          return o.value === value;
        };
        text = options.find(selectedOption)?.label;
      }

      Handsontable.renderers.TextRenderer.apply(this, [
        hotInstance,
        td,
        row,
        column,
        prop,
        text,
        cellProperties,
      ]);
    },
    getRowType(row) {
      const type = this.hotInstance.getDataAtCell(row, this.hotInstance.propToCol("type"));

      return type;
    },
    isRowBonus(row) {
      return this.getRowType(row) === "bonus";
    },
    getSheetTracking(row) {
      const idx = this.hotInstance.getDataAtCell(row, this.hotInstance.propToCol("index"));
      if (this.getRowType(row) === "bonus") {
        return this.bonuses[idx];
      }
      return this.trackings[idx];
    },
    toTitleCase(str) {
      return str.charAt(0).toUpperCase() + str.substr(1);
    },
    ...mapActions("userInfo", ["getUsers"]),
    ...mapActions("trackingsSheet", [
      "changeUser",
      "fetchProjects",
      "fetchCompanyBranches",
      "fetchIssues",
    ]),
  },
};
</script>

<style src="../../node_modules/handsontable/dist/handsontable.full.css"></style>

<style>
#user-tracking-table tr td {
  @apply whitespace-nowrap;
}
#user-tracking-table tr td.current {
  @apply whitespace-normal !important;
}
#user-tracking-table .htDimmed {
  @apply text-black;
}
#user-tracking-table th {
  @apply bg-transparent font-bold !important;
}
#user-tracking-table thead tr th {
  @apply border-b-2 !important;
}
#user-tracking-table thead tr th:nth-of-type(1),
#user-tracking-table .ht_clone_left th {
  @apply border-r-2 !important;
}
#user-tracking-table .htBorders {
  @apply absolute inset-0 !important;
}
#user-tracking-table .htSelectEditor {
  @apply p-0 !important;
}

/* hide the column menu button by default */
#user-tracking-table .changeType {
  visibility: hidden;
}

/* show the column menu button on hover and when filter active */
#user-tracking-table th .relative:hover .changeType {
  visibility: visible;
}
#user-tracking-table th.htFiltersActive .changeType {
  visibility: visible;
}
</style>

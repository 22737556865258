<template>
  <div class="y md:x items-center md:h-12">
    <input
      type="text"
      class="flex-1 h-full w-full md:mr-3 text-2xl text-center tracking-widest md:mb-0"
      :value="displayTime"
      @blur="timerInputChanged"
      :disabled="disabled"
    />
    <div class="h-2 w-full md:hidden"></div>
    <button
      :disabled="disabled"
      class="h-full flex-2 flex py-1 justify-center md:justify-start items-center w-full md:w-32 px-3 rounded-md text-white disabled:opacity-50"
      style="background: #ff6700"
      @click="isStopped ? start() : stop()"
      :class="{ 'opacity-90': !isStopped }"
    >
      <icon-start v-if="isStopped" />
      <i v-else class="isax isax-pause text-base" />
      <span
        class="align-top text-xl ml-2 md:ml-3.5"
        style="height: 24px !important; line-height: 22px !important"
      >
        {{ isStopped ? "Start" : "Stop" }}
      </span>
    </button>
  </div>
</template>

<script>
import IconStart from "../assets/icons/IconStart.vue";

export default {
  components: {
    IconStart,
  },
  props: {
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      isStopped: true,
      timerInterval: null,
      timer: {
        seconds: 0,
        minutes: 0,
        hours: 0,
      },
      date: new Date(),
    };
  },
  beforeDestroy() {
    if (this.timerInterval) clearInterval(this.timerInterval);
  },
  methods: {
    increaseTimer() {
      const now = new Date();
      Object.assign(this.timer, this.msToHMS(now - this.date));
    },
    start() {
      this.date = new Date(
        new Date().getTime() -
          this.HMSToMs(this.timer.hours, this.timer.minutes, this.timer.seconds),
      );
      this.timerInterval = setInterval(() => {
        this.increaseTimer();
        this.notifiyTimerChanged();
      }, 1000);
      this.isStopped = false;
      this.$emit("started");
    },
    stop() {
      if (this.timerInterval) clearInterval(this.timerInterval);
      this.isStopped = true;
      this.$emit("stopped");
    },
    reset() {
      this.stop();
      Object.assign(this.timer, {
        seconds: 0,
        minutes: 0,
        hours: 0,
      });
      this.date = new Date();
    },
    timerInputChanged(e) {
      this.stop();
      this.manuallySetTimer(...e.target.value.replace(/[^0-9\s]/g, "").split(" "));
    },
    manuallySetTimer(hours, minutes, seconds) {
      Object.assign(this.timer, {
        seconds: Number(seconds),
        minutes: Number(minutes),
        hours: Number(hours),
      });
      this.notifiyTimerChanged();
    },
    msToHMS(ms) {
      let seconds = ms / 1000;
      const hours = Math.floor(parseInt(seconds / 3600, 10));
      seconds %= 3600;
      const minutes = Math.floor(parseInt(seconds / 60, 10));
      seconds = Math.floor(seconds % 60);
      return { hours, minutes, seconds };
    },
    HMSToMs(h, m, s) {
      return h * 60 * 60 * 1000 + m * 60 * 1000 + s * 1000;
    },
    notifiyTimerChanged() {
      this.$emit("timerChanged", this.timer);
    },
  },
  computed: {
    displayTime() {
      const time = `${String(this.timer.hours).padStart(2, "0")}h ${String(
        this.timer.minutes,
      ).padStart(2, "0")}m ${String(this.timer.seconds).padStart(2, "0")}s`;
      return time;
    },
  },
};
</script>

<template>
  <div class="w-full h-full" @mouseover="toggleState" @mouseout="toggleState">
    <div class="w-full" v-if="showHovered">
      <slot name="on-hover" />
    </div>
    <div class="w-full" v-else>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    enabled: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      showHovered: false,
    };
  },
  methods: {
    toggleState() {
      if (this.enabled) this.showHovered = !this.showHovered;
    },
  },
};
</script>

<style></style>

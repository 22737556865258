<template>
  <div>
    <pop-up-modal :height="'auto'" :width="'auto'" @close="close" :disabled="!floating">
      <div class="w-full h-full" :class="{ 'pt-3': floating }">
        <div
          class="w-full pb-3 flex justify-between x text-black leading-5"
          :class="{ 'px-3': floating }"
        >
          <div class="y justify-between">
            <h1 class="text-lg font-bold">Work Schedule</h1>
            <p class="text-gray-500">Click a day to toggle it's status</p>
          </div>
          <div class="x gap-x-2">
            <time-zone-selector />

            <button-primary @click.native="setDefaultTime" class="h-10" v-if="onlyUser">
              Set default work time
            </button-primary>
            <button-secondary @click.native="showLogsModal = true" class="h-10" v-else>
              Logs
            </button-secondary>
            <button-secondary @click.native="$emit('showTimeline')" class="h-10">
              Show Timeline
            </button-secondary>
          </div>
        </div>
        <v-calendar
          class="custom-calendar max-w-full h-full"
          :masks="masks"
          disable-page-swipe
          is-expanded
          v-if="onlyUser"
        >
          <template v-slot:day-content="{ day }">
            <div
              class="group relative flex flex-col h-full justify-center items-center z-10 overflow-hidden"
            >
              <i
                v-if="haveFreeTime(day.date) === false && canEditDay(day.date)"
                class="isax isax-edit absolute z-tooltip top-1 right-1 text-base cursor-pointer text-transparent group-hover:text-white"
                @click="editTime(day.date)"
              />
              <div
                class="y w-full justify-center p-1 text-sm text-center text-white cursor-pointer hover:opacity-80"
                style="height: var(--day-height)"
                :style="
                  haveFreeTime(day.date) === false ? 'background: #FF6700' : 'background: #999999'
                "
                :class="{
                  'border-2 border-gray-200': isToday(day.date),
                }"
                @click="toggleDayCalendar(day)"
              >
                <b class="font-bold">{{ day.day }}</b>
                <span class="text-xs" v-for="time in getTimes(day.date, user)" :key="time.id"
                  >{{ toTimezone(time.from) }} - {{ toTimezone(time.to) }}
                </span>
              </div>
            </div>
          </template>
        </v-calendar>

        <div v-else class="overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <div class="x relative justify-between items-center px-6">
            <i
              class="isax isax-arrow-left-2 text-2xl text-gray-500 cursor-pointer"
              @click="weekOffset += -1"
            />
            <div class="relative text-center text-gray-500 my-3 font-semibold">
              {{ weekDays[0] ? weekDays[0] : "" | formatDate }} -
              {{ weekDays[weekDays.length - 1] ? weekDays[weekDays.length - 1] : "" | formatDate }}
              <date-picker
                @change="rangeChanged"
                :value="weekDays[0] ? weekDays[0] : null"
                :clearable="false"
                valueType="format"
                format="YYYY-MM-DD"
                class="disabled:bg-gray-100 absolute w-56 left-0 opacity-0"
                type="week"
                placeholder="Choose a date..."
                :lang="pickerLang"
              ></date-picker>
            </div>
            <i
              class="isax isax-arrow-right-3 text-2xl text-gray-500 cursor-pointer"
              @click="weekOffset += 1"
            />
          </div>
          <table
            class="plan-table body-scroll divide-y divide-gray-200 divide-x"
            style="border-spacing: 5px"
          >
            <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="px-2 py-3 border-transparent border-l-2 border-r-2"></th>
                <th
                  v-for="(day, index) of weekDays"
                  :key="index"
                  scope="col"
                  class="px-2 py-3 border-transparent border-l-2 border-r-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                  :class="{ 'text-brand': isToday(day) }"
                >
                  <p>{{ day | dateName }}</p>
                  <span class="font-light">{{ day | dateAndMonth }}</span>
                </th>
              </tr>
            </thead>
            <tbody
              class="bg-white divide-y divide-x divide-gray-200 scrollbar-thin scrollbar-thumb-rounded-3xl scrollbar-track-transparent scrollbar-thumb-gray-300"
              style="height: 65vh"
            >
              <tr v-for="(user, index) of users" :key="index">
                <td class="py-4 text-center text-sm font-medium text-gray-900 whitespace-pre-wrap">
                  {{ user.name }}
                </td>
                <td
                  @click="toggleDay(day, user)"
                  class="relative px-2 text-center font-bold py-4 border-l-2 border-r-2 border-white cursor-pointer hover:opacity-70 text-white"
                  :style="
                    haveFreeTime(day, user) === false
                      ? 'background: #FF6700'
                      : 'background: #999999'
                  "
                  v-for="(day, index) of weekDays"
                  :key="index"
                >
                  <i
                    v-if="haveFreeTime(day, user) === false && canEditDay(day, user)"
                    class="isax isax-edit absolute top-1 right-1 text-base cursor-pointer"
                    @click="
                      $event.stopPropagation();
                      editTime(day, user);
                    "
                  />
                  <div class="w-full h-full y">
                    <span class="text-xs" v-for="time in getTimes(day, user)" :key="time.id"
                      >{{ toTimezone(time.from) }} - {{ toTimezone(time.to) }}
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div
        class="w-full h-full x items-center justify-center absolute inset-0 bg-white z-tooltip bg-opacity-75"
        v-if="loading"
      >
        <loading />
      </div>

      <template #actionButton>
        <button-primary @click.native="close">OK</button-primary>
      </template>
    </pop-up-modal>

    <transition name="popup">
      <work-schedule-logs-modal @close="showLogsModal = false" v-if="showLogsModal" />
    </transition>

    <transition name="popup">
      <work-schedule-add-modal
        v-if="showScheduleModal"
        @close="
          (force) => {
            showScheduleModal = false;
            refreshData(force);
          }
        "
        :schedule="selectedSchedule"
      />
      <default-work-time-modal v-if="showDefaultTimeModal" @close="showDefaultTimeModal = false" />
    </transition>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import DatePicker from "vue2-datepicker";
import { mapActions, mapGetters } from "vuex";
import getColor from "number-to-color";
import ButtonPrimary from "./ButtonPrimary.vue";
import Loading from "./Loading.vue";
import PopUpModal from "./PopUpModal.vue";
import alerts from "../utils/alerts";
import WorkScheduleLogsModal from "./WorkScheduleLogsModal.vue";
import ButtonSecondary from "./ButtonSecondary.vue";
import WorkScheduleAddModal from "./WorkScheduleAddModal.vue";
import DefaultWorkTimeModal from "./DefaultWorkTimeModal.vue";
import TimeZoneSelector from "./TimeZoneSelector.vue";
import timezoneCalculator from "../compostions/useTimezoneCalculator";

export default {
  components: {
    ButtonPrimary,
    Loading,
    DatePicker,
    PopUpModal,
    WorkScheduleLogsModal,
    ButtonSecondary,
    WorkScheduleAddModal,
    DefaultWorkTimeModal,
    TimeZoneSelector,
  },
  setup() {
    const { toTimezone } = timezoneCalculator();

    return {
      toTimezone,
    };
  },
  mounted() {
    this.refreshData();
  },
  props: {
    user: {
      type: Object,
      default: () => null,
    },
    floating: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      masks: {
        weekdays: "WWW",
      },
      pickerLang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: false,
      },
      is_free: false,
      weekOffset: 0,
      showLogsModal: false,
      showScheduleModal: false,
      selectedSchedule: null,
      showDefaultTimeModal: false,
    };
  },
  methods: {
    rangeChanged(date) {
      const day = DateTime.fromISO(date);
      const today = DateTime.now();
      const offset = day.startOf("week").diff(today.startOf("week")).as("days");
      this.weekOffset = offset / 7;
    },
    close() {
      this.$emit("close");
    },
    getColorFromUser(user) {
      const color = getColor(user.id, 50);
      return `rgb(${color.r},${color.g},${color.b})`;
    },
    async refreshData(force = false) {
      await this.fetchData({ userId: this.user?.id, force });
    },
    haveFreeTime(day, user = this.$store.state.user) {
      const date = this.parseDate(day);
      const schedule = this.getScheduleByDayUser(day, user);
      if (schedule) return schedule.is_free;
      return this.getDefaultDayWorkPlan(date.toISO());
    },
    getTimes(day, user) {
      if (this.haveFreeTime(day, user)) return "";
      const schedule = this.getScheduleByDayUser(day, user);

      if (schedule && schedule.work_times.length > 0) {
        return schedule.work_times;
      }
      if (user.work_times?.length > 0) {
        return user.work_times;
      }

      return [];
    },
    getDefaultDayWorkPlan(date) {
      const day = DateTime.fromISO(date).toFormat("cccc");

      return day === "Saturday" || day === "Sunday";
    },
    canEditDay(day, user = this.$store.state.user) {
      const me = this.$store.state.user;
      const date = this.parseDate(day);
      const minDate = DateTime.now().plus({ days: 1 });

      const isMe = user.id === me.id;
      const isMaintainer = me.access_level === 40 && me.access_level > user.access_level;
      const isOwner = me.access_level === 50;
      const canChangeUsers = isMe || isMaintainer || isOwner;

      return canChangeUsers && date > minDate;
    },
    toggleDayCalendar(day) {
      const date = this.parseDate(day.date);
      this.toggleDay(date);
    },
    toggleDay(day, user = this.$store.state.user) {
      if (!this.canEditDay(day, user)) {
        this.showDayEditError(day);
        return;
      }

      const workSchedule = this.getScheduleByDayUser(day, user);
      if (workSchedule) this.editCalendarSchedule(workSchedule, !workSchedule.is_free);
      else {
        const isFree = !this.getDefaultDayWorkPlan(day);
        this.addCalendarSchedule(day, user, isFree);
      }
    },
    editTime(day, user = this.$store.state.user) {
      const d = this.parseDate(day);
      const workSchedule = this.getScheduleByDayUser(day, user);
      if (workSchedule)
        this.selectedSchedule = {
          ...workSchedule,
          user,
          from: workSchedule.work_time?.from ?? user.default_work_time?.from,
          to: workSchedule.work_time?.to ?? user.default_work_time?.to,
        };
      else
        this.selectedSchedule = {
          user,
          user_id: user.id,
          day: d.toFormat("kkkk-MM-dd"),
          is_free: false,
          from: user.default_work_time?.from,
          to: user.default_work_time?.to,
        };
      this.showScheduleModal = true;
    },
    setDefaultTime() {
      this.showDefaultTimeModal = true;
    },
    async editCalendarSchedule(schedule, isFree) {
      this.loading = true;
      try {
        const resp = await this.axios.put(`work-schedules/${schedule.id}`, { is_free: isFree });
        const scheduleData = resp.data.data;
        const idx = this.schedules.findIndex((s) => s.id === schedule.id);
        this.schedules[idx] = scheduleData;
        this.schedules = JSON.parse(JSON.stringify(this.schedules));
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    async addCalendarSchedule(day, user, isFree) {
      this.loading = true;
      try {
        const resp = await this.axios.post(`work-schedules`, {
          day: DateTime.fromISO(day).toFormat("yyyy-LL-dd"),
          is_free: isFree,
          user_id: user?.id,
        });
        const scheduleData = resp.data.data;
        this.schedules.push(scheduleData);
        this.schedules = JSON.parse(JSON.stringify(this.schedules));
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    getScheduleByDayUser(day, user) {
      const date = this.parseDate(day);
      const schedule = this.schedules.find((s) => {
        const workDate = DateTime.fromISO(s.day);
        return s.user.id === user.id && this.isTwoDatesEqual(workDate, date);
      });
      return schedule;
    },
    parseDate(date) {
      return DateTime.fromJSDate(new Date(date));
    },
    showDayEditError(day) {
      const today = DateTime.now();
      const date = DateTime.fromISO(day);
      let message = "";
      if (date < today) {
        message = "You cannot modify dates that are already passed by.";
      } else if (date > today && date < today.plus({ days: 3 })) {
        message = "You can only change your working schedule 1 day prior.";
      }
      if (message) alerts.showMessage(message, "", true);
    },
    isTwoDatesEqual(date1, date2) {
      return (
        date1.hasSame(date2, "year") && date1.hasSame(date2, "month") && date1.hasSame(date2, "day")
      );
    },
    isToday(date) {
      return this.isTwoDatesEqual(DateTime.now(), this.parseDate(date));
    },
    ...mapActions("workSchedule", ["fetchData"]),
  },
  computed: {
    onlyUser() {
      return !!this.user;
    },
    weekDays() {
      const weekdays = [];
      const today = DateTime.now();
      const firstDayOfWeek = today.startOf("week").plus({ days: this.weekOffset * 7 });
      for (let i = 0; i < 7; i += 1) {
        weekdays.push(firstDayOfWeek.plus({ days: i }).toISO());
      }
      return weekdays;
    },
    schedules: {
      get() {
        return this.$store.state.workSchedule.schedules;
      },
      set(val) {
        return this.$store.commit("workSchedule/setSchedules", val);
      },
    },
    loading: {
      get() {
        return this.$store.state.workSchedule.loading;
      },
      set(val) {
        return this.$store.commit("workSchedule/setLoading", val);
      },
    },
    ...mapGetters("workSchedule", ["users"]),
  },
  filters: {
    dateName(date) {
      return DateTime.fromISO(date).toFormat("cccc");
    },
    dateAndMonth(date) {
      return DateTime.fromISO(date).toFormat("dd/LL");
    },
  },
};
</script>

<style lang="postcss" scoped>
::-webkit-scrollbar {
  width: 0px;
}
::-webkit-scrollbar-track {
  display: none;
}

@media only screen and (min-width: 768px) {
  .custom-calendar {
    min-width: 768px;
  }
  .plan-table {
    min-width: 700px;
  }
}

/deep/ .custom-calendar.vc-container {
  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --day-width: 90px;
  --day-height: 90px;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #eaeaea;
  border-radius: 0;
  width: 100%;
  & .vc-header {
    background-color: #f1f5f8;
    padding: 10px 0;
  }
  & .vc-weeks {
    padding: 0;
  }
  & .vc-weekday {
    background-color: var(--weekday-bg);
    border-bottom: var(--weekday-border);
    border-top: var(--weekday-border);
    padding: 5px 0;
  }
  & .vc-day {
    padding: 0 5px 3px 5px;
    text-align: left;
    height: var(--day-height);
    min-width: var(--day-width);
    background-color: white;
    &.weekday-1,
    &.weekday-7 {
      background-color: #eff8ff;
    }
    &:not(.on-bottom) {
      border-bottom: var(--day-border);
      &.weekday-1 {
        border-bottom: var(--day-border-highlight);
      }
    }
    &:not(.on-right) {
      border-right: var(--day-border);
    }
  }
  & .vc-day-dots {
    margin-bottom: 5px;
  }
}
</style>

<template>
  <div class="y items-center w-full">
    <main class="p-3 s-6 max-w-screen-2xl w-full h-full">
      <!-- Breadcrumb -->
      <div class="x border-b border-gray-200 pb-2 items-center justify-center">
        <breadcrumb :links="breadcrumbLinks" class="border-none flex-1 pb-0" />
      </div>

      <div class="flex mt-3" v-if="loading">
        <div src="#" class="ssc-square rounded mr-2" style="height: 64px; width: 64px" />
        <div class="flex flex-col mt-1">
          <div class="mb-2 font-bold text-lg mr-2 ssc-square w-36 h-5 rounded-md" />
          <div class="font-bold text-lg mr-2 ssc-square w-80 h-4 rounded-md" />
        </div>
      </div>
      <tracking
        class="mt-4 mb-2"
        v-if="!loading"
        ref="timer"
        :project="project"
        @openReportModal="toggleReportModal"
      />
      <!-- Tabs -->
      <tabs-list v-if="!loading" class="mt-4">
        <tab-button
          v-for="tab in tabs"
          v-bind:key="tab.key"
          @click="changeTab(tab.key)"
          :title="tab.title"
          :active="selectedTab === tab.key"
          :loading="tab.loading && tab.loading()"
          :badge="tab.badge()"
        />
        <template #actionButtons>
          <button-secondary
            @click.native="openAssignModal"
            class="justify-end justify-self-end md:self-end mb-1 h-10"
            v-if="selectedTab === 'assignees'"
            >Add</button-secondary
          >
        </template>
      </tabs-list>

      <div v-if="!loading">
        <ul
          role="list"
          class="divide-y divide-solid"
          v-if="[`all_issues`, `opened_issues`, `closed_issues`].includes(selectedTab)"
        >
          <issue-excerpt v-for="issue in getFiltredIssues()" :key="issue.iid" :issue="issue" />
        </ul>
        <ul v-if="selectedTab === `tracks`">
          <tracking-loading-excerpt
            v-show="trackingsLoading"
            v-for="x in 5"
            v-bind:key="x"
            class="mb-3 w-full"
          />
          <tracking-excerpt
            v-show="!trackingsLoading"
            v-for="track in trackings"
            v-bind:key="track.id"
            :track="track"
            @clickedUser="toggleReportModal"
          />
        </ul>
        <ul v-if="selectedTab === `assignees`" class="y gap-y-3">
          <div v-if="loading">
            <tracking-loading-excerpt v-for="x in 5" v-bind:key="x" class="mb-3 w-full" />
          </div>
          <div
            v-show="!loading"
            v-for="user in project ? project.assignees : []"
            v-bind:key="user.id"
            class="x flex-1 items-center flex-nowrap pt-2"
          >
            <img
              class="h-10 w-10 rounded-full object-cover mr-2.5"
              :src="user.avatar"
              alt="avatar"
            />
            <div class="x w-full justify-between">
              <div class="y items-start">
                <div class="y">
                  <h1 class="text-base font-bold text-black cursor-pointer" @click="openUser(user)">
                    {{ user.name }}
                  </h1>
                  <div class="x gap-x-1">
                    {{ user.pivot.role }} -
                    <span
                      class="x items-center text-black text-xs"
                      :class="{ 'text-red-500': !userIsActive(user) }"
                    >
                      <i class="isax isax-clock mx-1 text-xs" />
                      <p class="text-xs font-normal">
                        {{ user.pivot.start_date }}
                        <span v-if="user.pivot.expires_at">- {{ user.pivot.expires_at }}</span>
                      </p>
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <button-secondary class="mr-2" @click.native="unassignUser(user)"
                  >unassign</button-secondary
                >
                <button-secondary v-if="userIsActive(user)" @click.native="inactivateUser(user)"
                  >Inactivate</button-secondary
                >
              </div>
            </div>
          </div>
        </ul>

        <no-data
          v-if="
            currentTab && (!currentTab.loading || !currentTab.loading()) && currentTab.badge() < 1
          "
        />
      </div>
      <div v-else class="divide-y divide-solid flex justify-center mt-5">
        <loading />
      </div>
    </main>
    <transition name="popup">
      <assign-user-modal
        v-if="showAssignModal"
        :project="project"
        :assignedUsers="project.assignees"
        @close="closeAssignModal"
      />
      <user-trackings-report
        v-if="showReportModal"
        :trackings="trackings"
        :user="selectedUser"
        @close="toggleReportModal"
      />
    </transition>
  </div>
</template>

<style scoped>
.number-input {
  appearance: none;
  @apply p-0 border-0 text-right;
  width: 1.75em;
}

button:disabled {
  color: #eee;
}
</style>

<script lang="ts">
import { mapGetters, mapMutations, mapState } from "vuex";
import Breadcrumb from "../components/Breadcrumb.vue";
import Loading from "../components/Loading.vue";
import Tracking from "../components/Tracking.vue";
import TrackingExcerpt from "../components/TrackingExcerpt.vue";
import IssueExcerpt from "../components/IssueExcerpt.vue";
import TrackingLoadingExcerpt from "../components/TrackingLoadingExcerpt.vue";
import TabButton from "../components/TabButton.vue";
import ButtonSecondary from "../components/ButtonSecondary.vue";
import TabsList from "../components/TabsList.vue";
import AssignUserModal from "../components/AssignUserModal.vue";
import NoData from "../components/NoData.vue";
import alerts from "../utils/alerts";
import UserTrackingsReport from "../components/UserTrackingsReport.vue";

export default {
  name: "App",
  components: {
    Breadcrumb,
    Tracking,
    Loading,
    TrackingExcerpt,
    IssueExcerpt,
    TrackingLoadingExcerpt,
    TabButton,
    ButtonSecondary,
    TabsList,
    AssignUserModal,
    NoData,
    UserTrackingsReport,
  },
  mounted() {
    if (this.$store.state.breadcrumbLinkForProject) {
      this.breadcrumbLinks = JSON.parse(JSON.stringify(this.$store.state.breadcrumbLinkForProject));
      this.$store.state.breadcrumbLinkForProject = null;
    }
    this.getProjectAndIssues();
    this.tabs = [
      {
        title: "All Issues",
        key: "all_issues",
        badge: () => this.issues.length,
      },
      {
        title: "Opened Issues",
        key: "opened_issues",
        badge: () => this.openedIssues.length,
      },
      {
        title: "Closed Issues",
        key: "closed_issues",
        badge: () => this.closedIssues.length,
      },
      {
        title: "Trackings",
        key: "tracks",
        badge: () => this.trackings.length,
        loading: () => this.trackingsLoading,
      },
      {
        title: "Assignees",
        key: "assignees",
        badge: () => (this.project ? this.project.assignees.length : 0),
        loading: () => this.loading,
      },
    ];
    const { key } = this.tabs[0];
    this.changeTab(key);
  },
  data() {
    return {
      projectPath: null,
      breadcrumbLinks: [],
      tabs: [],
      showAssignModal: false,
      showReportModal: false,
      selectedUser: null,
    };
  },
  computed: {
    ...mapState("tracking", [
      "timer",
      "issues",
      "submitting",
      "trackingsLoading",
      "selectedIssue",
      "project",
      "loading",
      "selectedTab",
    ]),
    ...mapGetters("tracking", ["trackings"]),
    closedIssues() {
      return this.issues.filter((issue) => issue.state === "closed");
    },
    openedIssues() {
      return this.issues.filter((issue) => issue.state !== "closed");
    },
    currentTab() {
      return this.tabs.find((tab) => tab.key === this.selectedTab);
    },
  },
  methods: {
    async getProjectAndIssues() {
      const projectPath = this.$route.params.project;
      if (!this.project || `${this.project.path_with_namespace}` !== projectPath) {
        await this.$store.dispatch("tracking/getProjectAndIssues", encodeURIComponent(projectPath));
      }
      this.setBreadcrumbLinks();
    },
    getFiltredIssues() {
      if (this.selectedTab === "opened_issues") return this.openedIssues;
      if (this.selectedTab === "closed_issues") return this.closedIssues;
      return this.issues;
    },
    async setBreadcrumbLinks() {
      this.breadcrumbLinks = this.$store.getters.projectBreadcrumb(
        this.project.namespace.full_path,
        this.project.name_with_namespace,
      );
      this.breadcrumbLinks.push({
        name: this.project.name,
        url: `/projects/${this.project.path_with_namespace}/track`,
      });
      const projectPath = this.breadcrumbLinks.slice(2, this.breadcrumbLinks.length);
      window.document.title = `${projectPath.map((p) => p.name).join(" > ")}  | WebOrigo Tracking`;
    },
    changeTab(tab) {
      this.$store.commit("tracking/setSelectedTab", tab);
    },
    openUser(user) {
      this.$router.push({
        name: "User",
        params: { id: user.id, user },
      });
    },
    setProjectAssigness(assignees) {
      const { project } = this;
      project.assignees = assignees;
      this.setProject(project);
    },
    openAssignModal() {
      this.showAssignModal = !this.showAssignModal;
    },
    closeAssignModal(assignees) {
      if (assignees) {
        this.setProjectAssigness(assignees);
      }
      this.showAssignModal = !this.showAssignModal;
    },
    userIsActive(user) {
      if (!user.pivot.expires_at) return true;
      return new Date(user.pivot.expires_at).getTime() > new Date().getTime();
    },
    async unassignUser(user) {
      const confirmDelete = await alerts.showConfirm({
        title: "Unassign User",
        message: `Are you sure you want to unassign this user (${user.name})?`,
      });
      if (!confirmDelete) return;
      try {
        const resp = await this.axios.delete(`/projects/${this.project.id}/user/${user.id}`);
        this.setProjectAssigness(resp.data.data);
      } catch (e) {
        //
      }
    },
    async inactivateUser(user) {
      const confirmDelete = await alerts.showConfirm({
        title: "Inactivate User",
        message: `Are you sure you want to inactivate this user (${user.name})?`,
      });
      if (!confirmDelete) return;
      try {
        const resp = await this.axios.post(
          `/projects/${this.project.id}/user/${user.id}/inactivate`,
        );
        this.setProjectAssigness(resp.data.data);
      } catch (e) {
        //
      }
    },
    toggleReportModal(user) {
      this.selectedUser = user;
      this.showReportModal = !this.showReportModal;
    },
    ...mapMutations("tracking", ["setProject"]),
  },
  async beforeRouteLeave(to, from, next) {
    window.document.title = "WebOrigo Tracking";
    if (this.timer.hours > 0 || this.timer.minutes > 0 || this.timer.seconds > 0) {
      const result = await window.confirm(
        "You have a tracking in progress, are you sure you want to leave the page?",
      );
      return next(result);
    }
    return next();
  },
};
</script>

<template>
  <div class="relative">
    <old-calendar @showTimeline="showNewCalender" v-if="showOldCalender" :floating="false" />
    <full-calendar
      class="weborigo-calendar"
      ref="fullCalendar"
      v-show="!showOldCalender"
      :options="calendarOptions"
    >
      <template v-slot:eventContent="arg">
        <p
          class="group"
          :class="{ 'text-white text-center pt-1.5': arg.event.display == 'background' }"
          v-if="
            arg.event.display == 'auto' || (arg.event.display == 'background' && !arg.event.allDay)
          "
        >
          <b v-if="arg.event.start">{{ arg.event.start | showTime }}</b>
          <b v-if="arg.event.end"> - {{ arg.event.end | showTime }}</b>
          <b> {{ arg.event.title }}</b>
          <i
            v-if="arg.event.url"
            class="isax isax-export-3 ml-1 text-xs cursor-pointer text-white"
          />
          <i
            v-if="canCreateEvent(arg.event) && arg.event.display != 'background'"
            @click.prevent="
              $event.stopPropagation();
              editEvent(arg.event);
            "
            class="isax isax-edit ml-2 text-base cursor-pointer text-transparent rounded-full p-2 group-hover:bg-orange group-hover:text-white"
          />
          <i
            v-if="canCreateEvent(arg.event) && arg.event.display != 'background'"
            @click.prevent="
              $event.stopPropagation();
              deleteEvent(arg.event);
            "
            class="isax isax-trash ml-2 text-base cursor-pointer text-transparent rounded-full p-2 group-hover:bg-orange group-hover:text-white"
          />
        </p>
      </template>
    </full-calendar>
    <div
      v-if="loading"
      class="w-full h-full x items-center justify-center absolute inset-0 bg-white z-tooltip bg-opacity-75"
    >
      <loading />
    </div>
    <transition name="popup">
      <event-add-modal
        v-if="showAddModal"
        :userId="user != null ? user.id : null"
        :event="editedEvent"
        @close="addModalClosed"
      />
    </transition>

    <transition name="popup">
      <work-schedule-add-modal
        v-if="showScheduleModal"
        @close="
          showScheduleModal = false;
          refreshData();
        "
        :schedule="selectedSchedule"
      />
      <default-work-time-modal
        v-if="showDefaultTimeModal"
        @close="
          (update) => {
            showDefaultTimeModal = false;
            update ? renderEvents() : null;
          }
        "
      />
    </transition>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import { mapActions, mapGetters } from "vuex";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import rrulePlugin from "@fullcalendar/rrule";
import getColor from "number-to-color";
import alerts from "../utils/alerts";
import utils from "../utils/utils";
import EventAddModal from "./EventAddModal.vue";
import OldCalendar from "./OldCalendar.vue";
import Loading from "./Loading.vue";
import WorkScheduleAddModal from "./WorkScheduleAddModal.vue";
import DefaultWorkTimeModal from "./DefaultWorkTimeModal.vue";
import timezoneCalculator from "../compostions/useTimezoneCalculator";

export default {
  components: {
    FullCalendar,
    EventAddModal,
    Loading,
    OldCalendar,
    WorkScheduleAddModal,
    DefaultWorkTimeModal,
  },
  setup() {
    const { toTimezone, fromTimezone, dateToTimezone, dateFromTimezone } = timezoneCalculator();

    return {
      toTimezone,
      fromTimezone,
      dateToTimezone,
      dateFromTimezone,
    };
  },
  data() {
    return {
      showOldCalender: false,
      timezoneChanged: false,
      showAddModal: false,
      editedEvent: null,
      masks: {
        weekdays: "WWW",
      },
      pickerLang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: false,
      },
      startDate: null,
      endDate: null,
      is_free: false,
      weekOffset: 0,
      showLogsModal: false,
      showScheduleModal: false,
      selectedSchedule: null,
      showDefaultTimeModal: false,
      calendarOptions: {
        height: "auto",
        plugins: [
          dayGridPlugin,
          interactionPlugin,
          timeGridPlugin,
          rrulePlugin,
          resourceTimelinePlugin,
        ],
        editable: false,
        customButtons: {
          createEvent: {
            text: "Create Event",
            click: () => {
              this.editedEvent = null;
              this.showAddModal = true;
            },
          },
          setWorkDays: {
            text: "Set default work time",
            click: () => {
              this.setDefaultTime();
            },
          },
          showOldCalender: {
            text: "month",
            click: () => {
              this.showOldCalender = true;
            },
          },
        },
        buttonText: {
          resourceTimeline: "day",
        },
        headerToolbar: this.getHeaderToolbar(),
        views: {
          resourceTimeGridFiveDay: {
            type: "resourceTimeGrid",
            duration: { days: 5 },
            buttonText: "5 days",
          },
        },
        eventClick: (event) => {
          if (event.event.url) {
            event.jsEvent.preventDefault();
            window.open(event.event.url, "_blank");
          }
        },
        datesSet: (event) => {
          const { start, end } = event;
          if (start === this.startDate && end === this.endDate) {
            return;
          }
          this.startDate = start;
          this.endDate = end;
          this.renderEvents();
        },
        dateClick: (info) => {
          if (!this.isMonthView()) {
            return;
          }
          this.toggleDay(info.dateStr, this.user);
        },
        dayCellClassNames: (arg) => {
          if (!this.isMonthView()) {
            return "";
          }
          const today = new Date();
          const end = new Date();
          end.setDate(today.getDate() + 7);
          if (this.haveFreeTime(DateTime.fromJSDate(arg.date).toFormat("yyyy-LL-dd"), this.user)) {
            return "not-work";
          }
          return "work";
        },
        initialView: this.user ? "timeGridWeek" : "resourceTimeline",
        events: [],
        resources: [],
      },
    };
  },
  mounted() {
    this.refreshData();
  },
  props: {
    user: {
      type: Object,
      default: () => null,
    },
    floating: {
      type: Boolean,
      default: () => true,
    },
  },
  methods: {
    openLink(link) {
      window.open(link, "_blank");
    },
    editEvent(ev) {
      const event = this.events.find((e) => `${e.id}` === `${ev.id}`);
      if (!event) {
        return;
      }
      this.editedEvent = event;
      this.showAddModal = true;
    },
    async deleteEvent(ev) {
      const event = this.events.find((e) => `${e.id}` === `${ev.id}`);
      if (!event) {
        return;
      }
      const confirm = await alerts.showConfirm({
        title: "Delete Event",
        message: `Are you sure you want to delete this event (${event.title}?`,
      });
      if (!confirm) return;
      this.axios.delete(`events/${ev.id}`);
      this.refreshData();
    },
    async renderEvents() {
      const start = this.startDate;
      const end = this.endDate;
      await this.fetchEvents([
        DateTime.fromJSDate(new Date(start)).toFormat("yyyy-LL-dd"),
        DateTime.fromJSDate(new Date(end)).toFormat("yyyy-LL-dd"),
        this.user?.id,
      ]);
      let loop = new Date(start);
      const backgroundEvents = [];
      this.calendarOptions.events.length = 0;
      const allTimes = [];
      while (loop <= end) {
        const date = DateTime.fromJSDate(loop);

        const users = this.user ? [this.user] : this.users;
        for (let u = 0; u < users.length; u += 1) {
          const user = users[u];
          const times = this.getTimes(date.toISO(), user);
          allTimes.push(...times);
          backgroundEvents.push({
            resourceId: user.id,
            start: date.set({ hour: 0, minute: 0 }).toISO(),
            allDay: true,
            display: "background",
            backgroundColor: "#999999",
          });
          for (let i = 0; i < times.length; i += 1) {
            const time = times[i];
            const { from, to } = time;
            const getHourTime = (t) => t.split(":");
            const [fromHour, fromMinute] = getHourTime(this.toTimezone(from));
            const [toHour, toMinute] = getHourTime(this.toTimezone(to));
            const eventStart = date.set({ hour: fromHour, minute: fromMinute }).toISO();
            const eventEnd = date.set({ hour: toHour, minute: toMinute }).toISO();

            backgroundEvents.push({
              resourceId: user.id,
              start: eventStart,
              end: eventEnd,
              display: "background",
              backgroundColor: "#FF6700",
            });
          }
        }
        const newDate = loop.setDate(loop.getDate() + 1);
        loop = new Date(newDate);
      }

      const addedEvents = this.events.map(this.eventToCalenderEvent);
      this.calendarOptions.events = backgroundEvents.concat(addedEvents);

      if (this.user && ["timeGridWeek", "timeGridDay"].includes(this.getCalenderView())) {
        document.querySelector(".weborigo-calendar.fc").style.height = "auto";
        document.querySelector(".weborigo-calendar.fc").style.minHeight = "50vh";
      } else {
        const h = window.innerHeight - 80;
        document.querySelector(".weborigo-calendar.fc").style.height = `${h}px`;
      }
      this.filterShowedHours(allTimes);
    },
    filterShowedHours(times) {
      if (!this.user) {
        return;
      }

      let els = Array.from(
        this.$refs.fullCalendar.calendar.el.querySelectorAll(".fc-timegrid-slots td[data-time]"),
      );
      for (let i = 0; i < els.length; i += 1) {
        const el = els[i];
        el.parentNode.style.display = "table-row";
      }

      if (["timeGridWeek", "timeGridDay"].includes(this.getCalenderView())) {
        // Convert a time string to minutes since midnight
        const timeToMinutes = (time) => {
          const [hours, minutes] = time.split(":").map(Number);
          return hours * 60 + minutes;
        };

        const workScheduleToMinutes = (w) => {
          const { from, to } = w;
          return [from, to].map(timeToMinutes);
        };

        const rangesInMinutes = times.map(workScheduleToMinutes);

        if (times.length < 0) {
          return;
        }

        els = els.filter((el) => {
          const t = el.getAttribute("data-time");
          return !rangesInMinutes.some(([start, end]) => {
            const timeInMinutes = timeToMinutes(t);
            return timeInMinutes >= start && timeInMinutes <= end;
          });
        });

        for (let i = 0; i < els.length; i += 1) {
          const el = els[i];
          el.parentNode.style.display = "none";
        }
      }
    },
    close() {
      this.$emit("close");
    },
    getColorFromUser(user) {
      const color = getColor(user.id, 50);
      return `rgb(${color.r},${color.g},${color.b})`;
    },
    async refreshData() {
      await this.fetchData({ userId: this.user?.id });
      this.calendarOptions.resources = this.resources;
      this.renderEvents();
    },
    eventToCalenderEvent(event) {
      const calenderEvent = {
        id: event.id,
        title: event.title,
        resourceId: event.user.id,
      };

      if (event.url) {
        Object.assign(calenderEvent, {
          url: event.url,
        });
      }
      if (event.is_recurring) {
        const [byhour, byminute] = event.from
          ? this.toTimezone(event.from)
              .split(":")
              .map((e) => parseInt(e, 0))
          : [];
        const rrule = {
          freq: event.recurring_type,
          byhour,
          byminute,
          dtstart: event.created_at ? this.dateToTimezone(event.created_at) : new Date(),
        };
        if (event.recurring_type === "daily") {
          //
        } else if (event.recurring_type === "weekly") {
          Object.assign(rrule, {
            byweekday: event.recurring_day,
          });
        } else {
          Object.assign(rrule, {
            bymonthday: event.recurring_day,
          });
        }
        let duration = null;
        if (event.from && event.to) {
          duration = utils.subtractTime(event.to, event.from);
        }
        Object.assign(calenderEvent, {
          allDay: !event.from && !event.to,
          duration,
          rrule,
        });
      } else {
        Object.assign(calenderEvent, {
          duration: "10:00",
          start: this.dateToTimezone(event.start_date),
          end: this.dateToTimezone(event.end_date),
        });
      }
      return calenderEvent;
    },
    async addModalClosed(event) {
      this.showAddModal = false;
      if (event != null) {
        this.refreshData();
      }
    },
    haveFreeTime(day, user = this.$store.state.user) {
      const date = this.parseDate(day);
      const schedule = this.getScheduleByDayUser(day, user);
      if (schedule) return schedule.is_free;
      return this.getDefaultDayWorkPlan(date.toISO());
    },
    getTimes(day, user) {
      if (this.haveFreeTime(day, user)) return [];
      const schedule = this.getScheduleByDayUser(day, user);

      if (schedule && schedule.work_times.length > 0) {
        return schedule.work_times;
      }
      if (user.work_times?.length > 0) {
        return user.work_times;
      }

      return [];
    },
    getDefaultDayWorkPlan(date) {
      const day = DateTime.fromISO(date).toFormat("cccc");

      return day === "Saturday" || day === "Sunday";
    },
    canEditDay(day, user = this.$store.state.user) {
      const me = this.$store.state.user;
      const date = this.parseDate(day);
      const minDate = DateTime.now().plus({ days: 1 });

      const isMe = user.id === me.id;
      const isMaintainer = me.access_level === 40 && me.access_level > user.access_level;
      const isOwner = me.access_level === 50;
      const canChangeUsers = isMe || isMaintainer || isOwner;

      return canChangeUsers && date > minDate;
    },
    toggleDayCalendar(day) {
      const date = this.parseDate(day.date);
      this.toggleDay(date);
    },
    async toggleDay(day, user = this.$store.state.user) {
      if (!this.canEditDay(day, user)) {
        this.showDayEditError(day);
        return;
      }

      const workSchedule = this.getScheduleByDayUser(day, user);
      if (workSchedule) await this.editCalendarSchedule(workSchedule, !workSchedule.is_free);
      else {
        const isFree = !this.getDefaultDayWorkPlan(day);
        await this.addCalendarSchedule(day, user, isFree);
      }
      this.$refs.fullCalendar.calendar.render();
    },
    editTime(day, user = this.$store.state.user) {
      const d = this.parseDate(day);
      const workSchedule = this.getScheduleByDayUser(day, user);
      if (workSchedule)
        this.selectedSchedule = {
          ...workSchedule,
          user,
          from: workSchedule.work_time?.from ?? user.default_work_time?.from,
          to: workSchedule.work_time?.to ?? user.default_work_time?.to,
        };
      else
        this.selectedSchedule = {
          user,
          user_id: user.id,
          day: d.toFormat("kkkk-MM-dd"),
          is_free: false,
          from: user.default_work_time?.from,
          to: user.default_work_time?.to,
        };
      this.showScheduleModal = true;
    },
    setDefaultTime() {
      this.showDefaultTimeModal = true;
    },
    async editCalendarSchedule(schedule, isFree) {
      this.loading = true;
      try {
        const resp = await this.axios.put(`work-schedules/${schedule.id}`, { is_free: isFree });
        const scheduleData = resp.data.data;
        const idx = this.schedules.findIndex((s) => s.id === schedule.id);
        this.schedules[idx] = scheduleData;
        this.schedules = JSON.parse(JSON.stringify(this.schedules));
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    async addCalendarSchedule(day, user, isFree) {
      this.loading = true;
      try {
        const resp = await this.axios.post(`work-schedules`, {
          day: DateTime.fromISO(day).toFormat("yyyy-LL-dd"),
          is_free: isFree,
          user_id: user?.id,
        });
        const scheduleData = resp.data.data;
        this.schedules.push(scheduleData);
        this.schedules = JSON.parse(JSON.stringify(this.schedules));
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    getScheduleByDayUser(day, user) {
      const date = this.parseDate(day);
      const schedule = this.schedules.find((s) => {
        const workDate = DateTime.fromISO(s.day);
        return s.user.id === user.id && this.isTwoDatesEqual(workDate, date);
      });
      return schedule;
    },
    parseDate(date) {
      return DateTime.fromJSDate(new Date(date));
    },
    showDayEditError(day) {
      const today = DateTime.now();
      const date = DateTime.fromISO(day);
      let message = "";
      if (date < today) {
        message = "You cannot modify dates that are already passed by.";
      } else if (date > today && date < today.plus({ days: 3 })) {
        message = "You can only change your working schedule 1 day prior.";
      }
      if (message) alerts.showMessage(message, "", true);
    },
    isTwoDatesEqual(date1, date2) {
      return (
        date1.hasSame(date2, "year") && date1.hasSame(date2, "month") && date1.hasSame(date2, "day")
      );
    },
    isToday(date) {
      return this.isTwoDatesEqual(DateTime.now(), this.parseDate(date));
    },
    canCreateEvent(ev) {
      return (
        `${this.$store.state.user.id}` === `${ev.resourceId}` ||
        this.$store.state.user.access_level >= 40
      );
    },
    getCalenderView() {
      return this.$refs.fullCalendar.calendar.currentData.viewSpec.type;
    },
    isMonthView() {
      const view = this.getCalenderView();
      return view === "dayGridMonth" || view === "resourceTimelineMonth";
    },
    showNewCalender() {
      this.showOldCalender = false;
      if (this.timezoneChanged) {
        this.renderEvents();
      }
      this.timezoneChanged = false;
    },
    getHeaderToolbar() {
      return {
        left: `prev,next today${
          `${this.$store.state.user.id}` === `${this.user?.id}` ||
          this.$store.state.user.access_level >= 40
            ? " createEvent"
            : ""
        }${`${this.$store.state.user.id}` === `${this.user?.id}` ? " setWorkDays" : ""}`,
        center: "title",
        right: this.user
          ? "dayGridMonth,timeGridWeek,timeGridDay"
          : "resourceTimeline,resourceTimelineWeek,showOldCalender",
      };
    },
    ...mapActions("workSchedule", ["fetchData"]),
    ...mapActions("calendar", ["fetchEvents"]),
  },
  computed: {
    resources() {
      return this.users.map((u) => ({
        id: u.id,
        title: u.name,
      }));
    },
    weekDays() {
      const weekdays = [];
      const today = DateTime.now();
      const firstDayOfWeek = today.startOf("week").plus({ days: this.weekOffset * 7 });
      for (let i = 0; i < 7; i += 1) {
        weekdays.push(firstDayOfWeek.plus({ days: i }).toISO());
      }
      return weekdays;
    },
    schedules: {
      get() {
        return this.$store.state.workSchedule.schedules;
      },
      set(val) {
        return this.$store.commit("workSchedule/setSchedules", val);
      },
    },
    workScheduleLoading: {
      get() {
        return this.$store.state.workSchedule.loading;
      },
      set(val) {
        return this.$store.commit("workSchedule/setLoading", val);
      },
    },
    loading: {
      get() {
        return this.$store.state.calendar.loading;
      },
      set(val) {
        return this.$store.commit("calendar/setLoading", val);
      },
    },
    ...mapGetters("workSchedule", ["users"]),
    ...mapGetters("calendar", ["events"]),
    ...mapGetters(["timezone"]),
  },
  filters: {
    showTime(date) {
      return `${String(date.getHours()).padStart(2, "0")}:${String(date.getMinutes()).padStart(
        2,
        "0",
      )}`;
    },
  },
  watch: {
    timezone() {
      this.timezoneChanged = true;
    },
    user() {
      this.calendarOptions.headerToolbar = this.getHeaderToolbar();
    },
  },
};
</script>

<style>
:root {
  --fc-button-bg-color: #ff6700;
  --fc-button-border-color: #ff6700;
  --fc-event-bg-color: #ff6700;
  --fc-event-border-color: #ff6700;
  --fc-button-active-bg-color: #cc5200;
  --fc-button-active-border-color: #cc5200;
  --fc-button-hover-bg-color: rgba(255, 103, 0, 0.8);
  --fc-button-hover-border-color: rgba(255, 103, 0, 0.8);
}
.fc .fc-button {
  border-radius: 0.375rem;
}
.fc .fc-license-message {
  display: none;
}
.fc-button-group {
  --fc-button-bg-color: #fff;
  --fc-button-border-color: #ff6700;
  --fc-button-active-bg-color: #ff6700;
  --fc-button-active-border-color: #ff6700;
}
.fc-button-group button:active:not(.fc-button-active) {
  --fc-button-text-color: #fff;
}
.fc-button:focus {
  box-shadow: none !important;
}
.fc-button-group button:not(.fc-button-active) {
  --fc-button-hover-bg-color: #efefef;
  --fc-button-hover-border-color: #ff6700;
  --fc-button-text-color: #ff6700;
}

.fc td.fc-day-today {
  border: 2px solid white;
  background-color: transparent !important;
}
.fc td.work,
.fc td.not-work {
  color: white;
  cursor: pointer;
}
.fc td.work:hover,
.fc td.not-work:hover {
  opacity: 0.7;
}
.fc td.work {
  background: #ff6700 !important;
}

.fc td.not-work {
  background: #999999 !important;
}
</style>

<template>
  <div class="x justify-between">
    <tracking-time :time="estimated" :estimate="true" />
    <tracking-time :time="spent" />
  </div>
</template>

<script>
import TrackingTime from "./TrackingTime.vue";

export default {
  components: {
    TrackingTime,
  },
  props: {
    spent: {
      type: Object,
      default: () => ({
        hours: 0,
        minutes: 0,
      }),
    },
    estimated: {
      type: Object,
      default: () => ({
        hours: 0,
        minutes: 0,
      }),
    },
  },
};
</script>

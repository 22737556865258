<template>
  <div>
    <div class="y flex-grow 2-grow pb-2">
      <WebOrigoERPNavbar
        class="h-14 md:h-16"
        :user="user"
        :userMenuItems="userMenuItems"
        @logout="logout"
      />
      <div class="x 2-grow scroll-container bg-gray-100">
        <WebOrigoERPSidebar
          :mainRoute="'/'"
          :items="sidebarItems"
          :mainIcon="'isax-convert-3d-cube'"
        >
          <template #item-3="{ item, isActive }">
            <WebOrigoERPSidebarMenuItem
              :title="item.title"
              :route="item.route"
              :isActive="isActive"
            >
              <template #icon>
                <icon-all-trackings class="weborigo-sidebar__item__container__icon" />
              </template>
            </WebOrigoERPSidebarMenuItem>
          </template>

          <template #item-6="{ item, isActive }">
            <WebOrigoERPSidebarMenuItem
              :title="item.title"
              :route="item.route"
              :isActive="isActive"
            >
              <template #icon>
                <p
                  class="isax isax-chart-1 transform rotate-90 data-icon weborigo-sidebar__item__container__icon"
                />
              </template>
            </WebOrigoERPSidebarMenuItem>
          </template>

          <template #item-9="{ item, isActive }">
            <WebOrigoERPSidebarMenuItem
              :title="item.title"
              :route="item.route"
              :isActive="isActive"
            >
              <template #icon>
                <icon-box-of-ideas class="weborigo-sidebar__item__container__icon" />
              </template>
            </WebOrigoERPSidebarMenuItem>
          </template>
        </WebOrigoERPSidebar>
        <div
          class="y overflow-y-scroll overflow-x-hidden scrollbar-thin scrollbar-thumb-gray-300 scrollbar-thumb-rounded-full scrollbar-track-transparent"
        >
          <router-view />
          <WebOrigoERPFooter class="relative bg-white md:h-28" style="margin-top: auto" />
        </div>
      </div>
      <div class="fixed top-12 right-12 w-auto h-auto z-10">
        <transition-group name="toasts" tag="ul">
          <toast
            v-for="toast of toasts"
            v-bind:key="toast.key"
            :type="toast.type"
            :message="toast.message"
          />
        </transition-group>
        <transition name="popup">
          <alert
            v-for="alert of alerts"
            v-bind:key="alert.key"
            :title="alert.title"
            :message="alert.message"
            :onConfirm="alert.onConfirm"
            :onCancel="alert.onCancel"
            :dontShow="alert.dontShow"
            :alertKey="alert.key"
          />
        </transition>
      </div>
    </div>
    <div
      class="absolute left-0 right-0 top-0 h-1/2 z-0 bg-brand"
      style="
        z-index: -1
        background-repeat: no-repeat;
        background-position-x: initial;
        background-position-y: initial;
      "
      :style="{
        'background-image': 'url(' + require('@/assets/bg-orange.jpg') + ')',
        'background-size': 'cover',
      }"
    ></div>
  </div>
</template>

<script lang="ts">
import { WebOrigoERPNavbar } from "@weborigo/erp-navbar";
import { WebOrigoERPSidebar, WebOrigoERPSidebarMenuItem } from "@weborigo/erp-sidebar";
import { WebOrigoERPFooter } from "@weborigo/erp-footer";
import Toast from "./components/Toast.vue";
import Alert from "./components/Alert.vue";
import IconAllTrackings from "./assets/icons/IconAllTrackings.vue";
import IconBoxOfIdeas from "./assets/icons/IconBoxOfIdeas.vue";

export default {
  name: "App",
  components: {
    WebOrigoERPNavbar,
    WebOrigoERPSidebar,
    WebOrigoERPSidebarMenuItem,
    WebOrigoERPFooter,
    Toast,
    Alert,
    IconAllTrackings,
    IconBoxOfIdeas,
  },
  metaInfo: {
    title: "Default App Title",
    titleTemplate: "%s | vue-meta Example App",
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/auth/login");
    },
  },
  computed: {
    toasts() {
      return this.$store.state.toasts.toasts;
    },
    alerts() {
      return this.$store.state.alerts.alerts;
    },
    user() {
      return this.$store.state.user;
    },
    userMenuItems() {
      const menu = [];

      if (this.user && this.user.state !== "external") {
        menu.push({
          text: "Dashboard",
          icon: "isax-setting-2",
          route: "/",
        });
      }

      return menu;
    },
    sidebarItems() {
      if (!this.$store.getters.isLoggedIn) {
        return [];
      }

      if (this.user.state === "external") {
        return [
          {
            title: "Projects",
            icon: "isax-folder",
            route: "/projects",
          },
          {
            title: "Gantt Chart",
            icon: "isax-chart-1",
            route: "/gantt",
          },
        ];
      }

      return [
        {
          title: "Issues & Todos",
          icon: "isax-copy-success",
          route: "/overview",
        },
        {
          title: "Projects",
          icon: "isax-folder",
          route: "/projects",
        },
        {
          title: "Tracking",
          icon: "isax-timer-start",
          route: "/tracking",
        },
        {
          title: "All Trackings",
          icon: "all-trackings",
          route: "/trackings",
        },
        {
          title: "Live Tracking",
          icon: "isax-next",
          route: "/now-tracking",
        },
        {
          title: "Colleagues",
          icon: "isax-profile-2user",
          route: "/users",
        },
        {
          title: "Thanksgiving Bonus",
          icon: "isax-heart-edit",
          route: "/thanks-giving-bonus",
        },
        {
          title: "Issue Bonus",
          icon: "isax-medal-star",
          route: "/issues-bonus",
        },
        {
          title: "Gantt Chart",
          icon: "isax-chart-1",
          route: "/gantt",
        },
        {
          title: "Box of Ideas",
          icon: "isax-box-search",
          route: "/ideas",
        },
      ];
    },
  },
};
</script>

<style>
body {
  font-size: 0.875rem;
  overflow: hidden !important;
  overflow-y: hidden !important;
}

.scroll-container {
  height: calc(100vh - 3.5rem);
}
@media (min-width: 768px) {
  .scroll-container {
    height: calc(100vh - 4rem);
  }
}

#app {
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

.data-icon {
  transform: rotate(-5deg) translateX(-5px);
}
.data-icon *:not(.path5):before {
  @apply text-current !important;
}
</style>

<template>
  <div class="y items-center w-full">
    <main class="y p-3 s-6 max-w-screen-2xl w-full h-full">
      <!-- Breadcrumb -->
      <breadcrumb :links="breadcrumbLinks" />
      <issues-list
        :issues="issues"
        :issuesLoading="issuesLoading && issuesFilters.page === 1"
        :trackings="trackings"
        :trackingsLoading="trackingsLoading && trackingsFilters.page === 1"
        :issuesBadges="issuesCount"
        :trackingsCount="trackingsCount"
        :paginationLoading="paginationLoading"
        @loadMoreIssues="getIssues"
        @loadMoreTrackings="getTrackings"
        :disableIssuesPaginations="issuesFilters.reachedEnd"
        :disableTrackingsPaginations="trackingsFilters.reachedEnd"
      />
    </main>
  </div>
</template>

<script lang="ts">
import { computed } from "@vue/composition-api";
import "vue2-datepicker/index.css";
import { mapActions, mapState } from "vuex";
import Breadcrumb from "../components/Breadcrumb.vue";
import IssuesList from "../components/IssuesList.vue";
import useLoadUserInfo from "../compostions/useLoadUserInfo";

export default {
  name: "UserIssues",
  components: {
    Breadcrumb,
    IssuesList,
  },
  setup(props, context) {
    const isUserRoute = computed(() => context.root.$route.name === "User Issues");
    const data = { isUserRoute };

    if (isUserRoute.value) {
      const { user, userLoading } = useLoadUserInfo({ context });
      Object.assign(data, {
        user,
        userLoading,
        isUserRoute,
      });
    }

    return data;
  },
  mounted() {
    if (this.isUserRoute) this.changeUser({ id: this.$route.params.id });
    this.getData();
  },
  methods: {
    getData() {
      if (this.isUserRoute) this.getUserIssues(true);
      else this.getAllIssues();
    },
    ...mapActions("userInfo", [
      "changeUser",
      "getUserIssues",
      "getAllIssues",
      "getIssues",
      "getTrackings",
    ]),
  },
  computed: {
    breadcrumbLinks() {
      if (this.isUserRoute) {
        return [
          { name: "Tracking", url: "/" },
          { name: "Users", url: "/users" },
          {
            name: this.user ? this.user.username : this.$route.params.id,
            url: `/users/${this.$route.params.id}/user`,
          },
          { name: "Issues", url: `/issues/users/${this.$route.params.id}` },
        ];
      }

      return [
        { name: "Tracking", url: "/" },
        { name: "Overview", url: "/overview" },
        { name: "Issues", url: "/overview/issues" },
      ];
    },
    paginationLoading() {
      const loading =
        (this.issuesLoading && this.issuesFilters.page > 1) ||
        (this.trackingsLoading && this.trackingsFilters.page > 1);
      return loading;
    },
    ...mapState("userInfo", [
      "issues",
      "issuesCount",
      "issuesLoading",
      "issuesFilters",
      "trackings",
      "trackingsLoading",
      "trackingsFilters",
      "trackingsCount",
    ]),
  },
};
</script>
